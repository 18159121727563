// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueBooleanFields } from './FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueBooleanWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceValueBoolean?: AcyclicFieldInstanceValueBooleanFields | null, crossWorkflowSinksFieldInstanceValueBoolean?: Array<AcyclicFieldInstanceValueBooleanFields> | null }
  & FieldInstanceValueBooleanFields
);

export const FieldInstanceValueBooleanWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueBooleanWithCrossWorkflowFields on FieldInstanceValueBoolean {
  ...FieldInstanceValueBooleanFields
  crossWorkflowSourceFieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
  crossWorkflowSinksFieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
}
    `;