// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { TeamMemberFields } from './TeamMemberFields.generated';
import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { gql } from '@apollo/client';
export type TeamFields = { __typename: 'Team', id: number, name: string, email: string, type: Types.TeamType, domain: string, createdAt: string, party: { id: number }, teamMembers: Array<TeamMemberFields>, permissions?: { canRead: boolean, canEdit: boolean, canDelete: boolean } | null };

export const TeamFields = gql`
    fragment TeamFields on Team {
  __typename
  id
  name
  email
  type
  party {
    id
  }
  domain
  teamMembers {
    ...TeamMemberFields
  }
  createdAt
  permissions {
    canRead
    canEdit
    canDelete
  }
}
    `;