// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueBooleanFields = (
  { sourceFieldInstanceValueBoolean?: AcyclicFieldInstanceValueBooleanFields | null, sinksFieldInstanceValueBoolean: Array<AcyclicFieldInstanceValueBooleanFields> }
  & FieldInstanceValueBooleanBaseFields
);

export const FieldInstanceValueBooleanFields = gql`
    fragment FieldInstanceValueBooleanFields on FieldInstanceValueBoolean {
  ...FieldInstanceValueBooleanBaseFields
  sourceFieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
  sinksFieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
}
    `;