import { useCallback, useEffect, useState } from "react";

declare global {
  // (dosipiuk): Add here any event that you want to be accessible globally.
  interface GlobalEventHandlersEventMap {
    regrelloPopoverStateChange: CustomEvent<boolean>;
  }
}

/** Use this utility instead of `new CustomEvent` to utilize type safety */
export const createCustomEvent = <T extends keyof GlobalEventHandlersEventMap>(
  type: T,
  eventInitDict: CustomEventInit<GlobalEventHandlersEventMap[T] extends CustomEvent<infer R> ? R : never>,
) => new CustomEvent(type, eventInitDict);

/**
 * This hook handles global custom event notifying MUI components that popover is open.
 * Useful to disable MUI focus traps.
 */
export function useRegrelloPopoverState() {
  const [hasOpenPopover, setHasOpenPopover] = useState(false);

  const onPopoverStateChange = useCallback((event: CustomEvent<boolean>) => {
    if (event.detail === true) {
      setHasOpenPopover(true);
    } else {
      setHasOpenPopover(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("regrelloPopoverStateChange", onPopoverStateChange);

    return () => {
      document.removeEventListener("regrelloPopoverStateChange", onPopoverStateChange);
    };
  }, [onPopoverStateChange]);

  return hasOpenPopover;
}
