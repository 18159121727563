import { RegrelloNonIdealStateWithRefresh } from "@regrello/ui-core";
import { ErrorMessage, ErrorOnLoadDescription } from "@regrello/ui-strings";
import { withProfiler } from "@sentry/react";
import { useAtom, useAtomValue } from "jotai";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { useAsync, useMount } from "react-use";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { FeatureFlagService } from "./services/FeatureFlagService";
import { isAuthenticatedAtom, isWaitingForAuth0AuthenticationAtom } from "./state/applicationState";
import { RegrelloAuthenticationProvider } from "./ui/app/authentication/RegrelloAuthenticationProvider";
import { RoutePaths } from "./ui/app/routes/consts";
import { getWorkspaceRouteTo } from "./ui/app/routes/routeCreatorUtils";
import { UnauthenticatedCredentialsErrorPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedCredentialsErrorPage";
import { UnauthenticatedEmailSentPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedEmailSentPage";
import { UnauthenticatedRequestForEmailPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedRequestForEmailPage";
import { UnauthenticatedSignInPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedSignInPage";
import { UnauthenticatedSignInSsoPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedSignInSsoPage";
import { UnauthenticatedSignUpPage } from "./ui/views/pages/auth/unauthenticatedPages/UnauthenticatedSignUpPage";
import { DeviceVerificationEmailSentPage } from "./ui/views/pages/regrelloLite/DeviceVerificationEmailSentPage";
import { RegrelloSessionStorageKey, sessionStorageSetTyped } from "./utils/getFromSessionStorage";
import { useAsyncEnvironmentRequest } from "./utils/hooks/useAsyncEnvironmentRequest";
import { useErrorLogger } from "./utils/hooks/useErrorLogger";
import { MonitorAndReportFpsPerformance } from "./utils/MonitorAndReportFpsPerformance";
import { AsyncLoaded } from "./utils/typescript/AsyncLoaded";

const LazyAppWithAuthProvider = React.lazy(() =>
  import("./AppWithAuthProvider").then((module) => ({
    default: module.AppWithAuthProvider,
  })),
);

const LazyNonLoggedInActionItemDetailPage = React.lazy(() =>
  import("./ui/views/pages/regrelloLite/NonLoggedInActionItemDetailPage").then((module) => ({
    default: module.NonLoggedInActionItemDetailPage,
  })),
);

// TODO (clewis): Use Sentry.useProfiler hook.
export const App = withProfiler(function AppFn() {
  const { logErrorMessage } = useErrorLogger();

  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const [isWaitingForAuth0Authentication, setIsWaitingForAuth0Authentication] = useAtom(
    isWaitingForAuth0AuthenticationAtom,
  );

  // If true, allow login spoofing (dev only).
  const [isLoginSpoofingEnabled, setIsLoginSpoofingEnabled] = useState(false);

  const handleLoginSpoofingChange = useCallback(() => {
    setIsLoginSpoofingEnabled(!isLoginSpoofingEnabled);
  }, [isLoginSpoofingEnabled]);

  useMount(() => {
    if (!isAuthenticated) {
      if (
        window.location.pathname !== RoutePaths.INTERNAL_GRANULAR_ACCESS &&
        window.location.pathname !== RoutePaths.LOGIN &&
        window.location.pathname !== RoutePaths.HOME
      ) {
        sessionStorageSetTyped(RegrelloSessionStorageKey.REDIRECT_URL_AFTER_LOGIN, window.location.href);
      }
    }
  });

  const asyncEnvironmentVariables = useAsyncEnvironmentRequest();

  useEffect(() => {
    if (isAuthenticated && isWaitingForAuth0Authentication) {
      setIsWaitingForAuth0Authentication(false);
    }
  }, [isAuthenticated, isWaitingForAuth0Authentication, setIsWaitingForAuth0Authentication]);

  useAsync(async () => {
    if (!AsyncLoaded.isLoaded(asyncEnvironmentVariables)) {
      return;
    }
    FeatureFlagService.registerFeatureFlagOverrides(asyncEnvironmentVariables.value.FEATURE_FLAG_OVERRIDES);
    await FeatureFlagService.initializeWithDefaultUserInNonAuthenticatedContext(
      asyncEnvironmentVariables.value.LAUNCH_DARKLY_CLIENT_ID,
    );
  }, [asyncEnvironmentVariables]);

  if (
    AsyncLoaded.isNotLoaded(asyncEnvironmentVariables) ||
    AsyncLoaded.isLoading(asyncEnvironmentVariables) ||
    !FeatureFlagService.isInitialized()
  ) {
    return null;
  }

  if (AsyncLoaded.isError(asyncEnvironmentVariables)) {
    logErrorMessage("Regrello failed to load environment configuration on application startup.", {
      message: asyncEnvironmentVariables.error?.message,
    });
    return <RegrelloNonIdealStateWithRefresh description={ErrorOnLoadDescription} title={ErrorMessage} />;
  }

  return (
    <RegrelloAuthenticationProvider
      auth0Audience={asyncEnvironmentVariables.value.AUTH0_AUDIENCE}
      auth0ClientID={asyncEnvironmentVariables.value.AUTH0_CLIENT_ID}
      auth0Domain={asyncEnvironmentVariables.value.AUTH0_DOMAIN}
      isLoginSpoofingEnabled={isLoginSpoofingEnabled}
    >
      <MonitorAndReportFpsPerformance />
      {isAuthenticated ? (
        <Suspense fallback={null}>
          <LazyAppWithAuthProvider
            enableSentryPiiCollection={asyncEnvironmentVariables.value.SENTRY_ENABLE_PII}
            launchDarklyClientId={asyncEnvironmentVariables.value.LAUNCH_DARKLY_CLIENT_ID}
          />
        </Suspense>
      ) : isWaitingForAuth0Authentication ? null : (
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
              <Route element={<UnauthenticatedCredentialsErrorPage />} path={RoutePaths.CREDENTIALS_ERROR} />
              <Route element={<UnauthenticatedSignInSsoPage />} path={RoutePaths.LOGIN_SSO} />
              <Route
                element={
                  <UnauthenticatedSignInPage
                    asyncEnvironmentVariables={asyncEnvironmentVariables}
                    isLoginSpoofingEnabled={isLoginSpoofingEnabled}
                    onLoginSpoofingChange={handleLoginSpoofingChange}
                  />
                }
                path={RoutePaths.LOGIN}
              />
              <Route element={<UnauthenticatedSignUpPage />} path={RoutePaths.SIGN_UP} />
              <Route
                element={<UnauthenticatedRequestForEmailPage mode="lostInvite" />}
                path={getWorkspaceRouteTo(RoutePaths.INVITE_REQUEST)}
              />
              <Route element={<UnauthenticatedEmailSentPage mode="lostInvite" />} path={RoutePaths.INVITE_SENT} />
              <Route
                element={<UnauthenticatedEmailSentPage mode="verificationSent" />}
                path={RoutePaths.VERIFICATION_SENT}
              />
              <Route
                element={<UnauthenticatedRequestForEmailPage mode="passwordReset" />}
                path={RoutePaths.PASSWORD_RESET_REQUEST}
              />
              <Route
                element={<UnauthenticatedEmailSentPage mode="passwordReset" />}
                path={RoutePaths.PASSWORD_RESET_SENT}
              />
              <Route
                element={
                  <Suspense fallback={null}>
                    <LazyNonLoggedInActionItemDetailPage
                      enableSentryPiiCollection={asyncEnvironmentVariables.value.SENTRY_ENABLE_PII}
                      launchDarklyClientId={asyncEnvironmentVariables.value.LAUNCH_DARKLY_CLIENT_ID}
                    />
                  </Suspense>
                }
                path={RoutePaths.SUBMISSION}
              />
              <Route element={<DeviceVerificationEmailSentPage />} path={RoutePaths.REQUEST_VERIFICATION} />

              <Route
                element={
                  <Navigate
                    replace={true}
                    to={{
                      pathname: RoutePaths.LOGIN,
                      search: window.location.search,
                    }}
                  />
                }
                path="*"
              />
            </Routes>
          </QueryParamProvider>
        </BrowserRouter>
      )}
    </RegrelloAuthenticationProvider>
  );
});
