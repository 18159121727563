// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueFloatFields } from './FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueFloatWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceValueFloat?: AcyclicFieldInstanceValueFloatFields | null, crossWorkflowSinksFieldInstanceValueFloat?: Array<AcyclicFieldInstanceValueFloatFields> | null }
  & FieldInstanceValueFloatFields
);

export const FieldInstanceValueFloatWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueFloatWithCrossWorkflowFields on FieldInstanceValueFloat {
  ...FieldInstanceValueFloatFields
  crossWorkflowSourceFieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
  crossWorkflowSinksFieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
}
    `;