// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueTimeFields = (
  { sourceFieldInstanceMultiValueTime?: AcyclicFieldInstanceMultiValueTimeFields | null, sinksFieldInstanceMultiValueTime: Array<AcyclicFieldInstanceMultiValueTimeFields> }
  & FieldInstanceMultiValueTimeBaseFields
);

export const FieldInstanceMultiValueTimeFields = gql`
    fragment FieldInstanceMultiValueTimeFields on FieldInstanceMultiValueTime {
  ...FieldInstanceMultiValueTimeBaseFields
  sourceFieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
  sinksFieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
}
    `;