import { Slot } from "@radix-ui/react-slot";
import { assertNever, clsx, mergeRefs, useSimpleFocus, WithDataTestId } from "@regrello/core-utils";
import React, { useCallback, useEffect, useRef } from "react";

import {
  getInputV2ButtonSizeForInputSize,
  getInputV2IconSizeForInputSize,
  INPUT_V2_DEFAULT_SIZE,
} from "./inputV2Utils";
import { RegrelloIntentV2 } from "../../utils/enums/RegrelloIntentV2";
import { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloButton, RegrelloButtonProps } from "../button/RegrelloButton";
import { RegrelloIcon, RegrelloIconName } from "../icons/RegrelloIcon";

export type RegrelloInputPeripheralElementProps =
  | {
      type: "icon";
      iconName: RegrelloIconName;
    }
  | {
      type: "button";
      buttonProps: Omit<RegrelloButtonProps, "size" | "variant">;
    }
  | {
      type: "interactive";
      element: JSX.Element;
    }
  | {
      type: "nonInteractive";
      element: JSX.Element;
    };

export interface RegrelloInputProps
  extends WithDataTestId,
    Omit<React.HTMLProps<HTMLInputElement>, "onChange" | "size"> {
  /**
   * Changes HTML component which should be used to display the button.
   */
  asChild?: boolean;

  /**
   * An arbitrary element to show inside the input on the end (i.e., right) side.
   * - If `"icon"` or `"nonInteractive"`, then clicking the `element` will focus the input (the
   *   padding will also be a little different).
   * - If `"button"`, then clicking the `element` or surrounding padding will _not_ focus the input.
   *   This can be used to provide a button, for example.
   * - If `"interactive"`, then you can provide any interactive element, like button wraaped in a popover.
   *
   * Prefer `icon` and `button` whenever possible. Reserve `nonInteractive` for rare custom use
   * cases like showing start or end text in an input.
   *
   * ___Warning:___ Buttons are not supported for `size="x-small"` and `size="small"`. They will
   * still render, but a console warning will be logged.
   */
  endElement?: RegrelloInputPeripheralElementProps;

  /** Whether the input should take up the full width of its parent. */
  fullWidth?: boolean;

  /**
   * The ref to the underlying input element. (Meanwhile, `ref` will be passed to a wrapping
   * `<div>` element.)
   */
  inputRef?: React.Ref<HTMLInputElement>;

  /**
   * The semantic intent of this input.
   * @default RegrelloIntentV2.NEUTRAL
   */
  intent?: Extract<RegrelloIntentV2, "neutral" | "warning" | "danger">;

  /** The callback to invoke when the input value changes. */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * The size of the input.
   * @default "large"
   */
  size?: RegrelloSize;

  /**
   * An arbitrary element to show inside the input on the start (i.e., left) side.
   * - If `"icon"` or `"nonInteractive"`, then clicking the `element` will focus the input (the
   *   padding will also be a little different).
   * - If `"button"`, then clicking the `element` or surrounding padding will _not_ focus the input.
   *   This can be used to provide a button, for example.
   * - If `"interactive"`, then you can provide any interactive element, like button wraaped in a popover.
   *
   * Prefer `icon` and `button` whenever possible. Reserve `nonInteractive` for rare custom use
   * cases like showing start or end text in an input.
   *
   * ___Warning:___ Buttons are not supported for `size="x-small"` and `size="small"`. They will
   * still render, but a console warning will be logged.
   */
  startElement?: RegrelloInputPeripheralElementProps;

  /** The `type` to aps */
  type?: "email" | "password" | "text";

  /**
   * Whether the input is the default styling or an inline editable header.
   */
  variant?: "body" | "h3" | "h5";
}

/**
 * Displays a single-line text-input field. Can show optional elements on the left and/or right
 * side within the bounds of the input (e.g., icons, extra-small buttons).
 */
export const RegrelloInput = React.memo<RegrelloInputProps>(function RegrelloInputFn({
  asChild = false,
  className,
  dataTestId,
  endElement,
  fullWidth = false,
  inputRef: propsInputRef,
  intent = "neutral",
  onBlur: propsOnBlur,
  onChange,
  onFocus: propsOnFocus,
  size = INPUT_V2_DEFAULT_SIZE,
  startElement,
  variant = "body",
  ...props
}) {
  const Comp = asChild ? Slot : "input";

  const { isFocused, onBlur, onFocus } = useSimpleFocus();
  const isDisabled = props.disabled;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const hasPeripheralButton = startElement?.type === "button" || endElement?.type === "button";
    const isSupportsPeriphalButton = size === "medium" || size === "large" || size === "x-large";
    if (hasPeripheralButton && !isSupportsPeriphalButton) {
      console.warn(
        "[RegrelloInput] Buttons are not supported in x-small or small inputs. " +
          "Still rendering it, but it won't look good.",
      );
    }
  }, [endElement?.type, size, startElement?.type]);

  const forceFocusInput = useCallback((event: React.MouseEvent<HTMLElement>) => {
    // (anthony): Don't focus if it's already focused.
    if (document.activeElement === inputRef.current) {
      return;
    }

    // (anthony): Don't interfere if the user is interacting with the input itself.
    if (event.target === inputRef.current) {
      return;
    }

    // (clewis): Prevent default to ensure that the inputRef can be focused as soon as the mouse is
    // down. Otherwise, it won't work.
    event.preventDefault();
    inputRef.current?.focus();
  }, []);

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.();
      propsOnFocus?.(event);
    },
    [onFocus, propsOnFocus],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.();
      propsOnBlur?.(event);
    },
    [onBlur, propsOnBlur],
  );

  const renderPeripheralElement = useCallback(
    (peripheralElement: NonNullable<RegrelloInputProps["startElement"]>, side: "start" | "end") => {
      switch (peripheralElement.type) {
        case "icon":
          return (
            <div className="cursor-text flex items-center">
              <RegrelloIcon
                iconName={peripheralElement.iconName}
                intent="neutral"
                size={getInputV2IconSizeForInputSize(size)}
              />
            </div>
          );
        case "button": {
          return (
            // (clewis): Stop propagation to the root div, so we don't pull focus to the text input.
            // Also, use margin instead of padding on this wrapper so clicks around the button will
            // focus the input.
            <div className={clsx("flex", side === "start" ? "ml-1" : "mr-1")} onMouseDown={(e) => e.stopPropagation()}>
              <RegrelloButton
                {...peripheralElement.buttonProps}
                disabled={isDisabled}
                size={getInputV2ButtonSizeForInputSize(size)}
                variant="ghost"
              />
            </div>
          );
        }
        case "interactive":
          return (
            <div className={side === "start" ? "ml-1" : "mr-1"} onMouseDown={(e) => e.stopPropagation()}>
              {peripheralElement.element}
            </div>
          );
        case "nonInteractive":
          return <div className="cursor-text flex items-center">{peripheralElement.element}</div>;
        default:
          assertNever(peripheralElement);
      }
    },
    [isDisabled, size],
  );

  // (clewis): The actual <input> exists within a container element, because we may need to show an
  // arbitrarily wide element before or after the input, within the borders.
  return (
    <div
      ref={props.ref}
      className={clsx(
        `
          relative
          rounded
          rg-box-border
          bg-background

          flex
          items-center

          cursor-text
        `,
        {
          "ring-inset ring-2 ring-primary-solid/100": isFocused,
          "shadow-warning-solid ring-warning-solid": intent === "warning",
          "shadow-danger-solid ring-danger-solid": intent === "danger",
          "w-full": fullWidth,
          "h-6 gap-1": size === "x-small",
          "h-7 gap-1.5": size === "small",
          "h-8 gap-1.5": size === "medium",
          "h-9 gap-1.5": size === "large",
          "h-10 gap-2": size === "x-large",
          "pointer-events-none opacity-30": isDisabled,
          [getPaddingLeftClassName(size)]: startElement?.type !== "button" && startElement?.type !== "interactive",
          [getPaddingRightClassName(size)]: endElement?.type !== "button" && endElement?.type !== "interactive",
        },
        className,
      )}
      onMouseDown={forceFocusInput}
    >
      {startElement != null && renderPeripheralElement(startElement, "start")}
      <Comp
        {...props}
        ref={mergeRefs(inputRef, propsInputRef)}
        // Note (clewis): I'd *like* our <input> to extend to the full height of the surrounding
        // border so that users could drag-to-select anywhere in the bounds of the borders, but that
        // causes weird visual artifacts with Chrome's autofill background color. We could do it by
        // applying padding and border-radius to the <input> on either side iff there is no
        // start/end element on that side, but that's more complexity that it's worth.
        className={clsx(
          `
            focus:ring-0
            focus:outline-none
            bg-transparent

            text-sm
            placeholder:text-textPlaceholder

            flex-auto
            `,
          // (clewis): Must set min-width: 0 to ensure the input will shrink if a fixed width is set
          // on the wrapper.
          "min-w-0",
          {
            "text-xs": size === "x-small",
            "text-xl": variant === "h3",
            "text-base": variant === "h5",
          },
        )}
        data-testid={dataTestId}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
      />
      {endElement != null && renderPeripheralElement(endElement, "end")}
    </div>
  );
});

function getPaddingLeftClassName(size: RegrelloSize) {
  switch (size) {
    case "x-small":
      return "pl-1";
    case "small":
      return "pl-1.5";
    case "medium":
      return "pl-2";
    case "large":
      return "pl-2.5";
    case "x-large":
      return "pl-2.5";
    default:
      assertNever(size);
  }
}

function getPaddingRightClassName(size: RegrelloSize) {
  switch (size) {
    case "x-small":
      return "pr-1";
    case "small":
      return "pr-1.5";
    case "medium":
      return "pr-2";
    case "large":
      return "pr-2.5";
    case "x-large":
      return "pr-2.5";
    default:
      assertNever(size);
  }
}
