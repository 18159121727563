// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type SpectrumFieldAllowedValueFields = { id: number, uuid: string, displayOrder: number, createdAt: string, stringValue?: string | null, intValue?: number | null, floatValue?: number | null, partyIDValue?: number | null, documentIDValue?: number | null };

export const SpectrumFieldAllowedValueFields = gql`
    fragment SpectrumFieldAllowedValueFields on SpectrumFieldAllowedValue {
  id
  uuid
  displayOrder
  createdAt
  stringValue
  intValue
  floatValue
  partyIDValue
  documentIDValue
}
    `;