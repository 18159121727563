// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsEmailUniqueQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type IsEmailUniqueQuery = { isEmailUnique: { isUnique: boolean } };


export const IsEmailUniqueQueryDocument = gql`
    query IsEmailUniqueQuery($email: String!) {
  isEmailUnique(email: $email) {
    isUnique
  }
}
    `;

/**
 * __useIsEmailUniqueQuery__
 *
 * To run a query within a React component, call `useIsEmailUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailUniqueQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailUniqueQuery(baseOptions: Apollo.QueryHookOptions<IsEmailUniqueQuery, IsEmailUniqueQueryVariables> & ({ variables: IsEmailUniqueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>(IsEmailUniqueQueryDocument, options);
      }
export function useIsEmailUniqueQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>(IsEmailUniqueQueryDocument, options);
        }
export function useIsEmailUniqueQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>(IsEmailUniqueQueryDocument, options);
        }
export type IsEmailUniqueQueryHookResult = ReturnType<typeof useIsEmailUniqueQuery>;
export type IsEmailUniqueQueryLazyQueryHookResult = ReturnType<typeof useIsEmailUniqueQueryLazyQuery>;
export type IsEmailUniqueQuerySuspenseQueryHookResult = ReturnType<typeof useIsEmailUniqueQuerySuspenseQuery>;
export type IsEmailUniqueQueryResult = Apollo.QueryResult<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>;