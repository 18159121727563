// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldFields } from '../../fragments/generated/FieldFields.generated';
import { PartyBaseFields } from '../../fragments/generated/PartyBaseFields.generated';
import { UserBaseFields } from '../../fragments/generated/UserBaseFields.generated';
import { TeamWithoutMembersFields } from '../../fragments/generated/TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from '../../fragments/generated/PropertyTypeFields.generated';
import { FieldUnitFields } from '../../fragments/generated/FieldUnitFields.generated';
import { RegrelloObjectFields } from '../../fragments/generated/RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from '../../fragments/generated/AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from '../../fragments/generated/RegrelloObjectPropertyFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FieldsQueryVariables = Types.Exact<{
  fieldId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  columnCustomizationTableSpecifier?: Types.InputMaybe<Types.ColumnCustomizationTableSpecifier>;
  fieldTypes?: Types.InputMaybe<Array<Types.InputMaybe<Types.FieldType>>>;
}>;


export type FieldsQuery = { fields: Array<FieldFields> };


export const FieldsQueryDocument = gql`
    query FieldsQuery($fieldId: ID, $columnCustomizationTableSpecifier: ColumnCustomizationTableSpecifier, $fieldTypes: [FieldType]) {
  fields(
    fieldId: $fieldId
    columnCustomizationTableSpecifier: $columnCustomizationTableSpecifier
    fieldTypes: $fieldTypes
  ) {
    ...FieldFields
  }
}
    ${FieldFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}`;

/**
 * __useFieldsQuery__
 *
 * To run a query within a React component, call `useFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      columnCustomizationTableSpecifier: // value for 'columnCustomizationTableSpecifier'
 *      fieldTypes: // value for 'fieldTypes'
 *   },
 * });
 */
export function useFieldsQuery(baseOptions?: Apollo.QueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldsQuery, FieldsQueryVariables>(FieldsQueryDocument, options);
      }
export function useFieldsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldsQuery, FieldsQueryVariables>(FieldsQueryDocument, options);
        }
export function useFieldsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FieldsQuery, FieldsQueryVariables>(FieldsQueryDocument, options);
        }
export type FieldsQueryHookResult = ReturnType<typeof useFieldsQuery>;
export type FieldsQueryLazyQueryHookResult = ReturnType<typeof useFieldsQueryLazyQuery>;
export type FieldsQuerySuspenseQueryHookResult = ReturnType<typeof useFieldsQuerySuspenseQuery>;
export type FieldsQueryResult = Apollo.QueryResult<FieldsQuery, FieldsQueryVariables>;