// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { RegrelloObjectInstanceAuditHistoryDeltaFields } from './RegrelloObjectInstanceAuditHistoryDeltaFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type RegrelloObjectInstanceAuditHistoryFields = { line: { regrelloObjectInstanceId: number, lineNumber: number }, fields: Array<RegrelloObjectInstanceAuditHistoryDeltaFields> };

export const RegrelloObjectInstanceAuditHistoryFields = gql`
    fragment RegrelloObjectInstanceAuditHistoryFields on RegrelloObjectInstanceAuditHistory {
  line {
    regrelloObjectInstanceId
    lineNumber
  }
  fields {
    ...RegrelloObjectInstanceAuditHistoryDeltaFields
  }
}
    `;