// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyFields } from './PartyFields.generated';
import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type DelegationFields = { id: number, createdAt: string, outOfOfficeEvent: { startAt: string, endAt?: string | null, party: PartyFields }, delegateValues?: { isCcdOnDelegatedTask: boolean, delegates: Array<PartyFields> } | null };

export const DelegationFields = gql`
    fragment DelegationFields on OutOfOfficeDelegationEvent {
  id
  createdAt
  outOfOfficeEvent {
    startAt
    endAt
    party {
      ...PartyFields
    }
  }
  delegateValues {
    isCcdOnDelegatedTask
    delegates {
      ...PartyFields
    }
  }
}
    `;