import { assertNever, EMPTY_STRING } from "@regrello/core-utils";
import { RegrelloButton, RegrelloInput, RegrelloLinkV2, RegrelloSpinner } from "@regrello/ui-core";
import {
  CheckYourEmail,
  ContactSupportAt,
  CopyrightTitleCased,
  DeviceVerificationAnEmailCouldNotBeSent,
  DeviceVerificationAnErrorOccurred,
  DeviceVerificationClientError,
  DeviceVerificationDontSeeAnEmail,
  DeviceVerificationEnterYourEmail,
  DeviceVerificationExampleEmail,
  DeviceVerificationInstructionsListItemOne,
  DeviceVerificationInstructionsListItemTwo,
  DeviceVerificationServerError,
  DeviceVerificationStillDidntGetIt,
  DeviceVerificationToCompleteYourWorkCTA,
  DeviceVerificationVerifyMyDevice,
  DeviceVerificationVerifyToUse,
  DeviceVerificationVerifyYourDevice,
  PrivacyPolicyTitleCased,
  SignIn,
  TryAgain,
} from "@regrello/ui-strings";
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useCounter } from "react-use";

import RegrelloLogo from "./_internal/regrelloLogoFull.svg?react";
import { REGRELLO_PRIVACY_POLICY_URL, REGRELLO_SUPPORT_EMAIL } from "../../../../constants/globalConstants";
import { RegrelloRestApiService } from "../../../../services/RegrelloRestApiService";
import { useQueryMap } from "../../../../utils/hooks/useQueryStrings";
import { RoutePaths, RouteQueryStringKeys } from "../../../app/routes/consts";

type EmailSentState = "waitingForUser" | "emailSending" | "emailSentSuccessfully" | "needsEmail" | "error";

const STATUS_BAD_REQUEST = 400;
const STATUS_NEED_TEAM_EMAIL = 432;
const STATUS_OK = 200;

/**
 * Displays a page for the user to submit their email for verification with Regrello Lite.
 *
 * (krashanoff): This component may require further changes for custom domain support at
 * a later time.
 */
export const DeviceVerificationEmailSentPage = React.memo(function DeviceVerificationEmailSentPageFn() {
  const { supplier: supplierQueryValue } = useQueryMap();
  const isSupplierMode = supplierQueryValue != null;
  const { [RouteQueryStringKeys.TOKEN]: granularAccessToken } = useQueryMap();
  const signInButtonHref = `${RoutePaths.LOGIN}/${
    isSupplierMode ? { [RouteQueryStringKeys.SUPPLIER]: supplierQueryValue } : ""
  }`;

  // If the state is not accounted for in the provided union
  const [consecutiveErrors, { inc: consecutiveErrorOccurred, reset: resetErrorCount }] = useCounter();
  const [likelyErrorReason, setLikelyErrorReason] = useState<string | null>(null);
  const [emailSentStatus, setEmailSentStatus] = useState<EmailSentState>("waitingForUser");

  const [email, setEmail] = useState<string | null>(null);

  // (zstanik): Request the workspace's personalization settings so the customized logo can be
  // rendered below.
  const [logoBase64, setLogoBase64] = useState<string | undefined>(undefined);
  const [isFruitEmailRedesignEnabled, setIsFruitEmailRedesignEnabled] = useState(false);
  const [isWorkspacePersonalizationLoading, setIsWorkspacePersonalizationLoading] = useState(true);
  useEffect(() => {
    if (!isWorkspacePersonalizationLoading) {
      return;
    }

    void RegrelloRestApiService.getWorkspacePersonalization(granularAccessToken).then((resp) => {
      if (resp.status === STATUS_OK) {
        setLogoBase64(resp.json.Logo?.value ?? undefined);
        setIsFruitEmailRedesignEnabled(resp.json.FeatureFlagContext?.IsFruitEmailRedesignEnabled ?? false);
      } else {
        setLogoBase64(undefined);
        setIsFruitEmailRedesignEnabled(false);
      }
      setIsWorkspacePersonalizationLoading(false);
    });
  }, [granularAccessToken, isWorkspacePersonalizationLoading]);

  const requestVerification = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setEmailSentStatus("emailSending");
      const { status } = await RegrelloRestApiService.requestVerificationLink(granularAccessToken, email);
      switch (status) {
        case STATUS_OK:
          resetErrorCount();
          setEmailSentStatus("emailSentSuccessfully");
          break;
        case STATUS_BAD_REQUEST:
          setLikelyErrorReason(
            "Something went wrong sending a request to our backend. Please report this to support@regrello.com.",
          );
          setEmailSentStatus("error");
          break;
        case STATUS_NEED_TEAM_EMAIL:
          resetErrorCount();
          setEmailSentStatus("needsEmail");
          break;
        default:
          consecutiveErrorOccurred(1);
          setEmailSentStatus("error");
          setLikelyErrorReason(getLikelyErrorFromStatus(status));
      }
    },
    [consecutiveErrorOccurred, email, granularAccessToken, resetErrorCount],
  );

  const waitForConfirmationContent = useMemo(
    () => (
      <>
        <h1 className="flex items-center text-4xl md:text-5xl">{DeviceVerificationVerifyYourDevice}</h1>

        <form className="flex flex-col gap-10 items-center" onSubmit={requestVerification}>
          <div>
            <p className="font-semibold text-base">{DeviceVerificationVerifyToUse}</p>
            <ul className="leading-6 list-disc ml-4 mt-4">
              <li>{DeviceVerificationInstructionsListItemOne}</li>
              <li>{DeviceVerificationInstructionsListItemTwo}</li>
            </ul>
          </div>

          {emailSentStatus === "emailSending" ? (
            <RegrelloSpinner />
          ) : (
            <RegrelloButton className="w-full p-3.5" intent="primary" type="submit">
              {DeviceVerificationVerifyMyDevice}
            </RegrelloButton>
          )}
        </form>
      </>
    ),
    [emailSentStatus, requestVerification],
  );

  const emailSentSuccessfullyContent = useMemo(
    () => (
      <>
        <h1 className="text-5xl">{CheckYourEmail}</h1>

        <div className="flex flex-col gap-10">
          <p className="leading-6">
            {DeviceVerificationToCompleteYourWorkCTA}
            <ul className="list-disc ml-4 mt-4">
              <li>{DeviceVerificationDontSeeAnEmail}</li>
              <li>{DeviceVerificationStillDidntGetIt}</li>
            </ul>
          </p>

          <div>
            {ContactSupportAt}{" "}
            <RegrelloLinkV2 className="font-normal" to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>
              {REGRELLO_SUPPORT_EMAIL}
            </RegrelloLinkV2>
          </div>
        </div>
      </>
    ),
    [],
  );

  const needsEmailContent = useMemo(
    () => (
      <>
        <h1 className="text-5xl">{DeviceVerificationVerifyYourDevice}</h1>

        <form className="flex flex-col gap-10 items-center" onSubmit={requestVerification}>
          <div>
            <p className="font-semibold text-base">{DeviceVerificationVerifyToUse}</p>
            <ul className="leading-6 list-disc ml-4 mt-4">
              <li>{DeviceVerificationInstructionsListItemOne}</li>
              <li>{DeviceVerificationInstructionsListItemTwo}</li>
            </ul>
          </div>

          <RegrelloInput
            className="w-full"
            // (krashanoff): One character username, one `@`, one character hostname. Extremely
            // unrealistic, but could happen in theory.
            minLength={3}
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder={DeviceVerificationExampleEmail}
            title={DeviceVerificationEnterYourEmail}
            value={email ?? EMPTY_STRING}
          />

          {emailSentStatus === "emailSending" ? (
            <RegrelloSpinner />
          ) : (
            <RegrelloButton className="w-full p-3.5" intent="primary" type="submit">
              {DeviceVerificationVerifyMyDevice}
            </RegrelloButton>
          )}
        </form>
      </>
    ),
    [email, emailSentStatus, requestVerification],
  );

  const errorContent = useMemo(
    () => (
      <>
        <h1 className="text-5xl">{DeviceVerificationAnErrorOccurred}</h1>

        <form className="flex flex-col gap-10" onSubmit={requestVerification}>
          <p className="leading-6">
            {DeviceVerificationAnEmailCouldNotBeSent} {likelyErrorReason}
          </p>

          {emailSentStatus === "emailSending" ? (
            <RegrelloSpinner />
          ) : (
            <RegrelloButton className="max-w-xs" intent="primary" type="submit">
              {TryAgain}
            </RegrelloButton>
          )}

          {consecutiveErrors > 2 && (
            <div>
              {ContactSupportAt}{" "}
              <RegrelloLinkV2 to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>{REGRELLO_SUPPORT_EMAIL}</RegrelloLinkV2>
            </div>
          )}
        </form>
      </>
    ),
    [consecutiveErrors, emailSentStatus, likelyErrorReason, requestVerification],
  );

  const currentContent = useMemo(() => {
    switch (emailSentStatus) {
      case "waitingForUser":
      case "emailSending":
        return waitForConfirmationContent;
      case "emailSentSuccessfully":
        return emailSentSuccessfullyContent;
      case "needsEmail":
        return needsEmailContent;
      case "error":
        return errorContent;
      default:
        assertNever(emailSentStatus);
    }
  }, [emailSentStatus, emailSentSuccessfullyContent, errorContent, needsEmailContent, waitForConfirmationContent]);

  return (
    <div className="w-full h-full flex flex-col justify-between">
      {/*
        (krashanoff): A negative margin is used here since relying on flexbox
        alone would lead to the box being technically centered, but visually askew.
        */}
      <div className="px-4 mx-auto mt-2 lg:mt-25 w-full max-w-168">
        <header className="flex justify-between items-center p-4">
          <div>
            {isWorkspacePersonalizationLoading ? (
              <RegrelloSpinner />
            ) : logoBase64 != null ? (
              <img alt="logo" className="max-w-35 max-h-10 object-contain" src={logoBase64} />
            ) : (
              <RegrelloLogo height={40} width={164} />
            )}
          </div>
          {!isFruitEmailRedesignEnabled && !isWorkspacePersonalizationLoading && (
            <RegrelloLinkV2 className="font-bold text-primary-textMuted" to={signInButtonHref}>
              {SignIn}
            </RegrelloLinkV2>
          )}
        </header>
        <hr />

        <div className="flex flex-col mt-10 gap-10">{currentContent}</div>
      </div>

      <footer className="w-full flex flex-row justify-around py-4">
        <div className="flex flex-row items-center justify-center gap-6 font-medium text-textMuted">
          <RegrelloLinkV2 className="text-textMuted" to={REGRELLO_PRIVACY_POLICY_URL}>
            {PrivacyPolicyTitleCased}
          </RegrelloLinkV2>
          <p>{CopyrightTitleCased()}</p>
        </div>
      </footer>
    </div>
  );
});

function getLikelyErrorFromStatus(status: number) {
  if (status >= 400 && status < 500) {
    return DeviceVerificationClientError;
  }
  if (status >= 500) {
    return DeviceVerificationServerError;
  }
  return null;
}
