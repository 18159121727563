import { FieldInstanceFields, FieldInstanceValueInputType } from "@regrello/graphql-api";

import { FieldInstanceBaseFields } from "../../types";
import { consoleWarnInDevelopmentModeOnly } from "../environmentUtils";

export const DEFAULT_FIELD_INSTANCE_VALUE_INPUT_TYPE: FieldInstanceValueInputType =
  FieldInstanceValueInputType.PROVIDED;

/**
 * A convenience function that returns the input type (requested, provided, etc.) for the current
 * custom-field instance. Assumes that all values on the field instance should have the same input
 * type.
 */
export function getCustomFieldInstanceInputType(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): FieldInstanceValueInputType {
  if (fieldInstance.values.length === 0) {
    return DEFAULT_FIELD_INSTANCE_VALUE_INPUT_TYPE;
  }

  const firstInputType = fieldInstance.values[0].inputType;
  const isAllSameInputType = fieldInstance.values.every((value) => value.inputType === firstInputType);

  if (!isAllSameInputType) {
    consoleWarnInDevelopmentModeOnly(
      "Some values have different inputTypes in the provided fieldInstance. " +
        "This is unexpected and probably means the the frontend sent back data incorrectly when saving values for this field instance.",
      { fieldInstance },
    );
  }

  return firstInputType;
}
