// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBlueprintImportFileMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateBlueprintImportFileMutation = { createBlueprintImportFile: { signedUrl: string, fileUuid: string } };


export const CreateBlueprintImportFileMutationDocument = gql`
    mutation CreateBlueprintImportFileMutation {
  createBlueprintImportFile {
    signedUrl
    fileUuid
  }
}
    `;
export type CreateBlueprintImportFileMutationFn = Apollo.MutationFunction<CreateBlueprintImportFileMutation, CreateBlueprintImportFileMutationVariables>;

/**
 * __useCreateBlueprintImportFileMutation__
 *
 * To run a mutation, you first call `useCreateBlueprintImportFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlueprintImportFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlueprintImportFileMutation, { data, loading, error }] = useCreateBlueprintImportFileMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateBlueprintImportFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlueprintImportFileMutation, CreateBlueprintImportFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlueprintImportFileMutation, CreateBlueprintImportFileMutationVariables>(CreateBlueprintImportFileMutationDocument, options);
      }
export type CreateBlueprintImportFileMutationHookResult = ReturnType<typeof useCreateBlueprintImportFileMutation>;
export type CreateBlueprintImportFileMutationResult = Apollo.MutationResult<CreateBlueprintImportFileMutation>;
export type CreateBlueprintImportFileMutationOptions = Apollo.BaseMutationOptions<CreateBlueprintImportFileMutation, CreateBlueprintImportFileMutationVariables>;