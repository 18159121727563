// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { gql } from '@apollo/client';
export type CustomFieldTableV2Field = (
  { workflowTemplateCounts: number, scimAttributeName?: string | null }
  & FieldFields
);

export const CustomFieldTableV2Field = gql`
    fragment CustomFieldTableV2Field on Field {
  ...FieldFields
  workflowTemplateCounts
  scimAttributeName
}
    `;