import { WithClassName } from "@regrello/core-utils";
import React from "react";

import { FlatParty } from "./utils";

export interface RegrelloPartyNameProps extends WithClassName {
  party: FlatParty;
}

/**
 * Displays a party name in a consistent way, including decorative text when necessary (e.g., an
 * "Inactive" label if the user has been deleted).
 */
export const RegrelloPartyName = React.memo(function RegrelloUserDisplayNameFn({
  className,
  party,
}: RegrelloPartyNameProps) {
  return (
    <span className={className}>
      {party.fullName}
      {party.deletedAt ? (
        // (clewis): Don't render a space unless the "Inactive" label is shown.
        <>
          {" "}
          <span className="text-textMuted">(Inactive)</span>
        </>
      ) : undefined}
    </span>
  );
});
