// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { ActionItemAuditHistoryFields } from './ActionItemAuditHistoryFields.generated';
import { ActionItemAuditHistoryDeltaFields } from './ActionItemAuditHistoryDeltaFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from './ActionItemAuditHistoryDocumentRelatedRecordFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from './ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated';
import { RootCommentFields } from './RootCommentFields.generated';
import { CommentFields } from './CommentFields.generated';
import { gql } from '@apollo/client';
export type ActionItemApprovalFields = { approvalStatus?: Types.ApprovalStatus | null, approvalActionItemTemplates: Array<{ id: number, uuid: string, assignees: Array<PartyBaseFields>, actionItems: Array<{ id: number, uuid: string, assignedBy: PartyBaseFields, createdBy: UserBaseFields, assignees: Array<PartyBaseFields>, cc: Array<PartyBaseFields>, auditHistory: Array<ActionItemAuditHistoryFields>, comments: Array<RootCommentFields> }> }>, approvalForActionItem?: { id: number, uuid: string, assignedBy: PartyBaseFields, createdBy: UserBaseFields, assignees: Array<PartyBaseFields>, cc: Array<PartyBaseFields>, approvalActionItemTemplates: Array<{ id: number, uuid: string, assignees: Array<PartyBaseFields>, actionItems: Array<{ id: number, uuid: string, assignedBy: PartyBaseFields, createdBy: UserBaseFields, assignees: Array<PartyBaseFields>, cc: Array<PartyBaseFields>, auditHistory: Array<ActionItemAuditHistoryFields>, comments: Array<RootCommentFields> }> }> } | null };

export const ActionItemApprovalFields = gql`
    fragment ActionItemApprovalFields on ActionItem {
  approvalActionItemTemplates {
    id
    uuid
    assignees {
      ...PartyBaseFields
    }
    actionItems {
      id
      uuid
      assignedBy {
        ...PartyBaseFields
      }
      createdBy {
        ...UserBaseFields
      }
      assignees {
        ...PartyBaseFields
      }
      cc {
        ...PartyBaseFields
      }
      auditHistory {
        ...ActionItemAuditHistoryFields
      }
      comments {
        ...RootCommentFields
      }
    }
  }
  approvalStatus
  approvalForActionItem {
    id
    uuid
    assignedBy {
      ...PartyBaseFields
    }
    createdBy {
      ...UserBaseFields
    }
    assignees {
      ...PartyBaseFields
    }
    cc {
      ...PartyBaseFields
    }
    approvalActionItemTemplates {
      id
      uuid
      assignees {
        ...PartyBaseFields
      }
      actionItems {
        id
        uuid
        assignedBy {
          ...PartyBaseFields
        }
        createdBy {
          ...UserBaseFields
        }
        assignees {
          ...PartyBaseFields
        }
        cc {
          ...PartyBaseFields
        }
        auditHistory {
          ...ActionItemAuditHistoryFields
        }
        comments {
          ...RootCommentFields
        }
      }
    }
  }
}
    `;