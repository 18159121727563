// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type NameTemplateFields = { stringTemplate: string, fieldIds: Array<number> };

export const NameTemplateFields = gql`
    fragment NameTemplateFields on NameTemplate {
  stringTemplate
  fieldIds
}
    `;