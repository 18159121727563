import { EMPTY_STRING } from "@regrello/core-utils";

import { RoutePaths } from "../ui/app/routes/consts";

// (dhanson): Assuming RoutePaths.WORKSPACE has a leading slash and no trailing slash, the following
// regex matches "/workspace/uuid", returning the UUID in a separate matching group.
const WORKSPACE_ROUTE_REGEX = new RegExp(`${RoutePaths.WORKSPACE}/([^/]+)`);

export function getWorkspaceUuidFromUrlPath(urlPath: string) {
  const workspacePathMatch = urlPath.match(WORKSPACE_ROUTE_REGEX);
  return workspacePathMatch?.[1];
}

/**
 * Returns the protocol of the given string if it's a valid URL. Otherwise, returns an empty string.
 */
export function getProtocol(str: string) {
  try {
    const u = new URL(str);
    return u.protocol.slice(0, -1);
  } catch (_error) {
    return EMPTY_STRING;
  }
}
