// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormSectionBaseFields } from './FormSectionBaseFields.generated';
import { PartyFields } from './PartyFields.generated';
import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { FormFieldFields } from './FormFieldFields.generated';
import { FormFieldConstraintFields } from './FormFieldConstraintFields.generated';
import { FormFieldConstraintRuleFields } from './FormFieldConstraintRuleFields.generated';
import { FormConstraintConditionGroupFields } from './FormConstraintConditionGroupFields.generated';
import { FormConstraintConditionGroupBaseFields } from './FormConstraintConditionGroupBaseFields.generated';
import { FormConstraintConditionFields } from './FormConstraintConditionFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { FormSectionConstraintFields } from './FormSectionConstraintFields.generated';
import { FormSectionConstraintRuleFields } from './FormSectionConstraintRuleFields.generated';
import { gql } from '@apollo/client';
export type FormSectionFields = (
  { formSections: Array<(
    { formSections: Array<FormSectionBaseFields> }
    & FormSectionBaseFields
  )> }
  & FormSectionBaseFields
);

export const FormSectionFields = gql`
    fragment FormSectionFields on FormSection {
  ...FormSectionBaseFields
  formSections {
    ...FormSectionBaseFields
    formSections {
      ...FormSectionBaseFields
    }
  }
}
    `;