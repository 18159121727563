// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type GenericWorkflowActionItemFields = { id: number, name: string, type: Types.ActionItemType, status: Types.WorkflowFrontendTaskStatus, dueOn?: string | null, dueOnIntervalSeconds?: number | null, isDueOnControlledByCustomField?: boolean | null, startedAt?: string | null, startAtFromTemplate?: string | null, completedOn?: string | null, startAfterActionItemTemplateID?: number | null, linkedWorkflowProps?: { blueprintPredictedDurationSeconds: number, endOn?: string | null, percentComplete: number } | null, approvalActionItems: Array<{ id: number, name: string, type: Types.ActionItemType, status: Types.WorkflowFrontendTaskStatus, dueOn?: string | null, dueOnIntervalSeconds?: number | null, startedAt?: string | null, startAtFromTemplate?: string | null, completedOn?: string | null }>, splitChildren: Array<{ id: number, name: string, type: Types.ActionItemType, status: Types.WorkflowFrontendTaskStatus, dueOn?: string | null, dueOnIntervalSeconds?: number | null, startedAt?: string | null, startAtFromTemplate?: string | null, completedOn?: string | null }> };

export const GenericWorkflowActionItemFields = gql`
    fragment GenericWorkflowActionItemFields on GenericWorkflowActionItem {
  id
  name
  type
  status
  dueOn
  dueOnIntervalSeconds
  isDueOnControlledByCustomField
  linkedWorkflowProps {
    blueprintPredictedDurationSeconds
    endOn
    percentComplete
  }
  startedAt
  startAtFromTemplate
  completedOn
  startAfterActionItemTemplateID
  approvalActionItems {
    id
    name
    type
    status
    dueOn
    dueOnIntervalSeconds
    startedAt
    startAtFromTemplate
    completedOn
  }
  splitChildren {
    id
    name
    type
    status
    dueOn
    dueOnIntervalSeconds
    startedAt
    startAtFromTemplate
    completedOn
  }
}
    `;