import { EMPTY_ARRAY } from "@regrello/core-utils";
import { useRegrelloObjectInstancesQueryLazyQuery } from "@regrello/graphql-api";

export function useInstancesQuery() {
  const [getRegrelloObjectInstancesQueryAsync, regrelloObjectInstancesQueryResult] =
    useRegrelloObjectInstancesQueryLazyQuery({ fetchPolicy: "no-cache" });
  const [getRegrelloObjectInstancesTotalCountQueryAsync, regrelloObjectInstancesQueryTotalCountResult] =
    useRegrelloObjectInstancesQueryLazyQuery({ fetchPolicy: "no-cache" });

  // Derived state from query results
  const queryResult = regrelloObjectInstancesQueryResult.loading
    ? regrelloObjectInstancesQueryResult.previousData
    : regrelloObjectInstancesQueryResult.data;
  const instances = queryResult?.regrelloObjectInstances?.regrelloObjectInstances || EMPTY_ARRAY;
  const totalCount = queryResult?.regrelloObjectInstances ? queryResult.regrelloObjectInstances.totalCount : 0;
  const hasMorePages = queryResult?.regrelloObjectInstances ? queryResult.regrelloObjectInstances.hasMore : false;

  const totalQueryResult = regrelloObjectInstancesQueryTotalCountResult.loading
    ? regrelloObjectInstancesQueryTotalCountResult.previousData
    : regrelloObjectInstancesQueryTotalCountResult.data;
  const filterTotalCount = totalQueryResult?.regrelloObjectInstances
    ? totalQueryResult.regrelloObjectInstances.totalCount
    : 0;

  return {
    hasMorePages,
    instances,
    isLoading: regrelloObjectInstancesQueryResult.loading ?? false,
    runInstancesQuery: getRegrelloObjectInstancesQueryAsync,
    runTotalCountQuery: getRegrelloObjectInstancesTotalCountQueryAsync,
    totalCount,
    filterTotalCount,
    filterTotalCountLoading: regrelloObjectInstancesQueryTotalCountResult.loading ?? false,
  };
}
