import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { FieldFields, FieldInstanceFields, PropertyDataType, SpectrumFieldVersionFields } from "@regrello/graphql-api";

import { FeatureFlagService } from "../../../../services/FeatureFlagService";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import { CheckboxFieldPlugin } from "../../customFields/plugins/CheckboxFieldPlugin";
import { CurrencyFieldPlugin } from "../../customFields/plugins/CurrencyFieldPlugin";
import { DateFieldPlugin } from "../../customFields/plugins/DateFieldPlugin";
import { DocumentFieldPlugin } from "../../customFields/plugins/DocumentFieldPlugin";
import { MultiSelectFieldPlugin } from "../../customFields/plugins/MultiSelectFieldPlugin";
import { NumberFieldPlugin } from "../../customFields/plugins/NumberFieldPlugin";
import { RegrelloObjectFieldPlugin } from "../../customFields/plugins/RegrelloObjectFieldPlugin";
import { SelectFieldPlugin } from "../../customFields/plugins/SelectFieldPlugin";
import { SignatureFieldPlugin } from "../../customFields/plugins/SignatureFieldPlugin";
import { TextFieldPlugin } from "../../customFields/plugins/TextFieldPlugin";
import { UserFieldPlugin } from "../../customFields/plugins/UserFieldPlugin";
import { SpectrumCheckboxFieldPluginDecorator } from "../SpectrumCheckboxFieldPluginDecorator";
import { SpectrumCurrencyFieldPluginDecorator } from "../SpectrumCurrencyFieldPluginDecorator";
import { SpectrumDateFieldPluginDecorator } from "../SpectrumDateFieldPluginDecorator";
import { SpectrumDocumentFieldPluginDecorator } from "../SpectrumDocumentFieldPluginDecorator";
import { SpectrumEmailFieldPluginDecorator } from "../SpectrumEmailFieldPluginDecorator";
import { SpectrumMultiselectFieldPluginDecorator } from "../SpectrumMultiselectFieldPluginDecorator";
import { SpectrumNumberFieldPluginDecorator } from "../SpectrumNumberFieldPluginDecorator";
import { SpectrumObjectFieldPluginDecorator } from "../SpectrumObjectFieldPluginDecorator";
import { SpectrumPhoneFieldPluginDecorator } from "../SpectrumPhoneFieldPluginDecorator";
import { SpectrumSelectFieldPluginDecorator } from "../SpectrumSelectFieldPluginDecorator";
import { SpectrumSignatureFieldPluginDecorator } from "../SpectrumSignatureFieldPluginDecorator";
import { SpectrumTextFieldPluginDecorator } from "../SpectrumTextFieldPluginDecorator";
import { SpectrumUserFieldPluginDecorator } from "../SpectrumUserFieldPluginDecorator";
import { SpectrumFieldPluginDecorator } from "../types/SpectrumFieldPluginDecorator";

export const SpectrumFieldPluginRegistrar = {
  getAllPlugins(): Array<SpectrumFieldPluginDecorator<unknown>> {
    if (
      FeatureFlagService.isEnabled(FeatureFlagKey.SPECTRUM_2023_08) ||
      FeatureFlagService.isEnabled(FeatureFlagKey.APP_GENERATION_2023_12)
    ) {
      return [
        new SpectrumCheckboxFieldPluginDecorator(CheckboxFieldPlugin),
        // new SpectrumCountryFieldPluginDecorator(SelectFieldPlugin),
        new SpectrumCurrencyFieldPluginDecorator(CurrencyFieldPlugin),
        new SpectrumDateFieldPluginDecorator(DateFieldPlugin),
        new SpectrumDocumentFieldPluginDecorator(DocumentFieldPlugin),
        new SpectrumEmailFieldPluginDecorator(TextFieldPlugin),
        new SpectrumMultiselectFieldPluginDecorator(MultiSelectFieldPlugin),
        new SpectrumNumberFieldPluginDecorator(NumberFieldPlugin),
        new SpectrumObjectFieldPluginDecorator(RegrelloObjectFieldPlugin),
        new SpectrumPhoneFieldPluginDecorator(TextFieldPlugin),
        new SpectrumSelectFieldPluginDecorator(SelectFieldPlugin),
        new SpectrumTextFieldPluginDecorator(TextFieldPlugin),
        new SpectrumUserFieldPluginDecorator(UserFieldPlugin),
        new SpectrumSignatureFieldPluginDecorator(SignatureFieldPlugin),
      ];
    }
    return [];
  },

  /**
   * Returns the one spectrum-field plugin that is compatible with the provided field.
   *
   * @throws if no compatible spectrum-field plugin is found, or if multiple are found
   */
  getPluginForField(field: FieldFields): SpectrumFieldPluginDecorator<unknown> {
    const matches: Array<SpectrumFieldPluginDecorator<unknown>> = [];

    for (const spectrumFieldPlugin of SpectrumFieldPluginRegistrar.getAllPlugins()) {
      if (spectrumFieldPlugin.canProcessField(field)) {
        matches.push(spectrumFieldPlugin);
      }
    }

    if (matches.length === 0) {
      throw new Error(
        getErrorMessageWithPayload(
          "Could not find a spectrum-field plugin that was able to process the current field. " +
            "This is a developer error. " +
            "Please review the `canProcessField` logic in all spectrum-field plugins.",
          { field },
        ),
      );
    }

    if (matches.length > 1) {
      throw new Error(
        getErrorMessageWithPayload(
          "Multiple spectrum-field plugins claimed to be able to process the current field. " +
            "Only one spectrum-field plugin should be able to process any given field. " +
            "This is a developer error. " +
            "Please review the `canProcessField` logic in the spectrum-field plugins mentioned here.",
          {
            field,
            ostensiblyCompatibleSpectrumFieldPluginUris: matches.map(({ uri }) => uri),
          },
        ),
      );
    }

    return matches[0];
  },

  /**
   * Returns the one spectrum-field plugin that is compatible with the provided field instance.
   *
   * @throws if no compatible spectrum-field plugin is found, or if multiple are found
   */
  getPluginForFieldInstance(fieldInstance: FieldInstanceFields): SpectrumFieldPluginDecorator<unknown> {
    const matches: Array<SpectrumFieldPluginDecorator<unknown>> = [];

    for (const spectrumFieldPlugin of SpectrumFieldPluginRegistrar.getAllPlugins()) {
      if (spectrumFieldPlugin.canProcessFieldInstance(fieldInstance)) {
        matches.push(spectrumFieldPlugin);
      }
    }

    if (matches.length === 0) {
      throw new Error(
        getErrorMessageWithPayload(
          "Could not find a spectrum-field plugin that was able to process the current field instance. " +
            "This is a developer error. " +
            "Please review the `canProcessFieldInstance` logic in all spectrum-field plugins.",
          { fieldInstance },
        ),
      );
    }

    if (matches.length > 1) {
      throw new Error(
        getErrorMessageWithPayload(
          "Multiple spectrum-field plugins claimed to be able to process the current field instance. " +
            "Only one spectrum-field plugin should be able to process any given field instance. " +
            "This is a developer error. " +
            "Please review the `canProcessFieldInstance` logic in the spectrum-field plugins mentioned here.",
          {
            fieldInstance,
            ostensiblyCompatibleSpectrumFieldPluginUris: matches.map(({ uri }) => uri),
          },
        ),
      );
    }

    return matches[0];
  },

  /**
   * Returns the one spectrum-field plugin that is compatible with the provided field instance.
   *
   * @throws if no compatible spectrum-field plugin is found, or if multiple are found
   */
  getPluginForSpectrumField(spectrumField: SpectrumFieldVersionFields): SpectrumFieldPluginDecorator<unknown> {
    const matches: Array<SpectrumFieldPluginDecorator<unknown>> = [];

    for (const spectrumFieldPlugin of SpectrumFieldPluginRegistrar.getAllPlugins()) {
      if (spectrumFieldPlugin.canProcessSpectrumField(spectrumField)) {
        matches.push(spectrumFieldPlugin);
      }
    }

    if (matches.length === 0) {
      throw new Error(
        "Could not find a spectrum-field plugin that was able to process the current field. " +
          "This is a developer error. " +
          "Please review the `canProcessSpectrumField` logic in all spectrum-field plugins." +
          JSON.stringify(spectrumField, undefined, 2),
      );
    }

    if (matches.length > 1) {
      throw new Error(
        getErrorMessageWithPayload(
          "Multiple spectrum-field plugins claimed to be able to process the current field. " +
            "Only one spectrum-field plugin should be able to process any given field. " +
            "This is a developer error. " +
            "Please review the `canProcessSpectrumField` logic in the spectrum-field plugins mentioned here.",
          {
            spectrumField,
            ostensiblyCompatibleSpectrumFieldPluginUris: matches.map(({ uri }) => uri),
          },
        ),
      );
    }

    return matches[0];
  },

  /**
   * Returns an array of spectrum-field plugins that are compatible with the provided
   * `PropertyDataType`. This is useful for determining which select field options (or form fields
   * in general) should be shown to users without having a `FieldInstanceFields` handy for providing
   * to the `getPluginForFieldInstance` function.
   *
   * It may also return an empty array.
   */
  getPluginsForPropertyDataType(propertyDataType: PropertyDataType): Array<SpectrumFieldPluginDecorator<unknown>> {
    const matches: Array<SpectrumFieldPluginDecorator<unknown>> = [];

    for (const spectrumFieldPlugin of SpectrumFieldPluginRegistrar.getAllPlugins()) {
      if (spectrumFieldPlugin.canProcessPropertyDataType(propertyDataType)) {
        matches.push(spectrumFieldPlugin);
      }
    }

    return matches;
  },
};
