import { RegrelloButton, RegrelloButtonProps } from "@regrello/ui-core";
import React, { useCallback } from "react";

export type RegrelloDataGridCellButtonProps = RegrelloButtonProps;

/**
 * Standard `RegrelloButton` wrapper to use in `RegrelloDataGrid` in order to avoid row-level
 * keyboard interactions interferring with button keyboard interactions. Use this instead of
 * `RegrelloButton` within `RegrelloDataGrid`.
 */
export const RegrelloDataGridCellButton = React.memo<RegrelloDataGridCellButtonProps>(
  function RegrelloDataGridCellButtonFn({
    onClick: onClickOriginal,
    ...regrelloButtonProps
  }: RegrelloDataGridCellButtonProps) {
    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === " " || event.key === "Enter") {
        event.stopPropagation();
        event.currentTarget.click();
      }
    }, []);

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        // (apalazzetti): Prevent bubbling the click event up to the row.
        event.stopPropagation();
        onClickOriginal?.(event);
      },
      [onClickOriginal],
    );

    return (
      <div onKeyDown={handleKeyDown}>
        <RegrelloButton {...regrelloButtonProps} onClick={handleClick} />
      </div>
    );
  },
);
