// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type AccessRoleFields = { id: number, name: Types.AccessRoleName, displayName: string, scope: Types.AccessRoleScope, userScope?: Types.AccessRoleUserScope | null, level: number };

export const AccessRoleFields = gql`
    fragment AccessRoleFields on AccessRole {
  id
  name
  displayName
  scope
  userScope
  level
}
    `;