import { browserTracingIntegration, replayIntegration, init as sentryInit } from "@sentry/react";
import { useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useAsync } from "react-use";

import { SENTRY_RELEASE } from "../../constants/globalConstants";
import { RegrelloRestApiService } from "../../services/RegrelloRestApiService";
import {
  boxClientIdAtom,
  docusignDeveloperClientIdAtom,
  docusignProductionClientIdAtom,
  isPendoEnabledAtom,
  isPrivacyAcknowledgementBannerEnabledAtom,
  privacyAcknowledgementBannerLinkAtom,
} from "../../state/applicationState";
import { consoleWarnInDevelopmentModeOnly } from "../../utils/environmentUtils";
import { AsyncLoaded } from "../typescript/AsyncLoaded";

export type RegrelloEnvironmentVariables = {
  AUTH0_AUDIENCE: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  BOX_CLIENT_ID: string;
  DOCUSIGN_DEVELOPER_CLIENT_ID: string;
  DOCUSIGN_PRODUCTION_CLIENT_ID: string;
  ENVIRONMENT: string;
  FEATURE_FLAG_OVERRIDES: Record<string, boolean>;
  LAUNCH_DARKLY_CLIENT_ID: string;
  SENTRY_DSN: string;
  SENTRY_SAMPLE_RATE: string;
  SENTRY_REPLAY_SESSION_SAMPLE_RATE: string;
  SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE: string;
  SENTRY_REPLAY_ENABLE_MASKING: boolean;
  SENTRY_ENABLE_PII: boolean;
  FORCE_PROVIDER_NAME: string | undefined;
  ENABLE_PRIVACY_ACKNOWLEDGEMENT_BANNER: boolean;
  PRIVACY_ACKNOWLEDGEMENT_BANNER_LINK: string;
  PENDO_ENABLED: boolean;
};

/**
 * Hook intended to be called by the top-level application on app mount, to load the environment
 * variables for the current environment. (We load environment variables on app load so we don't
 * have to do separate builds for review, stageing, and production.). This hook should only be
 * called at the top most level of the app.
 */
export function useAsyncEnvironmentRequest(): AsyncLoaded<RegrelloEnvironmentVariables> {
  const setBoxClientId = useSetAtom(boxClientIdAtom);
  const setDocusignDeveloperClientId = useSetAtom(docusignDeveloperClientIdAtom);
  const setDocusignProductionClientId = useSetAtom(docusignProductionClientIdAtom);
  const setEnablePrivacyAcknowledgementBannerAtom = useSetAtom(isPrivacyAcknowledgementBannerEnabledAtom);
  const setPrivacyAcknowledgementBannerLinkAtom = useSetAtom(privacyAcknowledgementBannerLinkAtom);
  const setIsPendoEnabledAtom = useSetAtom(isPendoEnabledAtom);
  const [isSentryInitialized, setIsSentryInitialized] = useState(false);

  const asyncEnvironmentConfig = useAsync(async () => {
    const json = await RegrelloRestApiService.getEnvironment();

    const {
      Auth0Audience: AUTH0_AUDIENCE,
      Auth0ClientId: AUTH0_CLIENT_ID,
      Auth0Domain: AUTH0_DOMAIN,
      BoxClientId: BOX_CLIENT_ID,
      Environment: ENVIRONMENT,
      DocusignDeveloperAccountIntegrationKey: DOCUSIGN_DEVELOPER_CLIENT_ID,
      DocusignProductionAccountIntegrationKey: DOCUSIGN_PRODUCTION_CLIENT_ID,
      LaunchDarklyClientId: LAUNCH_DARKLY_CLIENT_ID,
      ReactAppSentryDSN: SENTRY_DSN,
      SentrySampleRate: SENTRY_SAMPLE_RATE,
      SentryReplayOnErrorSampleRate: SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
      SentryReplaySessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
      SentryReplayEnableMasking: SENTRY_REPLAY_ENABLE_MASKING,
      SentryEnablePII: SENTRY_ENABLE_PII,
      FeatureFlagOverrides: FEATURE_FLAG_OVERRIDES,
      ForceProviderName: FORCE_PROVIDER_NAME,
      EnablePrivacyAcknowledgementBanner: ENABLE_PRIVACY_ACKNOWLEDGEMENT_BANNER,
      PrivacyAcknowledgementBannerLink: PRIVACY_ACKNOWLEDGEMENT_BANNER_LINK,
      IsPendoEnabled: PENDO_ENABLED,
    } = json;

    return {
      AUTH0_AUDIENCE,
      AUTH0_CLIENT_ID,
      AUTH0_DOMAIN,
      BOX_CLIENT_ID,
      DOCUSIGN_DEVELOPER_CLIENT_ID,
      DOCUSIGN_PRODUCTION_CLIENT_ID,
      ENVIRONMENT,
      FEATURE_FLAG_OVERRIDES,
      LAUNCH_DARKLY_CLIENT_ID,
      SENTRY_DSN,
      SENTRY_SAMPLE_RATE,
      SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
      SENTRY_REPLAY_SESSION_SAMPLE_RATE,
      SENTRY_REPLAY_ENABLE_MASKING,
      SENTRY_ENABLE_PII,
      FORCE_PROVIDER_NAME,
      ENABLE_PRIVACY_ACKNOWLEDGEMENT_BANNER,
      PRIVACY_ACKNOWLEDGEMENT_BANNER_LINK,
      PENDO_ENABLED,
    };
  }, []);

  const asyncResult: AsyncLoaded<RegrelloEnvironmentVariables> = useMemo(() => {
    if (
      !asyncEnvironmentConfig.loading &&
      asyncEnvironmentConfig.value == null &&
      asyncEnvironmentConfig.error == null
    ) {
      return AsyncLoaded.notLoaded();
    }

    if (asyncEnvironmentConfig.loading) {
      return AsyncLoaded.loading();
    }

    if (asyncEnvironmentConfig.error != null) {
      return AsyncLoaded.error(asyncEnvironmentConfig.error);
    }

    // (clewis): The types for useAsync are incorrect. If the request succeeded, the value will
    // definitely be present.
    //
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return AsyncLoaded.loaded(asyncEnvironmentConfig.value!);
  }, [asyncEnvironmentConfig]);

  useEffect(() => {
    if (!AsyncLoaded.isLoaded(asyncResult)) {
      return;
    }

    const unsetValues = Object.entries(asyncResult.value).filter(([, value]) => !isEnvironmentVariablePresent(value));
    if (unsetValues.length > 0) {
      const unsetKeys = unsetValues.map(([key]) => key);
      consoleWarnInDevelopmentModeOnly(
        `[Developer warning] The following environment variables are not set: ${unsetKeys.join(",")}. ` +
          `Please ensure that all environment variables have a value and that you've loaded environment variables properly before running Regrello.`,
      );
    }

    setBoxClientId(asyncResult.value.BOX_CLIENT_ID);
    setDocusignDeveloperClientId(asyncResult.value.DOCUSIGN_DEVELOPER_CLIENT_ID);
    setDocusignProductionClientId(asyncResult.value.DOCUSIGN_PRODUCTION_CLIENT_ID);
    setEnablePrivacyAcknowledgementBannerAtom(asyncResult.value.ENABLE_PRIVACY_ACKNOWLEDGEMENT_BANNER);
    setIsPendoEnabledAtom(asyncResult.value.PENDO_ENABLED);
    setPrivacyAcknowledgementBannerLinkAtom(asyncResult.value.PRIVACY_ACKNOWLEDGEMENT_BANNER_LINK);
  }, [
    asyncResult,
    setBoxClientId,
    setDocusignDeveloperClientId,
    setDocusignProductionClientId,
    setEnablePrivacyAcknowledgementBannerAtom,
    setIsPendoEnabledAtom,
    setPrivacyAcknowledgementBannerLinkAtom,
  ]);

  // (hchen): There can be one and only one Replay integration.
  useEffect(() => {
    if (!AsyncLoaded.isLoaded(asyncResult)) {
      return;
    }

    // (hchen): Sentry should be initialized exactly once, this wasn't enforced before when we only
    // had tracing. But now it's a hard requirement with replay.
    if (!isSentryInitialized) {
      sentryInit({
        dsn: asyncResult.value.SENTRY_DSN,
        environment: asyncResult.value.ENVIRONMENT,
        integrations: [
          browserTracingIntegration(),
          replayIntegration({
            maskAllText: asyncResult.value.SENTRY_REPLAY_ENABLE_MASKING,
            blockAllMedia: asyncResult.value.SENTRY_REPLAY_ENABLE_MASKING,
            // (hchen): After some sampling with manually clicking around the app, looks like it can
            // get up to 3000+ mutation with in the same event loop when rendering opening a task on
            // home page on top of the table. In most cases this number is below 100.
            mutationBreadcrumbLimit: 1000,
            mutationLimit: 5000,
          }),
        ],
        release: SENTRY_RELEASE,
        tracesSampleRate: Number.isNaN(Number.parseFloat(asyncResult.value.SENTRY_SAMPLE_RATE))
          ? 0.0
          : Number.parseFloat(asyncResult.value.SENTRY_SAMPLE_RATE),

        replaysSessionSampleRate: Number.isNaN(Number.parseFloat(asyncResult.value.SENTRY_REPLAY_SESSION_SAMPLE_RATE))
          ? 0.0
          : Number.parseFloat(asyncResult.value.SENTRY_REPLAY_SESSION_SAMPLE_RATE),
        replaysOnErrorSampleRate: Number.isNaN(Number.parseFloat(asyncResult.value.SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE))
          ? 0.0
          : Number.parseFloat(asyncResult.value.SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE),
      });

      setIsSentryInitialized(true);
    }
  }, [asyncResult, isSentryInitialized]);

  return asyncResult;
}

function isEnvironmentVariablePresent(value: string | boolean | null | Record<string, boolean> | undefined): boolean {
  return value != null && (typeof value !== "string" || value.length > 0);
}
