import {
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldInstanceValueInputType,
  FieldType,
  PropertyDataType,
} from "@regrello/graphql-api";

import {
  EMAIL_SYSTEM_FIELD_NAMES,
  SUBMISSION_PDF_FIELD_NAME,
  WORKFLOW_OWNER_FIELD_NAME,
} from "../../constants/globalConstants";
import { CheckboxFieldPlugin } from "../../ui/molecules/customFields/plugins/CheckboxFieldPlugin";
import { DocumentFieldPlugin } from "../../ui/molecules/customFields/plugins/DocumentFieldPlugin";
import { UserFieldPlugin } from "../../ui/molecules/customFields/plugins/UserFieldPlugin";
import { SpectrumFieldValidationType } from "../../ui/molecules/spectrumFields/types/SpectrumFieldValidationType";

export function isNonEmptyDocumentFieldInstance(fieldInstance: FieldInstanceFields) {
  return (
    DocumentFieldPlugin.canProcessFieldInstance(fieldInstance) &&
    !DocumentFieldPlugin.isFieldInstanceEmpty(fieldInstance)
  );
}

export function isNotSignatureFieldInstance(fieldInstance: FieldInstanceFields): boolean {
  return fieldInstance.spectrumFieldVersion?.validationType?.validationType !== SpectrumFieldValidationType.SIGNATURE;
}

export function isCheckboxFieldInstance(fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues) {
  return CheckboxFieldPlugin.canProcessFieldInstance(fieldInstance);
}

export function isDocumentFieldInstance(fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues) {
  return DocumentFieldPlugin.canProcessFieldInstance(fieldInstance);
}

export function isUserFieldInstance(fieldInstance: FieldInstanceFields) {
  return UserFieldPlugin.canProcessFieldInstance(fieldInstance);
}

export function isSyncedObjectFieldInstance(fieldInstance: FieldInstanceFields | null | undefined): boolean {
  return fieldInstance?.field.propertyType.dataType === PropertyDataType.REGRELLO_OBJECT_INSTANCE_ID;
}

export function retainDefaultFieldInstances<T extends FieldInstanceFields | FieldInstanceFieldsWithBaseValues>(
  fieldInstances: T[],
): T[] {
  return retainFieldInstancesByFieldType(fieldInstances, FieldType.DEFAULT);
}

export function retainDefaultOrNonDerivedWorkflowOwnerFieldInstances<
  T extends FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
>(fieldInstances: T[]): T[] {
  return fieldInstances.filter(
    (fieldInstance) =>
      fieldInstance.field.fieldType === FieldType.DEFAULT ||
      (fieldInstance.field.name === WORKFLOW_OWNER_FIELD_NAME &&
        fieldInstance.inputType !== FieldInstanceValueInputType.DERIVED),
  );
}

export function retainSystemFieldInstances<T extends FieldInstanceFields | FieldInstanceFieldsWithBaseValues>(
  fieldInstances: T[],
): T[] {
  return retainFieldInstancesByFieldType(fieldInstances, FieldType.SYSTEM);
}

export function retainFieldInstancesByFieldType<T extends FieldInstanceFields | FieldInstanceFieldsWithBaseValues>(
  fieldInstances: T[],
  fieldType: FieldType,
): T[] {
  return fieldInstances.filter((fieldInstance) => fieldInstance.field.fieldType === fieldType);
}

export function isEmailSystemField(field: FieldFields) {
  // (zstanik) HACK: as of Dec 2023 there's no property on system email fields that distinguishes
  // them from other system fields, so we need to check if the field name is in the list of email
  // system field names.
  return field.fieldType === FieldType.SYSTEM && EMAIL_SYSTEM_FIELD_NAMES.includes(field.name);
}

export function isSubmissionPdfField(field: Pick<FieldFields, "name">) {
  return field.name === SUBMISSION_PDF_FIELD_NAME;
}

export function isSubmissionPdfFieldInstance(
  fieldInstance: Pick<FieldInstanceFields, "inputType"> & {
    field: Pick<FieldInstanceFields["field"], "name">;
  },
) {
  return isSubmissionPdfField(fieldInstance.field) && fieldInstance.inputType === FieldInstanceValueInputType.DERIVED;
}
