import * as ProgressPrimitive from "@radix-ui/react-progress";
import { clsx } from "@regrello/core-utils";
import React from "react";

export type RegrelloProgressProps = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>;

export const RegrelloProgress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={clsx(
      "relative h-2 w-full overflow-hidden rounded bg-neutral-transparentHovered border border-primary-solid",
      className,
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className="h-full w-full flex-1 bg-primary-solid transition-all duration-300 ease-linear"
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));
RegrelloProgress.displayName = ProgressPrimitive.Root.displayName;
