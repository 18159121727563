/* eslint-disable class-methods-use-this */
import { noop } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  FormConstraintConditionOperator,
  PropertyDataType,
  PropertyTypeFields,
  SpectrumFieldValidationTypeFields,
  SpectrumFieldVersionFields,
  SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import {
  ConditionContains,
  ConditionDoesNotContain,
  ConditionEndsWith,
  ConditionIsEmpty,
  ConditionIsEqual,
  ConditionIsNotEmpty,
  ConditionIsNotEqual,
  ConditionStartsWith,
} from "@regrello/ui-strings";
import { ReactNode } from "react";
import { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import { FrontendValueConstraintRuleName, getValueConstraintDisplayName } from "./utils/spectrumFieldConstraintUtils";
import { ValidationRules } from "../../../constants/globalConstants";
import { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloControlledFormFieldNumber } from "../formFields/controlled/regrelloControlledFormFields";
import { RegrelloFormFieldText } from "../formFields/RegrelloFormFieldText";

type TextFieldPluginFrontendValue = string;

export class SpectrumTextFieldPluginDecorator extends SpectrumFieldPluginDecorator<TextFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<TextFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.text";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.RAW;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find((validationType) => validationType.validationType === SpectrumFieldValidationType.RAW);
  }

  public findValueConstraintsFromLoadedValueConstraints(valueConstraints: SpectrumValueConstraintFields[]) {
    return valueConstraints.filter(
      ({ valueConstraintRule }) =>
        valueConstraintRule === FrontendValueConstraintRuleName.MAX_CHARACTER ||
        valueConstraintRule === FrontendValueConstraintRuleName.MIN_CHARACTER,
    );
  }

  public isDataFormatToggleVisible() {
    return true;
  }

  public isValueConstraintEnabled() {
    return true;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: ConditionIsEmpty,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: ConditionIsNotEmpty,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.EQ]: {
        label: ConditionIsEqual,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.NOT]: {
        label: ConditionIsNotEqual,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_CONTAINS]: {
        label: ConditionContains,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_NOT_CONTAINS]: {
        label: ConditionDoesNotContain,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_STARTS_WITH]: {
        label: ConditionStartsWith,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_ENDS_WITH]: {
        label: ConditionEndsWith,
        inputCount: 1,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onBlur" as const;
  }

  public renderPreviewFormField() {
    return <RegrelloFormFieldText disabled={true} onChange={noop} size="medium" value="" />;
  }

  public renderValueConstraints(props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints", "id">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
    focusField: `valueConstraints.${number}.args.${number}`;
  }): ReactNode {
    const { constraints, disabled, form, focusField } = props;
    const getFieldKey = (index: number) => `valueConstraints.${index}.args.0` as const;

    const minCharacterConstraintIndex = constraints.findIndex(
      (constraint) => constraint.constraint.valueConstraintRule === FrontendValueConstraintRuleName.MIN_CHARACTER,
    );
    const maxCharacterConstraintIndex = constraints.findIndex(
      (constraint) => constraint.constraint.valueConstraintRule === FrontendValueConstraintRuleName.MAX_CHARACTER,
    );
    if (minCharacterConstraintIndex < 0 || maxCharacterConstraintIndex < 0) {
      return null;
    }
    const minCharacterConstraintArg = form.getValues(`valueConstraints.${minCharacterConstraintIndex}.args.0`);
    const maxCharacterConstraintArg = form.getValues(`valueConstraints.${maxCharacterConstraintIndex}.args.0`);

    const typedMinCharacterConstraintArg = minCharacterConstraintArg
      ? Number.parseInt(minCharacterConstraintArg, 10)
      : Number.MIN_SAFE_INTEGER;
    const typedMaxCharacterConstraintArg = maxCharacterConstraintArg
      ? Number.parseInt(maxCharacterConstraintArg, 10)
      : Number.MAX_SAFE_INTEGER;

    return (
      <>
        {constraints.map((row, constraintIndex) => {
          const fieldKey = getFieldKey(constraintIndex);
          return (
            <RegrelloControlledFormFieldNumber
              key={row.constraint.uuid}
              autoFocus={focusField === fieldKey}
              className="w-60"
              controllerProps={{
                control: form.control,
                name: fieldKey,
                rules:
                  constraintIndex === minCharacterConstraintIndex && maxCharacterConstraintArg != null
                    ? {
                        ...ValidationRules.INTEGER,
                        ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(typedMaxCharacterConstraintArg),
                        ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number.MIN_SAFE_INTEGER),
                      }
                    : constraintIndex === maxCharacterConstraintIndex && minCharacterConstraintArg != null
                      ? {
                          ...ValidationRules.INTEGER,
                          ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number.MAX_SAFE_INTEGER),
                          ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(typedMinCharacterConstraintArg),
                        }
                      : {
                          ...ValidationRules.INTEGER,
                          ...ValidationRules.LESS_THAN_OR_EQUAL_TO_UPPER_BOUND(Number.MAX_SAFE_INTEGER),
                          ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(Number.MIN_SAFE_INTEGER),
                        },
              }}
              dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_CONSTRAINT}
              disabled={disabled}
              label={getValueConstraintDisplayName(row.constraint)}
            />
          );
        })}
      </>
    );
  }
}
