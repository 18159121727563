import React from "react";

import { RegrelloSpinner } from "./RegrelloSpinner";

/** A `RegrelloSpinner` that covers its entire parent container. */
export const RegrelloFullViewSpinner = React.memo(function RegrelloFullViewSpinnerFn() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <RegrelloSpinner size="x-large" />
    </div>
  );
});
