// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormMinimalFields } from './FormMinimalFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { WorkflowTemplatePermissionsFields } from './WorkflowTemplatePermissionsFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { FormSectionFields } from './FormSectionFields.generated';
import { FormSectionBaseFields } from './FormSectionBaseFields.generated';
import { PartyFields } from './PartyFields.generated';
import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { FormFieldFields } from './FormFieldFields.generated';
import { FormFieldConstraintFields } from './FormFieldConstraintFields.generated';
import { FormFieldConstraintRuleFields } from './FormFieldConstraintRuleFields.generated';
import { FormConstraintConditionGroupFields } from './FormConstraintConditionGroupFields.generated';
import { FormConstraintConditionGroupBaseFields } from './FormConstraintConditionGroupBaseFields.generated';
import { FormConstraintConditionFields } from './FormConstraintConditionFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { FieldFields } from './FieldFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { FormSectionConstraintFields } from './FormSectionConstraintFields.generated';
import { FormSectionConstraintRuleFields } from './FormSectionConstraintRuleFields.generated';
import { gql } from '@apollo/client';
export type FormVersionFields = { id: number, uuid: string, createdAt: string, name: string, description: string, updatedAt: string, editingLocked: boolean, form: (
    { workflow?: { id: number, name: string, permissions: WorkflowPermissionsFields } | null, workflowTemplate?: { id: number, name: string, publishedAt?: string | null, permissions?: WorkflowTemplatePermissionsFields | null } | null }
    & FormMinimalFields
  ), createdBy: PartyBaseFields, defaultFormSection: FormSectionFields, occupiedSpectrumFieldNamesById: Array<{ spectrumFieldId: number, spectrumFieldVersionName: string }> };

export const FormVersionFields = gql`
    fragment FormVersionFields on FormVersion {
  id
  uuid
  createdAt
  name
  description
  createdAt
  updatedAt
  form {
    ...FormMinimalFields
    workflow {
      id
      name
      permissions {
        ...WorkflowPermissionsFields
      }
    }
    workflowTemplate {
      id
      name
      publishedAt
      permissions {
        ...WorkflowTemplatePermissionsFields
      }
    }
  }
  createdBy {
    ...PartyBaseFields
  }
  defaultFormSection {
    ...FormSectionFields
  }
  editingLocked
  occupiedSpectrumFieldNamesById {
    spectrumFieldId
    spectrumFieldVersionName
  }
}
    `;