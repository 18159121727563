import { ConditionConnective } from "@regrello/graphql-api";

export const SCHEDULE_TIME_FORM_LABEL_WIDTH = 45;

export const SPLITTER_FIELD_SELECT_LABEL_WIDTH = 305;

export const START_CONDITION_LABEL_WIDTH = 90;
export const START_CONDITION_BETWEEN_LABEL_WIDTH = 32;

export const START_CONDITION_CONNECTIVES = [ConditionConnective.AND, ConditionConnective.OR];
