// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceBaseFields } from './FieldInstanceBaseFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { FieldInstanceSpectrumMetadataFields } from './FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from './FieldInstanceSpectrumFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument, FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat, FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt, FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty, FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueBaseFieldsFieldInstanceMultiValueString, FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime, FieldInstanceValueBaseFieldsFieldInstanceValueBoolean, FieldInstanceValueBaseFieldsFieldInstanceValueFloat, FieldInstanceValueBaseFieldsFieldInstanceValueInt, FieldInstanceValueBaseFieldsFieldInstanceValueParty, FieldInstanceValueBaseFieldsFieldInstanceValueString, FieldInstanceValueBaseFieldsFieldInstanceValueTime } from './FieldInstanceValueBaseFields.generated';
import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { FieldInstanceValueTimeBaseFields } from './FieldInstanceValueTimeBaseFields.generated';
import { FieldInstanceValuePartyBaseFields } from './FieldInstanceValuePartyBaseFields.generated';
import { FieldInstanceMultiValuePartyBaseFields } from './FieldInstanceMultiValuePartyBaseFields.generated';
import { FieldInstanceMultiValueDocumentBaseFields } from './FieldInstanceMultiValueDocumentBaseFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { gql } from '@apollo/client';
import { FieldInstanceValueBaseFields } from './FieldInstanceValueBaseFields.generated';
export type FieldInstanceFieldsWithBaseValues = (
  { values: Array<FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument | FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat | FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt | FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty | FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance | FieldInstanceValueBaseFieldsFieldInstanceMultiValueString | FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime | FieldInstanceValueBaseFieldsFieldInstanceValueBoolean | FieldInstanceValueBaseFieldsFieldInstanceValueFloat | FieldInstanceValueBaseFieldsFieldInstanceValueInt | FieldInstanceValueBaseFieldsFieldInstanceValueParty | FieldInstanceValueBaseFieldsFieldInstanceValueString | FieldInstanceValueBaseFieldsFieldInstanceValueTime> }
  & FieldInstanceBaseFields
  & FieldInstanceSpectrumFields
);

export const FieldInstanceFieldsWithBaseValues = gql`
    fragment FieldInstanceFieldsWithBaseValues on FieldInstance {
  ...FieldInstanceBaseFields
  ...FieldInstanceSpectrumFields
  values {
    ...FieldInstanceValueBaseFields
  }
}
    `;