import { clsx, useDebouncedElementSize } from "@regrello/core-utils";
import React, { useLayoutEffect, useRef, useState } from "react";

import { RegrelloTooltipV4, RegrelloTooltipV4Props } from "../tooltip/RegrelloTooltipV4";

export interface RegrelloTooltippedTextProps {
  children: React.ReactNode;

  /**
   * Number of lines to clamp the text to before applying the tooltip.
   *
   * @default 1
   */
  lines?: number;

  /** Props to pass to the tooltip. Pass `disabled={true}` to disable the tooltip altogether. */
  tooltipProps?: Pick<RegrelloTooltipV4Props, "align" | "disabled" | "side" | "variant">;
}

/**
 * Component that shows overflowing content in a tooltip if and only if the child content overflows
 * its parent.
 */
export const RegrelloTooltippedText = React.memo<RegrelloTooltippedTextProps>(function RegrelloTooltippedTextFn({
  children,
  lines = 1,
  tooltipProps,
}) {
  const textElementRef = useRef<HTMLDivElement | null>(null);

  const [tooltipContent, setTooltipContent] = useState<string | null>(null);

  const textElementWidth = useDebouncedElementSize({ elementRef: textElementRef });

  useLayoutEffect(() => {
    if (textElementWidth) {
      // This hook needs to run whenever text width change
    }

    if (textElementRef.current == null) {
      return;
    }
    const contentWidth = textElementRef.current.scrollWidth;
    const availableWidth = textElementRef.current.clientWidth;
    const contentHeight = textElementRef.current.scrollHeight;
    const availableHeight = textElementRef.current.clientHeight;

    if (contentWidth > availableWidth || contentHeight > availableHeight) {
      setTooltipContent(textElementRef.current.textContent);
    } else {
      setTooltipContent(null);
    }
  }, [textElementWidth]);

  return (
    <RegrelloTooltipV4 align="center" content={tooltipContent} {...tooltipProps}>
      {/* (clewis): This text element must be display:block in order for text ellipses to show. */}
      <div
        ref={textElementRef}
        className={clsx({
          "line-clamp-1": lines <= 1,
          "line-clamp-2": lines === 2,
          "line-clamp-3": lines === 3,
          "line-clamp-4": lines === 4,
          "line-clamp-5": lines >= 5,
        })}
      >
        {children}
      </div>
    </RegrelloTooltipV4>
  );
});
