import { FieldInstanceFields, SplitChildDataFields } from "@regrello/graphql-api";

import { FieldInstanceFieldsWithSource } from "../../types/FieldInstanceFieldsWithSource";

/** Whether the given field instance is sourced from a splitter task or not. */
export function isFromSplitterTask(fieldInstance: FieldInstanceFieldsWithSource): boolean {
  return (
    (fieldInstance.source?.type === "previous" &&
      (fieldInstance.source?.actionItemIsSplitter || fieldInstance.source?.actionItemTemplateIsSplitter)) ??
    false
  );
}

/** Whether the given field instance is sourced from a splitter task or not. */
export function isOnSplitterTask(fieldInstance: FieldInstanceFields): boolean {
  return (fieldInstance.actionItem?.isSplitter ?? false) || (fieldInstance.actionItemTemplate?.isSplitter ?? false);
}

/** Whether the given field instance is sourced from a split child or not. */
export function isFromSplitChild(
  fieldInstance: FieldInstanceFieldsWithSource,
): fieldInstance is FieldInstanceFieldsWithSource & {
  source: {
    type: "previous";
    splitChildData: NonNullable<SplitChildDataFields>;
  };
} {
  const splitChildData = getSplitChildData(fieldInstance);
  return splitChildData != null;
}

/** Whether the given field instance is on a split child or not. */
export function isOnSplitChild(fieldInstance: FieldInstanceFieldsWithSource): boolean {
  return fieldInstance.actionItem?.splitterParentActionItemId != null;
}

export function getSplitChildData(fieldInstance: FieldInstanceFieldsWithSource): SplitChildDataFields | null {
  const splitChildData = fieldInstance.source?.type === "previous" ? fieldInstance.source?.splitChildData : null;
  return splitChildData != null ? splitChildData : null;
}
