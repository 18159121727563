import { FieldInstanceValueInputType } from "@regrello/graphql-api";
import {
  ConditionContains,
  ConditionDoesNotContain,
  ConditionIsAfterOrOn,
  ConditionIsBeforeOrOn,
  ConditionIsChecked,
  ConditionIsEqual,
  ConditionIsGreaterThanOrEqual,
  ConditionIsIn,
  ConditionIsLessThanOrEqual,
  ConditionIsNotChecked,
  ConditionIsNotEqual,
  ConditionIsNotIn,
  ConditionIsOnDate,
} from "@regrello/ui-strings";

import {
  getConditionOperatorBetween,
  getConditionOperatorConfigContainsAllOf,
  getConditionOperatorConfigContainsAnyOf,
  getConditionOperatorConfigContainsNoneOf,
  getConditionOperatorConfigEmpty,
  getConditionOperatorConfigEquals,
  getConditionOperatorConfigGreaterThanOrEquals,
  getConditionOperatorConfigLessThanOrEquals,
  getConditionOperatorConfigNotEmpty,
  getConditionOperatorConfigNotEquals,
} from "./conditionOperatorConfigs";
import { ConditionOperatorConfig } from "../types/ConditionOperator";

export const DEFAULT_INPUT_TYPE_IF_NO_VALUE: FieldInstanceValueInputType = FieldInstanceValueInputType.REQUESTED;

export const CONDITION_OPERATOR_CONFIGS_CHECKBOX: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEquals(ConditionIsChecked, true),
  getConditionOperatorConfigNotEquals(ConditionIsNotChecked, true),
];

export const CONDITION_OPERATOR_CONFIGS_CURRENCY: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigGreaterThanOrEquals(ConditionIsGreaterThanOrEqual),
  getConditionOperatorConfigLessThanOrEquals(ConditionIsLessThanOrEqual),
  getConditionOperatorConfigEquals(ConditionIsEqual),
  getConditionOperatorConfigNotEquals(ConditionIsNotEqual),
  getConditionOperatorBetween(),
];

export const CONDITION_OPERATOR_CONFIGS_DATE: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigGreaterThanOrEquals(ConditionIsAfterOrOn),
  getConditionOperatorConfigLessThanOrEquals(ConditionIsBeforeOrOn),
  getConditionOperatorConfigEquals(ConditionIsOnDate),
  getConditionOperatorBetween(),
];

export const CONDITION_OPERATOR_CONFIGS_MULTISELECT: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigContainsAnyOf(ConditionContains),
  getConditionOperatorConfigContainsNoneOf(ConditionDoesNotContain),
  getConditionOperatorConfigContainsAllOf(),
];

export const CONDITION_OPERATOR_CONFIGS_NUMBER: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigGreaterThanOrEquals(ConditionIsGreaterThanOrEqual),
  getConditionOperatorConfigLessThanOrEquals(ConditionIsLessThanOrEqual),
  getConditionOperatorConfigEquals(ConditionIsEqual),
  getConditionOperatorConfigNotEquals(ConditionIsNotEqual),
  getConditionOperatorBetween(),
];

export const CONDITION_OPERATOR_CONFIGS_REGRELLO_OBJECT: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigEquals(ConditionIsEqual),
  getConditionOperatorConfigNotEquals(ConditionIsNotEqual),
  getConditionOperatorConfigContainsAnyOf(ConditionContains),
  getConditionOperatorConfigContainsNoneOf(ConditionDoesNotContain),
];

export const CONDITION_OPERATOR_CONFIGS_SELECT: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigEquals(ConditionIsEqual),
  getConditionOperatorConfigNotEquals(ConditionIsNotEqual),
  getConditionOperatorConfigContainsAnyOf(ConditionIsIn),
  getConditionOperatorConfigContainsNoneOf(ConditionIsNotIn),
];

export const CONDITION_OPERATOR_CONFIGS_TEXT: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigEquals(ConditionIsEqual),
  getConditionOperatorConfigNotEquals(ConditionIsNotEqual),
  getConditionOperatorConfigContainsAnyOf(ConditionContains),
  getConditionOperatorConfigContainsNoneOf(ConditionDoesNotContain),
];

export const CONDITION_OPERATOR_CONFIGS_USER: ConditionOperatorConfig[] = [
  getConditionOperatorConfigEmpty(),
  getConditionOperatorConfigNotEmpty(),
  getConditionOperatorConfigContainsAnyOf(ConditionContains),
  getConditionOperatorConfigContainsNoneOf(ConditionDoesNotContain),
  getConditionOperatorConfigContainsAllOf(),
];
