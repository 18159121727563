import { isDefined } from "@regrello/core-utils";
import { FieldInstanceFields, FieldInstanceMultiValueStringFields } from "@regrello/graphql-api";

import { extractAllowedValuesForFrontend } from "./extractAllowedValuesForFrontend";
import { FieldInstanceBaseFields } from "../../../../../types";
import { getErrorMessageWithPayload } from "../../../../../utils/getErrorMessageWithPayload";
import {
  CustomFieldFrontendSelectableOption,
  INACTIVE_SELECTABLE_OPTION_ID,
} from "../types/CustomFieldFrontendSelectableOption";

/**
 * Extract a frontend-compatible selected value from the provided 'Multi-select' field instance.
 * Warning: If `includeInactiveOptions` is `true`, the returned selected values aren't guaranteed to
 * have unique IDs.
 *
 * @throws if any selected values do not appear in `field.allowedValues`
 * @throws if any selected values do not have type `"FieldInstanceMultiValueString"`
 */
export function extractSelectedValuesOrThrow<TReturnValue>(params: {
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields;
  errorMessageIfWrongValueType: string;
  getterIfNoValue: (allowedValues: CustomFieldFrontendSelectableOption[]) => TReturnValue;
  getterIfValue: (
    fieldInstanceValues: FieldInstanceMultiValueStringFields[],
    frontendSelectedOptions: CustomFieldFrontendSelectableOption[],
    allowedValues: CustomFieldFrontendSelectableOption[],
  ) => TReturnValue;
  options?: {
    includeInactiveOptions: boolean;
  };
}): TReturnValue {
  const { fieldInstance, errorMessageIfWrongValueType, getterIfNoValue, getterIfValue, options } = params;

  const { field, values } = fieldInstance;

  const frontendOptions = extractAllowedValuesForFrontend(field);

  if (values.length === 0) {
    return getterIfNoValue(frontendOptions);
  }

  const representativeSingleValue = values[0];

  // eslint-disable-next-line no-underscore-dangle
  const isSomeValueHasInvalidType = values.some((value) => value.__typename !== "FieldInstanceMultiValueString");

  // eslint-disable-next-line no-underscore-dangle
  if (representativeSingleValue.__typename !== "FieldInstanceMultiValueString" || isSomeValueHasInvalidType) {
    throw new Error(getErrorMessageWithPayload(errorMessageIfWrongValueType, { fieldInstance }));
  }

  // (clewis): We've just asserted above that this array contains string values only.
  const valuesTyped = values as FieldInstanceMultiValueStringFields[];

  const frontendOptionIdsByValues = new Map(frontendOptions.map((option) => [option.value, option.id]));
  const frontendSelectedOptionsWithWrongIds = valuesTyped
    .flatMap((value) =>
      value.stringMultiValue != null
        ? // (clewis): We eventually want the allowedValue.id, not the value.id, else it'll be
          // possible to select duplicate values.
          value.stringMultiValue.map((nestedValue) => ({ id: value.id, value: nestedValue }))
        : undefined,
    )
    .filter(isDefined);

  const frontendSelectedOptionsWithCorrectIds = frontendSelectedOptionsWithWrongIds.map((option) => {
    const optionId = frontendOptionIdsByValues.get(option.value);
    if (optionId == null && !options?.includeInactiveOptions) {
      return undefined;
    }
    // (clewis, zstanik): Replace with the original allowedValue ID. The allowed value may have been
    // deleted since the original data was submitted so set the ID to a default ID to indicate the
    // allowed value was deleted.
    return { id: optionId ?? INACTIVE_SELECTABLE_OPTION_ID, value: option.value };
  });

  return getterIfValue(valuesTyped, frontendSelectedOptionsWithCorrectIds.filter(isDefined), frontendOptions);
}
