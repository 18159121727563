// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceMultiValueRegrelloObjectInstance?: AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields | null, crossWorkflowSinksFieldInstanceMultiValueRegrelloObjectInstance?: Array<AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields> | null }
  & FieldInstanceMultiValueRegrelloObjectInstanceFields
);

export const FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields on FieldInstanceMultiValueRegrelloObjectInstance {
  ...FieldInstanceMultiValueRegrelloObjectInstanceFields
  crossWorkflowSourceFieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  crossWorkflowSinksFieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
}
    `;