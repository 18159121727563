// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type FieldInstanceValueFloatBaseFields = { __typename: 'FieldInstanceValueFloat', id: number, inputType: Types.FieldInstanceValueInputType, floatValue?: number | null };

export const FieldInstanceValueFloatBaseFields = gql`
    fragment FieldInstanceValueFloatBaseFields on FieldInstanceValueFloat {
  __typename
  id
  inputType
  floatValue
}
    `;