// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type ActionItemAuditHistoryFieldInstanceRelatedRecordFields = { fieldInstance: { field: { id: number, name: string, isMultiValued: boolean }, actionItem?: { id: number, name: string } | null, workflow?: { id: number, name: string } | null, values: Array<{ id: number } | { id: number } | { id: number } | { id: number } | { id: number } | { id: number } | { id: number } | { id: number } | { id: number } | { id: number } | { id: number } | { id: number } | { id: number }> } };

export const ActionItemAuditHistoryFieldInstanceRelatedRecordFields = gql`
    fragment ActionItemAuditHistoryFieldInstanceRelatedRecordFields on AuditHistoryFieldInstance {
  fieldInstance {
    field {
      id
      name
      isMultiValued
    }
    actionItem {
      id
      name
    }
    workflow {
      id
      name
    }
    values {
      id
    }
  }
}
    `;