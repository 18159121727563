// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { SpectrumFieldValidationTypeFields } from '../../fragments/generated/SpectrumFieldValidationTypeFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SpectrumFieldValidationTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SpectrumFieldValidationTypesQuery = { spectrumFieldValidationTypes: Array<SpectrumFieldValidationTypeFields> };


export const SpectrumFieldValidationTypesQueryDocument = gql`
    query SpectrumFieldValidationTypesQuery {
  spectrumFieldValidationTypes {
    ...SpectrumFieldValidationTypeFields
  }
}
    ${SpectrumFieldValidationTypeFields}`;

/**
 * __useSpectrumFieldValidationTypesQuery__
 *
 * To run a query within a React component, call `useSpectrumFieldValidationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpectrumFieldValidationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpectrumFieldValidationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpectrumFieldValidationTypesQuery(baseOptions?: Apollo.QueryHookOptions<SpectrumFieldValidationTypesQuery, SpectrumFieldValidationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpectrumFieldValidationTypesQuery, SpectrumFieldValidationTypesQueryVariables>(SpectrumFieldValidationTypesQueryDocument, options);
      }
export function useSpectrumFieldValidationTypesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpectrumFieldValidationTypesQuery, SpectrumFieldValidationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpectrumFieldValidationTypesQuery, SpectrumFieldValidationTypesQueryVariables>(SpectrumFieldValidationTypesQueryDocument, options);
        }
export function useSpectrumFieldValidationTypesQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpectrumFieldValidationTypesQuery, SpectrumFieldValidationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpectrumFieldValidationTypesQuery, SpectrumFieldValidationTypesQueryVariables>(SpectrumFieldValidationTypesQueryDocument, options);
        }
export type SpectrumFieldValidationTypesQueryHookResult = ReturnType<typeof useSpectrumFieldValidationTypesQuery>;
export type SpectrumFieldValidationTypesQueryLazyQueryHookResult = ReturnType<typeof useSpectrumFieldValidationTypesQueryLazyQuery>;
export type SpectrumFieldValidationTypesQuerySuspenseQueryHookResult = ReturnType<typeof useSpectrumFieldValidationTypesQuerySuspenseQuery>;
export type SpectrumFieldValidationTypesQueryResult = Apollo.QueryResult<SpectrumFieldValidationTypesQuery, SpectrumFieldValidationTypesQueryVariables>;