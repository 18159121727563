import { EMPTY_STRING, mergeRefs } from "@regrello/core-utils";
import React, { useCallback } from "react";
import { FieldPath, FieldValues, useController } from "react-hook-form";

import { getErrorProp, RegrelloControlledFormFieldProps } from "./utils";
import { RegrelloFormFieldText, RegrelloFormFieldTextProps } from "../RegrelloFormFieldText";

const RegrelloControlledFormFieldTextInternal = function RegrelloControlledFormFieldTextFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  controllerProps,
  onValueChange,
  ...textFieldProps
}: RegrelloControlledFormFieldProps<
  TFieldValues,
  TName,
  Omit<RegrelloFormFieldTextProps, "name" | "onChange" | "value">
> & {
  /**
   * Capture the change event before the rerender happens. This is useful for setting the
   * "useForm" form values to the correct type before rendering a new RegrelloFormField of a
   * different type. This avoids passing in a incorrect data type to the component.
   */
  onValueChange?: (name: string, newValue: string) => void;
}) {
  const { field, fieldState } = useController(controllerProps);
  const { ref, ...fieldProps } = field;

  const internalOnBlur = fieldProps.onBlur;
  const propsOnBlur = textFieldProps.onBlur;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      internalOnBlur();
      propsOnBlur?.(event);
    },
    [internalOnBlur, propsOnBlur],
  );

  return (
    <RegrelloFormFieldText
      {...fieldProps}
      {...textFieldProps}
      {...getErrorProp(fieldState)}
      dataTestId={textFieldProps.dataTestId ?? field.name}
      inputRef={textFieldProps.inputRef != null ? mergeRefs(ref, textFieldProps.inputRef) : ref}
      onBlur={handleBlur}
      onChange={(event) => {
        onValueChange?.(field.name, event.target.value);
        fieldProps.onChange(event.target.value);
      }}
      value={fieldProps.value ?? EMPTY_STRING}
    />
  );
};

export const RegrelloControlledFormFieldText = React.memo(
  RegrelloControlledFormFieldTextInternal,
) as typeof RegrelloControlledFormFieldTextInternal;
