import React, { ReactChild, useEffect, useRef } from "react";
import { useMount, useUnmount } from "react-use";

import { isDevEnvironment } from "../environmentUtils";

const DEVMODE_PREFIX = "[DEV] - ";

export interface RegrelloTitle {
  setTitle: <T extends string>(newTitle: T) => void;
  getTitle: () => string;
}

const Context = React.createContext<RegrelloTitle>({
  /** Updates `document.title` to the provided title. */
  setTitle: <T extends string>(newTitle: T) => {
    if (isDevEnvironment() && !newTitle.startsWith(DEVMODE_PREFIX)) {
      document.title = `${DEVMODE_PREFIX}${newTitle}`;
    } else {
      document.title = newTitle;
    }
  },

  /** Returns the current `document.title`. */
  getTitle: () => document.title,
});

/** Hook that returns functions for directly modifying `document.title`. */
export function useTitle() {
  return React.useContext(Context);
}

/**
 * Sets the page title to `title` until the current component is unmounted, at which point the page title will
 * be reverted to the title that was set before this component was mounted.
 */
export function usePageTitleUntilUnmount(title: ReactChild): void {
  const { getTitle, setTitle } = useTitle();

  const originalTitleRef = useRef<string | undefined>();

  useMount(() => {
    originalTitleRef.current = getTitle();
    if (typeof title === "string") {
      setTitle(title);
    }
  });

  useEffect(() => {
    if (typeof title === "string") {
      setTitle(title);
    }
  }, [setTitle, title]);

  useUnmount(() => {
    if (originalTitleRef.current != null) {
      setTitle(originalTitleRef.current);
    }
  });
}
