// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserFields } from '../../fragments/generated/UserFields.generated';
import { UserFieldsWithoutRoles } from '../../fragments/generated/UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from '../../fragments/generated/AccessRoleFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GiveAdminAccessToUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  tenantId: Types.Scalars['ID']['input'];
}>;


export type GiveAdminAccessToUserMutation = { giveAdminAccessToUser?: { user: UserFields } | null };


export const GiveAdminAccessToUserMutationDocument = gql`
    mutation GiveAdminAccessToUserMutation($id: ID!, $tenantId: ID!) {
  giveAdminAccessToUser(id: $id, tenantId: $tenantId) {
    user {
      ...UserFields
    }
  }
}
    ${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}`;
export type GiveAdminAccessToUserMutationFn = Apollo.MutationFunction<GiveAdminAccessToUserMutation, GiveAdminAccessToUserMutationVariables>;

/**
 * __useGiveAdminAccessToUserMutation__
 *
 * To run a mutation, you first call `useGiveAdminAccessToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveAdminAccessToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveAdminAccessToUserMutation, { data, loading, error }] = useGiveAdminAccessToUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGiveAdminAccessToUserMutation(baseOptions?: Apollo.MutationHookOptions<GiveAdminAccessToUserMutation, GiveAdminAccessToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiveAdminAccessToUserMutation, GiveAdminAccessToUserMutationVariables>(GiveAdminAccessToUserMutationDocument, options);
      }
export type GiveAdminAccessToUserMutationHookResult = ReturnType<typeof useGiveAdminAccessToUserMutation>;
export type GiveAdminAccessToUserMutationResult = Apollo.MutationResult<GiveAdminAccessToUserMutation>;
export type GiveAdminAccessToUserMutationOptions = Apollo.BaseMutationOptions<GiveAdminAccessToUserMutation, GiveAdminAccessToUserMutationVariables>;