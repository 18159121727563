import {
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldInstanceValueInputType,
  FieldInstanceWithCrossWorkflowFields,
} from "@regrello/graphql-api";

import { getCustomFieldInstanceInputType } from "./getCustomFieldInstanceInputType";
import { FieldInstanceBaseFields } from "../../types";

type GenericFieldInstanceFields =
  | FieldInstanceFields
  | FieldInstanceWithCrossWorkflowFields
  | FieldInstanceFieldsWithBaseValues;

export function retainInheritedFieldInstances<T extends GenericFieldInstanceFields>(fieldInstances: T[]): T[] {
  return retainFieldInstancesByInputType(fieldInstances, [FieldInstanceValueInputType.INHERITED]);
}

export function retainProvidedFieldInstances<T extends GenericFieldInstanceFields>(fieldInstances: T[]): T[] {
  return retainFieldInstancesByInputType(fieldInstances, [FieldInstanceValueInputType.PROVIDED]);
}

export function retainRequestedFieldInstances<T extends GenericFieldInstanceFields>(fieldInstances: T[]): T[] {
  return retainFieldInstancesByInputType(fieldInstances, [FieldInstanceValueInputType.REQUESTED]);
}

export function retainRequestedAndOptionalFieldInstances<T extends GenericFieldInstanceFields>(
  fieldInstances: T[],
): T[] {
  return retainFieldInstancesByInputType(fieldInstances, [
    FieldInstanceValueInputType.REQUESTED,
    FieldInstanceValueInputType.OPTIONAL,
  ]);
}

export function retainFieldInstancesByInputType<T extends GenericFieldInstanceFields>(
  fieldInstances: T[],
  inputTypes: FieldInstanceValueInputType | FieldInstanceValueInputType[],
): T[] {
  const inputTypesSet = new Set(inputTypesToArray(inputTypes));
  return fieldInstances.filter((fieldInstance) => inputTypesSet.has(getCustomFieldInstanceInputType(fieldInstance)));
}

export function retainFieldInstanceBasesByInputType(
  fieldInstances: FieldInstanceBaseFields[],
  inputTypes: FieldInstanceValueInputType | FieldInstanceValueInputType[],
): FieldInstanceBaseFields[] {
  const inputTypesSet = new Set(inputTypesToArray(inputTypes));
  return fieldInstances.filter((fieldInstance) => inputTypesSet.has(getCustomFieldInstanceInputType(fieldInstance)));
}

function inputTypesToArray(inputTypes: FieldInstanceValueInputType | FieldInstanceValueInputType[]) {
  return Array.isArray(inputTypes) ? inputTypes : [inputTypes];
}
