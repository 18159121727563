import { EMPTY_STRING } from "@regrello/core-utils";
import { useFormFieldConstraintRulesQuery } from "@regrello/graphql-api";

export const exclusiveRuleMap: Record<string, string> = {
  show: "hide",
  hide: "show",
};

export const MANDATORY_RULE_NAME = "mandatory";

export function useFormFieldConstraintRulesMap() {
  const { data: constraintRulesData } = useFormFieldConstraintRulesQuery({
    fetchPolicy: "cache-and-network",
  });

  const mandatoryRuleUuid =
    constraintRulesData?.formFieldConstraintRules.find(
      (constraint) => constraint.formFieldConstraintRule === MANDATORY_RULE_NAME,
    )?.uuid || EMPTY_STRING;

  return {
    constraintRulesList: constraintRulesData?.formFieldConstraintRules || [],
    mandatoryRuleUuid,
  };
}
