import { ControllerFieldState, FieldPath, FieldValues, UseControllerProps } from "react-hook-form";

export interface RegrelloControlledFormFieldBaseProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  controllerProps: UseControllerProps<TFieldValues, TName>;
}

export type RegrelloControlledFormFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TRegrelloFormFieldProps,
> = RegrelloControlledFormFieldBaseProps<TFieldValues, TName> & TRegrelloFormFieldProps;

export function getErrorProp(fieldState: ControllerFieldState) {
  // (clewis): If there is no fieldState.error, return an empty object so we don't clobber an
  // `error` that may have been passed from above.
  return fieldState.error != null ? { error: fieldState.error?.message } : {};
}
