import { clsx, EMPTY_STRING, useSimpleDialog } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  RegrelloObjectFields,
  RegrelloObjectInstanceFields,
} from "@regrello/graphql-api";
import { RegrelloButton, RegrelloField } from "@regrello/ui-core";
import { Edit, Select, SelectFrom, Show } from "@regrello/ui-strings";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";
import { RegrelloObjectSearchDialog } from "./regrelloObjectSearchDialog/RegrelloObjectSearchDialog";
import { RegrelloObjectSelectedValues } from "../customFields/plugins/RegrelloObjectFieldPlugin";

export interface RegrelloFormFieldRegrelloObjectProps
  extends Pick<
    RegrelloFormFieldBaseProps<RegrelloObjectInstanceFields | undefined>,
    | "className"
    | "dataTestId"
    | "disabled"
    | "error"
    | "helperText"
    | "isDeleted"
    | "isRequiredAsteriskShown"
    | "label"
    | "labelPlacement"
    | "labelWidth"
    | "variant"
  > {
  context?: "RegrelloActionItemViewRequestedInfo" | "RegrelloConfigureCustomFieldsFormSection";
  isRequiredAsteriskShown?: boolean;
  onChange: (newValue: RegrelloObjectInstanceFields[] | undefined) => void;
  regrelloObject: RegrelloObjectFields;
  value?: RegrelloObjectInstanceFields[];
  fieldInstance?: FieldInstanceFields | FieldInstanceFieldsWithBaseValues;
}

export const RegrelloFormFieldRegrelloObject = React.memo(function RegrelloFormFieldRegrelloObjectFn({
  className,
  context,
  dataTestId,
  disabled,
  error,
  isDeleted,
  isRequiredAsteriskShown,
  label,
  labelPlacement,
  labelWidth,
  onChange,
  regrelloObject,
  value,
  variant = "default",
  fieldInstance,
}: RegrelloFormFieldRegrelloObjectProps) {
  const { isOpen: isDialogOpen, open: openDialog, close: closeDialog } = useSimpleDialog();
  const showHideButtonRef = useRef<HTMLButtonElement | null>(null);
  const isValueShownInPopover = context === "RegrelloConfigureCustomFieldsFormSection";

  const [isExpanded, setIsExpanded] = useState(!isValueShownInPopover);
  const isMultiselectEnabled = fieldInstance?.isMultiValued || false;

  const handleShowHideButtonClick = useCallback(() => {
    setIsExpanded((previousValue) => !previousValue);
  }, []);

  const objectFieldElement = useMemo(() => {
    return (
      <>
        {(value == null || value.length === 0) && (
          <RegrelloButton
            className="bg-background mr-1"
            dataTestId={DataTestIds.SYNCED_OBJECTS_SELECT_BUTTON}
            disabled={disabled}
            onClick={openDialog}
            variant="outline"
          >
            {Select}
          </RegrelloButton>
        )}

        {value != null && value.length !== 0 && (
          <>
            <RegrelloButton className="bg-background mr-1" disabled={disabled} onClick={openDialog} variant="outline">
              {Edit}
            </RegrelloButton>
            {isValueShownInPopover && (
              <RegrelloButton
                ref={showHideButtonRef}
                className="bg-background mr-1"
                onClick={handleShowHideButtonClick}
                variant="outline"
              >
                {Show}
              </RegrelloButton>
            )}
          </>
        )}
      </>
    );
  }, [disabled, handleShowHideButtonClick, isValueShownInPopover, openDialog, value]);

  return (
    <div className={clsx("mb-2", className)}>
      {variant === "spectrum" ? (
        <RegrelloField
          dataTestId={dataTestId}
          deleted={isDeleted}
          errorMessage={error}
          label={typeof label === "string" ? label : EMPTY_STRING}
          name={EMPTY_STRING}
          required={isRequiredAsteriskShown}
        >
          {() => objectFieldElement}
        </RegrelloField>
      ) : (
        <RegrelloFormFieldLayout
          className="items-start"
          dataTestId={dataTestId}
          error={error}
          isDeleted={isDeleted}
          isRequiredAsteriskShown={isRequiredAsteriskShown}
          label={label}
          labelPlacement={labelPlacement}
          labelWidth={labelWidth}
          variant={variant}
        >
          {objectFieldElement}
        </RegrelloFormFieldLayout>
      )}

      {value != null && value.length !== 0 && isValueShownInPopover && isExpanded && (
        <RegrelloObjectSelectedValues
          fieldInstance={fieldInstance}
          onClose={handleShowHideButtonClick}
          regrelloObject={regrelloObject}
          regrelloObjectInstances={value}
          withPopoverOnly={isValueShownInPopover}
        />
      )}

      {value != null && value.length !== 0 && !isValueShownInPopover && (
        <RegrelloObjectSelectedValues
          fieldInstance={fieldInstance}
          regrelloObject={regrelloObject}
          regrelloObjectInstances={value}
        />
      )}

      {isDialogOpen ? (
        <RegrelloObjectSearchDialog
          fieldInstance={fieldInstance}
          onChange={onChange}
          onClose={closeDialog}
          open={isDialogOpen}
          regrelloObject={regrelloObject}
          title={SelectFrom(isMultiselectEnabled, regrelloObject.name)}
          value={value}
        />
      ) : null}
    </div>
  );
});
