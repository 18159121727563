// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueStringFields } from './FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueStringWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceValueString?: AcyclicFieldInstanceValueStringFields | null, crossWorkflowSinksFieldInstanceValueString?: Array<AcyclicFieldInstanceValueStringFields> | null }
  & FieldInstanceValueStringFields
);

export const FieldInstanceValueStringWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueStringWithCrossWorkflowFields on FieldInstanceValueString {
  ...FieldInstanceValueStringFields
  crossWorkflowSourceFieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
  crossWorkflowSinksFieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
}
    `;