/**
 * This is exactly equivalent to `setTimeout(callback, 0)`.
 *
 * Enqueues the provided callback at the end of the JavaScript event queue (a.k.a. the macrotask
 * queue). The callback will be invoked when the queue is emptied.
 *
 * This is similar to JavaScript's native `queueMicrotask`, except it will invoke the provided
 * callback when _all_ tasks in the queue have executed, instead of only after whatever _current_
 * task has executed.
 *
 * __Example:__ This is often useful when attempting to programmatically focus an `input` after some
 * rendering work has happened.
 */
// See: https://javascript.info/event-loop
export function queueMacrotask<T extends (...args: unknown[]) => void>(callback: T): void {
  window.setTimeout(callback, 0);
}
