import isEqual from "lodash/isEqual";
import { useRef } from "react";

export interface UseEffectWithWatchedFormValuesArgs<T> {
  /**
   * The form value that triggers the effect when it changes. In order for this hook to work as
   * intended this should be a value returned from calling `form.watch` (rerenders at the root
   * `useForm` level) or `useWatch` (rerenders at the component level).
   */
  watchedFormValue: T;

  /** Callback invoked when the provided watched form value changes. */
  onChange: (nextValue: T) => void;
}

/**
 * A custom hook for triggering an effect when a watched value from `react-hook-form` changes. Use
 * this hook instead of the standard `useEffect` or `useDeepCompareEffect` because, per the
 * library's documentation, watched value changes trigger a rerender but are mutated from the
 * previous value so the normal `useEffect` dependency checks don't pick up on value changes.
 */
export const useEffectWithWatchedFormValues = function useEffectWithWatchedFormValues<T>({
  watchedFormValue,
  onChange,
}: UseEffectWithWatchedFormValuesArgs<T>) {
  const prevValue = useRef<T>();
  if (!isEqual(prevValue.current, watchedFormValue)) {
    onChange(watchedFormValue);
    // (zstanik): Update the ref with a deep copy of the watched value so the next render of the
    // hook can deep compare the next value (which gets mutated) with the correct previous value.
    prevValue.current = structuredClone(watchedFormValue);
  }
};
