/**
 * Returns a new array with the specified index removed. Does not mutate the original array.
 *
 * @throws If the index is out of bounds.
 */
export function arrayRemoveAtIndex<T>(array: readonly T[], index: number) {
  if (index < 0 || index >= array.length) {
    throw new Error(`Index out of bounds (arrayLength=${array.length}, index=${index})`);
  }
  return [...array.slice(0, index), ...array.slice(index + 1)];
}
