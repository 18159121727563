// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormVersionFields } from '../../fragments/generated/FormVersionFields.generated';
import { FormMinimalFields } from '../../fragments/generated/FormMinimalFields.generated';
import { WorkflowPermissionsFields } from '../../fragments/generated/WorkflowPermissionsFields.generated';
import { WorkflowTemplatePermissionsFields } from '../../fragments/generated/WorkflowTemplatePermissionsFields.generated';
import { PartyBaseFields } from '../../fragments/generated/PartyBaseFields.generated';
import { UserBaseFields } from '../../fragments/generated/UserBaseFields.generated';
import { TeamWithoutMembersFields } from '../../fragments/generated/TeamWithoutMembersFields.generated';
import { FormSectionFields } from '../../fragments/generated/FormSectionFields.generated';
import { FormSectionBaseFields } from '../../fragments/generated/FormSectionBaseFields.generated';
import { PartyFields } from '../../fragments/generated/PartyFields.generated';
import { UserFields } from '../../fragments/generated/UserFields.generated';
import { UserFieldsWithoutRoles } from '../../fragments/generated/UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from '../../fragments/generated/AccessRoleFields.generated';
import { FormFieldFields } from '../../fragments/generated/FormFieldFields.generated';
import { FormFieldConstraintFields } from '../../fragments/generated/FormFieldConstraintFields.generated';
import { FormFieldConstraintRuleFields } from '../../fragments/generated/FormFieldConstraintRuleFields.generated';
import { FormConstraintConditionGroupFields } from '../../fragments/generated/FormConstraintConditionGroupFields.generated';
import { FormConstraintConditionGroupBaseFields } from '../../fragments/generated/FormConstraintConditionGroupBaseFields.generated';
import { FormConstraintConditionFields } from '../../fragments/generated/FormConstraintConditionFields.generated';
import { SpectrumFieldVersionFields } from '../../fragments/generated/SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from '../../fragments/generated/SpectrumFieldFields.generated';
import { PropertyTypeFields } from '../../fragments/generated/PropertyTypeFields.generated';
import { SpectrumFieldValidationTypeFields } from '../../fragments/generated/SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from '../../fragments/generated/SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from '../../fragments/generated/SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from '../../fragments/generated/SpectrumFieldAllowedValueFields.generated';
import { FieldUnitFields } from '../../fragments/generated/FieldUnitFields.generated';
import { FieldFields } from '../../fragments/generated/FieldFields.generated';
import { RegrelloObjectFields } from '../../fragments/generated/RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from '../../fragments/generated/AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from '../../fragments/generated/RegrelloObjectPropertyFields.generated';
import { FormSectionConstraintFields } from '../../fragments/generated/FormSectionConstraintFields.generated';
import { FormSectionConstraintRuleFields } from '../../fragments/generated/FormSectionConstraintRuleFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LatestFormVersionQueryVariables = Types.Exact<{
  formUUID: Types.Scalars['String']['input'];
}>;


export type LatestFormVersionQuery = { latestFormVersion: FormVersionFields };


export const LatestFormVersionQueryDocument = gql`
    query LatestFormVersionQuery($formUUID: String!) {
  latestFormVersion(formUUID: $formUUID) {
    ...FormVersionFields
  }
}
    ${FormVersionFields}
${FormMinimalFields}
${WorkflowPermissionsFields}
${WorkflowTemplatePermissionsFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${FormSectionFields}
${FormSectionBaseFields}
${PartyFields}
${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${FormFieldFields}
${FormFieldConstraintFields}
${FormFieldConstraintRuleFields}
${FormConstraintConditionGroupFields}
${FormConstraintConditionGroupBaseFields}
${FormConstraintConditionFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${PropertyTypeFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldUnitFields}
${FieldFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}
${FormSectionConstraintFields}
${FormSectionConstraintRuleFields}`;

/**
 * __useLatestFormVersionQuery__
 *
 * To run a query within a React component, call `useLatestFormVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestFormVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestFormVersionQuery({
 *   variables: {
 *      formUUID: // value for 'formUUID'
 *   },
 * });
 */
export function useLatestFormVersionQuery(baseOptions: Apollo.QueryHookOptions<LatestFormVersionQuery, LatestFormVersionQueryVariables> & ({ variables: LatestFormVersionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestFormVersionQuery, LatestFormVersionQueryVariables>(LatestFormVersionQueryDocument, options);
      }
export function useLatestFormVersionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestFormVersionQuery, LatestFormVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestFormVersionQuery, LatestFormVersionQueryVariables>(LatestFormVersionQueryDocument, options);
        }
export function useLatestFormVersionQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LatestFormVersionQuery, LatestFormVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LatestFormVersionQuery, LatestFormVersionQueryVariables>(LatestFormVersionQueryDocument, options);
        }
export type LatestFormVersionQueryHookResult = ReturnType<typeof useLatestFormVersionQuery>;
export type LatestFormVersionQueryLazyQueryHookResult = ReturnType<typeof useLatestFormVersionQueryLazyQuery>;
export type LatestFormVersionQuerySuspenseQueryHookResult = ReturnType<typeof useLatestFormVersionQuerySuspenseQuery>;
export type LatestFormVersionQueryResult = Apollo.QueryResult<LatestFormVersionQuery, LatestFormVersionQueryVariables>;