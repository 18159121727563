import { clsx, EMPTY_STRING } from "@regrello/core-utils";
import { RegrelloField, RegrelloInput, RegrelloInputProps, RegrelloLinkify, RegrelloSize } from "@regrello/ui-core";
import React, { useId } from "react";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldEmptyValueEmpty } from "./_internal/RegrelloFormFieldEmptyValueEmpty";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";
import { getSelfContainedFormInternal } from "./_internal/selfContainedForm/getSelfContainedFormInternal";

export interface RegrelloFormFieldTextProps
  extends RegrelloFormFieldBaseProps<string>,
    Pick<
      RegrelloInputProps,
      | "autoFocus"
      | "inputRef"
      | "onBlur"
      | "onChange"
      | "onFocus"
      | "onKeyDown"
      | "onPaste"
      | "placeholder"
      | "startElement"
      | "endElement" // TODO:  "inputRef"
    > {
  /**
   * Whether to hide the form field error icon and message. Added (potentially temporarily depending
   * on Design System updates) for Conditional Branching to support the new error state for stage
   * start date forms.
   */
  isErrorMessageHidden?: boolean;

  /** The input field type. if type is password, input values will be masked by dots. */
  type?: "text" | "password";

  /** Whether to show the value in a monospace font. Useful for showing code, JSON expressions, etc. */
  monospace?: boolean;

  /**
   * If provided, will show an 'x' button in the input that invokes this callback when clicked.
   * Expected usage thereafter is for the callback to clear the input's value.
   */
  onClearClick?: () => void;

  /**
   * The size of the input field.
   * @default RegrelloSize.LARGE
   */
  size?: RegrelloSize;

  value: string;
}

export const RegrelloFormFieldText = React.memo(function RegrelloFormFieldTextFn({
  className,
  dataTestId,
  error,
  helperText,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  isDefaultMarginsOmitted,
  isDeleted,
  isEmphasized,
  isErrorMessageHidden = false,
  isLabelVerticallyCentered,
  isRequiredAsteriskShown,
  label,
  labelPlacement,
  labelWidth,
  monospace,
  selfContainedForm,
  size,
  value,
  variant = "default",
  warning,
  ...textFieldProps
}: RegrelloFormFieldTextProps) {
  const id = useId();
  const hasError = error != null;

  if (variant === "spectrum" && selfContainedForm == null) {
    return (
      <RegrelloField
        dataTestId={dataTestId}
        deleted={isDeleted}
        description={infoTooltipText}
        errorMessage={isErrorMessageHidden ? undefined : error}
        helperText={typeof helperText === "string" ? helperText : undefined}
        htmlFor={id}
        label={typeof label === "string" ? label : EMPTY_STRING}
        name={textFieldProps.name ?? EMPTY_STRING}
        required={isRequiredAsteriskShown}
      >
        {() => (
          <RegrelloInput
            {...textFieldProps}
            autoFocus={textFieldProps.autoFocus || selfContainedForm != null}
            className={clsx({ "font-mono": monospace })}
            id={id}
            intent={hasError ? "danger" : isEmphasized ? "warning" : undefined}
            size={size}
            value={value}
          />
        )}
      </RegrelloField>
    );
  }

  return (
    <RegrelloFormFieldLayout
      className={className}
      dataTestId={dataTestId}
      error={isErrorMessageHidden ? undefined : error}
      helperText={helperText}
      htmlFor={id}
      infoTooltipIconName={infoTooltipIconName}
      infoTooltipText={infoTooltipText}
      infoTooltipVariant={infoTooltipVariant}
      isDefaultMarginsOmitted={isDefaultMarginsOmitted}
      isDeleted={isDeleted}
      isLabelVerticallyCentered={isLabelVerticallyCentered}
      isRequiredAsteriskShown={isRequiredAsteriskShown}
      label={label}
      labelPlacement={labelPlacement}
      labelWidth={labelWidth}
      selfContainedForm={getSelfContainedFormInternal(selfContainedForm, (savedValue) =>
        savedValue === EMPTY_STRING ? (
          <RegrelloFormFieldEmptyValueEmpty />
        ) : (
          <RegrelloLinkify>{savedValue}</RegrelloLinkify>
        ),
      )}
      variant={variant}
      warning={warning}
    >
      <RegrelloInput
        {...textFieldProps}
        autoFocus={textFieldProps.autoFocus || selfContainedForm != null}
        className={clsx({ "font-mono": monospace })}
        id={id}
        intent={hasError ? "danger" : isEmphasized ? "warning" : undefined}
        size={size}
        value={value}
      />
    </RegrelloFormFieldLayout>
  );
});
