export function getBoundedValue(value: string, min: number | undefined, max: number | undefined) {
  const valueAsNumber = Number(value);
  if (!Number.isFinite(valueAsNumber)) {
    return value.trim();
  }

  return min != null && valueAsNumber < min
    ? min.toString()
    : max != null && valueAsNumber > max
      ? max.toString()
      : valueAsNumber.toString();
}
