import { TagFields } from "@regrello/graphql-api";

import { PartyTypeUnion } from "../utils/parties/PartyTypeUnion";

export enum RegrelloFormFieldFilterType {
  CHECKBOX = "checkbox",
  DATE = "date",
  // DOCUMENT = "document",
  MULTI_SELECT = "multi-select",
  NUMBER = "number",
  PARTY_SELECT = "party-select",
  SELECT = "select",
  TAG_SELECT = "tag-select",
  TEXT = "text",
  // TEXT_MULTILINE = "text-multiLine",
  DIVIDER = "divider",
}

interface FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType;
  label: string;
}

interface FilterTypeUnion_Checkbox extends FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType.CHECKBOX;
  checkboxFilter: {
    value: boolean;
  };
}

interface FilterTypeUnion_Date extends FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType.DATE;
  dateFilter: {
    /** An ISO 8601 date string. */
    value: string | null;
  };
}

interface FilterTypeUnion_Number extends FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType.NUMBER;
  numberFilter: {
    value: string;
  };
}

interface FilterTypeUnion_MultiSelect extends FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType.MULTI_SELECT;
  multiSelectFilter: {
    value: string[];
    options: string[];
  };
}

interface FilterTypeUnion_PartySelect extends FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType.PARTY_SELECT;
  partySelectFilter: {
    value: PartyTypeUnion[];
  };
}

interface FilterTypeUnion_Select extends FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType.SELECT;
  selectFilter: {
    value: string | null; // nit: I might use 'null' rather than 'undefined' here, to indicate a "controlled" empty value.
    options: string[];
  };
}

interface FilterTypeUnion_TagSelect extends FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType.TAG_SELECT;
  tagSelectFilter: {
    value: TagFields[];
  };
}

interface FilterTypeUnion_Text extends FilterTypeUnionBase {
  type: RegrelloFormFieldFilterType.TEXT;
  textFilter: {
    value: string;
  };
}

interface FilterTypeUnion_Divider extends FilterTypeUnionBase {
  isQueryFilter: false;
  type: RegrelloFormFieldFilterType.DIVIDER;
}

function isCheckbox(obj: FilterTypeUnion): obj is FilterTypeUnion_Checkbox {
  return obj.type === RegrelloFormFieldFilterType.CHECKBOX;
}

function checkbox(obj: FilterTypeUnion_Checkbox): FilterTypeUnion_Checkbox["checkboxFilter"] {
  return obj.checkboxFilter;
}

function isDate(obj: FilterTypeUnion): obj is FilterTypeUnion_Date {
  return obj.type === RegrelloFormFieldFilterType.DATE;
}

function date(obj: FilterTypeUnion_Date): FilterTypeUnion_Date["dateFilter"] {
  return obj.dateFilter;
}

function isNumber(obj: FilterTypeUnion): obj is FilterTypeUnion_Number {
  return obj.type === RegrelloFormFieldFilterType.NUMBER;
}

function number(obj: FilterTypeUnion_Number): FilterTypeUnion_Number["numberFilter"] {
  return obj.numberFilter;
}

function isMultiSelect(obj: FilterTypeUnion): obj is FilterTypeUnion_MultiSelect {
  return obj.type === RegrelloFormFieldFilterType.MULTI_SELECT;
}

function multiSelect(obj: FilterTypeUnion_MultiSelect): FilterTypeUnion_MultiSelect["multiSelectFilter"] {
  return obj.multiSelectFilter;
}

function isPartySelect(obj: FilterTypeUnion): obj is FilterTypeUnion_PartySelect {
  return obj.type === RegrelloFormFieldFilterType.PARTY_SELECT;
}

function partySelect(obj: FilterTypeUnion_PartySelect): FilterTypeUnion_PartySelect["partySelectFilter"] {
  return obj.partySelectFilter;
}

function isSelect(obj: FilterTypeUnion): obj is FilterTypeUnion_Select {
  return obj.type === RegrelloFormFieldFilterType.SELECT;
}

function select(obj: FilterTypeUnion_Select): FilterTypeUnion_Select["selectFilter"] {
  return obj.selectFilter;
}

function isTagSelect(obj: FilterTypeUnion): obj is FilterTypeUnion_TagSelect {
  return obj.type === RegrelloFormFieldFilterType.TAG_SELECT;
}

function tagSelect(obj: FilterTypeUnion_TagSelect): FilterTypeUnion_TagSelect["tagSelectFilter"] {
  return obj.tagSelectFilter;
}

function isText(obj: FilterTypeUnion): obj is FilterTypeUnion_Text {
  return obj.type === RegrelloFormFieldFilterType.TEXT;
}

function text(obj: FilterTypeUnion_Text): FilterTypeUnion_Text["textFilter"] {
  return obj.textFilter;
}

function isDivider(obj: FilterTypeUnion): obj is FilterTypeUnion_Divider {
  return obj.type === RegrelloFormFieldFilterType.DIVIDER;
}

function divider(_obj: FilterTypeUnion): null {
  return null;
}

export interface FilterTypeUnionVisitor<T> {
  checkbox: (obj: FilterTypeUnion_Checkbox) => T;
  date: (obj: FilterTypeUnion_Date) => T;
  number: (obj: FilterTypeUnion_Number) => T;
  partySelect: (obj: FilterTypeUnion_PartySelect) => T;
  tagSelect: (obj: FilterTypeUnion_TagSelect) => T;
  select: (obj: FilterTypeUnion_Select) => T;
  text: (obj: FilterTypeUnion_Text) => T;
  multiSelect: (obj: FilterTypeUnion_MultiSelect) => T;
  divider: (obj: FilterTypeUnion_Divider) => T;
  unknown: (obj: never) => T;
}

export interface FilterTypeUnionValueVisitor {
  checkbox: (obj: FilterTypeUnion_Checkbox) => boolean;
  date: (obj: FilterTypeUnion_Date) => string | null;
  number: (obj: FilterTypeUnion_Number) => string;
  partySelect: (obj: FilterTypeUnion_PartySelect) => PartyTypeUnion[];
  tagSelect: (obj: FilterTypeUnion_TagSelect) => TagFields[];
  select: (obj: FilterTypeUnion_Select) => string | null;
  text: (obj: FilterTypeUnion_Text) => string;
  multiSelect: (obj: FilterTypeUnion_MultiSelect) => string[];
  divider: (obj: FilterTypeUnion_Divider) => undefined;
  unknown: (obj: FilterTypeUnion_Divider | never) => undefined;
}

function visit<T>(obj: FilterTypeUnion, visitor: FilterTypeUnionVisitor<T> | FilterTypeUnionValueVisitor) {
  if (isCheckbox(obj)) {
    return visitor.checkbox(obj);
  }
  if (isDate(obj)) {
    return visitor.date(obj);
  }
  if (isNumber(obj)) {
    return visitor.number(obj);
  }
  if (isMultiSelect(obj)) {
    return visitor.multiSelect(obj);
  }
  if (isPartySelect(obj)) {
    return visitor.partySelect(obj);
  }
  if (isSelect(obj)) {
    return visitor.select(obj);
  }
  if (isText(obj)) {
    return visitor.text(obj);
  }
  if (isTagSelect(obj)) {
    return visitor.tagSelect(obj);
  }
  if (isDivider(obj)) {
    return visitor.divider(obj);
  }
  return visitor.unknown(obj);
}

export type FilterTypeUnion =
  | FilterTypeUnion_Checkbox
  | FilterTypeUnion_Date
  | FilterTypeUnion_Number
  | FilterTypeUnion_MultiSelect
  | FilterTypeUnion_PartySelect
  | FilterTypeUnion_Select
  | FilterTypeUnion_TagSelect
  | FilterTypeUnion_Text
  | FilterTypeUnion_Divider;

export const FilterTypeUnionUtil = {
  isCheckbox,
  checkbox,
  isDate,
  date,
  isNumber,
  number,
  isMultiSelect,
  multiSelect,
  isPartySelect,
  partySelect,
  isSelect,
  select,
  isTagSelect,
  tagSelect,
  isText,
  text,
  isDivider,
  divider,
  visit,
};
