// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from './ActionItemAuditHistoryDocumentRelatedRecordFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from './ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated';
import { gql } from '@apollo/client';
export type ActionItemAuditHistoryDeltaFields = { id: number, fieldName: string, valueBefore?: string | null, valueAfter?: string | null, collectionAdd?: string | null, collectionRemove?: string | null, field?: { id: number, isMultiValued: boolean, name: string, allowedValues: Array<{ __typename: 'FieldAllowedValueParty', id: number, displayOrder: number, partyValue: PartyBaseFields } | { __typename: 'FieldAllowedValueString', id: number, displayOrder: number, stringValue: string }>, fieldUnit?: { id: number, symbol: string } | null, regrelloObject?: { id: number, properties: Array<RegrelloObjectPropertyFields> } | null, fieldRestriction?: { filterByRole?: { name: string } | null } | null } | null, addedRecords: Array<{ id: number, name?: string | null }>, removedRecords: Array<{ id: number, name?: string | null }>, addedRecordsV2: Array<{ __typename: 'AuditHistoryRegrelloObjectInstance', regrelloObjectInstance: RegrelloObjectInstanceFields } | {}>, removedRecordsV2: Array<{ __typename: 'AuditHistoryRegrelloObjectInstance', regrelloObjectInstance: RegrelloObjectInstanceFields } | {}>, relatedRecords: Array<ActionItemAuditHistoryDocumentRelatedRecordFields | ActionItemAuditHistoryFieldInstanceRelatedRecordFields | {}> };

export const ActionItemAuditHistoryDeltaFields = gql`
    fragment ActionItemAuditHistoryDeltaFields on ActionItemAuditHistoryDelta {
  id
  fieldName
  field {
    id
    isMultiValued
    allowedValues {
      ... on FieldAllowedValueString {
        __typename
        id
        displayOrder
        stringValue
      }
      ... on FieldAllowedValueParty {
        __typename
        id
        displayOrder
        partyValue {
          ...PartyBaseFields
        }
      }
    }
    name
    fieldUnit {
      id
      symbol
    }
    regrelloObject {
      id
      properties {
        ...RegrelloObjectPropertyFields
      }
    }
    fieldRestriction {
      filterByRole {
        name
      }
    }
  }
  valueBefore
  valueAfter
  collectionAdd
  collectionRemove
  addedRecords {
    id
    name
  }
  removedRecords {
    id
    name
  }
  addedRecordsV2 {
    ... on AuditHistoryRegrelloObjectInstance {
      __typename
      regrelloObjectInstance {
        ...RegrelloObjectInstanceFields
      }
    }
  }
  removedRecordsV2 {
    ... on AuditHistoryRegrelloObjectInstance {
      __typename
      regrelloObjectInstance {
        ...RegrelloObjectInstanceFields
      }
    }
  }
  relatedRecords {
    ...ActionItemAuditHistoryDocumentRelatedRecordFields
    ...ActionItemAuditHistoryFieldInstanceRelatedRecordFields
  }
}
    `;