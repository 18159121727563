import {
  AdminRoleFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  RoleFields,
  TeamWithoutMembersFields,
  UserBaseFields,
  UserFields,
  UserFieldsWithRoles,
} from "@regrello/graphql-api";

export type PartyTypeUnion_User = {
  type: "user";
  user: UserFields;
};

export type PartyTypeUnion_UserWithRoles = {
  type: "userWithRoles";
  user: UserFieldsWithRoles;
};

export type PartyTypeUnion_BaseUser = {
  type: "baseUser";
  baseUser: UserBaseFields;
};

/** The successor to `PartyTypeUnion_CustomFieldInstance`. */
export type PartyTypeUnion_FieldInstance = {
  type: "fieldInstance";
  fieldInstance: FieldInstanceFieldsWithBaseValues;
};

export type PartyTypeUnion_Team = {
  type: "team";
  team: TeamWithoutMembersFields;
};

export type PartyTypeUnion_Role = {
  type: "role";
  role: RoleFields | AdminRoleFields;
};

function isUser(obj: PartyTypeUnion): obj is PartyTypeUnion_User | PartyTypeUnion_UserWithRoles {
  return obj.type === "user" || obj.type === "userWithRoles";
}

function isBaseUser(obj: PartyTypeUnion): obj is PartyTypeUnion_BaseUser {
  return obj.type === "baseUser";
}

function isUserWithRoles(obj: PartyTypeUnion): obj is PartyTypeUnion_UserWithRoles {
  return obj.type === "userWithRoles";
}

function user(obj: UserFields): PartyTypeUnion_User {
  return {
    type: "user",
    user: obj,
  };
}

function baseUser(obj: UserBaseFields): PartyTypeUnion_BaseUser {
  return {
    type: "baseUser",
    baseUser: obj,
  };
}

function userWithRoles(obj: UserFieldsWithRoles): PartyTypeUnion_UserWithRoles {
  return {
    type: "userWithRoles",
    user: obj,
  };
}

function isFieldInstance(obj: PartyTypeUnion): obj is PartyTypeUnion_FieldInstance {
  return obj.type === "fieldInstance";
}

function fieldInstance(obj: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): PartyTypeUnion_FieldInstance {
  return {
    type: "fieldInstance",
    fieldInstance: obj,
  };
}

function isTeam(obj: PartyTypeUnion): obj is PartyTypeUnion_Team {
  return obj.type === "team";
}

function isRole(obj: PartyTypeUnion): obj is PartyTypeUnion_Role {
  return obj.type === "role";
}

function team(obj: TeamWithoutMembersFields): PartyTypeUnion_Team {
  return {
    type: "team",
    team: obj,
  };
}

function role(obj: RoleFields | AdminRoleFields): PartyTypeUnion_Role {
  return {
    type: "role",
    role: obj,
  };
}

export type PartyTypeUnion =
  | PartyTypeUnion_User
  | PartyTypeUnion_FieldInstance
  | PartyTypeUnion_Team
  | PartyTypeUnion_Role
  | PartyTypeUnion_BaseUser
  | PartyTypeUnion_UserWithRoles;

export type NonFieldInstancePartyTypeUnion = PartyTypeUnion_User | PartyTypeUnion_Team;

export function isNonFieldInstancePartyTypeUnionArray(value: unknown): value is NonFieldInstancePartyTypeUnion[] {
  if (value == null || !Array.isArray(value) || value.some((element) => typeof element !== "object")) {
    return false;
  }

  const castedValue = value as PartyTypeUnion[];

  for (const element of castedValue) {
    switch (element.type) {
      case "team":
        continue;
      case "user":
        continue;
      case "baseUser":
        continue;
      case "userWithRoles":
        continue;
      case "role":
        continue;
      default:
        return false;
    }
  }

  return true;
}

export interface IPartyTypeUnionVisitor<T> {
  user: (obj: UserFields) => T;
  baseUser: (obj: UserBaseFields) => T;
  userWithRoles: (obj: UserFieldsWithRoles) => T;
  fieldInstance: (obj: FieldInstanceFields | FieldInstanceFieldsWithBaseValues) => T;
  team: (obj: TeamWithoutMembersFields) => T;
  role: (obj: RoleFields | AdminRoleFields) => T;
  unknown: (obj: never) => T;
}

function visit<T>(obj: PartyTypeUnion, visitor: IPartyTypeUnionVisitor<T>): T {
  if (isUserWithRoles(obj)) {
    return visitor.userWithRoles(obj.user);
  }
  if (isUser(obj)) {
    return visitor.user(obj.user);
  }
  if (isBaseUser(obj)) {
    return visitor.baseUser(obj.baseUser);
  }
  if (isFieldInstance(obj)) {
    return visitor.fieldInstance(obj.fieldInstance);
  }
  if (isTeam(obj)) {
    return visitor.team(obj.team);
  }
  if (isRole(obj)) {
    return visitor.role(obj.role);
  }
  return visitor.unknown(obj);
}

// (clewis): This contruct conforms to Conjure union typings.
// See: https://github.com/palantir/conjure-typescript/blob/develop/src/commands/generate/__tests__/resources/types/unionTypeExample.ts
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartyTypeUnion = {
  isUser,
  isUserWithRoles,
  isRole,
  user,
  userWithRoles,
  baseUser,
  isFieldInstance,
  fieldInstance,
  visit,
  isTeam,
  team,
  role,
};
