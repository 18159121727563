import { useEffect, useRef } from "react";
import { useMount } from "react-use";

/** Hook that runs a callback when `isOpen=true`. */
export function useOnOpen(isOpen: boolean, callbackFn: () => void) {
  const hasMountedRef = useRef(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: (clewis): Don't include `callbackFn` in the dependency array, because otherwise this effect would probably trigger on every render.
  useEffect(() => {
    if (hasMountedRef.current && isOpen) {
      callbackFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // (clewis): This must be defined after the useEffect invocation above, otherwise hasMountedRef
  // would already be true by the time the useEffect ran.
  useMount(() => {
    hasMountedRef.current = true;
  });
}
