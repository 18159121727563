// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { FieldInstanceSpectrumMetadataFields } from './FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from './FieldInstanceSpectrumFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceBaseFields = (
  { formFieldID?: number | null, regrelloObjectPropertyId?: number | null, displayOrder?: number | null, controlsActionItemOrActionItemTemplateField?: Types.ActionItemOrActionItemTemplateField | null, inputType?: Types.FieldInstanceValueInputType | null, isCopy?: boolean | null, isMultiValued?: boolean | null, isSplitter?: boolean | null, shouldSplitAssignees?: boolean | null, controllerBehaviorModifier?: Types.ControllerBehaviorModifierType | null, field: FieldFields, actionItem?: { id: number, name: string, status: Types.ActionItemStatus, isSplitter?: boolean | null, splitterParentActionItemId?: number | null, displayOrder: number, workflowReference?: { stageId: number, stageName: string, stageExecutionOrder: number } | null } | null, actionItemTemplate?: { id: number, name: string, isSplitter?: boolean | null, displayOrder: number, workflowReference?: { stageId: number, stageName: string, stageExecutionOrder: number } | null, workflowTemplateReference?: { stageTemplateId: number, stageTemplateName: string, stageTemplateExecutionOrder: number } | null } | null, workflow?: { id: number, name: string, scheduleStatus?: Types.WorkflowScheduleStatus | null } | null, workflowTemplate?: { id: number, name: string } | null, writtenByActionItem?: { id: number, name: string, workflowReference?: { stageId: number, stageName: string } | null } | null, projection?: { selectedRegrelloObjectPropertyIds: Array<number> } | null, spectrumMetadata?: Array<FieldInstanceSpectrumMetadataFields> | null }
  & FieldInstanceSpectrumFields
);

export const FieldInstanceBaseFields = gql`
    fragment FieldInstanceBaseFields on FieldInstance {
  field {
    ...FieldFields
  }
  actionItem {
    id
    name
    status
    isSplitter
    splitterParentActionItemId
    displayOrder
    workflowReference {
      stageId
      stageName
      stageExecutionOrder
    }
  }
  actionItemTemplate {
    id
    name
    isSplitter
    displayOrder
    workflowReference {
      stageId
      stageName
      stageExecutionOrder
    }
    workflowTemplateReference {
      stageTemplateId
      stageTemplateName
      stageTemplateExecutionOrder
    }
  }
  workflow {
    id
    name
    scheduleStatus
  }
  workflowTemplate {
    id
    name
  }
  writtenByActionItem {
    id
    name
    workflowReference {
      stageId
      stageName
    }
  }
  formFieldID
  regrelloObjectPropertyId
  displayOrder
  controlsActionItemOrActionItemTemplateField
  inputType
  isCopy
  isMultiValued
  projection {
    selectedRegrelloObjectPropertyIds
  }
  spectrumMetadata {
    ...FieldInstanceSpectrumMetadataFields
  }
  isSplitter
  shouldSplitAssignees
  controllerBehaviorModifier
  ...FieldInstanceSpectrumFields
}
    `;