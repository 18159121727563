import { FieldFields, FieldType } from "@regrello/graphql-api";
import { RegrelloIconProps } from "@regrello/ui-core";

import { CustomFieldPlugin } from "../plugins/types/CustomFieldPlugin";

export interface RegrelloCustomFieldPluginIconProps {
  /** Provide the field if the plugin needs to render a variant based on field-level data. */
  field?: FieldFields;

  /** Props to pass to the `renderIcon` function. */
  iconProps?: Omit<RegrelloIconProps, "iconName"> & { fieldType?: FieldType };

  /** The custom field plugin whose icon should be rendered. */
  plugin: CustomFieldPlugin<unknown>;
}

/** Renders `plugin.renderIcon()` with some sensible base styles. */
// (clewis): No React.memo because it's a cheaply rendered leaf node.
export function RegrelloCustomFieldPluginIcon({ field, iconProps, plugin }: RegrelloCustomFieldPluginIconProps) {
  return <>{plugin.renderIcon({ intent: "neutral", field, ...iconProps })}</>;
}
