// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { gql } from '@apollo/client';
export type ActionItemAuditHistoryDocumentRelatedRecordFields = { document: DocumentFields };

export const ActionItemAuditHistoryDocumentRelatedRecordFields = gql`
    fragment ActionItemAuditHistoryDocumentRelatedRecordFields on AuditHistoryDocument {
  document {
    ...DocumentFields
  }
}
    `;