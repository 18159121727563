// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { gql } from '@apollo/client';
export type FieldFields = { id: number, name: string, description?: string | null, isMultiValued: boolean, fieldType: Types.FieldType, updatedAt?: string | null, deletedAt?: string | null, allowedValues: Array<{ __typename: 'FieldAllowedValueParty', id: number, displayOrder: number, partyValue: PartyBaseFields } | { __typename: 'FieldAllowedValueString', id: number, displayOrder: number, stringValue: string }>, propertyType: PropertyTypeFields, fieldUnit?: FieldUnitFields | null, regrelloObject?: RegrelloObjectFields | null, fieldRestriction?: { filterByRole?: { id: number } | null } | null };

export const FieldFields = gql`
    fragment FieldFields on Field {
  id
  name
  description
  isMultiValued
  fieldType
  allowedValues {
    ... on FieldAllowedValueString {
      __typename
      id
      displayOrder
      stringValue
    }
    ... on FieldAllowedValueParty {
      __typename
      id
      displayOrder
      partyValue {
        ...PartyBaseFields
      }
    }
  }
  propertyType {
    ...PropertyTypeFields
  }
  fieldUnit {
    ...FieldUnitFields
  }
  regrelloObject {
    ...RegrelloObjectFields
  }
  fieldRestriction {
    filterByRole {
      id
    }
  }
  updatedAt
  deletedAt
}
    `;