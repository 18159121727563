// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { WorkflowGraphStageFields } from './WorkflowGraphStageFields.generated';
import { GenericWorkflowStageFields } from './GenericWorkflowStageFields.generated';
import { GenericWorkflowActionItemFields } from './GenericWorkflowActionItemFields.generated';
import { WorkflowGraphActionItemFields } from './WorkflowGraphActionItemFields.generated';
import { gql } from '@apollo/client';
export type WorkflowGraphFields = { workflow: { id: number, startedAt?: string | null }, rootStages: Array<WorkflowGraphStageFields>, headlessStages: Array<WorkflowGraphStageFields>, allStages: Array<WorkflowGraphStageFields>, allActionItems: Array<WorkflowGraphActionItemFields> };

export const WorkflowGraphFields = gql`
    fragment WorkflowGraphFields on WorkflowGraph {
  workflow {
    id
    startedAt
  }
  rootStages {
    ...WorkflowGraphStageFields
  }
  headlessStages {
    ...WorkflowGraphStageFields
  }
  allStages {
    ...WorkflowGraphStageFields
  }
  allActionItems {
    ...WorkflowGraphActionItemFields
  }
}
    `;