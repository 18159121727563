// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { RoleFields } from './RoleFields.generated';
import { gql } from '@apollo/client';
export type UserFieldsWithRoles = (
  { party: { id: number, roles: Array<RoleFields> } }
  & UserFieldsWithoutRoles
);

export const UserFieldsWithRoles = gql`
    fragment UserFieldsWithRoles on User {
  ...UserFieldsWithoutRoles
  party {
    id
    roles {
      ...RoleFields
    }
  }
}
    `;