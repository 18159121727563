/**
 * Pass this to a child element's `onKey*` callback to prevent the event from propagating up to
 * parent elements.
 */
export function swallowChildKeyboardEvent(event: React.KeyboardEvent<HTMLElement>) {
  event.stopPropagation();
}

/**
 * Pass this to a child element's `onMouse*` callback to prevent the event from propagating up to
 * parent elements.
 */
export function swallowChildMouseEvent(event: React.MouseEvent<HTMLElement>) {
  event.stopPropagation();
}

/**
 * Pass this to a child element's `onMouse*` callback to prevent the event from propagating up to
 * parent elements.
 */
export function swallowChildDragEvent(event: React.DragEvent<HTMLElement>) {
  event.stopPropagation();
}
