// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueStringFields = (
  { sourceFieldInstanceValueString?: AcyclicFieldInstanceValueStringFields | null, sinksFieldInstanceValueString: Array<AcyclicFieldInstanceValueStringFields> }
  & FieldInstanceValueStringBaseFields
);

export const FieldInstanceValueStringFields = gql`
    fragment FieldInstanceValueStringFields on FieldInstanceValueString {
  ...FieldInstanceValueStringBaseFields
  sourceFieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
  sinksFieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
}
    `;