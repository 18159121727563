import { DocumentFields } from "@regrello/graphql-api";

import { DeleteableDocumentFields } from "../ui/views/modals/actionItemDetailDialog/_internal/documentsSection/DocumentRow";

export function getDeletableDocumentFields(
  document: DocumentFields,
  isDeletable: boolean,
  reasonWhyNotDeletable?: string,
): DeleteableDocumentFields {
  return {
    document,
    isDeletable,
    reasonWhyNotDeletable,
  };
}

export function getFileExtension(fileName: string) {
  const fileNameSplitByPeriod = fileName.split(".");
  if (fileNameSplitByPeriod.length < 2) {
    return undefined;
  }
  return fileNameSplitByPeriod[fileNameSplitByPeriod.length - 1].toLocaleLowerCase();
}

export function getDocumentFields(name: string, uuid: string): DocumentFields {
  return {
    id: 0,
    name: name,
    currentVersion: {
      id: 0,
      uid: uuid,
      createdAt: "",
      createdBy: {
        __typename: "Party",
        id: 0,
        user: undefined,
        team: undefined,
      },
    },
    documentType: {
      id: 0,
      name: "",
      icon: getIconUrl("file.png"),
    },
    tags: [],
    permissions: {
      canDelete: false,
    },
  };
}

export function getIconUrl(iconFile: string) {
  return `https://www.regrello.com/images/files/${iconFile}`;
}
