// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { gql } from '@apollo/client';
export type DocumentFields = { id: number, name: string, currentVersion: DocumentVersionFields, documentType: { id: number, name: string, icon: string }, comment?: { id: number, text: string } | null, actionItem?: { id: number, uuid: string, name: string, status: Types.ActionItemStatus } | null, actionItemTemplate?: { id: number, uuid: string, name: string } | null, tags: Array<TagFields>, permissions: { canDelete: boolean }, workflow?: { id: number, name: string, permissions: WorkflowPermissionsFields } | null };

export const DocumentFields = gql`
    fragment DocumentFields on Document {
  id
  name
  currentVersion {
    ...DocumentVersionFields
  }
  documentType {
    id
    name
    icon
  }
  comment {
    id
    text
  }
  actionItem {
    id
    uuid
    name
    status
  }
  actionItemTemplate {
    id
    uuid
    name
  }
  tags {
    ...TagFields
  }
  permissions {
    canDelete
  }
  workflow {
    id
    name
    permissions {
      ...WorkflowPermissionsFields
    }
  }
}
    `;