import { EMPTY_ARRAY } from "../../constants/emptyArray";

/**
 * Concatenates multiple maybe-defined arrays into one flattened array, which contains only the
 * values from the arrays that were not undefined.
 *
 * Equivalent to:
 * ```
 * [
 *   ...(arr1 ?? EMPTY_ARRAY),
 *   ...(arr2 ?? EMPTY_ARRAY),
 *   // ...
 * ]
 * ```
 */
export function arrayConcatDefined<T>(...optionalArrs: Array<T[] | undefined>): T[] {
  return optionalArrs.reduce<T[]>((agg, optionalArr) => {
    agg.push(...(optionalArr ?? EMPTY_ARRAY));
    return agg;
  }, []);
}
