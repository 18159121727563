// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueFloatFields = (
  { sourceFieldInstanceValueFloat?: AcyclicFieldInstanceValueFloatFields | null, sinksFieldInstanceValueFloat: Array<AcyclicFieldInstanceValueFloatFields> }
  & FieldInstanceValueFloatBaseFields
);

export const FieldInstanceValueFloatFields = gql`
    fragment FieldInstanceValueFloatFields on FieldInstanceValueFloat {
  ...FieldInstanceValueFloatBaseFields
  sourceFieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
  sinksFieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
}
    `;