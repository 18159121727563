// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueIntFields } from './FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueIntWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceMultiValueInt?: AcyclicFieldInstanceMultiValueIntFields | null, crossWorkflowSinksFieldInstanceMultiValueInt?: Array<AcyclicFieldInstanceMultiValueIntFields> | null }
  & FieldInstanceMultiValueIntFields
);

export const FieldInstanceMultiValueIntWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueIntWithCrossWorkflowFields on FieldInstanceMultiValueInt {
  ...FieldInstanceMultiValueIntFields
  crossWorkflowSourceFieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
  crossWorkflowSinksFieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
}
    `;