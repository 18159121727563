import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { clsx, WithDataTestId } from "@regrello/core-utils";
import React, { useCallback, useEffect, useState } from "react";

import { REGRELLO_CHECKBOX_SIZE_CLASS_NAME } from "./regrelloControlConstants";

const CHECK_SVG = (
  <svg fill="none" height="11" viewBox="0 0 14 11" width="14" xmlns="http://www.w3.org/2000/svg">
    <title>Checkbox</title>
    <path d="M0 5.42L5 10.42L14 1.42L12.59 0L5 7.59L1.41 4.01L0 5.42Z" fill="white" />
  </svg>
);

const INDETERMINATE_DASH_SVG = (
  <svg fill="none" height="2" viewBox="0 0 8 2" width="8" xmlns="http://www.w3.org/2000/svg">
    <title>Indeterminate dash</title>
    <path d="M0 2H8V0H0V2Z" fill="white" />
  </svg>
);

export interface RegrelloCheckboxProps
  extends WithDataTestId,
    // (clewis): Use onCheckedChange instead of onChange.
    Omit<React.ComponentProps<typeof CheckboxPrimitive.Root>, "onChange" | "onCheckedChange"> {
  /** Whether the checkbox is in an error state. */
  hasError?: boolean;

  // (clewis): We'll never emit "indeterminate" as the next checked state. Just narrow to `boolean`.
  onCheckedChange?: (nextChecked: boolean) => void;
}

const RegrelloCheckbox = React.memo(
  React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, Omit<RegrelloCheckboxProps, "ref">>(
    function RegrelloCheckboxFn({ checked, className, dataTestId, hasError, onCheckedChange, ...props }, ref) {
      const [checkedStatus, setCheckedStatus] = useState<CheckboxPrimitive.CheckedState>(
        props.defaultChecked ?? checked ?? false,
      );

      const handleCheckedChange = useCallback(
        (nextCheckedStatus: CheckboxPrimitive.CheckedState) => {
          if (nextCheckedStatus === "indeterminate") {
            // (clewis): It isn't realistic for the next checked status to be "indeterminate", so
            // ignore this to simplify types for the consumer.
            return;
          }
          if (checked == null) {
            setCheckedStatus(nextCheckedStatus);
          }
          onCheckedChange?.(nextCheckedStatus);
        },
        [checked, onCheckedChange],
      );

      // (clewis): Stay in sync with controlled value.
      useEffect(() => {
        if (checked != null) {
          setCheckedStatus(checked);
        }
      }, [checked]);

      return (
        <CheckboxPrimitive.Root
          ref={ref}
          checked={checkedStatus}
          className={clsx(
            `
          ${REGRELLO_CHECKBOX_SIZE_CLASS_NAME}
          rounded
          `,
            // (clewis): Use box-shadows for checkbox borders. Using border caused the focus ring to
            // look way more curved than was correct.
            `
          rg-box-border
          bg-background
          hover:bg-neutral-transparentHovered
          active:bg-neutral-transparentPressed

          flex
          items-center
          justify-center
          self-center
          shrink-0

          focus-visible:outline-primary-solid
          focus-visible:outline-offset-3

          data-[state=checked]:shadow-none
          data-[state=checked]:bg-primary-solid
          data-[state=checked]:text-textContrast
          data-[state=checked]:hover:bg-primary-solidHovered
          data-[state=checked]:active:rg-solidPressed

          data-[state=indeterminate]:shadow-none
          data-[state=indeterminate]:bg-primary-solid
          data-[state=indeterminate]:text-textContrast
          data-[state=indeterminate]:hover:bg-primary-solidHovered
          data-[state=indeterminate]:active:rg-solidPressed

          disabled:bg-background
          disabled:data-[state=checked]:bg-primary-solid
          disabled:data-[state=indeterminate]:bg-primary-solid
          disabled:cursor-not-allowed
          disabled:opacity-disabled
          disabled:outline-none
          `,
            {
              [`
              shadow-danger-solid

              data-[state=checked]:bg-danger-solid
              data-[state=checked]:hover:bg-danger-solidHovered
              data-[state=checked]:active:rg-solidPressed

              data-[state=indeterminate]:bg-danger-solid
              data-[state=indeterminate]:hover:bg-danger-solidHovered
              data-[state=indeterminate]:active:rg-solidPressed
            `]: hasError,
            },
            className,
          )}
          data-testid={dataTestId}
          onCheckedChange={handleCheckedChange}
          {...props}
        >
          <CheckboxPrimitive.Indicator>
            {checkedStatus === "indeterminate" ? INDETERMINATE_DASH_SVG : CHECK_SVG}
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
      );
    },
  ),
);
RegrelloCheckbox.displayName = CheckboxPrimitive.Root.displayName;

export { RegrelloCheckbox };
