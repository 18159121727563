import { FieldInstanceFields } from "@regrello/graphql-api";

import { getSelectedFieldInstanceIdOrSourceId } from "./getFieldInstanceId";

export function equateFieldInstanceFields(
  fieldInstance1: FieldInstanceFields,
  fieldInstance2: FieldInstanceFields,
): boolean {
  return getSelectedFieldInstanceIdOrSourceId(fieldInstance1) === getSelectedFieldInstanceIdOrSourceId(fieldInstance2);
}
