import {
  ControllerBehaviorModifierType,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  TeamWithoutMembersFields,
  UserFields,
} from "@regrello/graphql-api";

import { getFieldInstanceId } from "./customFields/getFieldInstanceId";
import { PartyTypeUnion } from "./parties/PartyTypeUnion";
import { getPartyId } from "./parties/partyUtils";

// (hchen): Moving this util to a seperate file to avoid circular import
export function getIndexOfPartyInPartyTypeUnionArray(partyToFind: PartyTypeUnion, parties: PartyTypeUnion[]) {
  return PartyTypeUnion.visit(partyToFind, {
    user: (user: UserFields) => parties.findIndex((otherParty) => getPartyId(otherParty) === user.party.id),
    baseUser: (baseUser) => parties.findIndex((otherParty) => getPartyId(otherParty) === baseUser.party.id),
    userWithRoles: (userWithRoles) =>
      parties.findIndex((otherParty) => getPartyId(otherParty) === userWithRoles.party.id),
    role: (role) => parties.findIndex((otherParty) => otherParty.type === "role" && otherParty.role.id === role.id),
    fieldInstance: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues) =>
      parties.findIndex((otherParty) => {
        const otherPartyManagersOf = PartyTypeUnion.isFieldInstance(otherParty)
          ? otherParty.fieldInstance.controllerBehaviorModifier === ControllerBehaviorModifierType.MANAGERS_OF
          : null;

        const partyRedirectManagersOf = PartyTypeUnion.isFieldInstance(partyToFind)
          ? partyToFind.fieldInstance.controllerBehaviorModifier === ControllerBehaviorModifierType.MANAGERS_OF
          : null;

        return (
          PartyTypeUnion.isFieldInstance(otherParty) &&
          getFieldInstanceId(otherParty.fieldInstance) === getFieldInstanceId(fieldInstance) &&
          otherPartyManagersOf === partyRedirectManagersOf
        );
      }),
    team: (team: TeamWithoutMembersFields) =>
      parties.findIndex((otherParty) => getPartyId(otherParty) === team.party.id),
    unknown: () => -1,
  });
}
