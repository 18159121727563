// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type AcyclicFieldInstanceValueFloatFields = { __typename: 'FieldInstanceValueFloat', id: number, inputType: Types.FieldInstanceValueInputType, floatValue?: number | null };

export const AcyclicFieldInstanceValueFloatFields = gql`
    fragment AcyclicFieldInstanceValueFloatFields on FieldInstanceValueFloat {
  __typename
  id
  inputType
  floatValue
}
    `;