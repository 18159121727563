import { ComparatorResult } from "@regrello/core-utils";

import {
  COMPARATOR_RESULT_IF_BOTH_VALUES_UNDEFINED,
  COMPARATOR_RESULT_IF_ONLY_VALUE_A_UNDEFINED,
  COMPARATOR_RESULT_IF_ONLY_VALUE_B_UNDEFINED,
} from "./comparatorConstants";

export function numberComparator(valueA: number | undefined, valueB: number | undefined): ComparatorResult {
  if (valueA == null && valueB == null) {
    return COMPARATOR_RESULT_IF_BOTH_VALUES_UNDEFINED;
  }
  if (valueA == null) {
    return COMPARATOR_RESULT_IF_ONLY_VALUE_A_UNDEFINED;
  }
  if (valueB == null) {
    return COMPARATOR_RESULT_IF_ONLY_VALUE_B_UNDEFINED;
  }

  if (valueA < valueB) {
    return ComparatorResult.BEFORE;
  }
  if (valueB < valueA) {
    return ComparatorResult.AFTER;
  }
  return ComparatorResult.EQUAL;
}
