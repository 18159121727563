// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type PartyFields = { __typename: 'Party', id: number, user?: UserFields | null, team?: TeamWithoutMembersFields | null };

export const PartyFields = gql`
    fragment PartyFields on Party {
  __typename
  id
  user {
    ...UserFields
  }
  team {
    ...TeamWithoutMembersFields
  }
}
    `;