// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RetrieveDocumentDownloadUrlQueryVariables = Types.Exact<{
  documentVersionId: Types.Scalars['ID']['input'];
}>;


export type RetrieveDocumentDownloadUrlQuery = { retrieveDocumentDownloadUrl?: { signedUrl?: string | null } | null };


export const RetrieveDocumentDownloadUrlQueryDocument = gql`
    query RetrieveDocumentDownloadUrlQuery($documentVersionId: ID!) {
  retrieveDocumentDownloadUrl(documentVersionId: $documentVersionId) {
    signedUrl
  }
}
    `;

/**
 * __useRetrieveDocumentDownloadUrlQuery__
 *
 * To run a query within a React component, call `useRetrieveDocumentDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveDocumentDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveDocumentDownloadUrlQuery({
 *   variables: {
 *      documentVersionId: // value for 'documentVersionId'
 *   },
 * });
 */
export function useRetrieveDocumentDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<RetrieveDocumentDownloadUrlQuery, RetrieveDocumentDownloadUrlQueryVariables> & ({ variables: RetrieveDocumentDownloadUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveDocumentDownloadUrlQuery, RetrieveDocumentDownloadUrlQueryVariables>(RetrieveDocumentDownloadUrlQueryDocument, options);
      }
export function useRetrieveDocumentDownloadUrlQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveDocumentDownloadUrlQuery, RetrieveDocumentDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveDocumentDownloadUrlQuery, RetrieveDocumentDownloadUrlQueryVariables>(RetrieveDocumentDownloadUrlQueryDocument, options);
        }
export function useRetrieveDocumentDownloadUrlQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RetrieveDocumentDownloadUrlQuery, RetrieveDocumentDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RetrieveDocumentDownloadUrlQuery, RetrieveDocumentDownloadUrlQueryVariables>(RetrieveDocumentDownloadUrlQueryDocument, options);
        }
export type RetrieveDocumentDownloadUrlQueryHookResult = ReturnType<typeof useRetrieveDocumentDownloadUrlQuery>;
export type RetrieveDocumentDownloadUrlQueryLazyQueryHookResult = ReturnType<typeof useRetrieveDocumentDownloadUrlQueryLazyQuery>;
export type RetrieveDocumentDownloadUrlQuerySuspenseQueryHookResult = ReturnType<typeof useRetrieveDocumentDownloadUrlQuerySuspenseQuery>;
export type RetrieveDocumentDownloadUrlQueryResult = Apollo.QueryResult<RetrieveDocumentDownloadUrlQuery, RetrieveDocumentDownloadUrlQueryVariables>;