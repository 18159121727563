// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { TeamWithoutMembersFields } from '../../fragments/generated/TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TeamSelectorQueryResultsQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
  partyIdsToPrioritize?: Types.InputMaybe<Array<Types.Scalars['Int']['input']>>;
  roleIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type TeamSelectorQueryResultsQuery = { teamSelectorQueryResults?: { isRoleFilteringApplied: boolean, teams: Array<TeamWithoutMembersFields>, filteringByRoles?: Array<{ id: number, name: string }> | null } | null };


export const TeamSelectorQueryResultsQueryDocument = gql`
    query TeamSelectorQueryResultsQuery($query: String!, $partyIdsToPrioritize: [Int!], $roleIds: [ID!]) {
  teamSelectorQueryResults(
    input: {query: $query, partyIdsToPrioritize: $partyIdsToPrioritize, roleIds: $roleIds}
  ) {
    teams {
      ...TeamWithoutMembersFields
    }
    isRoleFilteringApplied
    filteringByRoles {
      id
      name
    }
  }
}
    ${TeamWithoutMembersFields}`;

/**
 * __useTeamSelectorQueryResultsQuery__
 *
 * To run a query within a React component, call `useTeamSelectorQueryResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamSelectorQueryResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamSelectorQueryResultsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      partyIdsToPrioritize: // value for 'partyIdsToPrioritize'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useTeamSelectorQueryResultsQuery(baseOptions: Apollo.QueryHookOptions<TeamSelectorQueryResultsQuery, TeamSelectorQueryResultsQueryVariables> & ({ variables: TeamSelectorQueryResultsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamSelectorQueryResultsQuery, TeamSelectorQueryResultsQueryVariables>(TeamSelectorQueryResultsQueryDocument, options);
      }
export function useTeamSelectorQueryResultsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamSelectorQueryResultsQuery, TeamSelectorQueryResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamSelectorQueryResultsQuery, TeamSelectorQueryResultsQueryVariables>(TeamSelectorQueryResultsQueryDocument, options);
        }
export function useTeamSelectorQueryResultsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TeamSelectorQueryResultsQuery, TeamSelectorQueryResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamSelectorQueryResultsQuery, TeamSelectorQueryResultsQueryVariables>(TeamSelectorQueryResultsQueryDocument, options);
        }
export type TeamSelectorQueryResultsQueryHookResult = ReturnType<typeof useTeamSelectorQueryResultsQuery>;
export type TeamSelectorQueryResultsQueryLazyQueryHookResult = ReturnType<typeof useTeamSelectorQueryResultsQueryLazyQuery>;
export type TeamSelectorQueryResultsQuerySuspenseQueryHookResult = ReturnType<typeof useTeamSelectorQueryResultsQuerySuspenseQuery>;
export type TeamSelectorQueryResultsQueryResult = Apollo.QueryResult<TeamSelectorQueryResultsQuery, TeamSelectorQueryResultsQueryVariables>;