import { RegrelloNonIdealStateWithRefresh } from "@regrello/ui-core";
import { ErrorBoundaryDescription, ErrorBoundaryTitle } from "@regrello/ui-strings";
import { createRoot } from "react-dom/client";

import { App } from "./App";
import { RegrelloErrorBoundary } from "./ui/molecules/errorBoundary/RegrelloErrorBoundary";
import { onBeforeSentryCaptureOnAppRoot } from "./utils/sentryScopeUtils";

import "@unocss/reset/tailwind.css";
import "virtual:uno.css";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <RegrelloErrorBoundary
    beforeCapture={onBeforeSentryCaptureOnAppRoot}
    fallback={<RegrelloNonIdealStateWithRefresh description={ErrorBoundaryDescription} title={ErrorBoundaryTitle} />}
  >
    <App />
  </RegrelloErrorBoundary>,
);
