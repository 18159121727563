// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type PartyBaseFields = { __typename: 'Party', id: number, user?: UserBaseFields | null, team?: TeamWithoutMembersFields | null };

export const PartyBaseFields = gql`
    fragment PartyBaseFields on Party {
  __typename
  id
  user {
    ...UserBaseFields
  }
  team {
    ...TeamWithoutMembersFields
  }
}
    `;