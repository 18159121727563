// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { WorkflowTemplateForSelectFieldFields } from '../../fragments/generated/WorkflowTemplateForSelectFieldFields.generated';
import { FieldInstanceFields } from '../../fragments/generated/FieldInstanceFields.generated';
import { FieldInstanceBaseFields } from '../../fragments/generated/FieldInstanceBaseFields.generated';
import { FieldFields } from '../../fragments/generated/FieldFields.generated';
import { PartyBaseFields } from '../../fragments/generated/PartyBaseFields.generated';
import { UserBaseFields } from '../../fragments/generated/UserBaseFields.generated';
import { TeamWithoutMembersFields } from '../../fragments/generated/TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from '../../fragments/generated/PropertyTypeFields.generated';
import { FieldUnitFields } from '../../fragments/generated/FieldUnitFields.generated';
import { RegrelloObjectFields } from '../../fragments/generated/RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from '../../fragments/generated/AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from '../../fragments/generated/RegrelloObjectPropertyFields.generated';
import { FieldInstanceSpectrumMetadataFields } from '../../fragments/generated/FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from '../../fragments/generated/FieldInstanceSpectrumFields.generated';
import { SpectrumFieldVersionFields } from '../../fragments/generated/SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from '../../fragments/generated/SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from '../../fragments/generated/SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from '../../fragments/generated/SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from '../../fragments/generated/SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from '../../fragments/generated/SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValueFieldsFieldInstanceMultiValueDocument, FieldInstanceValueFieldsFieldInstanceMultiValueFloat, FieldInstanceValueFieldsFieldInstanceMultiValueInt, FieldInstanceValueFieldsFieldInstanceMultiValueParty, FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueFieldsFieldInstanceMultiValueString, FieldInstanceValueFieldsFieldInstanceMultiValueTime, FieldInstanceValueFieldsFieldInstanceValueBoolean, FieldInstanceValueFieldsFieldInstanceValueFloat, FieldInstanceValueFieldsFieldInstanceValueInt, FieldInstanceValueFieldsFieldInstanceValueParty, FieldInstanceValueFieldsFieldInstanceValueString, FieldInstanceValueFieldsFieldInstanceValueTime } from '../../fragments/generated/FieldInstanceValueFields.generated';
import { FieldInstanceValueStringFields } from '../../fragments/generated/FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from '../../fragments/generated/FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from '../../fragments/generated/AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringFields } from '../../fragments/generated/FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from '../../fragments/generated/FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntFields } from '../../fragments/generated/FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from '../../fragments/generated/FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from '../../fragments/generated/AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatFields } from '../../fragments/generated/FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from '../../fragments/generated/FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from '../../fragments/generated/AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanFields } from '../../fragments/generated/FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from '../../fragments/generated/FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from '../../fragments/generated/AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeFields } from '../../fragments/generated/FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from '../../fragments/generated/FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from '../../fragments/generated/AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from '../../fragments/generated/AcyclicFieldInstanceFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from '../../fragments/generated/AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from '../../fragments/generated/AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from '../../fragments/generated/DocumentBaseFields.generated';
import { TagFields } from '../../fragments/generated/TagFields.generated';
import { TagTypeFields } from '../../fragments/generated/TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from '../../fragments/generated/RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceValuePartyFields } from '../../fragments/generated/FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyFields } from '../../fragments/generated/FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentFields } from '../../fragments/generated/FieldInstanceMultiValueDocumentFields.generated';
import { DocumentFields } from '../../fragments/generated/DocumentFields.generated';
import { DocumentVersionFields } from '../../fragments/generated/DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from '../../fragments/generated/WorkflowPermissionsFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from '../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntFields } from '../../fragments/generated/FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from '../../fragments/generated/FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatFields } from '../../fragments/generated/FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from '../../fragments/generated/FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeFields } from '../../fragments/generated/FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from '../../fragments/generated/FieldInstanceMultiValueTimeBaseFields.generated';
import { NameTemplateFields } from '../../fragments/generated/NameTemplateFields.generated';
import { gql } from '@apollo/client';
import { FieldInstanceValueFields } from '../../fragments/generated/FieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValueFields } from '../../fragments/generated/AcyclicFieldInstanceValueFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWorkflowTemplateMutationVariables = Types.Exact<{
  input: Types.CreateWorkflowTemplateInputs;
}>;


export type CreateWorkflowTemplateMutation = { createWorkflowTemplate?: { workflowTemplate: (
      { description?: string | null }
      & WorkflowTemplateForSelectFieldFields
    ) } | null };


export const CreateWorkflowTemplateMutationDocument = gql`
    mutation CreateWorkflowTemplateMutation($input: CreateWorkflowTemplateInputs!) {
  createWorkflowTemplate(input: $input) {
    workflowTemplate {
      ...WorkflowTemplateForSelectFieldFields
      description
    }
  }
}
    ${WorkflowTemplateForSelectFieldFields}
${FieldInstanceFields}
${FieldInstanceBaseFields}
${FieldFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}
${FieldInstanceSpectrumMetadataFields}
${FieldInstanceSpectrumFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldInstanceValueFields}
${FieldInstanceValueStringFields}
${FieldInstanceValueStringBaseFields}
${AcyclicFieldInstanceValueStringFields}
${FieldInstanceMultiValueStringFields}
${FieldInstanceMultiValueStringBaseFields}
${AcyclicFieldInstanceMultiValueStringFields}
${FieldInstanceValueIntFields}
${FieldInstanceValueIntBaseFields}
${AcyclicFieldInstanceValueIntFields}
${FieldInstanceValueFloatFields}
${FieldInstanceValueFloatBaseFields}
${AcyclicFieldInstanceValueFloatFields}
${FieldInstanceValueBooleanFields}
${FieldInstanceValueBooleanBaseFields}
${AcyclicFieldInstanceValueBooleanFields}
${FieldInstanceValueTimeFields}
${FieldInstanceValueTimeBaseFields}
${AcyclicFieldInstanceValueTimeFields}
${AcyclicFieldInstanceFields}
${AcyclicFieldInstanceValueFields}
${AcyclicFieldInstanceValuePartyFields}
${AcyclicFieldInstanceMultiValuePartyFields}
${AcyclicFieldInstanceMultiValueDocumentFields}
${DocumentBaseFields}
${TagFields}
${TagTypeFields}
${AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields}
${RegrelloObjectInstanceFields}
${AcyclicFieldInstanceMultiValueIntFields}
${AcyclicFieldInstanceMultiValueFloatFields}
${AcyclicFieldInstanceMultiValueTimeFields}
${FieldInstanceValuePartyFields}
${FieldInstanceMultiValuePartyFields}
${FieldInstanceMultiValueDocumentFields}
${DocumentFields}
${DocumentVersionFields}
${WorkflowPermissionsFields}
${FieldInstanceMultiValueRegrelloObjectInstanceFields}
${FieldInstanceMultiValueIntFields}
${FieldInstanceMultiValueIntBaseFields}
${FieldInstanceMultiValueFloatFields}
${FieldInstanceMultiValueFloatBaseFields}
${FieldInstanceMultiValueTimeFields}
${FieldInstanceMultiValueTimeBaseFields}
${NameTemplateFields}`;
export type CreateWorkflowTemplateMutationFn = Apollo.MutationFunction<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>;

/**
 * __useCreateWorkflowTemplateMutation__
 *
 * To run a mutation, you first call `useCreateWorkflowTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkflowTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkflowTemplateMutation, { data, loading, error }] = useCreateWorkflowTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkflowTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>(CreateWorkflowTemplateMutationDocument, options);
      }
export type CreateWorkflowTemplateMutationHookResult = ReturnType<typeof useCreateWorkflowTemplateMutation>;
export type CreateWorkflowTemplateMutationResult = Apollo.MutationResult<CreateWorkflowTemplateMutation>;
export type CreateWorkflowTemplateMutationOptions = Apollo.BaseMutationOptions<CreateWorkflowTemplateMutation, CreateWorkflowTemplateMutationVariables>;