import { clsx, EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FieldFields } from "@regrello/graphql-api";
import {
  RegrelloFullViewSpinner,
  RegrelloIcon,
  RegrelloSize,
  RegrelloTooltipV4,
  RegrelloTypography,
} from "@regrello/ui-core";
import { AutogenerateWorkflowName, NamingConvention, NamingConventionTooltipText, Preview } from "@regrello/ui-strings";
import { JSONContent } from "@tiptap/react";
import React, { Suspense, useCallback, useState } from "react";

import { RegrelloNameTemplateFormFieldProps } from "./RegrelloNameTemplateFormField";
import {
  FrontendNameTemplate,
  getNameTemplateInputsFromJson,
  getPreviewStringFromJson,
  NameTemplateHandle,
} from "./utils/nameTemplateUtils";
import { RegrelloFormFieldSwitch } from "../formFields/RegrelloFormFieldSwitch";

// (zstanik): Tiptap is a relatively large library that shouldn't be included in our main app
// bundle, especially given its limited use cases in our product for now. Any component leveraging
// it should be dynamically imported so it's split into a different chunk when the app is built.
const LazyRegrelloNameTemplateFormField = React.lazy(() =>
  import("./RegrelloNameTemplateFormField").then((module) => ({
    default: module.RegrelloNameTemplateFormField,
  })),
);

export interface RegrelloNameTemplateFormSectionProps
  extends Pick<RegrelloNameTemplateFormFieldProps, "allowCreateFields" | "context"> {
  /**
   * Places the name template editor container at the end of the toggle to turn it on or off.
   *
   * @default false
   */
  alignToEndOfSwitch?: boolean;

  /** An initial name template to convert to JSON and load into the editor. */
  defaultNameTemplate?: FrontendNameTemplate;

  /**
   * Whether the whole name template form section is disabled.
   * @default false
   */
  disabled?: boolean;

  /** An error message to display for this form field. */
  error?: string;

  /**
   * Callback invoked to get the info state tooltip text for a given field name (the full field
   * object isn't available at this level). No tooltip is rendered if this prop is undefined or
   * returns undefined.
   */
  getFieldChipInfoStateTooltipText?: (fieldName: string) => string | undefined;

  /**
   * Width of labels in this form. Used to adjust styling to line up with other form fields. Narrow
   * type definition since Tailwind CSS doesn't play well with dynamic values. If another width is
   * needed just add it to the accepted scalar values and add adjust the classnames as appropriate.
   *
   * @default 108
   */
  labelWidth?: 0 | 108 | 148;

  /**
   * A ref to pass onto the name template form field. Exposes a handle for getting name template
   * inputs from the current editor content.
   */
  nameTemplateFormFieldRef: React.RefObject<NameTemplateHandle>;

  preloadedFields?: FieldFields[];

  /**
   * Title of the section.
   *
   * @default AutogenerateWorkflowName
   */
  title?: JSX.Element | string;

  /**
   * Text displayed in a tooltip next to the title for the section.
   * Pass `null` to remove the help tooltip.
   *
   * @default NamingConventionTooltipText
   */
  helpTooltip?: JSX.Element | string | null;

  /** Callback invoked when the fields in use in the name template change. */
  onFieldsUpdate: (fields: FieldFields[]) => void;

  /**
   * Callback invoked when the string input changes. When the user hides the Naming Convention form
   * field, this callback will be invoked with `undefined`.
   */
  onTemplateUpdate?: (template: string | undefined) => void;
}

/**
 * Renders a form section for name templates, including the input, a header, and a preview of the
 * string template.
 */
export const RegrelloNameTemplateFormSection = React.memo<RegrelloNameTemplateFormSectionProps>(
  function RegrelloNameTemplateFormSectionFn({
    alignToEndOfSwitch = false,
    allowCreateFields,
    defaultNameTemplate,
    disabled = false,
    error,
    context,
    getFieldChipInfoStateTooltipText,
    labelWidth = 108,
    nameTemplateFormFieldRef,
    preloadedFields,
    title = AutogenerateWorkflowName,
    helpTooltip = NamingConventionTooltipText,
    onFieldsUpdate,
    onTemplateUpdate,
  }: RegrelloNameTemplateFormSectionProps) {
    const [isNameTemplateInputPresent, setIsNameTemplateInputPresent] = useState(defaultNameTemplate != null);
    const [previousFields, setPreviousFields] = useState<FieldFields[]>(EMPTY_ARRAY);
    const [previousFrontendNameTemplate, setPreviousFrontendNameTemplate] = useState<FrontendNameTemplate | undefined>(
      undefined,
    );
    const [nameTemplatePreview, setNameTemplatePreview] = useState("");

    const handleFieldsUpdate = useCallback(
      (fields: FieldFields[]) => {
        setPreviousFields(fields);
        onFieldsUpdate(fields);
      },
      [onFieldsUpdate],
    );

    // (zstanik): Restore the previous content if the name template was turned off then on again
    // without closing the dialog.
    const handleNameTemplateSwitchToggle = useCallback(() => {
      const nameTemplateInputs = nameTemplateFormFieldRef.current?.getNameTemplateInputs();
      if (nameTemplateInputs != null) {
        setPreviousFrontendNameTemplate({ stringTemplate: nameTemplateInputs.stringTemplate, fields: previousFields });
      }
      onFieldsUpdate(isNameTemplateInputPresent ? EMPTY_ARRAY : previousFields);
      onTemplateUpdate?.(isNameTemplateInputPresent ? undefined : EMPTY_STRING);
      setIsNameTemplateInputPresent((value) => !value);
    }, [isNameTemplateInputPresent, nameTemplateFormFieldRef, onFieldsUpdate, onTemplateUpdate, previousFields]);

    const nameTemplateComponent = (
      <LazyRegrelloNameTemplateFormField
        ref={nameTemplateFormFieldRef}
        allowCreateFields={allowCreateFields}
        context={context}
        defaultNameTemplate={previousFrontendNameTemplate ?? defaultNameTemplate}
        error={error}
        getFieldChipInfoStateTooltipText={getFieldChipInfoStateTooltipText}
        isReadOnly={disabled}
        onFieldsUpdate={handleFieldsUpdate}
        onUpdate={(json: JSONContent) => {
          setNameTemplatePreview(getPreviewStringFromJson(json));
          onTemplateUpdate?.(
            getNameTemplateInputsFromJson(json)?.stringTemplate ??
              (isNameTemplateInputPresent ? EMPTY_STRING : undefined),
          );
        }}
        preloadedFields={preloadedFields}
      />
    );

    return (
      <div className="mb-2">
        <RegrelloFormFieldSwitch
          checked={isNameTemplateInputPresent}
          dataTestId={DataTestIds.NAME_TEMPLATE_SWITCH}
          disabled={disabled}
          label=" " // HACKHACK (clewis): Must provide a non-empty label to get the switch to vertically align with the other form fields.
          labelWidth={labelWidth}
          onChange={handleNameTemplateSwitchToggle}
          secondaryLabel={
            <div className="flex items-center gap-1">
              {title}
              {helpTooltip != null && (
                <RegrelloTooltipV4 align="start" content={helpTooltip} side="top" variant="popover">
                  <div>
                    <RegrelloIcon iconName="help-outline" intent="neutral" size={RegrelloSize.X_SMALL} />
                  </div>
                </RegrelloTooltipV4>
              )}
            </div>
          }
        />

        {isNameTemplateInputPresent && (
          <Suspense fallback={<RegrelloFullViewSpinner />}>
            {disabled ? (
              <div
                className={clsx({
                  "ml-39": alignToEndOfSwitch && labelWidth === 108,
                  "ml-12": alignToEndOfSwitch && labelWidth === 0,
                  "ml-29": !alignToEndOfSwitch && labelWidth === 108,
                  "ml-2": !alignToEndOfSwitch && labelWidth === 0,
                })}
              >
                {nameTemplateComponent}
              </div>
            ) : (
              <div
                className={clsx("p-2 bg-backgroundSoft", {
                  "ml-12": alignToEndOfSwitch && labelWidth === 0,
                  "ml-2": !alignToEndOfSwitch && labelWidth === 0,
                  "ml-39": alignToEndOfSwitch && labelWidth === 108,
                  "ml-29": !alignToEndOfSwitch && labelWidth === 108,
                  "ml-49": alignToEndOfSwitch && labelWidth === 148,
                  "ml-39 ": !alignToEndOfSwitch && labelWidth === 148,
                })}
              >
                <span className="flex items-center gap-1 mb-2 font-medium">{NamingConvention}</span>
                {nameTemplateComponent}
                <RegrelloTypography className="mt-1.5">
                  <span className="text-textPlaceholder">{`${Preview}: `}</span>
                  {nameTemplatePreview}
                </RegrelloTypography>
              </div>
            )}
          </Suspense>
        )}
      </div>
    );
  },
);
