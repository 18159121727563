/** Returns true if the provided arrays are deeply equal according to the provided callback. */
export function isArraysEqual<T>(a: T[], b: T[], isElementsEqual: (elementA: T, elementB: T) => boolean): boolean {
  if (a == null && b == null) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }
  return a.every((element, index) => isElementsEqual(element, b[index]));
}
