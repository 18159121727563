// (dosipiuk): Disabled due to false-positives const exports using PascalCase
/* eslint-disable react-refresh/only-export-components */
import {
  EMPTY_STRING,
  formatDateString,
  isValidEmail,
  isValidMultiEmail,
  isValidRestrictiveEmail,
  ValuesUnion,
} from "@regrello/core-utils";
import {
  ContactEmail,
  FormErrorAfterDate,
  FormErrorBeforeDate,
  FormErrorBulkEmailInvalid,
  FormErrorEmailInvalid,
  FormErrorFileTypeInvalid,
  FormErrorGreaterThanOrEqualToLowerBound,
  FormErrorIsRequired,
  FormErrorLessThanOrEqualToUpperBound,
  FormErrorMaxLength,
  FormErrorMinLength,
  InvalidEmailErrorMessage,
  MustBeNumber,
  MustBeUniqueValue,
  MustBeValidUrl,
  MustBeWholeNumber,
  MustBeWholeNumberInRange,
  PleaseEnterAMessage,
} from "@regrello/ui-strings";
import { addMilliseconds, isAfter, isBefore } from "date-fns";

import { getFileExtension } from "../utils/documentUtil";

// These constants are injected by our build step. See vite.config.ts.
/* eslint-disable no-underscore-dangle, no-restricted-syntax */
declare const __INJECTED_APP_VERSION__: string;
// (clewis): The slash may be escaped when injected via the CI Build job. Handle both cases.
export const APP_VERSION = __INJECTED_APP_VERSION__.replace("\\t", " ").replace("\t", " ");
/* eslint-enable no-underscore-dangle, no-restricted-syntax */

/**
 * Make sure this matches in `vite.config.ts`. It's duplicated because `vite` throws an error if I
 * inject it using the same technique as `__INJECTED_APP_VERSION__`.
 */
export const SENTRY_RELEASE = APP_VERSION.replace(/[^\w.]/g, "-").slice(0, 200);

export const API_HOSTNAME = import.meta.env.VITE_REGRELLO_API_HOSTNAME;

export const ACTION_ITEM_UUID_EXPECTED_FORMAT =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

export const DocumentsTableColumnName = {
  FILE_NAME: "fileName",
  ACTION: "action",
  WORKFLOW: "workflow",
  UPLOAD_DATE: "uploadDate",
  ADDED_BY: "addedBy",
  TAGS: "tags",
  DELETE: "delete",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DocumentsTableColumnName = ValuesUnion<typeof DocumentsTableColumnName>;

export const CSS_FULL_WIDTH = "100%";

export const DEFAULT_DOCUMENTS_TABLE_COLUMN_WIDTHS_BY_ID: Record<DocumentsTableColumnName, number> = {
  fileName: 242,
  action: 222,
  workflow: 222,
  uploadDate: 166,
  addedBy: 180,
  // (clewis): Will be automeasured to fit all cell content. When the delete column is enabled, we
  // use 'flex: 1' instead of this width.
  tags: 100,
  delete: 50,
};

export const DEBOUNCE_TIMEOUT = 150;
export const THROTTLE_TIMEOUT = 200;

export const MESSAGE_HIGHLIGHT_AUTO_DISMISS_DURATION = 2000;

export const REGRELLO_PRIVACY_POLICY_URL = "https://www.regrello.com/privacy-policy";

export enum ResponseStatus {
  SUCCESS = 200,
  TOO_MANY_REQUESTS = 429,
  NOT_FOUND = 404,
}

export const TabType: Record<string, string> = {
  TAG: "tag",
  INDIVIDUAL: "individual",
};

export const WORKFLOW_HEADER_HEIGHT = 85;
export const WORKFLOW_HEADER_HEIGHT_V2 = 44;
export const WORKFLOW_HEADER_HEIGHT_V2_CLASS = "sm:h-11";

export const SECONDS_IN_DAY = 60 * 60 * 24;
export const SECONDS_IN_HOUR = 60 * 60;
export const SECONDS_IN_MINUTE = 60;

export const MILLISECONDS_IN_MINUTE = 1000 * 60;
export const DAYS_IN_WEEK = 7;

export enum TimeUnit {
  DAYS = "Days",
  HOURS = "Hours",
  MINUTES = "Minutes",
}
export const TimeToCompleteUnits = [TimeUnit.DAYS, TimeUnit.HOURS, TimeUnit.MINUTES];

export const ValidationRules = {
  AFTER_DATE: (dateToCompare: Date) => ({
    validate: (value: Date | null) => {
      // (max): Null values are not validated; an empty value is neither before nor after.
      if (value == null || isAfter(value, dateToCompare)) {
        return true;
      }
      return FormErrorAfterDate(formatDateString(dateToCompare));
    },
  }),
  BEFORE_DATE: (dateToCompare: Date) => ({
    validate: (value: Date | null) => {
      // (max): Null values are not validated; an empty value is neither before nor after.
      if (value == null || isBefore(value, addMilliseconds(dateToCompare, 1))) {
        return true;
      }
      return FormErrorBeforeDate(formatDateString(dateToCompare));
    },
  }),
  GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND: (lowerBound: number) => ({
    min: {
      value: lowerBound,
      message: FormErrorGreaterThanOrEqualToLowerBound(Math.max(lowerBound, Number.MIN_SAFE_INTEGER)),
    },
  }),
  INTEGER: {
    validate: {
      isInteger: (value: unknown) =>
        value == null || value === EMPTY_STRING // (hchen): Handles the case that useForm sets a field with an empty string when the corresponding default value is empty, this validation fails incorrectly.
          ? true
          : Number.isInteger(Number(value))
            ? Number(value) > Number.MAX_SAFE_INTEGER
              ? FormErrorLessThanOrEqualToUpperBound(Number.MAX_SAFE_INTEGER)
              : Number(value) < Number.MIN_SAFE_INTEGER
                ? FormErrorGreaterThanOrEqualToLowerBound(Number.MIN_SAFE_INTEGER)
                : true
            : MustBeWholeNumber,
    },
  },
  LESS_THAN_OR_EQUAL_TO_UPPER_BOUND: (upperBound: number) => ({
    max: {
      value: upperBound,
      message: FormErrorLessThanOrEqualToUpperBound(Math.min(upperBound, Number.MAX_VALUE)),
    },
  }),
  /** Pass in `null` to turn off this validation rule. */
  MIN_LENGTH: (minLength: number | null) => ({
    minLength:
      minLength != null
        ? {
            value: minLength,
            message: FormErrorMinLength(minLength),
          }
        : undefined,
  }),
  MAX_LENGTH: (maxLength: number) => ({
    maxLength: {
      value: maxLength,
      message: FormErrorMaxLength(maxLength),
    },
  }),
  NON_EMPTY_STRING_WHEN_TRIMMED: {
    validate: (value: string) => (value.trim().length > 0 ? true : PleaseEnterAMessage),
  },
  NOT_REQUIRED: {
    required: false,
  },
  NUMBER: {
    validate: {
      isNumber: (value: unknown) =>
        value == null || value === EMPTY_STRING // (hchen): Handles the case that useForm sets a field with an empty string when the corresponding default value is empty, this validation fails incorrectly.
          ? true
          : !Number.isNaN(Number(value))
            ? Number(value) > Number.MAX_SAFE_INTEGER
              ? FormErrorLessThanOrEqualToUpperBound(Number.MAX_SAFE_INTEGER)
              : Number(value) < Number.MIN_SAFE_INTEGER
                ? FormErrorGreaterThanOrEqualToLowerBound(Number.MIN_SAFE_INTEGER)
                : true
            : MustBeNumber,
    },
  },
  POSITIVE_INTEGER_IN_RANGE: ({ min, max }: { min: number; max: number }) => {
    const param = { min: Math.max(min, Number.MIN_SAFE_INTEGER), max: Math.min(max, Number.MAX_SAFE_INTEGER) };
    return {
      min: {
        message: MustBeWholeNumberInRange(param),
        value: min,
      },
      max: {
        message: MustBeWholeNumberInRange(param),
        value: max,
      },
      pattern: {
        message: MustBeWholeNumberInRange(param),
        value: /^\d+$/, // Does not include negative numbers
      },
    };
  },
  REQUIRED: {
    validate: (value: unknown) => {
      if (typeof value !== "string") {
        return true;
      } else {
        return value.trim().length > 0 ? true : FormErrorIsRequired;
      }
    },
    required: {
      value: true,
      message: FormErrorIsRequired,
    },
  },
  REQUIRED_WITH_MESSAGE: (errorMessage: string) => {
    return {
      validate: (value: unknown) => {
        if (typeof value !== "string") {
          return true;
        } else {
          return value.trim().length > 0 ? true : errorMessage;
        }
      },
      required: {
        value: true,
        message: errorMessage,
      },
    };
  },
  /** Validate that the value does not exist in the provided string array. */
  UNIQUE_IN: ({ otherValues }: { otherValues: string[] }) => {
    return {
      validate: (value: string) => {
        return otherValues.includes(value) ? MustBeUniqueValue : true;
      },
    };
  },
  VALID_URL: {
    validate: (value: string) => {
      const browserUrlRegex =
        /^https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+,.~#?&//=]*)$/;
      return browserUrlRegex.test(value) ? true : MustBeValidUrl;
    },
  },
  VALID_URL_WITH_OPTIONAL_SCHEME: {
    validate: (value: string) => {
      let validateValue = value;
      // This validation should be kept in sync with utils/url.go
      const schemeRegex = /^[a-zA-Z]+:\/\//;
      const browserUrlRegex =
        /^https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+,.~#?&//=]*)$/;
      if (!schemeRegex.test(validateValue)) {
        validateValue = "https://" + validateValue;
      }
      return browserUrlRegex.test(validateValue) ? true : MustBeValidUrl;
    },
  },
  VALID_EMAIL: {
    validate: (value: string) => (isValidEmail(value) ? true : FormErrorEmailInvalid),
  },
  /** Pass in `null` to turn off this validation rule. */
  VALID_BLUEPRINT_CONTACT_EMAIL_ALIAS: (emailDomain: string | null) => ({
    validate:
      emailDomain != null
        ? (value: string) =>
            isValidRestrictiveEmail(ContactEmail(value, emailDomain)) ? true : InvalidEmailErrorMessage
        : undefined,
  }),
  VALID_OR_EMPTY_EMAIL: {
    validate: (value: string) => (value.length === 0 ? true : isValidEmail(value) ? true : FormErrorEmailInvalid),
  },
  VALID_MULTI_EMAIL: {
    validate: (value: string) =>
      value.length === 0 ? true : isValidMultiEmail(value) ? true : FormErrorBulkEmailInvalid,
  },
  VALID_DOCUMENT_FORMAT: (acceptedFormats: FrontendAllowedFileTypes[]) => {
    return {
      validate: (values: Array<{ name: string; currentVersion: { externalLink?: string } }>) => {
        return values.every((document) => {
          // (hchen): Don't validate links attached as documents.
          if (document.currentVersion.externalLink != null) {
            return true;
          }
          const documentType = getFileExtension(document.name);
          return documentType != null && acceptedFormats.includes(documentType as FrontendAllowedFileTypes);
        })
          ? true
          : FormErrorFileTypeInvalid(acceptedFormats);
      },
    };
  },

  // (hchen): Passing in undefined or null to `rule` doesn`t reset the rules automatically. We need
  // to either use this rule or unregister the field completely. However, unregistering will erase
  // the values too so it's not ideal.
  REMOVE_ALL_RULES: {
    required: false,
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    minLength: -1,
    maxLength: Number.MAX_SAFE_INTEGER,
    pattern: /.*/,
    validate: () => true,
  },
};

export const WORKFLOW_ACTION_ITEM_LEAD_TIME_MIN_DAYS = 1;
export const WORKFLOW_ACTION_ITEM_LEAD_TIME_MAX_DAYS = 365;

/** How long to wait before polling for new data via a given GraphQL query. */
export enum DataRefreshPollInterval {
  DEFAULT = 10_000, // Realtime-ish.
  OCCASIONALLY = 30_000,
  OFTEN = 5_000,
  NEVER = 0,
}

export enum RegrelloFieldControllerNames {
  REGRELLO_FIELD_CONTROLLER_ASSIGNEES = "RegrelloTaskAssignees",
  REGRELLO_FIELD_CONTROLLER_CC = "RegrelloTaskCc",
  REGRELLO_FIELD_CONTROLLER_DUE_ON = "RegrelloTaskDueOn",
}

// (zstanik): don't change these names unless the corresponding system fields are changed on the BE
// (which is highly unlikely since these fields already exist in a number of workspaces now). These
// are the exact names of the system fields as created upon running migrations in a new workspace.
// If these are changed, tests will fail too.
export const SUBMISSION_PDF_FIELD_NAME = "Submission PDF";
export const WORKFLOW_OWNER_FIELD_NAME = "Workflow owner";
export const WORKFLOW_REFERENCE_ID_FIELD_NAME = "Workflow Reference ID";
export const EMAIL_SYSTEM_FIELD_NAMES = [
  "Requestor email",
  "Email attachments",
  "Email body",
  "Email body (PDF)",
  "Email subject",
];

/** Indicates that the column does not exist on the backend yet and needs to be created. */
export const UNDEFINED_COLUMN_ID = -1;

export const REGRELLO_SUPPORT_EMAIL = "support@regrello.com";

export const FrontendAllowedFileTypes = {
  PDF: "pdf",
  XLS: "xls",
  XLSX: "xlsx",
  CSV: "csv",
  PNG: "png",
  JPG: "jpg",
  DOC: "doc",
  DOCX: "docx",
  DWG: "dwg",
  DXF: "dxf",
  PPT: "ppt",
  GIF: "gif",
  HTML: "html",
  MSG: "msg",
  PSD: "psd",
  AI: "ai",
  TXT: "txt",
  XML: "xml",
  ZIP: "zip",
  TIFF: "tiff",
  REX: "rex", // (wbuchanan): Regrello blueprint export file
  KEY: "key",
  NUMBERS: "numbers",
  PAGES: "pages",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FrontendAllowedFileTypes = ValuesUnion<typeof FrontendAllowedFileTypes>;

export const AllAllowedFileTypes = Object.values(FrontendAllowedFileTypes);
