import { clsx, ValuesUnion } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  RegrelloButton,
  RegrelloIcon,
  RegrelloIconName,
  RegrelloIconStyler,
  RegrelloTypography,
} from "@regrello/ui-core";
import { memo, useCallback } from "react";
import { toast } from "sonner";

export const RegrelloToastIntent = {
  INFO: "info" as const, // blue indicator
  SUCCESS: "success" as const, // green indicator
  WARNING: "warning" as const, // orange indicator
  DANGER: "danger" as const, // red indicator
  NONE: "none" as const, // no indicator
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RegrelloToastIntent = ValuesUnion<typeof RegrelloToastIntent>;

export interface RegrelloToastProps {
  id?: number | string;

  /**
   * Content to display in the body of a toast. If a plain string is provided, it will
   * be rendered a secondary text.
   */
  content: string | React.ReactNode;

  /** An icon to show before the toast message title. */
  icon?: RegrelloIconName | JSX.Element;

  /**
   * Determines the color of the vertical indicator bar.
   */
  intent?: RegrelloToastIntent;

  /**
   * An optional title to display in the toast. Will be rendered above the {@link content}.
   */
  title?: string;
}

export const RegrelloToast = memo<RegrelloToastProps & { toastId?: string | number }>(function RegrelloToastFn({
  content,
  title,
  icon,
  intent,
  toastId,
}: RegrelloToastProps & { toastId?: string | number }) {
  const renderStartIcon = useCallback((iconInternal: RegrelloIconName | JSX.Element) => {
    return typeof iconInternal === "string" ? (
      <RegrelloIcon iconName={iconInternal} size="medium" />
    ) : (
      <RegrelloIconStyler size="medium">{iconInternal}</RegrelloIconStyler>
    );
  }, []);

  const renderContent = useCallback(() => {
    return typeof content === "string" ? (
      <RegrelloTypography muted={true}>{content}</RegrelloTypography>
    ) : (
      <div className="text-textMuted">{content}</div>
    );
  }, [content]);

  return (
    <div
      className="w-full max-w-100 flex bg-background rounded border shadow-md"
      data-testid={DataTestIds.TOAST_MESSAGE}
    >
      <div
        className={clsx("self-stretch w-1.5 my-1 ml-1 rounded-circular", {
          "bg-danger-icon": intent === "danger",
          "bg-primary-icon": intent === "info",
          "bg-success-icon": intent === "success",
          "bg-warning-icon": intent === "warning",
        })}
      />
      <div className="w-full flex items-center pl-4 pr-2">
        {icon != null && (
          <div
            className={clsx("self-start mt-2.5", {
              "text-danger-icon": intent === "danger",
              "text-primary-icon": intent === "info",
              "text-success-icon": intent === "success",
              "text-warning-icon": intent === "warning",
            })}
          >
            {renderStartIcon(icon)}
          </div>
        )}
        <div className="flex-1 pl-4 pr-8 py-3">
          {title != null && <RegrelloTypography>{title}</RegrelloTypography>}
          <div>{renderContent()}</div>
        </div>
        <RegrelloButton
          dataTestId={DataTestIds.TOAST_MESSAGE_CLOSE_BUTTON}
          iconOnly={true}
          onClick={() => toast.dismiss(toastId)}
          shape="circle"
          startIcon="close"
          variant="ghost"
        />
      </div>
    </div>
  );
});
