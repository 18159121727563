import { clsx } from "@regrello/core-utils";
import { SyncedObjectFieldsCount } from "@regrello/ui-strings";
import React from "react";

import { RegrelloIcon } from "../icons/RegrelloIcon";
import { RegrelloTooltipV4 } from "../tooltip/RegrelloTooltipV4";
import { RegrelloTypography } from "../typography/RegrelloTypography";

export interface RegrelloObjectChipProps {
  regrelloObject: {
    name: string;
    properties: Array<{ id: number; displayName?: string | null }>;
  };
}

/**
 * An older, non-standard chip representing a capital-'o' Regrello Object. Should eventually
 * consolidate this with `RegrelloChip`, which is the standard chip component in Regrello.
 */
export const RegrelloObjectChip = React.memo(
  React.forwardRef<HTMLDivElement, RegrelloObjectChipProps>(function RegrelloObjectChip({ regrelloObject }, ref) {
    const chipCssClasses = clsx(
      "bg-backgroundSoft",
      "box-inset",
      "shadow-neutral-border",
      "rounded",
      "text-textMuted",
      "flex",
      "py-1",
      "px-2",
      "break-words",
    );

    return (
      <div ref={ref} className={chipCssClasses}>
        <div className="mr-1.25">
          <RegrelloIcon iconName="object-outline" intent="neutral" />
        </div>

        <div className="flex items-baseline gap-1">
          <RegrelloTypography>{regrelloObject.name}</RegrelloTypography>

          <RegrelloTooltipV4
            content={
              <div className="flex flex-col gap-1 my-1">
                {regrelloObject.properties.map(({ id, displayName }) => (
                  <div key={`synced-object-property-${id}`} className={chipCssClasses}>
                    <div className="mr-1.25">
                      <RegrelloIcon iconName="text-field" intent="neutral" />
                    </div>
                    <RegrelloTypography>{displayName}</RegrelloTypography>
                  </div>
                ))}
              </div>
            }
            variant="popover"
          >
            <span className="inline-flex items-baseline">
              <RegrelloTypography
                className="text-primary-textMuted flex shrink-0 items-baseline cursor-default"
                variant="body-xs"
                weight="semi-bold"
              >
                ({SyncedObjectFieldsCount(regrelloObject.properties.length)})
              </RegrelloTypography>
            </span>
          </RegrelloTooltipV4>
        </div>
      </div>
    );
  }),
);
