/**
 * A typeguard that can be used as `.filter(isDefined)` to properly restrict `Array<T | null |
 * undefined>` to `T[]` later in the functional chain.
 *
 * @example
 * const arr1: Array<number | undefined> = [1, 2, undefined, 3];
 * const arr2: number[] = arr1.filter(isDefined).map((number) => ...);
 */
export function isDefined<T>(element: T | null | undefined): element is T {
  return element != null;
}
