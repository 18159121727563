import { EMPTY_ARRAY, isDefined } from "@regrello/core-utils";
import {
  ConditionalExpressionGroupFields,
  ConditionConnective,
  FieldInstanceFields,
  UpdateConditionGroupInputs,
} from "@regrello/graphql-api";
import { VariantConditions, VariantConditionsHelperText } from "@regrello/ui-strings";
import React, { useCallback } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "../../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { SelectFieldPlugin } from "../../../../../molecules/customFields/plugins/SelectFieldPlugin";
import { ConditionOperatorConfig } from "../../../../../molecules/customFields/plugins/types/ConditionOperator";
import { RegrelloFormSection } from "../../../../../molecules/formSection/RegrelloFormSection";
import { RegrelloConfigureConditionsFormSection } from "../../schedule/RegrelloConfigureConditionsFormSection";
import {
  areOperatorValuesCompatible,
  FieldInstanceCondition,
  RegrelloScheduleTimeFormSectionBase,
  ScheduleTimeKeys,
} from "../../schedule/RegrelloScheduleTimeFormSectionBase";

export namespace ConfigureVariantBlueprintConditionsFormSection {
  export function getConditionGroupMutationInputs(
    form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>,
  ): UpdateConditionGroupInputs {
    const connective = form.getValues(ScheduleTimeKeys.CONNECTIVE);

    const variantConditions = form.getValues(ScheduleTimeKeys.CONDITIONS) as FieldInstanceCondition[];
    const variantConditionsInput = variantConditions
      .filter((condition) => condition.fieldInstance != null && condition.operator != null)
      .map((condition) => {
        return CustomFieldPluginRegistrar.getPluginForFieldInstance(
          condition.fieldInstance,
        ).getUpdateStartingConditionsInputsFromFormValues(
          condition.fieldInstance,
          condition.operator.numFields > 1 ? [condition.value1, condition.value2] : condition.value1,
          condition.operator.operator,
          condition.fieldInstancePropertyId,
        );
      })
      .filter(isDefined);

    return {
      connective,
      conditions: variantConditionsInput,
      conditionGroups: EMPTY_ARRAY,
    };
  }

  export function getDefaultValues(
    conditionGroup?: Pick<ConditionalExpressionGroupFields, "connective" | "conditions">,
  ): Pick<RegrelloScheduleTimeFormSectionBase.Fields, ScheduleTimeKeys.CONNECTIVE | ScheduleTimeKeys.CONDITIONS> {
    return conditionGroup != null
      ? {
          [ScheduleTimeKeys.CONNECTIVE]: conditionGroup.connective,
          [ScheduleTimeKeys.CONDITIONS]: RegrelloScheduleTimeFormSectionBase.getFormStartingConditions(
            conditionGroup.conditions,
          ),
        }
      : {
          [ScheduleTimeKeys.CONNECTIVE]: ConditionConnective.AND,
          [ScheduleTimeKeys.CONDITIONS]: [{ fieldInstance: null, operator: null, value1: null, value2: null }],
        };
  }

  export type Props = {
    /**
     * The form for configuring the variant conditions. Should have `mode` set to 'all' for form
     * validation to work properly.
     */
    // (zstanik) blueprint variations TODO: Do a more thorough refactor of
    // `RegrelloConfigureConditionsFormSection` such that we don't need to use a form with more
    // fields than necessary here.
    form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;

    /**
     * Whether the form section is disabled.
     * @default false
     */
    isDisabled?: boolean;

    /**
     * ID of the standard blueprint if creating a variant and ID of the variant blueprint if
     * updating a variant.
     */
    blueprintId: number;
  };

  /** A form section for configuring the conditions for selecting a blueprint variant. */
  export const Component = React.memo<ConfigureVariantBlueprintConditionsFormSection.Props>(
    function ConfigureVariantBlueprintConditionsFormSectionFn({
      form,
      isDisabled = false,
      blueprintId,
    }: ConfigureVariantBlueprintConditionsFormSection.Props) {
      const {
        fields: conditionRows,
        append: appendCondition,
        remove: removeCondition,
        update: updateCondition,
      } = useFieldArray({
        control: form.control,
        name: ScheduleTimeKeys.CONDITIONS,
        shouldUnregister: true,
      });

      const handleAddConditionRow = useCallback(() => {
        appendCondition(
          {
            fieldInstance: null,
            operator: null,
            value1: null,
            value2: null,
          },
          {
            shouldFocus: false,
          },
        );
      }, [appendCondition]);

      const handleConditionFieldInstanceChange = useCallback(
        (newFieldInstance: FieldInstanceFields | null, index: number) => {
          // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
          // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
          // the fieldArray fields and set it as `never`.
          updateCondition(index, { fieldInstance: newFieldInstance, operator: null, value1: null, value2: null });
        },
        [updateCondition],
      );

      const handleConditionOperatorChange = useCallback(
        (
          currentFieldInstance: FieldInstanceFields | null,
          fieldInstancePropertyId: number | null,
          newOperator: ConditionOperatorConfig | null,
          oldValue1: unknown,
          index: number,
        ) => {
          // (dyury): If the user switched from an operator with operands to an operator without operands  (e.g. IS_GREATER_THAN -> IS_EMPTY)
          // we need to clear validation rules
          form.unregister(`conditions.${index}.value1`);
          // (zstanik): If the user switched from a BETWEEN operator, need to unregister the 2nd form
          // field to clear any stale validation rules.
          form.unregister(`conditions.${index}.value2`);

          const newValue1 =
            currentFieldInstance != null && newOperator != null
              ? CustomFieldPluginRegistrar.getPluginForFieldInstance(currentFieldInstance).getEmptyValueForFrontend({
                  operator: newOperator.operator,
                })
              : null;

          // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
          // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
          // the fieldArray fields and set it as `never`, making it unusable.
          updateCondition(index, {
            fieldInstance: currentFieldInstance,
            fieldInstancePropertyId,
            operator: newOperator,
            value1: areOperatorValuesCompatible(oldValue1, newValue1) ? oldValue1 : newValue1,
            value2: null,
          });
        },
        [form, updateCondition],
      );

      return (
        <RegrelloFormSection
          description={VariantConditionsHelperText}
          isRequiredAsteriskShown={true}
          title={VariantConditions}
        >
          <RegrelloConfigureConditionsFormSection
            allowedFieldPlugins={[SelectFieldPlugin]}
            conditionRows={conditionRows}
            form={form}
            isDisabled={isDisabled}
            onConditionAdd={handleAddConditionRow}
            onConditionDelete={removeCondition}
            onConditionFieldInstanceChange={handleConditionFieldInstanceChange}
            onConditionOperatorChange={handleConditionOperatorChange}
            workflowContext={{
              type: "workflowTemplate",
              workflowTemplateId: blueprintId,
              // (zstanik): Create via email can't be enabled for standard or variant workflow
              // templates.
              workflowTemplateIsCreateViaEmailEnabled: false,
            }}
          />
        </RegrelloFormSection>
      );
    },
  );
}
