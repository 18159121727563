// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateWorkflowQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  startImmediately: Types.Scalars['Boolean']['input'];
}>;


export type ValidateWorkflowQuery = { validateWorkflow?: { success: boolean, error?: string | null } | null };


export const ValidateWorkflowQueryDocument = gql`
    query ValidateWorkflowQuery($id: ID!, $startImmediately: Boolean!) {
  validateWorkflow(id: $id, startImmediately: $startImmediately) {
    success
    error
  }
}
    `;

/**
 * __useValidateWorkflowQuery__
 *
 * To run a query within a React component, call `useValidateWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateWorkflowQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startImmediately: // value for 'startImmediately'
 *   },
 * });
 */
export function useValidateWorkflowQuery(baseOptions: Apollo.QueryHookOptions<ValidateWorkflowQuery, ValidateWorkflowQueryVariables> & ({ variables: ValidateWorkflowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateWorkflowQuery, ValidateWorkflowQueryVariables>(ValidateWorkflowQueryDocument, options);
      }
export function useValidateWorkflowQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateWorkflowQuery, ValidateWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateWorkflowQuery, ValidateWorkflowQueryVariables>(ValidateWorkflowQueryDocument, options);
        }
export function useValidateWorkflowQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateWorkflowQuery, ValidateWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateWorkflowQuery, ValidateWorkflowQueryVariables>(ValidateWorkflowQueryDocument, options);
        }
export type ValidateWorkflowQueryHookResult = ReturnType<typeof useValidateWorkflowQuery>;
export type ValidateWorkflowQueryLazyQueryHookResult = ReturnType<typeof useValidateWorkflowQueryLazyQuery>;
export type ValidateWorkflowQuerySuspenseQueryHookResult = ReturnType<typeof useValidateWorkflowQuerySuspenseQuery>;
export type ValidateWorkflowQueryResult = Apollo.QueryResult<ValidateWorkflowQuery, ValidateWorkflowQueryVariables>;