// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type DocumentVersionFields = { id: number, uid: string, createdAt: string, deletedAt?: string | null, externalLink?: string | null, createdBy: PartyBaseFields };

export const DocumentVersionFields = gql`
    fragment DocumentVersionFields on DocumentVersion {
  id
  uid
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  deletedAt
  externalLink
}
    `;