// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type IntegrationAutomationSummaryFields = { id: number, integrationAutomationType: Types.IntegrationAutomationType, displayName: string, integration: { id: number, integrationType: Types.IntegrationType } };

export const IntegrationAutomationSummaryFields = gql`
    fragment IntegrationAutomationSummaryFields on IntegrationAutomation {
  id
  integrationAutomationType
  displayName
  integration {
    id
    integrationType
  }
}
    `;