// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { gql } from '@apollo/client';
export type SpectrumValueConstraintFields = { id: number, uuid: string, createdAt: string, valueConstraintRule: string, spectrumFieldValidationType?: SpectrumFieldValidationTypeFields | null, spectrumFieldPropertyType?: PropertyTypeFields | null };

export const SpectrumValueConstraintFields = gql`
    fragment SpectrumValueConstraintFields on SpectrumValueConstraint {
  id
  uuid
  createdAt
  valueConstraintRule
  spectrumFieldValidationType {
    ...SpectrumFieldValidationTypeFields
  }
  spectrumFieldPropertyType {
    ...PropertyTypeFields
  }
}
    `;