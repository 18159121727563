// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueFloatFields } from './FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueFloatWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceMultiValueFloat?: AcyclicFieldInstanceMultiValueFloatFields | null, crossWorkflowSinksFieldInstanceMultiValueFloat?: Array<AcyclicFieldInstanceMultiValueFloatFields> | null }
  & FieldInstanceMultiValueFloatFields
);

export const FieldInstanceMultiValueFloatWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueFloatWithCrossWorkflowFields on FieldInstanceMultiValueFloat {
  ...FieldInstanceMultiValueFloatFields
  crossWorkflowSourceFieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
  crossWorkflowSinksFieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
}
    `;