export enum RegrelloLocalStorageKey {
  AUTH_0_USER_KEY = "_auth0_user",
  BANNERS = "regrelloBanners",
  COLUMN_WIDTHS_BY_TABLE_ID = "columnWidthsByTableId",
  FORM_CREATED_VIA_FORM_SELECT_CREATE_OPTION = "FormOpenedInNewTabOption",
  HOME_SAVED_VIEW = "homeSavedView",
  IS_NO_THANKS_PREFERENCE_INDICATED_DOMAIN_SWITCH = "isNoThanksPreferenceIndicatedDomainSwitch",
  IS_SIDEBAR_COLLAPSED = "isSidebarCollapsed",
  JOB_LIST_HIDE_ITEM = "jobListHideItem",
  LAST_WINDOW_CURRENT_TENANT = "regrelloLastWindowCurrentTenant",
  SHOW_NEW_WORKFLOW_PROGRESS_BAR = "showNewWorkflowProgressBar",
  SPOOFING_USER_INFO = "spoofingUserInfo",
  USER_THEME = "userTheme",
}

export interface Auth0User {
  sub: string;
  email: string;
  token: string;
  emailVerified: string;
}

export interface HomeSavedView {
  id: number;
  userId: number;
  tenantId: number;
}

export interface SpoofingUserInfo {
  authUserId: number;
  tenantUserId: number;
  token: string;
  email: string;
}

interface StorageEntry {
  value: boolean;
  expiry: number;
}

export interface StorageItem {
  buster: string;
  banners: Record<string, StorageEntry>;
}

export interface FormOpenedInNewTabOption {
  uuid: string;
}

export type UserTheme = "light" | "dark" | "system";

export type RegrelloLocalStorageValueMap = {
  [RegrelloLocalStorageKey.AUTH_0_USER_KEY]: Auth0User;
  [RegrelloLocalStorageKey.BANNERS]: StorageItem;
  [RegrelloLocalStorageKey.COLUMN_WIDTHS_BY_TABLE_ID]: string;
  [RegrelloLocalStorageKey.FORM_CREATED_VIA_FORM_SELECT_CREATE_OPTION]: FormOpenedInNewTabOption | undefined;
  [RegrelloLocalStorageKey.HOME_SAVED_VIEW]: HomeSavedView;
  /**
   * (krashanoff): Though typed as a string or boolean, this should be an ISO datetime
   * that is converted using `date-fns`'s `parseISO`.
   */
  [RegrelloLocalStorageKey.IS_NO_THANKS_PREFERENCE_INDICATED_DOMAIN_SWITCH]: string | boolean;
  [RegrelloLocalStorageKey.IS_SIDEBAR_COLLAPSED]: string;
  [RegrelloLocalStorageKey.JOB_LIST_HIDE_ITEM]: number[];
  [RegrelloLocalStorageKey.LAST_WINDOW_CURRENT_TENANT]: number;
  [RegrelloLocalStorageKey.SPOOFING_USER_INFO]: SpoofingUserInfo;
  [RegrelloLocalStorageKey.SHOW_NEW_WORKFLOW_PROGRESS_BAR]: boolean;
  [RegrelloLocalStorageKey.USER_THEME]: UserTheme;
};

export function localStorageGetTyped<K extends RegrelloLocalStorageKey, V extends RegrelloLocalStorageValueMap[K]>(
  key: K,
): V | undefined {
  // (clewis): Return undefined instead of null so we don't have two types of empty values leaking
  // around our codebase.
  //
  if (
    key === RegrelloLocalStorageKey.AUTH_0_USER_KEY ||
    key === RegrelloLocalStorageKey.BANNERS ||
    key === RegrelloLocalStorageKey.HOME_SAVED_VIEW ||
    key === RegrelloLocalStorageKey.SPOOFING_USER_INFO ||
    key === RegrelloLocalStorageKey.JOB_LIST_HIDE_ITEM ||
    key === RegrelloLocalStorageKey.LAST_WINDOW_CURRENT_TENANT
  ) {
    // eslint-disable-next-line no-restricted-properties
    const itemData = localStorage.getItem(key);
    if (itemData != null) {
      try {
        return (JSON.parse(itemData) as V) ?? undefined;
      } catch {
        // Invalid item, remove it
        localStorage.removeItem(key);
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  // eslint-disable-next-line no-restricted-properties
  return (localStorage.getItem(key) as V) ?? undefined;
}

export function localStorageSetTyped<K extends RegrelloLocalStorageKey>(
  key: K,
  value: RegrelloLocalStorageValueMap[K],
): void {
  // eslint-disable-next-line no-restricted-properties
  localStorage.setItem(key, typeof value === "string" ? value : JSON.stringify(value));
}
