import { useCallback, useState } from "react";

export interface UsePaginationProps {
  dataLength: number;
  filterTotalCount: number;
  hasMorePages: boolean;
  hideUnselected: boolean;
  isFiltered: boolean;
  pageSize: number;
  totalCount: number;
}

export function usePagination({
  dataLength,
  filterTotalCount,
  hasMorePages,
  hideUnselected,
  isFiltered,
  pageSize,
  totalCount,
}: UsePaginationProps) {
  const [offset, setOffset] = useState(0);

  const resetOffset = useCallback(() => {
    setOffset(0);
  }, []);

  const setNextPage = useCallback(() => {
    setOffset(offset + pageSize);
  }, [offset, pageSize]);

  const setPrevPage = useCallback(() => {
    setOffset(Math.max(0, offset - pageSize));
  }, [offset, pageSize]);

  let state = {
    startIndex: offset + 1,
    endIndex: dataLength + offset,
    totalRows: totalCount,
    hasPrevPage: offset > 0,
    hasNextPage: hasMorePages,
  };

  if (isFiltered) {
    state.totalRows = filterTotalCount;
  }

  if (hideUnselected) {
    state = {
      startIndex: 1,
      endIndex: dataLength,
      totalRows: dataLength,
      hasPrevPage: false,
      hasNextPage: false,
    };
  }

  if (dataLength === 0) {
    state = {
      startIndex: 0,
      endIndex: 0,
      totalRows: 0,
      hasPrevPage: false,
      hasNextPage: false,
    };
  }

  return {
    offset,
    resetOffset,
    setNextPage,
    setPrevPage,
    ...state,
  };
}
