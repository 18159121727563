// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserFields } from '../../fragments/generated/UserFields.generated';
import { UserFieldsWithoutRoles } from '../../fragments/generated/UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from '../../fragments/generated/AccessRoleFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkCreateUsersMutationVariables = Types.Exact<{
  input: Types.BulkCreateUsersInput;
}>;


export type BulkCreateUsersMutation = { bulkCreateUsers: Array<{ error?: string | null, user?: UserFields | null }> };


export const BulkCreateUsersMutationDocument = gql`
    mutation BulkCreateUsersMutation($input: BulkCreateUsersInput!) {
  bulkCreateUsers(input: $input) {
    user {
      ...UserFields
    }
    error
  }
}
    ${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}`;
export type BulkCreateUsersMutationFn = Apollo.MutationFunction<BulkCreateUsersMutation, BulkCreateUsersMutationVariables>;

/**
 * __useBulkCreateUsersMutation__
 *
 * To run a mutation, you first call `useBulkCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateUsersMutation, { data, loading, error }] = useBulkCreateUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateUsersMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateUsersMutation, BulkCreateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateUsersMutation, BulkCreateUsersMutationVariables>(BulkCreateUsersMutationDocument, options);
      }
export type BulkCreateUsersMutationHookResult = ReturnType<typeof useBulkCreateUsersMutation>;
export type BulkCreateUsersMutationResult = Apollo.MutationResult<BulkCreateUsersMutation>;
export type BulkCreateUsersMutationOptions = Apollo.BaseMutationOptions<BulkCreateUsersMutation, BulkCreateUsersMutationVariables>;