// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type ActionItemTemplatePermissionsFields = { canDelete: boolean, canEdit: boolean, canRead: boolean, canStart: boolean, canEditAdvancedSettings: boolean, canEditApprovals: boolean, canEditApprovers: boolean, canEditAssignees: boolean, canEditCc: boolean, canEditDescription: boolean, canEditDisplayOrder: boolean, canEditDocuments: boolean, canEditDueOn: boolean, canEditInheritedFields: boolean, canEditAutomationInputs: boolean, canEditLinkedBlueprint: boolean, canEditName: boolean, canEditAutomationOutputs: boolean, canEditRejectAction: boolean, canEditRequestedFields: boolean, canEditStartConditions: boolean, canEditTags: boolean, canRemoveExistingAssignees: boolean, canRemoveRequestedFields: boolean };

export const ActionItemTemplatePermissionsFields = gql`
    fragment ActionItemTemplatePermissionsFields on PermissionsToActionItemTemplate {
  canDelete
  canEdit
  canRead
  canStart
  canEditAdvancedSettings
  canEditApprovals
  canEditApprovers
  canEditAssignees
  canEditCc
  canEditDescription
  canEditDisplayOrder
  canEditDocuments
  canEditDueOn
  canEditInheritedFields
  canEditAutomationInputs
  canEditLinkedBlueprint
  canEditName
  canEditAutomationOutputs
  canEditRejectAction
  canEditRequestedFields
  canEditStartConditions
  canEditTags
  canRemoveExistingAssignees
  canRemoveRequestedFields
}
    `;