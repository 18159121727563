export enum CustomFieldSourceType {
  LINE = "line",
  WORKFLOW = "workflow",
  TASK = "task",
}

/**
 * Describes the original context within which a particular custom field instance was added
 * (including both the type of context as well as data about the particular instance of that
 * context).
 */
export type CustomFieldSource =
  | {
      type: CustomFieldSourceType.TASK;
      name: string;
      stageName?: string;
    }
  | {
      type: CustomFieldSourceType.WORKFLOW;
      name: string;
    }
  | {
      type: CustomFieldSourceType.LINE;
      regrelloObjectPropertyId: number;
      actionItem?: {
        id: number;
        name: string;
        workflowStage?: {
          id: number;
          name: string;
        };
      };
    };
