// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type AcyclicFieldInstanceValueTimeFields = { __typename: 'FieldInstanceValueTime', id: number, inputType: Types.FieldInstanceValueInputType, timeValue?: string | null };

export const AcyclicFieldInstanceValueTimeFields = gql`
    fragment AcyclicFieldInstanceValueTimeFields on FieldInstanceValueTime {
  __typename
  id
  inputType
  timeValue
}
    `;