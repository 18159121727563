import { clsx, WithChildren, WithClassName, WithDataTestId } from "@regrello/core-utils";
import React from "react";

import { RegrelloButtonProps } from "./RegrelloButton";

export interface RegrelloTextButtonProps
  extends WithClassName,
    WithDataTestId,
    WithChildren,
    Pick<RegrelloButtonProps, "onClick"> {
  /** When `true`, will render the children without the link, and wrapped in a span. */
  disabled?: boolean;
}

/** A button that resembles an anchor tag but does not naviagete the user */
export const RegrelloTextButton = React.memo(
  React.forwardRef<HTMLButtonElement, RegrelloTextButtonProps>(function RegrelloTextButtonFn(
    { children, className, dataTestId, disabled = false, onClick },
    ref,
  ) {
    if (disabled) {
      return <span className="font-medium text-sm truncate">{children}</span>;
    }

    return (
      <button
        ref={ref}
        className={clsx("font-medium text-sm truncate text-primary-textMuted hover:underline", className)}
        data-testid={dataTestId}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    );
  }),
);
