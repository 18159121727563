import { EMPTY_ARRAY } from "../../constants/emptyArray";
import { isDefined } from "../typeGuards/isDefined";

/**
 * Retains only the elements in the provided array that are not `null` or `undefined`, with proper
 * type safety.
 *  @deprecated Prefer `.filter(isDefined)`
 */
export function arrayRetainDefinedElements<T>(array: Array<T | null | undefined>): T[] {
  return array.filter(isDefined);
}

/**
 * Invokes `arrayRetainDefinedElements` on a nullable array, returning a stable empty-array
 * reference if the array is not defined.
 */
export function optionalArrayRetainDefinedElements<T>(array: Array<T | null | undefined> | null | undefined): T[] {
  return array != null ? arrayRetainDefinedElements(array) : EMPTY_ARRAY;
}
