import { EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  LatestFormVersionQueryDocument,
  LatestSpectrumFieldVersionsV2QueryDocument,
  LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryDocument,
  SpectrumFieldVersionFields,
  useCreateSpectrumFieldAndVersionMutation,
  useCreateSpectrumFieldVersionMutation,
  usePropertyTypesQuery,
  useSpectrumFieldValidationTypesQuery,
  useSpectrumValueConstraintsQuery,
} from "@regrello/graphql-api";
import { Create, CreateNewField, EditField, FieldNameAlreadyInUse, Save } from "@regrello/ui-strings";
import React, { useCallback, useMemo, useState } from "react";

import {
  ConfigureSpectrumFieldForm,
  ConfigureSpectrumFieldFormFormFields,
} from "./_internal/ConfigureSpectrumFieldForm";
import { SubmitHandler } from "../../../../../types/form";
import { consoleWarnInDevelopmentModeOnly } from "../../../../../utils/environmentUtils";
import { refetchWithLatestVariablesByDocument } from "../../../../../utils/graphqlUtils";
import { useErrorHandler } from "../../../../../utils/hooks/useErrorHandler";
import { RegrelloFormDialogName } from "../../../../../utils/sentryScopeUtils";
import { AsyncLoaded } from "../../../../../utils/typescript/AsyncLoaded";
import { RegrelloFormDialog } from "../../../../atoms/dialog/RegrelloFormDialog";
import { SpectrumFieldPluginRegistrar } from "../../../../molecules/spectrumFields/registry/spectrumFieldPluginRegistrar";
import { SpectrumFieldPluginDecorator } from "../../../../molecules/spectrumFields/types/SpectrumFieldPluginDecorator";

export interface ConfigureSpectrumFieldDialogProps {
  /**
   * The UUID of the spectrum field that updates are being made on. This is applicable only when the
   * dialog is in editing mode.
   */
  spectrumFieldUUID?: string;
  /**
   * An allow list for filtering which field plugins this dialog should allow the user to create.
   */
  allowedFieldPlugins?: Array<SpectrumFieldPluginDecorator<unknown>>;

  /**
   * An initial value to show in the "name" field. Intended to save the user some time.
   * @default ""
   */
  defaultValues?: ConfigureSpectrumFieldFormFormFields;

  /**
   * The name of the form field that will be focused when the dialog is open. Currently only "name"
   * and "helpText" is supported
   */
  focusField?: string;

  /** Whether the dialog should render as open. */
  isOpen: boolean;

  /** The `mode` property determines whether the field **type** will be editable. */
  mode?: "create" | "edit";

  /** Callback invoked when the dialog wants to close. */
  onClose: () => void;

  /** Callback invoked when the dialog is in "create" mode and is submitted successfully */
  onAfterCreation?: (newField: SpectrumFieldVersionFields) => void;

  /** Async callback invoked when the dialog is in "create" mode and is submitted successfully */
  onAfterCreationAsync?: (newField: SpectrumFieldVersionFields) => void;
}

export const ConfigureSpectrumFieldDialog = React.memo<ConfigureSpectrumFieldDialogProps>(
  function ConfigureSpectrumFieldDialogFn({
    spectrumFieldUUID,
    allowedFieldPlugins,
    defaultValues,
    focusField = "name",
    mode,
    isOpen,
    onAfterCreation,
    onAfterCreationAsync,
    onClose,
  }) {
    const { handleError } = useErrorHandler();

    const spectrumFieldPlugins = useMemo(() => {
      // (clewis): Assumes that all plugins are finished registering before this component is ever
      // rendered, which should always be the case since plugins are registered synchronously on
      // initial app load as of this writing.
      return allowedFieldPlugins != null && allowedFieldPlugins.length > 0
        ? allowedFieldPlugins
        : SpectrumFieldPluginRegistrar.getAllPlugins();
    }, [allowedFieldPlugins]);

    const getPluginByUri = useCallback(
      (pluginUri: string) => {
        return spectrumFieldPlugins.find((plugin) => plugin.uri === pluginUri);
      },
      [spectrumFieldPlugins],
    );

    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const propertyTypesQueryResult = usePropertyTypesQuery({
      onError: (error) => handleError(error),
    });
    const asyncLoadedPropertyTypes = AsyncLoaded.fromGraphQlQueryResult(propertyTypesQueryResult);

    const spectrumFieldValidationTypesQueryResult = useSpectrumFieldValidationTypesQuery({
      onError: (error) => {
        handleError(error);
      },
    });

    const asyncLoadedValidationTypes = AsyncLoaded.fromGraphQlQueryResult(spectrumFieldValidationTypesQueryResult);

    const valueConstraintsQueryResult = useSpectrumValueConstraintsQuery({
      onError: (error) => {
        handleError(error);
      },
    });

    const asyncLoadedValueConstraints = AsyncLoaded.fromGraphQlQueryResult(valueConstraintsQueryResult);

    const [createSpectrumFieldAsync] = useCreateSpectrumFieldAndVersionMutation({
      onError: (error) => handleError(error, { toastMessage: error.message }),
    });
    const [createSpectrumFieldVersionAsync] = useCreateSpectrumFieldVersionMutation({
      onError: (error) => handleError(error, { toastMessage: error.message }),
    });

    const handleSubmit: SubmitHandler<ConfigureSpectrumFieldFormFormFields> = useCallback(
      async (data) => {
        const spectrumFieldPlugin = SpectrumFieldPluginRegistrar.getAllPlugins().find(
          (plugin) => plugin.uri === data.pluginUri,
        );
        if (spectrumFieldPlugin == null) {
          consoleWarnInDevelopmentModeOnly("The CreateField form was submitted with a missing plugin.");
          return false;
        }

        if (!AsyncLoaded.isLoaded(asyncLoadedPropertyTypes)) {
          return false;
        }
        const propertyType = spectrumFieldPlugin.findPropertyTypeFromLoadedPropertyTypes(
          asyncLoadedPropertyTypes.value.propertyTypes,
        );
        if (propertyType == null) {
          consoleWarnInDevelopmentModeOnly(
            "Could not find a property type for the field in the submitted CreateField form.",
          );
          return false;
        }

        if (!AsyncLoaded.isLoaded(asyncLoadedValidationTypes)) {
          return false;
        }
        const validationType = spectrumFieldPlugin.findValidationTypeFromLoadedValidationTypes(
          asyncLoadedValidationTypes.value.spectrumFieldValidationTypes,
        );
        if (validationType == null) {
          consoleWarnInDevelopmentModeOnly(
            "Could not find a valdiation type for the field in the submitted CreateField form.",
          );
          return false;
        }

        let newSpectrumField: SpectrumFieldVersionFields | undefined;

        const allowedValuesInputs = data.allowedValues.map(({ value }, index) => {
          return {
            displayOrder: index,
            stringValue: value,
          };
        });
        const valueConstraintsInputs = data.valueConstraints
          .filter(({ args }) => args.length > 0 && args.every((arg) => arg != null && arg.length !== 0))
          .map(({ constraint, args }) => {
            return {
              spectrumFieldVersionUUID: newSpectrumField?.uuid ?? EMPTY_STRING, // (hchen): We checked `newSpectrumField` is not null above.
              spectrumValueConstraintUUID: constraint.uuid,
              // (hchen): Although the type enforce it to be a string, the underlying data is
              // determined by the type of FormField used. For example, if we use a checkbox, it
              // will be a boolean here. So we have to do the convertion here.
              constraintArgs: args.map((arg) => arg.toString()),
            };
          });
        if (mode === "edit" && spectrumFieldUUID != null) {
          const createSpectrumFieldVersionResult = await createSpectrumFieldVersionAsync({
            variables: {
              input: {
                spectrumFieldUUID,
                name: data.name,
                description: data.helpText,
                helperText: data.helpText,
                validationTypeUUID: validationType.uuid,
                dataType: propertyType.dataType,
                fieldUnitID: data.fieldUnit?.id,
                allowedValues: allowedValuesInputs,
                fieldConstraints: valueConstraintsInputs,
              },
            },
            refetchQueries: refetchWithLatestVariablesByDocument([
              LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryDocument,
              LatestSpectrumFieldVersionsV2QueryDocument,
              LatestFormVersionQueryDocument,
            ]),
          });

          newSpectrumField = createSpectrumFieldVersionResult?.data?.createSpectrumFieldVersion;

          if (newSpectrumField == null) {
            console.warn("Created a field successfully, but no field data was returned in the response.");
            return false;
          }
        } else {
          const createSpectrumFieldResult = await createSpectrumFieldAsync({
            variables: {
              input: {
                name: data.name,
                description: data.helpText,
                helperText: data.helpText,
                validationTypeUUID: validationType.uuid,
                dataType: propertyType.dataType,
                fieldUnitID: data.fieldUnit?.id,
                allowedValues: allowedValuesInputs,
                fieldConstraints: valueConstraintsInputs,
              },
            },
            refetchQueries: refetchWithLatestVariablesByDocument([LatestSpectrumFieldVersionsV2QueryDocument]),
            onError: (_) => {
              setErrorMessage(FieldNameAlreadyInUse(data.name));
              return false;
            },
          });

          newSpectrumField = createSpectrumFieldResult?.data?.createSpectrumFieldAndVersion;

          if (newSpectrumField == null) {
            console.warn("Updated field successfully, but no field data was returned in the response.");
            return false;
          }
        }

        onAfterCreation?.(newSpectrumField);
        await onAfterCreationAsync?.(newSpectrumField);
        return true;
      },
      [
        asyncLoadedPropertyTypes,
        asyncLoadedValidationTypes,
        mode,
        spectrumFieldUUID,
        onAfterCreation,
        onAfterCreationAsync,
        createSpectrumFieldVersionAsync,
        createSpectrumFieldAsync,
      ],
    );

    const applicableConstraints = useMemo(() => {
      if (defaultValues?.pluginUri == null) {
        return undefined;
      }

      if (!AsyncLoaded.isLoaded(asyncLoadedValueConstraints)) {
        return undefined;
      }
      return (
        getPluginByUri(defaultValues.pluginUri)?.findValueConstraintsFromLoadedValueConstraints(
          asyncLoadedValueConstraints.value.spectrumValueConstraints,
        ) ?? EMPTY_ARRAY
      );
    }, [defaultValues, getPluginByUri, asyncLoadedValueConstraints]);

    const defaultValueConstraints = useMemo(() => {
      if (applicableConstraints == null) {
        return undefined;
      }

      const maybeAugmentedDefaultValueConstraints = applicableConstraints.reduce<
        ConfigureSpectrumFieldFormFormFields["valueConstraints"]
      >((acc, applicableConstraint) => {
        const constraintOnExistingField = defaultValues?.valueConstraints.find(
          ({ constraint }) => constraint.uuid === applicableConstraint.uuid,
        );
        if (constraintOnExistingField != null) {
          acc.push(constraintOnExistingField);
          return acc;
        }
        acc.push({
          constraint: applicableConstraint,
          args: EMPTY_ARRAY,
        });
        return acc;
      }, []);

      return maybeAugmentedDefaultValueConstraints;
    }, [applicableConstraints, defaultValues?.valueConstraints]);

    const defaultValuesInternal: ConfigureSpectrumFieldFormFormFields =
      defaultValues != null && defaultValueConstraints != null
        ? {
            ...defaultValues,
            valueConstraints: defaultValueConstraints,
          }
        : defaultValues != null
          ? defaultValues
          : {
              name: EMPTY_STRING,
              pluginUri: EMPTY_STRING,
              allowedValues: EMPTY_ARRAY,
              fieldUnit: undefined,
              helpText: EMPTY_STRING,
              isValueConstraintsEnabled: false,
              valueConstraints: EMPTY_ARRAY,
            };

    // (hchen): Make sure the default value doesn't get set when the async data required for the
    // calulation is not loaded. Changing default values after the dialog already mounts will not
    // cause a re-render.
    if (
      !AsyncLoaded.isLoaded(asyncLoadedPropertyTypes) ||
      !AsyncLoaded.isLoaded(asyncLoadedValidationTypes) ||
      !AsyncLoaded.isLoaded(asyncLoadedValueConstraints)
    ) {
      return null;
    }

    return (
      <RegrelloFormDialog
        dataTestId={mode === "edit" ? DataTestIds.EDIT_FIELD_DIALOG : DataTestIds.CREATE_FIELD_DIALOG}
        defaultValues={defaultValuesInternal}
        error={errorMessage}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        scopeNameForSentry={RegrelloFormDialogName.CREATE_FIELD}
        showRequiredInstruction={true}
        submitButtonText={mode === "edit" ? Save : Create}
        title={mode === "edit" ? EditField : CreateNewField}
      >
        {(form) => {
          return (
            <ConfigureSpectrumFieldForm
              focusField={focusField}
              form={form}
              getPluginByUri={getPluginByUri}
              loadedValueConstraints={asyncLoadedValueConstraints.value.spectrumValueConstraints}
              mode={mode}
              spectrumFieldPlugins={spectrumFieldPlugins}
            />
          );
        }}
      </RegrelloFormDialog>
    );
  },
);
