// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { SpectrumValueConstraintFields } from '../../fragments/generated/SpectrumValueConstraintFields.generated';
import { SpectrumFieldValidationTypeFields } from '../../fragments/generated/SpectrumFieldValidationTypeFields.generated';
import { PropertyTypeFields } from '../../fragments/generated/PropertyTypeFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SpectrumValueConstraintsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SpectrumValueConstraintsQuery = { spectrumValueConstraints: Array<SpectrumValueConstraintFields> };


export const SpectrumValueConstraintsQueryDocument = gql`
    query SpectrumValueConstraintsQuery {
  spectrumValueConstraints {
    ...SpectrumValueConstraintFields
  }
}
    ${SpectrumValueConstraintFields}
${SpectrumFieldValidationTypeFields}
${PropertyTypeFields}`;

/**
 * __useSpectrumValueConstraintsQuery__
 *
 * To run a query within a React component, call `useSpectrumValueConstraintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpectrumValueConstraintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpectrumValueConstraintsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpectrumValueConstraintsQuery(baseOptions?: Apollo.QueryHookOptions<SpectrumValueConstraintsQuery, SpectrumValueConstraintsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpectrumValueConstraintsQuery, SpectrumValueConstraintsQueryVariables>(SpectrumValueConstraintsQueryDocument, options);
      }
export function useSpectrumValueConstraintsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpectrumValueConstraintsQuery, SpectrumValueConstraintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpectrumValueConstraintsQuery, SpectrumValueConstraintsQueryVariables>(SpectrumValueConstraintsQueryDocument, options);
        }
export function useSpectrumValueConstraintsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpectrumValueConstraintsQuery, SpectrumValueConstraintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpectrumValueConstraintsQuery, SpectrumValueConstraintsQueryVariables>(SpectrumValueConstraintsQueryDocument, options);
        }
export type SpectrumValueConstraintsQueryHookResult = ReturnType<typeof useSpectrumValueConstraintsQuery>;
export type SpectrumValueConstraintsQueryLazyQueryHookResult = ReturnType<typeof useSpectrumValueConstraintsQueryLazyQuery>;
export type SpectrumValueConstraintsQuerySuspenseQueryHookResult = ReturnType<typeof useSpectrumValueConstraintsQuerySuspenseQuery>;
export type SpectrumValueConstraintsQueryResult = Apollo.QueryResult<SpectrumValueConstraintsQuery, SpectrumValueConstraintsQueryVariables>;