// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { gql } from '@apollo/client';
export type UserFields = (
  { party: { id: number } }
  & UserFieldsWithoutRoles
);

export const UserFields = gql`
    fragment UserFields on User {
  ...UserFieldsWithoutRoles
  party {
    id
  }
}
    `;