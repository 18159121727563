// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type ActionItemPermissionsFields = { canDiscover: boolean, canRead: boolean, canComment: boolean, canComplete: boolean, canReopen: boolean, canApprove: boolean, canReject: boolean, canDelete: boolean, canEdit: boolean, canEditApprovers: boolean, canEditAssignees: boolean, canEditAssignor: boolean, canEditCC: boolean, canEditDescription: boolean, canEditDocuments: boolean, canEditDueOn: boolean, canReportException: boolean, canMute: boolean, canEditTags: boolean, canEditName: boolean, canAcknowledge: boolean, canRequireAcknowledge: boolean, canRemoveExistingAssignees: boolean, canRequireApproval: boolean };

export const ActionItemPermissionsFields = gql`
    fragment ActionItemPermissionsFields on PermissionsToActionItem {
  canDiscover
  canRead
  canComment
  canComplete
  canReopen
  canApprove
  canReject
  canDelete
  canEdit
  canEditApprovers
  canEditAssignees
  canEditAssignor
  canEditCC
  canEditDescription
  canEditDocuments
  canEditDueOn
  canReportException
  canMute
  canEditTags
  canEditName
  canAcknowledge
  canRequireAcknowledge
  canRemoveExistingAssignees
  canRequireApproval
}
    `;