// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from './AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from './AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { gql } from '@apollo/client';
export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument = AcyclicFieldInstanceMultiValueDocumentFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat = AcyclicFieldInstanceMultiValueFloatFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt = AcyclicFieldInstanceMultiValueIntFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty = AcyclicFieldInstanceMultiValuePartyFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance = AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString = AcyclicFieldInstanceMultiValueStringFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime = AcyclicFieldInstanceMultiValueTimeFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean = AcyclicFieldInstanceValueBooleanFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat = AcyclicFieldInstanceValueFloatFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueInt = AcyclicFieldInstanceValueIntFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueParty = AcyclicFieldInstanceValuePartyFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueString = AcyclicFieldInstanceValueStringFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueTime = AcyclicFieldInstanceValueTimeFields;

export type AcyclicFieldInstanceValueFields = AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime | AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean | AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat | AcyclicFieldInstanceValueFieldsFieldInstanceValueInt | AcyclicFieldInstanceValueFieldsFieldInstanceValueParty | AcyclicFieldInstanceValueFieldsFieldInstanceValueString | AcyclicFieldInstanceValueFieldsFieldInstanceValueTime;

export const AcyclicFieldInstanceValueFields = gql`
    fragment AcyclicFieldInstanceValueFields on FieldInstanceValue {
  ... on FieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
  ... on FieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
  ... on FieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
  ... on FieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
  ... on FieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
  ... on FieldInstanceValueTime {
    ...AcyclicFieldInstanceValueTimeFields
  }
  ... on FieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
  ... on FieldInstanceMultiValueParty {
    ...AcyclicFieldInstanceMultiValuePartyFields
  }
  ... on FieldInstanceMultiValueDocument {
    ...AcyclicFieldInstanceMultiValueDocumentFields
  }
  ... on FieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  ... on FieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
  ... on FieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
  ... on FieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
}
    `;