import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useQueryStrings() {
  const queryParamString = useLocation().search;
  // (clewis): Be sure to memoize, else downstream code can get into infinite loops.
  const memoizedUrlSearchParams = useMemo(() => new URLSearchParams(queryParamString), [queryParamString]);
  return memoizedUrlSearchParams;
}

export function useQueryMap() {
  const query = useQueryStrings();
  return Object.fromEntries(query.entries());
}
