/** Returns true if `element` is completely inside `ancestor`, otherwise false. */
export function isElementInViewport(element: HTMLElement, ancestor: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const ancestorRect = ancestor.getBoundingClientRect();

  return (
    rect.top >= ancestorRect.top &&
    rect.left >= ancestorRect.left &&
    rect.bottom <= ancestorRect.bottom &&
    rect.right <= ancestorRect.right
  );
}
