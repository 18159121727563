import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloObjectFields } from "@regrello/graphql-api";
import { RegrelloIcon, RegrelloTooltipV4, RegrelloTypography } from "@regrello/ui-core";
import { Fields } from "@regrello/ui-strings";
import React from "react";

import { RegrelloFormFieldSelectOptionContainer } from "./RegrelloFormFieldSelectOptionContainer";

export interface RegrelloFormFieldSelectOptionRegrelloObjectProps {
  regrelloObject: RegrelloObjectFields;
}

/**
 * A "select" menu option that shows RegrelloObject details, including property data.
 */
export const RegrelloFormFieldSelectOptionRegrelloObject = React.memo<RegrelloFormFieldSelectOptionRegrelloObjectProps>(
  function RegrelloFormFieldSelectOptionRegrelloObjectFn({ regrelloObject }) {
    return (
      <RegrelloFormFieldSelectOptionContainer dataTestId={DataTestIds.FORM_FIELD_SELECT_OPTION}>
        <div className="mr-1">
          <RegrelloIcon iconName="object-outline" />
        </div>

        <div className="flex grow items-baseline">
          <span>{regrelloObject.name}</span>

          <RegrelloTooltipV4
            content={
              <div className="flex flex-col gap-2">
                {regrelloObject.properties.map(({ id, displayName }) => (
                  <div
                    key={`synced-object-property-${id}`}
                    className="flex items-center gap-1 px-2 py-1 bg-backgroundSoft rounded text-textDefault"
                  >
                    <RegrelloIcon iconName="text-field" intent="neutral" />
                    <RegrelloTypography noWrap={true}>{displayName}</RegrelloTypography>
                  </div>
                ))}
              </div>
            }
            variant="popover"
          >
            <div>
              <RegrelloTypography
                className="ml-1"
                intent="primary"
                muted={true}
                variant="body-xs"
                weight="semi-bold"
              >{`(${regrelloObject.properties.length} ${Fields.toLocaleLowerCase()})`}</RegrelloTypography>
            </div>
          </RegrelloTooltipV4>
        </div>
      </RegrelloFormFieldSelectOptionContainer>
    );
  },
);
