// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyFields } from './PartyFields.generated';
import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type WorkflowCollaborationFields = { isFromWorkflowTemplate: boolean, party: PartyFields, accessRole: AccessRoleFields };

export const WorkflowCollaborationFields = gql`
    fragment WorkflowCollaborationFields on WorkflowCollaboration {
  party {
    ...PartyFields
  }
  accessRole {
    ...AccessRoleFields
  }
  isFromWorkflowTemplate
}
    `;