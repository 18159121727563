// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueDocumentFields = { __typename: 'FieldInstanceMultiValueDocument', id: number, inputType: Types.FieldInstanceValueInputType, documentMultiValue: Array<DocumentFields>, sourceFieldInstanceMultiValueDocument?: AcyclicFieldInstanceMultiValueDocumentFields | null, sinksFieldInstanceMultiValueDocument: Array<AcyclicFieldInstanceMultiValueDocumentFields> };

export const FieldInstanceMultiValueDocumentFields = gql`
    fragment FieldInstanceMultiValueDocumentFields on FieldInstanceMultiValueDocument {
  __typename
  id
  inputType
  documentMultiValue {
    ...DocumentFields
  }
  sourceFieldInstanceMultiValueDocument {
    ...AcyclicFieldInstanceMultiValueDocumentFields
  }
  sinksFieldInstanceMultiValueDocument {
    ...AcyclicFieldInstanceMultiValueDocumentFields
  }
}
    `;