/** The width of all table and cell borders. */
export const DATA_GRID_BORDER_WIDTH = 1;

/** The height in pixels of header cells. */
export const DATA_GRID_HEADER_HEIGHT = 40;

export const DATA_GRID_BULK_EDIT_CELL_WIDTH = 36;
export const DATA_GRID_BULK_EDIT_WITH_SORT_CELL_WIDTH = 48;

export const bulkEditColumnId = "bulkEdit";
