import { useEffect, useState } from "react";

export namespace useWindowFocus {
  export interface Args {
    /** Callback invoked when the window gains focus. */
    onFocus?: () => void;

    /** Callback invoked when the window loses focus. */
    onBlur?: () => void;
  }

  export interface Return {
    /** Whether the user's cursor is currently focused in this window. */
    isWindowFocused: boolean;
  }
}

/**
 * Hook that returns whether the window is currently focused. Re-evaluates whenever the window's "is
 * focused" state changes.
 */
// (clewis): Inspired by https://github.com/jpalumickas/use-window-focus/blob/main/src/index.ts.
export function useWindowFocus({ onFocus, onBlur }: useWindowFocus.Args = {}): useWindowFocus.Return {
  const [isWindowFocused, setIsWindowFocused] = useState(hasFocus); // Focus for first render.

  useEffect(() => {
    setIsWindowFocused(hasFocus()); // Focus for following renders.

    const onFocusInternal = () => {
      onFocus?.();
      setIsWindowFocused(true);
    };
    const onBlurInternal = () => {
      onBlur?.();
      setIsWindowFocused(false);
    };

    window.addEventListener("focus", onFocusInternal);
    window.addEventListener("blur", onBlurInternal);

    return () => {
      window.removeEventListener("focus", onFocusInternal);
      window.removeEventListener("blur", onBlurInternal);
    };
  }, [onBlur, onFocus]);

  return { isWindowFocused };
}

function hasFocus() {
  return document.hasFocus();
}
