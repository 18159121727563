import { RegrelloConfirmationDialog, RegrelloConfirmationDialogProps } from "@regrello/ui-core";
import {
  AvoidLosingEditsPromptCancel,
  AvoidLosingEditsPromptConfirm,
  AvoidLosingEditsPromptDescription,
  AvoidLosingEditsPromptTitle,
} from "@regrello/ui-strings";
import React from "react";
import { useBeforeUnload } from "react-use";

export interface RegrelloAvoidLosingEditsPromptProps
  extends Pick<
    RegrelloConfirmationDialogProps,
    "isOpen" | "isSubmitting" | "onClose" | "onConfirm" | "onSecondaryButtonClick"
  > {
  /** Whether to warn the user about potential data loss when closing the browser window. */
  isBrowserNativePromptEnabled?: boolean;
}

/**
 * A simple prompt that asks, "Are you sure you want to close?". Useful to show when the user might
 * accidentally close a dialog with unsaved edits.
 */
export const RegrelloAvoidLosingEditsPrompt = React.memo<RegrelloAvoidLosingEditsPromptProps>(
  function RegrelloAvoidLosingEditsPromptFn({
    isBrowserNativePromptEnabled = false,
    isOpen,
    isSubmitting,
    onClose,
    onConfirm,
    onSecondaryButtonClick,
  }) {
    // (clewis): Prompt if the window would close while any edits remain unsubmitted. The message
    // here tends not to be respected in most modern browsers, but omitting it apprently causes
    // useBeforeUnload not to work?
    useBeforeUnload(isBrowserNativePromptEnabled, AvoidLosingEditsPromptTitle);

    return (
      <RegrelloConfirmationDialog
        cancelText={AvoidLosingEditsPromptCancel}
        confirmIntent="danger"
        confirmText={AvoidLosingEditsPromptConfirm}
        content={AvoidLosingEditsPromptDescription}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        onClose={onClose}
        onConfirm={onConfirm}
        onSecondaryButtonClick={onSecondaryButtonClick}
        secondaryButtonText="Save and close"
        title={AvoidLosingEditsPromptTitle}
      />
    );
  },
);
