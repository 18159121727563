import { Slot } from "@radix-ui/react-slot";
import { assertNever, clsx, WithChildren, WithDataTestId } from "@regrello/core-utils";
import React, { useCallback } from "react";

import { buttonVariants } from "./regrelloButtonUtils";
import { RegrelloButtonVariant } from "./RegrelloButtonVariant";
import { RegrelloIntentV2 } from "../../utils/enums/RegrelloIntentV2";
import { RegrelloJustification } from "../../utils/enums/RegrelloJustification";
import { RegrelloShape } from "../../utils/enums/RegrelloShape";
import { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloIcon, RegrelloIconName } from "../icons/RegrelloIcon";
import { RegrelloIconStyler } from "../icons/RegrelloIconStyler";
import { RegrelloSpinner } from "../spinner/RegrelloSpinner";

export interface RegrelloButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    WithChildren,
    WithDataTestId {
  /**
   * Whether to allow text to wrap in the button. This should almost always be `false`, except for
   * when making a custom button.
   *
   * @default false
   */
  allowWrap?: boolean;

  /**
   * Changes HTML component which should be used to display the button.
   */
  asChild?: boolean;

  /**
   * Additional class to apply to button content container.
   */
  contentClassName?: string;

  /**
   * An icon to show after the button text.
   */
  endIcon?: RegrelloIconName | JSX.Element;

  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth?: boolean;

  /**
   * Changes size of the button to look good with icon.
   */
  iconOnly?: boolean;

  /**
   * A semantic intent for the button.
   * @default "neutral"
   *
   * __Warning:__ `warning` and `success` are not yet supported in the Button component.
   */
  intent?: Exclude<RegrelloIntentV2, "warning" | "secondary" | "success"> | "alternate";

  /**
   * How to lay out the icons and text within the button. Useful when {@link fullWidth} is `true`.
   * @default RegrelloJustification.CENTER
   */
  justification?: RegrelloJustification;

  /**
   * Whether to show the button in a non-interactive loading state.
   */
  loading?: boolean;

  /**
   * The shape of the button's corners.
   * @default "rectangle"
   */
  shape?: RegrelloShape;

  /**
   * The size of the button.
   *
   * ## Rules
   * Here are some rules for when to use each button size:
   *
   * | Size | Usage |
   * | --- | --- |
   * | `x-large` | Use if and only if the button is particularly important, ideally uniquely important (e.g., the sole button in an empty state) |
   * | `large` _(default)_ | The default size. Use this unless you have a very good reason not to. This drives consistency across the app. |
   * | `medium` | Avoid in general; this is present solely for some internal design-system use cases (e.g., buttons inside of inputs). |
   * | `small` | Avoid in general; this is present solely for some internal design-system use cases (e.g., buttons inside of inputs). |
   * | `x-small` | Use if you really need to fit the button in a tight vertical space. |
   *
   * @default "large"
   */
  size?: RegrelloSize;

  /**
   * An icon to show before the button text.
   */
  startIcon?: RegrelloIconName | JSX.Element;

  /**
   * The visual emphasis of the button (solid, outlined, ghost).
   * @default "solid"
   */
  variant?: RegrelloButtonVariant;
}

/** Buttons trigger actions when clicked. */
const RegrelloButton = React.forwardRef<HTMLButtonElement, RegrelloButtonProps>(
  (
    {
      allowWrap = false,
      asChild = false,
      children,
      className,
      contentClassName,
      dataTestId,
      disabled,
      endIcon,
      fullWidth,
      iconOnly,
      intent = "neutral",
      justification = "center",
      loading,
      shape = "rectangle",
      size = "large",
      startIcon,
      variant = "solid",
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    const renderButtonIcon = useCallback(
      (icon: RegrelloIconName | JSX.Element) => {
        const iconSize = getIconSizeForButtonOfSize(size);
        // Use "block" instead of "inline-block" to eliminate excessive line height in some icons.
        return typeof icon === "string" ? (
          <RegrelloIcon iconName={icon} size={iconSize} />
        ) : (
          <RegrelloIconStyler size={iconSize}>{icon}</RegrelloIconStyler>
        );
      },
      [size],
    );

    const buttonVariantClasses = buttonVariants({
      iconOnly: iconOnly ? size : undefined,
      intent,
      shape,
      size: iconOnly ? undefined : size,
      variant,
    });

    return (
      <Comp
        ref={ref}
        className={clsx(
          buttonVariantClasses,
          {
            "w-full": fullWidth,
            "text-transparent": loading,
            "justify-between": justification === "stretch" && !loading,
          },
          className,
        )}
        data-testid={dataTestId}
        disabled={disabled || loading}
        role="button"
        type="button"
        {...props}
      >
        {loading ? (
          <RegrelloSpinner
            className={clsx("absolute", "text-textContrast", {
              "text-textDefault": intent === "neutral" || variant !== "solid",
            })}
          />
        ) : null}
        {startIcon != null && <div className={clsx({ "mr-1": !iconOnly })}>{renderButtonIcon(startIcon)}</div>}
        {iconOnly ? null : (
          <div
            className={clsx(
              "min-w-0",
              {
                truncate: !allowWrap,
              },
              contentClassName,
            )}
          >
            {/* (clewis): Prevent the button height from collapsing when there are no children. */}
            {children == null || children === "" ? <>&nbsp;</> : children}
          </div>
        )}
        {!iconOnly && endIcon != null && <div className="ml-1">{renderButtonIcon(endIcon)}</div>}
      </Comp>
    );
  },
);
RegrelloButton.displayName = "RegrelloButton";

function getIconSizeForButtonOfSize(buttonSize: RegrelloSize): RegrelloSize {
  switch (buttonSize) {
    case RegrelloSize.X_SMALL:
      return RegrelloSize.X_SMALL;
    case RegrelloSize.SMALL:
      return RegrelloSize.SMALL;
    case RegrelloSize.MEDIUM:
      return RegrelloSize.SMALL;
    case RegrelloSize.LARGE:
      return RegrelloSize.SMALL;
    case RegrelloSize.X_LARGE:
      return RegrelloSize.MEDIUM;
    default:
      assertNever(buttonSize);
  }
}

export { RegrelloButton };
