import React from "react";

import { REGRELLO_CONTROL_CHILD_CONTENT_OFFSET_CLASS_NAME } from "./regrelloControlConstants";

/** Properly indented child content to display beneath a `<RegrelloControlWithLabel>`. */
export const RegrelloControlChildContent = React.memo(function RegrelloControlChildContentFn({
  children,
}: React.PropsWithChildren) {
  // (clewis): Must keep in sync with `regrelloControlConstants.tsx`.
  return <div className={REGRELLO_CONTROL_CHILD_CONTENT_OFFSET_CLASS_NAME}>{children}</div>;
});
