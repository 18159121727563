export function getFeatureFlaggedFieldName(
  isSpectrumEnabled: boolean,
  fieldInstance: {
    field: { name: string };
    spectrumFieldVersion?: { name: string } | null;
  },
): string {
  return isSpectrumEnabled
    ? fieldInstance.spectrumFieldVersion?.name ?? fieldInstance.field.name
    : fieldInstance.field.name;
}
