// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { FieldInstanceValueTimeBaseFields } from './FieldInstanceValueTimeBaseFields.generated';
import { FieldInstanceValuePartyBaseFields } from './FieldInstanceValuePartyBaseFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { FieldInstanceMultiValuePartyBaseFields } from './FieldInstanceMultiValuePartyBaseFields.generated';
import { FieldInstanceMultiValueDocumentBaseFields } from './FieldInstanceMultiValueDocumentBaseFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument = (
  { id: number }
  & FieldInstanceMultiValueDocumentBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat = (
  { id: number }
  & FieldInstanceMultiValueFloatBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt = (
  { id: number }
  & FieldInstanceMultiValueIntBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty = (
  { id: number }
  & FieldInstanceMultiValuePartyBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance = (
  { id: number }
  & FieldInstanceMultiValueRegrelloObjectInstanceFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueString = (
  { id: number }
  & FieldInstanceMultiValueStringBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime = (
  { id: number }
  & FieldInstanceMultiValueTimeBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceValueBoolean = (
  { id: number }
  & FieldInstanceValueBooleanBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceValueFloat = (
  { id: number }
  & FieldInstanceValueFloatBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceValueInt = (
  { id: number }
  & FieldInstanceValueIntBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceValueParty = (
  { id: number }
  & FieldInstanceValuePartyBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceValueString = (
  { id: number }
  & FieldInstanceValueStringBaseFields
);

export type FieldInstanceValueBaseFieldsFieldInstanceValueTime = (
  { id: number }
  & FieldInstanceValueTimeBaseFields
);

export type FieldInstanceValueBaseFields = FieldInstanceValueBaseFieldsFieldInstanceMultiValueDocument | FieldInstanceValueBaseFieldsFieldInstanceMultiValueFloat | FieldInstanceValueBaseFieldsFieldInstanceMultiValueInt | FieldInstanceValueBaseFieldsFieldInstanceMultiValueParty | FieldInstanceValueBaseFieldsFieldInstanceMultiValueRegrelloObjectInstance | FieldInstanceValueBaseFieldsFieldInstanceMultiValueString | FieldInstanceValueBaseFieldsFieldInstanceMultiValueTime | FieldInstanceValueBaseFieldsFieldInstanceValueBoolean | FieldInstanceValueBaseFieldsFieldInstanceValueFloat | FieldInstanceValueBaseFieldsFieldInstanceValueInt | FieldInstanceValueBaseFieldsFieldInstanceValueParty | FieldInstanceValueBaseFieldsFieldInstanceValueString | FieldInstanceValueBaseFieldsFieldInstanceValueTime;

export const FieldInstanceValueBaseFields = gql`
    fragment FieldInstanceValueBaseFields on FieldInstanceValue {
  id
  ... on FieldInstanceValueString {
    ...FieldInstanceValueStringBaseFields
  }
  ... on FieldInstanceMultiValueString {
    ...FieldInstanceMultiValueStringBaseFields
  }
  ... on FieldInstanceValueInt {
    ...FieldInstanceValueIntBaseFields
  }
  ... on FieldInstanceValueFloat {
    ...FieldInstanceValueFloatBaseFields
  }
  ... on FieldInstanceValueBoolean {
    ...FieldInstanceValueBooleanBaseFields
  }
  ... on FieldInstanceValueTime {
    ...FieldInstanceValueTimeBaseFields
  }
  ... on FieldInstanceValueParty {
    ...FieldInstanceValuePartyBaseFields
  }
  ... on FieldInstanceMultiValueParty {
    ...FieldInstanceMultiValuePartyBaseFields
  }
  ... on FieldInstanceMultiValueDocument {
    ...FieldInstanceMultiValueDocumentBaseFields
  }
  ... on FieldInstanceMultiValueInt {
    ...FieldInstanceMultiValueIntBaseFields
  }
  ... on FieldInstanceMultiValueFloat {
    ...FieldInstanceMultiValueFloatBaseFields
  }
  ... on FieldInstanceMultiValueTime {
    ...FieldInstanceMultiValueTimeBaseFields
  }
  ... on FieldInstanceMultiValueRegrelloObjectInstance {
    ...FieldInstanceMultiValueRegrelloObjectInstanceFields
  }
}
    `;