// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueIntFields } from './FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueIntWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceValueInt?: AcyclicFieldInstanceValueIntFields | null, crossWorkflowSinksFieldInstanceValueInt?: Array<AcyclicFieldInstanceValueIntFields> | null }
  & FieldInstanceValueIntFields
);

export const FieldInstanceValueIntWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueIntWithCrossWorkflowFields on FieldInstanceValueInt {
  ...FieldInstanceValueIntFields
  crossWorkflowSourceFieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
  crossWorkflowSinksFieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
}
    `;