// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueRegrelloObjectInstanceFields = { __typename: 'FieldInstanceMultiValueRegrelloObjectInstance', id: number, inputType: Types.FieldInstanceValueInputType, regrelloObjectInstanceMultiValue: Array<RegrelloObjectInstanceFields>, sourceFieldInstanceMultiValueRegrelloObjectInstance?: AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields | null, sinksFieldInstanceMultiValueRegrelloObjectInstance: Array<AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields> };

export const FieldInstanceMultiValueRegrelloObjectInstanceFields = gql`
    fragment FieldInstanceMultiValueRegrelloObjectInstanceFields on FieldInstanceMultiValueRegrelloObjectInstance {
  __typename
  id
  inputType
  regrelloObjectInstanceMultiValue {
    ...RegrelloObjectInstanceFields
  }
  sourceFieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  sinksFieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
}
    `;