import { queueMacrotask } from "@regrello/core-utils";
import { useLayoutEffect } from "react";

export namespace useScrollToMenuItem {
  export interface Args {
    /** The index of the item to scroll to. */
    itemIndex: number;

    /** A ref to the scrollable container that contains `[role="menuitem"]` children. */
    scrollContainerRef: { current: HTMLElement | null };

    /**
     * Whether a change to the `itemIndex` should be permitted to happen "silently", without keeping
     * the item in view.
     *
     * This can be useful if mousing over an item highlights that item but should not trigger
     * scrolling.
     *
     * @default false
     */
    silent?: boolean;
  }
}

/**
 * Hook that auto-scrolls to always keep a specified menu item in view. Runs after every render.
 * Menu items must have a `[role="menuitem"]` attribute.
 */
export function useKeepMenuItemInView({
  itemIndex,
  scrollContainerRef,
  silent: isSilent = false,
}: useScrollToMenuItem.Args) {
  useLayoutEffect(() => {
    queueMacrotask(() => {
      if (isSilent) {
        return;
      }

      const itemElement = scrollContainerRef.current
        ?.querySelectorAll(`[role="menuitem"],[role="option"]`)
        .item(itemIndex);

      if (itemElement == null) {
        console.warn("Cannot scroll to menu item: item not found.");
        return;
      }

      if (typeof window === "undefined" || typeof itemElement.scrollIntoView !== "function") {
        // (clewis): Don't fail when running FE unit tests.
        console.warn("Cannot scroll to menu item: scrollIntoView is not supported in this environment.");
        return;
      }

      itemElement.scrollIntoView({
        behavior: "auto",
        block: "nearest",
      });
    });
  }, [isSilent, itemIndex, scrollContainerRef]);
}
