// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type SpectrumFieldFields = { id: number, uuid: string, createdAt: string };

export const SpectrumFieldFields = gql`
    fragment SpectrumFieldFields on SpectrumField {
  id
  uuid
  createdAt
}
    `;