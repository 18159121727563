// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type FormSectionConstraintRuleFields = { id: number, uuid: string, formSectionConstraintRule: string };

export const FormSectionConstraintRuleFields = gql`
    fragment FormSectionConstraintRuleFields on FormSectionConstraintRule {
  id
  uuid
  formSectionConstraintRule
}
    `;