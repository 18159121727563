import { RegrelloAuthenticationContext } from "./RegrelloAuthenticationProvider";
import { UserContextType } from "./UserContext";
import { createNonNullableContext } from "../../../utils/createNonNullableContext";

const [useRegrelloAuthV2, RegrelloAuthenticationProviderInternal] =
  createNonNullableContext<RegrelloAuthenticationContext>();

const [useUser, Provider] = createNonNullableContext<UserContextType>();

export { useRegrelloAuthV2, RegrelloAuthenticationProviderInternal, useUser, Provider };
