import { isApolloError, ServerParseError } from "@apollo/client";
import { useCallback } from "react";

import { useErrorLogger } from "./useErrorLogger";
import { HttpStatusCode } from "../../constants/HttpStatusCodes";
import { RoutePaths, RouteQueryStringKeys } from "../../ui/app/routes/consts";
import { useToastMessageQueue } from "../../ui/molecules/toast/useToastMessageQueue";
import { getRegrelloErrorFriendlyMessageFromApolloError } from "../graphqlUtils";

export interface ErrorHandlerOptions {
  /**
   * If defined, will show the provided message in an error toast, unless a back-end-provided error
   * message exists in the GraphQL payload in `extensions.friendlyMessage`.
   *
   * __Provide cautiously when:__
   *
   * 1. __The error results from direct user action.__
   * 1. __Ideally, the user is not expected to fix the error and retry.__ (e.g., for form validation
   *    errors, it's better to show a specific error message inline rather than show a toast.) If
   *    the inline validation error is not possible or practical, then it's okay to show a toast
   *    with the error, as it's better than showing generic failure messages.
   */
  toastMessage?: string | JSX.Element;

  /**
   * If `true`, the toast will forcefully _avoid_ using use the back-end "friendly" message from the
   * GraphQL response's `extensions.friendlyMessage` field and will instead only use the
   * `toastMessage` argument, if provided. If neither are found, the toast will not be shown.
   *
   * @default false
   *
   */
  disableBackendFriendlyMessage?: boolean;
}

export namespace useErrorHandler {
  export interface Return {
    /**
     * Logs an error to our error-logging service (so our development team can track and analyze
     * errors in aggregate), and displays the error in the UI if desired.
     */
    handleError: (error: Error | string, options?: ErrorHandlerOptions) => void;
  }
}

/**
 * This hook is a wrapper around useErrorLogger. It sends the error to Sentry and shows a user-facing
 * error message.
 */
export function useErrorHandler(): useErrorHandler.Return {
  const { showToast } = useToastMessageQueue();
  const { logError, logErrorMessage } = useErrorLogger();

  const handleError = useCallback(
    (error: Error | string, options: ErrorHandlerOptions = {}) => {
      if (typeof error === "string") {
        logErrorMessage(error);
      } else if (isApolloError(error)) {
        // (krashanoff): If we ever receive a 417 from the backend during a GraphQL
        // request, then we are attempting to make a request using a granular access
        // token without a device verification token. We should redirect away from
        // the page.
        try {
          const parseError = error.networkError as ServerParseError;
          if (parseError.statusCode === HttpStatusCode.ExpectationFailed) {
            const portPart = window.location.port.length !== 0 ? `:${window.location.port}` : "";
            window.location.href = `${window.location.protocol}//${window.location.hostname}${portPart}${
              RoutePaths.REQUEST_VERIFICATION
            }?${RouteQueryStringKeys.TOKEN}=${new URLSearchParams(window.location.search).get(
              RouteQueryStringKeys.TOKEN,
            )}`;
          }
        } catch (_e) {
          // (krashanoff): Throws error if the typecast fails; can be safely ignored since this
          // cast is only used for Regrello Lite security (as of this writing).
        }
      } else {
        logError(error);
      }

      // Extract a friendly message from the GraphQL error, if it exists.
      const friendlyMessageFromBackend = getRegrelloErrorFriendlyMessageFromApolloError(error);
      if (friendlyMessageFromBackend != null && !options.disableBackendFriendlyMessage) {
        showToast({ content: friendlyMessageFromBackend, intent: "danger" });
      } else if (options.toastMessage != null) {
        showToast({ content: options.toastMessage, intent: "danger" });
      }
    },
    [logError, logErrorMessage, showToast],
  );

  return { handleError };
}
