import { format, isThisYear, isValid, parse } from "date-fns";

import { endOfDay, isDateWithinNextHours, isEndOfDay, parseDate } from "./dateUtils";
// (clewis): Supply-chain managers are *very* passionate about the day-month-year order of this
// formatted date. Please ask others before changing this.
// Ref: https://docs.oracle.com/javase/6/docs/api/java/text/SimpleDateFormat.html
// (surya): [IMPORTANT] If you make changes here, be sure to also change
// dateFormatUtils.ts in the email-renderer repo.
export const DATE_STRING = "dd MMM yyyy"; // "02 Jan 2006"
const DATE_TIME_STRING = "dd MMM yyyy hh:mm aa"; // "02 Jan 2006 03:45 PM"
const TIME_STRING = "hh:mm aa"; // "03:45 PM"
const ISO_STRING = "yyyy-MM-dd'T'HH:mm:ss'Z'"; // "2006-01-02T15:45:59Z"

/** Returns "02 Jan 2006", e.g. */
export function formatDateString(date: string | Date): string {
  return formatInternal(date, DATE_STRING);
}

/** Returns "02 Jan 2006 03:45 PM", e.g. */
export function formatDateTimeString(date: string | Date): string {
  return formatInternal(date, DATE_TIME_STRING);
}

/** Returns "03:45 PM", e.g. */
export function formatTimeString(date: string | Date): string {
  return formatInternal(date, TIME_STRING);
}

/** Returns "03:45 PM", e.g. */
export function parseWithFallback(date: string): Date {
  const dateParsed = parse(date, "HH:mm:ssxxx", new Date());
  if (isValid(dateParsed)) {
    return dateParsed;
  }
  return parse(date, "HH:mm:ssx", new Date());
}

export function formatIsoStringInLocalTimezone(date: string | Date): string {
  const parsedDate = parseDate(date);
  return format(endOfDay(parsedDate), ISO_STRING);
}

/**
 * Converts a date into a compact readable context-aware datetime based on these rules:
 * - Do not show year if it is this year.
 * - Do not show time if the time is end of day for the user.
 * - Do not show leading zeros in date or time.
 * E.g. "3:45 PM on 2 Jan", "2 Jan", "2 Jan 2045"
 */
export function formatCompactDateString(date: string | Date): string {
  const parsedDate = parseDate(date);

  const isDueThisYear = isThisYear(parsedDate);
  const isTimeWorthShowing = !isEndOfDay(parsedDate);

  const datePart = format(parsedDate, isDueThisYear ? "d MMM" : "d MMM yyyy");

  if (isTimeWorthShowing) {
    const timePart = format(parsedDate, "h:mm aa");
    return `${timePart} on ${datePart}`;
  }

  return datePart;
}

/** Parses the date into ISO format and then formats it based on the input pattern. */
function formatInternal(date: string | Date, pattern: string): string {
  const parsedDate = parseDate(date);
  return format(parsedDate, pattern);
}

/**
 * Show full date and optionally show time if explicitly set with "isTimeShown",
 * or if the time is all of:
 * - not EOD time
 * - at most 24 hours away
 * E.g. either "3:45 PM on 2 Jan 2006" or "2 Jan 2006"
 */
export function formatDateAndConditionallyShowTime(
  date: string | Date | null | undefined,
  isTimeShown?: boolean,
): string {
  if (date == null) {
    return "";
  }
  const parsedDate = parseDate(date);

  const isDateWithinNext24Hours = isDateWithinNextHours(parsedDate, 24);
  const isTimeWorthShowing = isDateWithinNext24Hours && !isEndOfDay(parsedDate);

  const datePart = format(parsedDate, "d MMM yyyy");

  if (isTimeShown || isTimeWorthShowing) {
    const timePart = format(parsedDate, "h:mm aa");
    return `${timePart} on ${datePart}`;
  }

  return datePart;
}
