// (clewis): These class names and values determine all sizing information for checkboxes. The class names must be explicitly
// stated in code else UnoCSS won't pick it up. Please keep the values with the same prefix, in sync.
//
// Also, this must be a .tsx file, not a .ts file, in order for class names to be picked up by UnoCSS.

export const REGRELLO_CHECKBOX_SIZE = 18;
export const REGRELLO_CHECKBOX_SIZE_CLASS_NAME = "h-4.5 w-4.5"; // 18px;

export const REGRELLO_CONTROL_LABEL_OFFSET = 10;
export const REGRELLO_CONTROL_LABEL_OFFSET_CLASS_NAME = "space-x-2.5";

export const REGRELLO_CONTROL_CHILD_CONTENT_OFFSET_CLASS_NAME = "ml-7"; // sum of 4.5 and 2.5
