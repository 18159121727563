// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { gql } from '@apollo/client';
export type SpectrumFieldConstraintFields = { id: number, uuid: string, createdAt: string, constraintArgs: Array<string>, spectrumValueConstraint: SpectrumValueConstraintFields };

export const SpectrumFieldConstraintFields = gql`
    fragment SpectrumFieldConstraintFields on SpectrumFieldConstraint {
  id
  uuid
  createdAt
  spectrumValueConstraint {
    ...SpectrumValueConstraintFields
  }
  constraintArgs
}
    `;