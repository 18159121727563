import { ConditionOperator } from "@regrello/graphql-api";
import { ConditionContainsAll, ConditionIsBetween, ConditionIsEmpty, ConditionIsNotEmpty } from "@regrello/ui-strings";

const conditionOperatorConfigConstructor = (displayValue: string, operator: ConditionOperator, numFields: number) => {
  return {
    displayValue,
    operator,
    numFields,
  };
};

export const getConditionOperatorConfigEmpty = () => {
  return conditionOperatorConfigConstructor(ConditionIsEmpty, ConditionOperator.EMPTY, 0);
};
export const getConditionOperatorConfigNotEmpty = () => {
  return conditionOperatorConfigConstructor(ConditionIsNotEmpty, ConditionOperator.NOT_EMPTY, 0);
};
export const getConditionOperatorConfigGreaterThanOrEquals = (displayValue: string) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.GREATER_THAN_OR_EQUALS, 1);
};
export const getConditionOperatorConfigLessThanOrEquals = (displayValue: string) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.LESS_THAN_OR_EQUALS, 1);
};
export const getConditionOperatorConfigEquals = (displayValue: string, excludeFormField?: boolean) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.EQUALS, excludeFormField ? 0 : 1);
};
export const getConditionOperatorConfigNotEquals = (displayValue: string, excludeFormField?: boolean) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.NOT_EQUALS, excludeFormField ? 0 : 1);
};
export const getConditionOperatorBetween = () => {
  return conditionOperatorConfigConstructor(ConditionIsBetween, ConditionOperator.BETWEEN, 2);
};
export const getConditionOperatorConfigContainsAnyOf = (displayValue: string) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.CONTAINS_ANY_OF, 1);
};
export const getConditionOperatorConfigContainsNoneOf = (displayValue: string) => {
  return conditionOperatorConfigConstructor(displayValue, ConditionOperator.CONTAINS_NONE_OF, 1);
};
export const getConditionOperatorConfigContainsAllOf = () => {
  return conditionOperatorConfigConstructor(ConditionContainsAll, ConditionOperator.CONTAINS_ALL_OF, 1);
};
