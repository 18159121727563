import { RegrelloTypography } from "@regrello/ui-core";
import { Inactive } from "@regrello/ui-strings";
import React from "react";

/**
 * Displays an "inactive" badge if the object has been deleted (e.g. user or custom field).
 */
export const RegrelloInactiveBadge = React.memo(function RegrelloInactiveBadgeFn() {
  return (
    <RegrelloTypography className="inline ml-1" muted={true} variant="body-xs">
      ({Inactive})
    </RegrelloTypography>
  );
});
