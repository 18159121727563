// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { RegrelloObjectInstanceAuditHistoryFields } from './RegrelloObjectInstanceAuditHistoryFields.generated';
import { RegrelloObjectInstanceAuditHistoryDeltaFields } from './RegrelloObjectInstanceAuditHistoryDeltaFields.generated';
import { gql } from '@apollo/client';
export type RegrelloObjectInstancesAuditHistoryFields = { id: number, createdAt: string, event: Types.AuditHistoryEvent, createdBy: PartyBaseFields, fields: Array<RegrelloObjectInstanceAuditHistoryFields> };

export const RegrelloObjectInstancesAuditHistoryFields = gql`
    fragment RegrelloObjectInstancesAuditHistoryFields on RegrelloObjectInstancesAuditHistory {
  id
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  event
  fields {
    ...RegrelloObjectInstanceAuditHistoryFields
  }
}
    `;