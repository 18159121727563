import { EMPTY_STRING, RegrelloIconSize } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { DocumentFields, useRetrieveDocumentDownloadUrlQueryLazyQuery } from "@regrello/graphql-api";
import { RegrelloChip, RegrelloChipProps, RegrelloProgress } from "@regrello/ui-core";
import { ErrorDownloadDocument } from "@regrello/ui-strings";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { getProtocol } from "../../../utils/urlUtils";

export interface RegrelloDocumentChipProps extends Omit<RegrelloChipProps, "onDelete" | "isClickable"> {
  /**
   * If isDeletable is not set to 'false', this document chip should behave as a deletable
   * chip. A deletable chip is expected to have the following behavior
   *    1. Delete itself on clicking the "X" icon
   *    2. The chip should show a progress bar while uploading
   *    3. The chip should be clickable once the upload completes
   *    4. The "X" icon should show up once the upload completes.
   */
  document: DocumentFields;

  /**
   * Whether the document chip should be clickable by the user to navigate them to the
   * proper document URL. Disabling this behavior is useful if, for example, the document
   * is not accessible to the current user.
   *
   * @default true
   */
  isClickable?: boolean;

  /**
   * Whether the document deletion should only pertain to the UI state or be persisted to the
   * server, which happens by default. This behavior needs to be bypassed in certain contexts like,
   * for example, duplicating a task and the user decides to delete one of the attached documents.
   *
   * @default false
   */
  isDeletionLocalOnly?: boolean;

  /**
   * Callback to invoke when the user clicks a delete button. The delete button is not shown unless
   * this prop is provided.
   */
  onDelete?: (documentToDelete: DocumentFields) => void;

  /**
   * Whether the document is currently being uploaded. If true, a fake animated progress bar will be
   * shown until the upload completes.
   */
  isUploading?: boolean;
}

const FAKE_PROGRESS_ANIMATION_INTERVAL = 500;
const COMPLETED_PROGRESS = 100;
const RIGHT_BEFORE_COMPLETE = 99;

type UploadProgress = {
  percent: number;
  show: boolean;
};

export const RegrelloDocumentChip = React.memo(function RegrelloDocumentChipFn({
  className,
  document,
  isDeletionLocalOnly,
  onDelete,
  isUploading,
  isClickable = true,
  ...restProps
}: RegrelloDocumentChipProps) {
  const { handleError } = useErrorHandler();

  const [uploadProgress, setUploadProgress] = useState<UploadProgress>(
    isUploading ? { percent: 0, show: true } : { percent: COMPLETED_PROGRESS, show: false },
  );
  useEffect(() => {
    if (isUploading) {
      setUploadProgress({ percent: 0, show: true });
    }

    // (hchen): Animate the progress bar. We don't actually get progress number from the endpoint,
    // so we have to fake it!
    const timer = setInterval(() => {
      if (isUploading) {
        setUploadProgress((progress) => {
          return {
            // (raymond): While the upload is ongoing, cap the progress bar at some amount less than 100%.
            percent: Math.min(progress.percent + Math.random(), RIGHT_BEFORE_COMPLETE),
            show: true,
          };
        });
        return;
      }

      setUploadProgress((progress) => {
        // (raymond): If the progress bar was being animated, first finish the animation by showing
        // a completed progress bar. Then hide it on the next tick.
        if (progress.percent < COMPLETED_PROGRESS) {
          return { percent: COMPLETED_PROGRESS, show: true };
        } else {
          clearInterval(timer);
          return { percent: COMPLETED_PROGRESS, show: false };
        }
      });
    }, FAKE_PROGRESS_ANIMATION_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [isUploading]);

  const documentVersionId = document.currentVersion.id;

  const [retrieveDocumentDownloadUrlAsyncLazy] = useRetrieveDocumentDownloadUrlQueryLazyQuery({
    onError: (error) => handleError(error, { toastMessage: ErrorDownloadDocument }),
    onCompleted: (data) => {
      if (data.retrieveDocumentDownloadUrl?.signedUrl != null) {
        window.open(data.retrieveDocumentDownloadUrl.signedUrl);
      }
    },
    // (hchen): Always retrieve the latest url from the server since we don't know when does a url
    // expire.
    fetchPolicy: "no-cache",
  });

  const onDeleteSelf = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // (anthony): Prevent the default events because deleting a document from a form is causing
      // a submission of the form.
      event.preventDefault();

      onDelete?.(document);
    },
    [document, onDelete],
  );

  const onClick = useCallback(async () => {
    if (document.currentVersion.externalLink == null) {
      await retrieveDocumentDownloadUrlAsyncLazy({
        variables: {
          documentVersionId,
        },
      });
    }
  }, [document.currentVersion.externalLink, documentVersionId, retrieveDocumentDownloadUrlAsyncLazy]);

  const isClickEnabled = isClickable && !isUploading;
  const isDeletable = onDelete != null;

  const DocumentName = useMemo(() => {
    return isDeletable ? (
      // (clewis): Use an inline div so that text ellipses show correctly. (Can't use span because
      // other divs may be rendered within this.)
      <div className="inline">
        {document.name}
        {uploadProgress.show && (
          <div className="w-full" data-testid={DataTestIds.DOCUMENT_CHIP_PROGRESS}>
            <RegrelloProgress className="h-1" value={uploadProgress.percent} />
          </div>
        )}
      </div>
    ) : (
      document.name
    );
  }, [document.name, isDeletable, uploadProgress]);

  if (document == null) {
    return null;
  }

  // Link to a URL. Pass relevant props to `RegrelloChip`.
  if (document.currentVersion.externalLink != null) {
    const protocol = getProtocol(document.currentVersion.externalLink);
    const href =
      protocol !== EMPTY_STRING
        ? document.currentVersion.externalLink
        : `https://${document.currentVersion.externalLink}`;

    return (
      <RegrelloChip
        {...restProps}
        className={className ?? EMPTY_STRING}
        dataTestId={DataTestIds.DOCUMENT_CHIP}
        href={href}
        icon={{
          type: "custom",
          element: (
            <img
              alt="file type icon"
              className="w-3.75"
              src={document.documentType.icon}
              style={{ height: RegrelloIconSize.STANDARD }}
            />
          ),
        }}
        onDelete={isDeletable ? onDeleteSelf : undefined}
        rel="noopener noreferrer"
        target="_blank"
      >
        {DocumentName}
      </RegrelloChip>
    );
  }

  return (
    <RegrelloChip
      {...restProps}
      className={className}
      dataTestId={DataTestIds.DOCUMENT_CHIP}
      icon={{
        type: "custom",
        element: (
          <img
            alt="file type icon"
            className="w-3.75"
            src={document.documentType.icon}
            style={{ height: RegrelloIconSize.STANDARD }}
          />
        ),
      }}
      onClick={(document.currentVersion.uid != null || isDeletionLocalOnly) && isClickEnabled ? onClick : undefined}
      onDelete={isDeletable ? onDeleteSelf : undefined}
      visibleChipClassName="truncate"
    >
      {DocumentName}
    </RegrelloChip>
  );
});
