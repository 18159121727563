import { ValuesUnion } from "@regrello/core-utils";

export const RegrelloSize = {
  /** 16x16 */
  X_SMALL: "x-small" as const,

  /** 20x20 */
  SMALL: "small" as const,

  /** 24x24 */
  MEDIUM: "medium" as const,

  /** 32x32 */
  LARGE: "large" as const,

  /** 60x60 */
  X_LARGE: "x-large" as const,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RegrelloSize = ValuesUnion<typeof RegrelloSize>;
