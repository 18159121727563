// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { TagTypeFields } from './TagTypeFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type TagTableTagFields = { createdAt: string, id: number, name: string, tagType: TagTypeFields, createdBy: { party: PartyBaseFields } };

export const TagTableTagFields = gql`
    fragment TagTableTagFields on Tag {
  tagType {
    ...TagTypeFields
  }
  createdAt
  createdBy {
    party {
      ...PartyBaseFields
    }
  }
  id
  name
}
    `;