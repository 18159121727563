import { clsx } from "@regrello/core-utils";
import React from "react";

import { RegrelloIntentV2 } from "../../../utils/enums/RegrelloIntentV2";

export const RegrelloMenuV2Shortcut = React.memo(function RegrelloMenuV2ShortcutFn({
  className,
  intent,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & { intent?: RegrelloIntentV2 }) {
  return (
    <span
      className={clsx(
        "text-sm",
        {
          "text-neutral-textMuted": intent === "neutral",
          "text-primary-textMuted": intent === "primary",
          "text-success-textMuted": intent === "success",
          "text-warning-textMuted": intent === "warning",
          "text-danger-textMuted": intent === "danger",
        },
        className,
      )}
      {...props}
    />
  );
});
