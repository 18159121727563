// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { RegrelloObjectInstanceFields } from '../../fragments/generated/RegrelloObjectInstanceFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegrelloObjectInstancesQueryVariables = Types.Exact<{
  regrelloObjectId: Types.Scalars['ID']['input'];
  filters?: Types.InputMaybe<Array<Types.RegrelloObjectInstanceSearchFilterInput>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  queryV2?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortByPropertyId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  fieldInstanceId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  totalCountOnly?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type RegrelloObjectInstancesQuery = { regrelloObjectInstances?: { totalCount: number, hasMore: boolean, regrelloObjectInstances: Array<RegrelloObjectInstanceFields> } | null };


export const RegrelloObjectInstancesQueryDocument = gql`
    query RegrelloObjectInstancesQuery($regrelloObjectId: ID!, $filters: [RegrelloObjectInstanceSearchFilterInput!], $searchTerm: String, $queryV2: Boolean, $limit: Int, $offset: Int, $sortByPropertyId: Int, $sortOrder: SortOrder, $fieldInstanceId: ID, $totalCountOnly: Boolean) {
  regrelloObjectInstances(
    regrelloObjectId: $regrelloObjectId
    filters: $filters
    searchTerm: $searchTerm
    queryV2: $queryV2
    limit: $limit
    offset: $offset
    sortByPropertyId: $sortByPropertyId
    sortOrder: $sortOrder
    fieldInstanceId: $fieldInstanceId
    totalCountOnly: $totalCountOnly
  ) {
    regrelloObjectInstances {
      ...RegrelloObjectInstanceFields
    }
    totalCount
    hasMore
  }
}
    ${RegrelloObjectInstanceFields}`;

/**
 * __useRegrelloObjectInstancesQuery__
 *
 * To run a query within a React component, call `useRegrelloObjectInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegrelloObjectInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegrelloObjectInstancesQuery({
 *   variables: {
 *      regrelloObjectId: // value for 'regrelloObjectId'
 *      filters: // value for 'filters'
 *      searchTerm: // value for 'searchTerm'
 *      queryV2: // value for 'queryV2'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortByPropertyId: // value for 'sortByPropertyId'
 *      sortOrder: // value for 'sortOrder'
 *      fieldInstanceId: // value for 'fieldInstanceId'
 *      totalCountOnly: // value for 'totalCountOnly'
 *   },
 * });
 */
export function useRegrelloObjectInstancesQuery(baseOptions: Apollo.QueryHookOptions<RegrelloObjectInstancesQuery, RegrelloObjectInstancesQueryVariables> & ({ variables: RegrelloObjectInstancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegrelloObjectInstancesQuery, RegrelloObjectInstancesQueryVariables>(RegrelloObjectInstancesQueryDocument, options);
      }
export function useRegrelloObjectInstancesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegrelloObjectInstancesQuery, RegrelloObjectInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegrelloObjectInstancesQuery, RegrelloObjectInstancesQueryVariables>(RegrelloObjectInstancesQueryDocument, options);
        }
export function useRegrelloObjectInstancesQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RegrelloObjectInstancesQuery, RegrelloObjectInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RegrelloObjectInstancesQuery, RegrelloObjectInstancesQueryVariables>(RegrelloObjectInstancesQueryDocument, options);
        }
export type RegrelloObjectInstancesQueryHookResult = ReturnType<typeof useRegrelloObjectInstancesQuery>;
export type RegrelloObjectInstancesQueryLazyQueryHookResult = ReturnType<typeof useRegrelloObjectInstancesQueryLazyQuery>;
export type RegrelloObjectInstancesQuerySuspenseQueryHookResult = ReturnType<typeof useRegrelloObjectInstancesQuerySuspenseQuery>;
export type RegrelloObjectInstancesQueryResult = Apollo.QueryResult<RegrelloObjectInstancesQuery, RegrelloObjectInstancesQueryVariables>;