// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type FormInstanceSectionMetadataFields = { type: string, value: string };

export const FormInstanceSectionMetadataFields = gql`
    fragment FormInstanceSectionMetadataFields on FormInstanceSectionMetadata {
  type
  value
}
    `;