import { EMPTY_STRING } from "../../constants/emptyString";

/**
 * Get the initials from the a user's full name. Returns at most two letters (from the first word
 * and from the last).
 */
export function getInitials(fullName: string) {
  const x = getInitialsFromString(removeTextInParenthesis(fullName));
  return x.length > 0 ? x : getInitialsFromString(fullName);
}

function getInitialsFromString(fullName: string) {
  const tokens = fullName.split(" ");
  if (tokens.length === 0) {
    return EMPTY_STRING;
  }
  const firstInitial = getFirstLetter(tokens[0]);
  if (tokens.length === 1) {
    return firstInitial.toLocaleUpperCase();
  }

  // (clewis): Skip middle initials since there isn't enough space for more than two.
  const lastInitial = getFirstLetter(tokens[tokens.length - 1]);
  return `${firstInitial}${lastInitial}`.toLocaleUpperCase();
}

function getFirstLetter(token: string) {
  return token.length === 0 ? EMPTY_STRING : token[0];
}

function removeTextInParenthesis(name: string) {
  return name.replace(/\s*\([^)]*\)\s*/g, "");
}
