import { ComparatorResult } from "@regrello/core-utils";

/** Sorts boolean values. False will come before true. */
export function booleanComparator(value1: boolean, value2: boolean): ComparatorResult {
  if (value1 === false && value2 === true) {
    return ComparatorResult.BEFORE;
  }

  if (value2 === false && value1 === true) {
    return ComparatorResult.AFTER;
  }

  return ComparatorResult.EQUAL;
}
