// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormFieldConstraintRuleFields } from './FormFieldConstraintRuleFields.generated';
import { FormConstraintConditionGroupFields } from './FormConstraintConditionGroupFields.generated';
import { FormConstraintConditionGroupBaseFields } from './FormConstraintConditionGroupBaseFields.generated';
import { FormConstraintConditionFields } from './FormConstraintConditionFields.generated';
import { gql } from '@apollo/client';
export type FormFieldConstraintFields = { id: number, uuid: string, createdAt: string, formFieldConstraintRule: FormFieldConstraintRuleFields, condition: FormConstraintConditionGroupFields };

export const FormFieldConstraintFields = gql`
    fragment FormFieldConstraintFields on FormFieldConstraint {
  id
  uuid
  createdAt
  formFieldConstraintRule {
    ...FormFieldConstraintRuleFields
  }
  condition {
    ...FormConstraintConditionGroupFields
  }
}
    `;