// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserBaseFields } from './UserBaseFields.generated';
import { gql } from '@apollo/client';
export type IntegrationAuthenticationFields = { id: number, isActive: boolean, error?: string | null, createdAt: string, updatedAt: string, user: UserBaseFields, extraConfig?: { sharePointSiteName?: string | null } | null };

export const IntegrationAuthenticationFields = gql`
    fragment IntegrationAuthenticationFields on IntegrationAuthentication {
  id
  isActive
  error
  user {
    ...UserBaseFields
  }
  createdAt
  updatedAt
  extraConfig {
    ... on IntegrationAuthenticationExtraConfigSharePoint {
      sharePointSiteName
    }
  }
}
    `;