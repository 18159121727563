import React from "react";

import { Linkify } from "./_internal/Linkify";

export interface RegrelloLinkifyProps {
  children: React.ReactNode; // (clewis): Make `children` required.
}

/**
 * Wrap this component around text content to automatically convert "link-like" tokens into
 * clickable links.
 */
export const RegrelloLinkify = React.memo(function RegrelloLinkifyFn({ children }: RegrelloLinkifyProps) {
  return (
    <Linkify
      linkAttributes={{
        // HACKHACK (clewis): Auto-add all the class names that `RegrelloLinkV2` include, so these links
        // will look the same as other links in our app:
        className: "font-medium text-sm text-primary-textMuted hover:underline",
        target: "_blank",
        rel: "noopener noreferrer",
      }}
    >
      {children}
    </Linkify>
  );
});
