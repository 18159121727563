import { TeamWithoutMembersFields, UserBaseFields } from "@regrello/graphql-api";

export type PartyBaseTypeUnion_User = {
  type: "user";
  user: UserBaseFields;
};

export type PartyBaseTypeUnion_Team = {
  type: "team";
  team: TeamWithoutMembersFields;
};

function isUser(obj: PartyBaseTypeUnion): obj is PartyBaseTypeUnion_User {
  return obj.type === "user";
}

function user(obj: UserBaseFields): PartyBaseTypeUnion_User {
  return {
    type: "user",
    user: obj,
  };
}

function isTeam(obj: PartyBaseTypeUnion): obj is PartyBaseTypeUnion_Team {
  return obj.type === "team";
}

function team(obj: TeamWithoutMembersFields): PartyBaseTypeUnion_Team {
  return {
    type: "team",
    team: obj,
  };
}

export type PartyBaseTypeUnion = PartyBaseTypeUnion_User | PartyBaseTypeUnion_Team;

export interface IPartyBaseTypeUnionVisitor<T> {
  user: (obj: UserBaseFields) => T;
  team: (obj: TeamWithoutMembersFields) => T;
  unknown: (obj: never) => T;
}

function visit<T>(obj: PartyBaseTypeUnion, visitor: IPartyBaseTypeUnionVisitor<T>): T {
  if (isUser(obj)) {
    return visitor.user(obj.user);
  }
  if (isTeam(obj)) {
    return visitor.team(obj.team);
  }
  return visitor.unknown(obj);
}

// (clewis): This contruct conforms to Conjure union typings.
// See: https://github.com/palantir/conjure-typescript/blob/develop/src/commands/generate/__tests__/resources/types/unionTypeExample.ts
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartyBaseTypeUnion = {
  isUser,
  user,
  visit,
  isTeam,
  team,
};
