// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { gql } from '@apollo/client';
export type AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields = { __typename: 'FieldInstanceMultiValueRegrelloObjectInstance', id: number, inputType: Types.FieldInstanceValueInputType, regrelloObjectInstanceMultiValue: Array<RegrelloObjectInstanceFields> };

export const AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields = gql`
    fragment AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields on FieldInstanceMultiValueRegrelloObjectInstance {
  __typename
  id
  inputType
  regrelloObjectInstanceMultiValue {
    ...RegrelloObjectInstanceFields
  }
}
    `;