import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { clsx, WithClassName } from "@regrello/core-utils";
import React from "react";

export interface RegrelloRadioGroupProps
  extends WithClassName,
    // (clewis): Use onValueChange instead of onChange.
    Omit<React.ComponentProps<typeof RadioGroupPrimitive.Root>, "onChange" | "onValueChange"> {
  // (clewis): Restating the important props for clarity.

  /** The initial value to select */
  defaultValue?: string;

  /** Callback invoked when the user has changed the value. */
  onChange?: (nextValue: string) => void;

  /** The current value if controlled by the parent. */
  value?: string;
}

const RegrelloRadioGroup = React.memo(
  React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, Omit<RegrelloRadioGroupProps, "ref">>(
    function RegrelloRadioGroupFn({ className, onChange, ...props }, ref) {
      return (
        <RadioGroupPrimitive.Root
          {...props}
          ref={ref}
          className={clsx("flex flex-col space-y-2", className)}
          onValueChange={onChange}
        />
      );
    },
  ),
);
RegrelloRadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RegrelloRadioGroupItem = React.memo(
  React.forwardRef<
    React.ElementRef<typeof RadioGroupPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
  >(({ className, children: _unused, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={clsx(
          `
          h-4.5
          w-4.5
          rounded-circular
          `,
          // (clewis): Use box-shadows for radio-button borders. Using border caused the focus ring
          // to look way more curved than was correct.
          `
          rg-box-border
          bg-background
          hover:bg-neutral-transparentHovered
          active:bg-neutral-transparentPressed

          flex
          items-center
          justify-center
          shrink-0

          focus-visible:outline-primary-solid
          focus-visible:outline-offset-3

          data-[state=checked]:shadow-none
          data-[state=checked]:bg-primary-solid
          data-[state=checked]:text-primary-textMuted
          data-[state=checked]:hover:bg-primary-solidHovered
          data-[state=checked]:active:rg-solidPressed

          disabled:bg-background
          disabled:data-[state=checked]:bg-primary-solid
          disabled:cursor-not-allowed
          disabled:opacity-disabled
          disabled:outline-none

          hover:cursor-pointer
          `,
          className,
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
          <div className="w-2 h-2 rounded-circular bg-background" />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
    );
  }),
);
RegrelloRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RegrelloRadioGroup, RegrelloRadioGroupItem };
