// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormFieldFields } from '../../fragments/generated/FormFieldFields.generated';
import { FormFieldConstraintFields } from '../../fragments/generated/FormFieldConstraintFields.generated';
import { FormFieldConstraintRuleFields } from '../../fragments/generated/FormFieldConstraintRuleFields.generated';
import { FormConstraintConditionGroupFields } from '../../fragments/generated/FormConstraintConditionGroupFields.generated';
import { FormConstraintConditionGroupBaseFields } from '../../fragments/generated/FormConstraintConditionGroupBaseFields.generated';
import { FormConstraintConditionFields } from '../../fragments/generated/FormConstraintConditionFields.generated';
import { SpectrumFieldVersionFields } from '../../fragments/generated/SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from '../../fragments/generated/SpectrumFieldFields.generated';
import { PropertyTypeFields } from '../../fragments/generated/PropertyTypeFields.generated';
import { SpectrumFieldValidationTypeFields } from '../../fragments/generated/SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from '../../fragments/generated/SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from '../../fragments/generated/SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from '../../fragments/generated/SpectrumFieldAllowedValueFields.generated';
import { FieldUnitFields } from '../../fragments/generated/FieldUnitFields.generated';
import { FieldFields } from '../../fragments/generated/FieldFields.generated';
import { PartyBaseFields } from '../../fragments/generated/PartyBaseFields.generated';
import { UserBaseFields } from '../../fragments/generated/UserBaseFields.generated';
import { TeamWithoutMembersFields } from '../../fragments/generated/TeamWithoutMembersFields.generated';
import { RegrelloObjectFields } from '../../fragments/generated/RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from '../../fragments/generated/AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from '../../fragments/generated/RegrelloObjectPropertyFields.generated';
import { FieldInstanceFields } from '../../fragments/generated/FieldInstanceFields.generated';
import { FieldInstanceBaseFields } from '../../fragments/generated/FieldInstanceBaseFields.generated';
import { FieldInstanceSpectrumMetadataFields } from '../../fragments/generated/FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from '../../fragments/generated/FieldInstanceSpectrumFields.generated';
import { FieldInstanceValueFieldsFieldInstanceMultiValueDocument, FieldInstanceValueFieldsFieldInstanceMultiValueFloat, FieldInstanceValueFieldsFieldInstanceMultiValueInt, FieldInstanceValueFieldsFieldInstanceMultiValueParty, FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueFieldsFieldInstanceMultiValueString, FieldInstanceValueFieldsFieldInstanceMultiValueTime, FieldInstanceValueFieldsFieldInstanceValueBoolean, FieldInstanceValueFieldsFieldInstanceValueFloat, FieldInstanceValueFieldsFieldInstanceValueInt, FieldInstanceValueFieldsFieldInstanceValueParty, FieldInstanceValueFieldsFieldInstanceValueString, FieldInstanceValueFieldsFieldInstanceValueTime } from '../../fragments/generated/FieldInstanceValueFields.generated';
import { FieldInstanceValueStringFields } from '../../fragments/generated/FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from '../../fragments/generated/FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from '../../fragments/generated/AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringFields } from '../../fragments/generated/FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from '../../fragments/generated/FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntFields } from '../../fragments/generated/FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from '../../fragments/generated/FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from '../../fragments/generated/AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatFields } from '../../fragments/generated/FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from '../../fragments/generated/FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from '../../fragments/generated/AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanFields } from '../../fragments/generated/FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from '../../fragments/generated/FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from '../../fragments/generated/AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeFields } from '../../fragments/generated/FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from '../../fragments/generated/FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from '../../fragments/generated/AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from '../../fragments/generated/AcyclicFieldInstanceFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from '../../fragments/generated/AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from '../../fragments/generated/AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from '../../fragments/generated/DocumentBaseFields.generated';
import { TagFields } from '../../fragments/generated/TagFields.generated';
import { TagTypeFields } from '../../fragments/generated/TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from '../../fragments/generated/RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from '../../fragments/generated/AcyclicFieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceValuePartyFields } from '../../fragments/generated/FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyFields } from '../../fragments/generated/FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentFields } from '../../fragments/generated/FieldInstanceMultiValueDocumentFields.generated';
import { DocumentFields } from '../../fragments/generated/DocumentFields.generated';
import { DocumentVersionFields } from '../../fragments/generated/DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from '../../fragments/generated/WorkflowPermissionsFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from '../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntFields } from '../../fragments/generated/FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from '../../fragments/generated/FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatFields } from '../../fragments/generated/FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from '../../fragments/generated/FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeFields } from '../../fragments/generated/FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from '../../fragments/generated/FieldInstanceMultiValueTimeBaseFields.generated';
import { gql } from '@apollo/client';
import { FieldInstanceValueFields } from '../../fragments/generated/FieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValueFields } from '../../fragments/generated/AcyclicFieldInstanceValueFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormSelectLatestFormFieldInstancesQueryVariables = Types.Exact<{
  formUUID: Types.Scalars['String']['input'];
}>;


export type FormSelectLatestFormFieldInstancesQuery = { latestFormVersion: { defaultFormSection: { formFields: Array<FormFieldFields>, formSections: Array<{ formFields: Array<FormFieldFields>, formSections: Array<{ formFields: Array<FormFieldFields> }> }> }, fieldInstances: Array<FieldInstanceFields> } };


export const FormSelectLatestFormFieldInstancesQueryDocument = gql`
    query FormSelectLatestFormFieldInstancesQuery($formUUID: String!) {
  latestFormVersion(formUUID: $formUUID) {
    defaultFormSection {
      formFields {
        ...FormFieldFields
      }
      formSections {
        formFields {
          ...FormFieldFields
        }
        formSections {
          formFields {
            ...FormFieldFields
          }
        }
      }
    }
    fieldInstances {
      ...FieldInstanceFields
    }
  }
}
    ${FormFieldFields}
${FormFieldConstraintFields}
${FormFieldConstraintRuleFields}
${FormConstraintConditionGroupFields}
${FormConstraintConditionGroupBaseFields}
${FormConstraintConditionFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${PropertyTypeFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldUnitFields}
${FieldFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}
${FieldInstanceFields}
${FieldInstanceBaseFields}
${FieldInstanceSpectrumMetadataFields}
${FieldInstanceSpectrumFields}
${FieldInstanceValueFields}
${FieldInstanceValueStringFields}
${FieldInstanceValueStringBaseFields}
${AcyclicFieldInstanceValueStringFields}
${FieldInstanceMultiValueStringFields}
${FieldInstanceMultiValueStringBaseFields}
${AcyclicFieldInstanceMultiValueStringFields}
${FieldInstanceValueIntFields}
${FieldInstanceValueIntBaseFields}
${AcyclicFieldInstanceValueIntFields}
${FieldInstanceValueFloatFields}
${FieldInstanceValueFloatBaseFields}
${AcyclicFieldInstanceValueFloatFields}
${FieldInstanceValueBooleanFields}
${FieldInstanceValueBooleanBaseFields}
${AcyclicFieldInstanceValueBooleanFields}
${FieldInstanceValueTimeFields}
${FieldInstanceValueTimeBaseFields}
${AcyclicFieldInstanceValueTimeFields}
${AcyclicFieldInstanceFields}
${AcyclicFieldInstanceValueFields}
${AcyclicFieldInstanceValuePartyFields}
${AcyclicFieldInstanceMultiValuePartyFields}
${AcyclicFieldInstanceMultiValueDocumentFields}
${DocumentBaseFields}
${TagFields}
${TagTypeFields}
${AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields}
${RegrelloObjectInstanceFields}
${AcyclicFieldInstanceMultiValueIntFields}
${AcyclicFieldInstanceMultiValueFloatFields}
${AcyclicFieldInstanceMultiValueTimeFields}
${FieldInstanceValuePartyFields}
${FieldInstanceMultiValuePartyFields}
${FieldInstanceMultiValueDocumentFields}
${DocumentFields}
${DocumentVersionFields}
${WorkflowPermissionsFields}
${FieldInstanceMultiValueRegrelloObjectInstanceFields}
${FieldInstanceMultiValueIntFields}
${FieldInstanceMultiValueIntBaseFields}
${FieldInstanceMultiValueFloatFields}
${FieldInstanceMultiValueFloatBaseFields}
${FieldInstanceMultiValueTimeFields}
${FieldInstanceMultiValueTimeBaseFields}`;

/**
 * __useFormSelectLatestFormFieldInstancesQuery__
 *
 * To run a query within a React component, call `useFormSelectLatestFormFieldInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSelectLatestFormFieldInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSelectLatestFormFieldInstancesQuery({
 *   variables: {
 *      formUUID: // value for 'formUUID'
 *   },
 * });
 */
export function useFormSelectLatestFormFieldInstancesQuery(baseOptions: Apollo.QueryHookOptions<FormSelectLatestFormFieldInstancesQuery, FormSelectLatestFormFieldInstancesQueryVariables> & ({ variables: FormSelectLatestFormFieldInstancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormSelectLatestFormFieldInstancesQuery, FormSelectLatestFormFieldInstancesQueryVariables>(FormSelectLatestFormFieldInstancesQueryDocument, options);
      }
export function useFormSelectLatestFormFieldInstancesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormSelectLatestFormFieldInstancesQuery, FormSelectLatestFormFieldInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormSelectLatestFormFieldInstancesQuery, FormSelectLatestFormFieldInstancesQueryVariables>(FormSelectLatestFormFieldInstancesQueryDocument, options);
        }
export function useFormSelectLatestFormFieldInstancesQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormSelectLatestFormFieldInstancesQuery, FormSelectLatestFormFieldInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormSelectLatestFormFieldInstancesQuery, FormSelectLatestFormFieldInstancesQueryVariables>(FormSelectLatestFormFieldInstancesQueryDocument, options);
        }
export type FormSelectLatestFormFieldInstancesQueryHookResult = ReturnType<typeof useFormSelectLatestFormFieldInstancesQuery>;
export type FormSelectLatestFormFieldInstancesQueryLazyQueryHookResult = ReturnType<typeof useFormSelectLatestFormFieldInstancesQueryLazyQuery>;
export type FormSelectLatestFormFieldInstancesQuerySuspenseQueryHookResult = ReturnType<typeof useFormSelectLatestFormFieldInstancesQuerySuspenseQuery>;
export type FormSelectLatestFormFieldInstancesQueryResult = Apollo.QueryResult<FormSelectLatestFormFieldInstancesQuery, FormSelectLatestFormFieldInstancesQueryVariables>;