// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HasCurrentUserAcknowledgedPrivacyPolicyQuery = { hasCurrentUserAcknowledgedPrivacyPolicy: boolean };


export const HasCurrentUserAcknowledgedPrivacyPolicyQueryDocument = gql`
    query HasCurrentUserAcknowledgedPrivacyPolicyQuery {
  hasCurrentUserAcknowledgedPrivacyPolicy
}
    `;

/**
 * __useHasCurrentUserAcknowledgedPrivacyPolicyQuery__
 *
 * To run a query within a React component, call `useHasCurrentUserAcknowledgedPrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasCurrentUserAcknowledgedPrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasCurrentUserAcknowledgedPrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasCurrentUserAcknowledgedPrivacyPolicyQuery(baseOptions?: Apollo.QueryHookOptions<HasCurrentUserAcknowledgedPrivacyPolicyQuery, HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasCurrentUserAcknowledgedPrivacyPolicyQuery, HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables>(HasCurrentUserAcknowledgedPrivacyPolicyQueryDocument, options);
      }
export function useHasCurrentUserAcknowledgedPrivacyPolicyQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasCurrentUserAcknowledgedPrivacyPolicyQuery, HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasCurrentUserAcknowledgedPrivacyPolicyQuery, HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables>(HasCurrentUserAcknowledgedPrivacyPolicyQueryDocument, options);
        }
export function useHasCurrentUserAcknowledgedPrivacyPolicyQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HasCurrentUserAcknowledgedPrivacyPolicyQuery, HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HasCurrentUserAcknowledgedPrivacyPolicyQuery, HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables>(HasCurrentUserAcknowledgedPrivacyPolicyQueryDocument, options);
        }
export type HasCurrentUserAcknowledgedPrivacyPolicyQueryHookResult = ReturnType<typeof useHasCurrentUserAcknowledgedPrivacyPolicyQuery>;
export type HasCurrentUserAcknowledgedPrivacyPolicyQueryLazyQueryHookResult = ReturnType<typeof useHasCurrentUserAcknowledgedPrivacyPolicyQueryLazyQuery>;
export type HasCurrentUserAcknowledgedPrivacyPolicyQuerySuspenseQueryHookResult = ReturnType<typeof useHasCurrentUserAcknowledgedPrivacyPolicyQuerySuspenseQuery>;
export type HasCurrentUserAcknowledgedPrivacyPolicyQueryResult = Apollo.QueryResult<HasCurrentUserAcknowledgedPrivacyPolicyQuery, HasCurrentUserAcknowledgedPrivacyPolicyQueryVariables>;