// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValuePartyFields } from './FieldInstanceValuePartyFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValuePartyWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceValueParty?: AcyclicFieldInstanceValuePartyFields | null, crossWorkflowSinksFieldInstanceValueParty?: Array<AcyclicFieldInstanceValuePartyFields> | null }
  & FieldInstanceValuePartyFields
);

export const FieldInstanceValuePartyWithCrossWorkflowFields = gql`
    fragment FieldInstanceValuePartyWithCrossWorkflowFields on FieldInstanceValueParty {
  ...FieldInstanceValuePartyFields
  crossWorkflowSourceFieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
  crossWorkflowSinksFieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
}
    `;