// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { AdminRoleFields } from './AdminRoleFields.generated';
import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { gql } from '@apollo/client';
export type AdminRoleFieldsWithCount = { usedInWorkflowTemplates?: number | null, memberCount?: number | null, role: AdminRoleFields };

export const AdminRoleFieldsWithCount = gql`
    fragment AdminRoleFieldsWithCount on RolesQueryRole {
  role {
    ...AdminRoleFields
  }
  usedInWorkflowTemplates
  memberCount
}
    `;