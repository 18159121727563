import * as SwitchPrimitive from "@radix-ui/react-switch";
import { clsx, WithClassName, WithDataTestId } from "@regrello/core-utils";
import React from "react";

export type RegrelloSwitchProps = WithClassName &
  WithDataTestId &
  // (clewis): Use onCheckedChange instead of onChange.
  Omit<React.ComponentProps<typeof SwitchPrimitive.Root>, "onChange">;

const RegrelloSwitch = React.memo(
  React.forwardRef<React.ElementRef<typeof SwitchPrimitive.Root>, Omit<RegrelloSwitchProps, "ref">>(
    function RegrelloSwitchFn({ className, dataTestId, ...props }, ref) {
      return (
        <SwitchPrimitive.Root
          ref={ref}
          className={clsx(
            `
            h-4.5
            min-w-8
            rounded-circular
            p-0.5

            bg-neutral-solidMuted
            hover:bg-neutral-solid
            active:bg-neutral-solidHovered

            flex
            items-center
            shrink-0

            focus-visible:outline-primary-solid
            focus-visible:outline-offset-3

            data-[state=checked]:shadow-none
            data-[state=checked]:bg-primary-solid
            data-[state=checked]:text-primary-textMuted
            data-[state=checked]:hover:bg-primary-solidHovered
            data-[state=checked]:active:rg-solidPressed

            disabled:bg-neutral-solidMuted
            disabled:data-[state=checked]:bg-primary-solid
            disabled:cursor-not-allowed
            disabled:opacity-disabled
            disabled:outline-none

            relative
        `,
            className,
          )}
          data-testid={dataTestId}
          {...props}
        >
          <SwitchPrimitive.Thumb
            className={`
            block
            w-3.5
            h-3.5

            bg-background
            rounded-circular

            transition-transform
            duration-100
            will-change-transform
            data-[state=checked]:translate-x-3.5
        `}
          />
        </SwitchPrimitive.Root>
      );
    },
  ),
);
RegrelloSwitch.displayName = SwitchPrimitive.Root.displayName;

export { RegrelloSwitch };
