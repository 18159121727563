export function isDevEnvironment(): boolean {
  return import.meta.env.DEV;
}

export function isProductionEnvironment(): boolean {
  return import.meta.env.PROD;
}

export function consoleWarnInDevelopmentModeOnly(...params: Parameters<typeof console.warn>): void {
  if (!isProductionEnvironment()) {
    console.warn("%c[Development warning]", "font-weight: bold; color: #E0A04C", ...params);
  }
}
