// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type FieldInstanceMultiValueFloatBaseFields = { __typename: 'FieldInstanceMultiValueFloat', id: number, inputType: Types.FieldInstanceValueInputType, floatMultiValue: Array<number> };

export const FieldInstanceMultiValueFloatBaseFields = gql`
    fragment FieldInstanceMultiValueFloatBaseFields on FieldInstanceMultiValueFloat {
  __typename
  id
  inputType
  floatMultiValue
}
    `;