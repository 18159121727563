// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueStringFields = (
  { sourceFieldInstanceMultiValueString?: AcyclicFieldInstanceMultiValueStringFields | null, sinksFieldInstanceMultiValueString: Array<AcyclicFieldInstanceMultiValueStringFields> }
  & FieldInstanceMultiValueStringBaseFields
);

export const FieldInstanceMultiValueStringFields = gql`
    fragment FieldInstanceMultiValueStringFields on FieldInstanceMultiValueString {
  ...FieldInstanceMultiValueStringBaseFields
  sourceFieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
  sinksFieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
}
    `;