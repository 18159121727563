// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueTimeFields } from './FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueTimeWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceMultiValueTime?: AcyclicFieldInstanceMultiValueTimeFields | null, crossWorkflowSinksFieldInstanceMultiValueTime?: Array<AcyclicFieldInstanceMultiValueTimeFields> | null }
  & FieldInstanceMultiValueTimeFields
);

export const FieldInstanceMultiValueTimeWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueTimeWithCrossWorkflowFields on FieldInstanceMultiValueTime {
  ...FieldInstanceMultiValueTimeFields
  crossWorkflowSourceFieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
  crossWorkflowSinksFieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
}
    `;