// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueIntFields = (
  { sourceFieldInstanceMultiValueInt?: AcyclicFieldInstanceMultiValueIntFields | null, sinksFieldInstanceMultiValueInt: Array<AcyclicFieldInstanceMultiValueIntFields> }
  & FieldInstanceMultiValueIntBaseFields
);

export const FieldInstanceMultiValueIntFields = gql`
    fragment FieldInstanceMultiValueIntFields on FieldInstanceMultiValueInt {
  ...FieldInstanceMultiValueIntBaseFields
  sourceFieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
  sinksFieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
}
    `;