import EmptyStateHomeFullColorIcon from "./svgs/empty-state-home-full-color-icon.svg?react";
import FullColorReceiveInviteIcon from "./svgs/receive-invite-full-color-icon.svg?react";

/**
 * The goal of this file is to keep the colored icons in a seperate place than our other icons as
 * these icons breaks some assumptions we made when we build the <RegrelloIcon /> component.
 */

export const RegrelloFullColorLogoMarkIcon = () => {
  return <EmptyStateHomeFullColorIcon />;
};

export const RegrelloFullColorReceiveInviteIcon = () => {
  return <FullColorReceiveInviteIcon />;
};
