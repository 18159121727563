// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { gql } from '@apollo/client';
export type AcyclicFieldInstanceMultiValueDocumentFields = { __typename: 'FieldInstanceMultiValueDocument', id: number, inputType: Types.FieldInstanceValueInputType, documentMultiValue: Array<DocumentBaseFields> };

export const AcyclicFieldInstanceMultiValueDocumentFields = gql`
    fragment AcyclicFieldInstanceMultiValueDocumentFields on FieldInstanceMultiValueDocument {
  __typename
  id
  inputType
  documentMultiValue {
    ...DocumentBaseFields
  }
}
    `;