import { ComparatorResult } from "@regrello/core-utils";
import { TagFields } from "@regrello/graphql-api";

/**
 * Comparator for sorting tags first by tag type and then by tag name, for display of tag
 * suggestions in a tag select. Allows for proper sort order when tags are "grouped by" their tag
 * type in the suggestions menu.
 */
export function tagSortComparator(tagA: TagFields, tagB: TagFields) {
  // Sort by tag-type name first.
  if (tagA.tagType.name < tagB.tagType.name) {
    return ComparatorResult.BEFORE;
  }
  if (tagA.tagType.name > tagB.tagType.name) {
    return ComparatorResult.AFTER;
  }

  // Sort by tag name within each tag type.
  if (tagA.name < tagB.name) {
    return ComparatorResult.BEFORE;
  }
  if (tagA.name > tagB.name) {
    return ComparatorResult.AFTER;
  }
  return ComparatorResult.EQUAL;
}
