// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormSectionConstraintRuleFields } from './FormSectionConstraintRuleFields.generated';
import { FormConstraintConditionGroupFields } from './FormConstraintConditionGroupFields.generated';
import { FormConstraintConditionGroupBaseFields } from './FormConstraintConditionGroupBaseFields.generated';
import { FormConstraintConditionFields } from './FormConstraintConditionFields.generated';
import { gql } from '@apollo/client';
export type FormSectionConstraintFields = { id: number, uuid: string, formSectionConstraintRule: FormSectionConstraintRuleFields, condition: FormConstraintConditionGroupFields };

export const FormSectionConstraintFields = gql`
    fragment FormSectionConstraintFields on FormSectionConstraint {
  id
  uuid
  formSectionConstraintRule {
    ...FormSectionConstraintRuleFields
  }
  condition {
    ...FormConstraintConditionGroupFields
  }
}
    `;