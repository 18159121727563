export enum RegrelloSessionStorageKey {
  CURRENT_TENANT_ID = "currentTenantId",
  JWT_EMAIL_VERIFIED = "jwtEmailVerified",
  REDIRECT_URL_AFTER_LOGIN = "redirectUrlAfterLogin",
  USER_VERIFICATION_UUID = "userVerificationUuid",
  IS_WAITING_FOR_AUTH0_AUTHENTICATION = "isWaitingForAuth0Authentication",
}

export function sessionStorageGetTyped(key: RegrelloSessionStorageKey): string | undefined {
  // (clewis): Return undefined instead of null so we don't have two types of empty values leaking
  // around our codebase.
  //
  // eslint-disable-next-line no-restricted-properties
  return sessionStorage.getItem(key) ?? undefined;
}

export function sessionStorageSetTyped(key: RegrelloSessionStorageKey, value: string): void {
  // eslint-disable-next-line no-restricted-properties
  sessionStorage.setItem(key, value);
}

export function sessionStorageRemoveTyped(key: RegrelloSessionStorageKey): void {
  // eslint-disable-next-line no-restricted-properties
  sessionStorage.removeItem(key);
}
