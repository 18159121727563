import { useCallback, useMemo, useState } from "react";
import { useMount } from "react-use";

export const STATIC_DOCUMENT_FIELD_ID = -1;

export namespace useDocumentUploadStatus {
  export interface Args {
    documentKeys: number[];
  }

  export interface Return {
    isUploading: boolean;
    onUploadFinish: (key: number) => void;
    onUploadStart: (key: number) => void;
  }
}

export function useDocumentUploadStatus({
  documentKeys,
}: useDocumentUploadStatus.Args): useDocumentUploadStatus.Return {
  const [documentFieldStatusByKey, setDocumentFieldStatusByKey] = useState<Map<number, number>>(new Map());

  useMount(() => {
    const initialDocumentFieldStatusByKey = new Map<number, number>();
    documentKeys.forEach((key) => initialDocumentFieldStatusByKey.set(key, 0));
    setDocumentFieldStatusByKey(initialDocumentFieldStatusByKey);
  });

  const isUploading = useMemo(() => {
    for (const activeUpload of documentFieldStatusByKey.values()) {
      if (activeUpload > 0) {
        return true;
      }
    }
    return false;
  }, [documentFieldStatusByKey]);

  const onUploadFinish = useCallback((key: number) => {
    setDocumentFieldStatusByKey((prevDocumentFieldStatusByKey) => {
      const activeUpload = prevDocumentFieldStatusByKey.get(key);
      if (activeUpload == null) {
        return prevDocumentFieldStatusByKey;
      }
      prevDocumentFieldStatusByKey.set(key, activeUpload - 1);
      return new Map(prevDocumentFieldStatusByKey);
    });
  }, []);

  const onUploadStart = useCallback((key: number) => {
    setDocumentFieldStatusByKey((prevDocumentFieldStatusByKey) => {
      const activeUpload = prevDocumentFieldStatusByKey.get(key);
      if (activeUpload == null) {
        prevDocumentFieldStatusByKey.set(key, 1);
      } else {
        prevDocumentFieldStatusByKey.set(key, activeUpload + 1);
      }
      return new Map(prevDocumentFieldStatusByKey);
    });
  }, []);

  return {
    isUploading,
    onUploadFinish,
    onUploadStart,
  };
}
