import { clsx, WithClassName, WithDataTestId } from "@regrello/core-utils";
import React from "react";

export interface RegrelloSpinnerProps extends WithClassName, WithDataTestId {
  /**
   * Whether to use the color specified by the parent, rather than the default spinner color.
   * @default "default"
   */
  color?: "default" | "inherit";

  /**
   * The diameter of the spinner.
   * @default "default"
   */
  size?: "default" | "x-small" | "small" | "large" | "x-large";
}

export const RegrelloSpinner = React.memo(function RegrelloSpinnerFn({
  className,
  color,
  dataTestId,
  size = "default",
}: RegrelloSpinnerProps) {
  return (
    <span
      className={clsx(
        "overflow-hidden inline-block relative",
        "before:animate-spin before:content-[''] before:absolute before:inset-0 before:rounded-circular",
        "before:[background:radial-gradient(farthest-side,var(--reg-spinner-color)_94%,transparent)_top/var(--reg-spinner-width)_var(--reg-spinner-width)_no-repeat,conic-gradient(transparent_30%,var(--reg-spinner-color))]",
        "before:[mask:radial-gradient(farthest-side,transparent_calc(100%-var(--reg-spinner-width)),#fff_0)]",
        "before:[-webkit-mask:radial-gradient(farthest-side,transparent_calc(100%-var(--reg-spinner-width)),#fff_0)]",
        {
          "[--reg-spinner-color:currentColor]": color === "inherit",
          "[--reg-spinner-color:#CED2D6]": color !== "inherit",
          "size-3 [--reg-spinner-width:2px]": size === "x-small",
          "size-4 [--reg-spinner-width:3px]": size === "small",
          "size-5 [--reg-spinner-width:3px]": size === "default",
          "size-6 [--reg-spinner-width:4px]": size === "large",
          "size-9 [--reg-spinner-width:5px]": size === "x-large",
        },
        className,
      )}
      data-testid={dataTestId}
      role="progressbar"
    />
  );
});
