import { assertNever } from "@regrello/core-utils";

import { RegrelloSize } from "../../utils/enums/RegrelloSize";

export const INPUT_V2_DEFAULT_SIZE = "large";

export function getInputV2IconSizeForInputSize(size: RegrelloSize | undefined): RegrelloSize {
  const resolvedSize = size ?? INPUT_V2_DEFAULT_SIZE;
  return resolvedSize === "x-small" ? "x-small" : "small";
}

export function getInputV2ButtonSizeForInputSize(size: RegrelloSize | undefined): RegrelloSize {
  const resolvedSize = size ?? INPUT_V2_DEFAULT_SIZE;
  switch (resolvedSize) {
    case "x-small":
      return "x-small"; // Buttons are not actually supported in x-small inputs, so this won't look good.
    case "small":
      return "x-small"; // Buttons are not actually supported in small inputs, so this won't look good.
    case "medium":
      return "x-small";
    case "large":
      return "small";
    case "x-large":
      return "medium";
    default:
      return assertNever(resolvedSize);
  }
}
