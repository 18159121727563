import { ComparatorResult, EMPTY_ARRAY, EMPTY_STRING, isArraysEqual, stringComparator } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  ConditionOperator,
  CreateFieldInstanceValueInputs,
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldInstanceMultiValuePartyFields,
  FieldInstanceValueInputType,
  FieldType,
  PropertyDataType,
  PropertyTypeFields,
  UpdateFieldInstanceValueInputs,
  UpdateStartingConditionsInputs,
  ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloPartyAvatar, RegrelloPartyAvatars } from "@regrello/ui-app-molecules";
import { RegrelloIcon } from "@regrello/ui-core";
import {
  HelperTextSelectOneOrMore,
  MultiEmailHelperText,
  MultiEmailPlaceholderText,
  People,
  SingleEmailPlaceholderText,
} from "@regrello/ui-strings";
import { FieldPath, FieldValues } from "react-hook-form";

import { CustomFieldPlugin, CustomFieldPluginV2RenderFormFieldProps } from "./types/CustomFieldPlugin";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { CONDITION_OPERATOR_CONFIGS_USER } from "./utils/customFieldConstants";
import {
  getIsFieldInstanceValueFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { getFieldInstanceFieldsFromAcyclicFieldInstanceFields } from "./utils/getFieldInstanceFieldsFromAcyclicFieldInstanceFields";
import { getUpdateStartingConditionsInputsForEmptyOperators } from "./utils/getUpdateStartingConditionsInputsForEmptyOperators";
import { ValidationRules } from "../../../../constants/globalConstants";
import { FeatureFlagService } from "../../../../services/FeatureFlagService";
import { FieldInstanceBaseFields } from "../../../../types";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import {
  isNonFieldInstancePartyTypeUnionArray,
  PartyTypeUnion,
  PartyTypeUnion_Team,
} from "../../../../utils/parties/PartyTypeUnion";
import {
  getBasePartyTypeUnion,
  getPartyFullName,
  getPartyFullNameAndOrganization,
  getPartyId,
  getPartyIds,
} from "../../../../utils/parties/partyUtils";
import { toFlatParty, toFlatPartyArray } from "../../../../utils/parties/toFlatParty";
import { getPartiallyEmptyPartyTypeUnionFromBaseFields } from "../../../../utils/partyUtils";
import { TableCellDefaultWidths } from "../../../../utils/tableCellWidthUtils";
import { getPartyValues } from "../../../../utils/tableFilterUtils";
import { useUser } from "../../../app/authentication/userContextUtils";
import {
  RegrelloControlledFormFieldPartySelect,
  RegrelloControlledFormFieldPartySingleSelect,
} from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../formFields/controlled/RegrelloControlledFormFieldText";
import {
  getRegrelloDefaultFilterDefinitionMultiSelectValue,
  getRegrelloDefaultFilterDefinitionSingleSelectValue,
  getRegrelloFilterDefinitionPartyMultiSelectValue,
  getRegrelloFilterDefinitionPartySingleSelectValue,
} from "../../tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'user' field is invalid";
const ERROR_INVALID_FORM_VALUE = "Provided 'user'-field form value does not appear to be a valid UserFields array";
const WARNING_INVALID_FORM_VALUE = "Provided 'user' field form value is not a valid array of parties";
const ERROR_INVALID_VALUE_COUNT = "Provided 'user' field instance cannot have multiple values";
const ERROR_INVALID_VALUE_TYPE = "Provided 'user' field instance value must have type 'FieldInstanceMultiValueParty'";
const WARNING_INVALID_OPERATOR_TYPE = "Provided operator type is invalid for 'user' fields";
const WARNING_UNEXPECTED_DEFINED_FORM_VALUE =
  "Provided 'user' field form value must not be defined given the provided operator type";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.PARTY_ID;
}

function canProcessField(field: FieldFields): boolean {
  return canProcessPropertyDataType(field.propertyType.dataType);
}

type UserFieldPluginFrontendValue = PartyTypeUnion[];
type UserFieldPluginType = CustomFieldPlugin<UserFieldPluginFrontendValue>;

const getConditionOperators: UserFieldPluginType["getConditionOperators"] = () => {
  return CONDITION_OPERATOR_CONFIGS_USER;
};

const renderDisplayValue: UserFieldPluginType["renderDisplayValue"] = (fieldInstance, options = {}) => {
  const isFeatureFlagInactiveAssigneesEnabled = FeatureFlagService.isEnabled(FeatureFlagKey.INACTIVE_ASSIGNEES_2024_08);
  const { values: value } = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
  const parties = value.map((party) => getPartyFullNameAndOrganization(party)).join(", ");

  if (options.context === "tableV2") {
    return value.length === 0
      ? EMPTY_STRING
      : value.map((party) => (
          // (clewis): Tiny margin on all sides just to keep avatars from touching each other.
          <div key={getPartyId(party)} style={{ margin: 2 }}>
            <RegrelloPartyAvatar party={toFlatParty(party)} size="small" />
          </div>
        ));
  }

  if (options.context === "dataTab") {
    return value.length === 0 ? (
      EMPTY_STRING
    ) : (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {value.map((party) => (
          // (clewis): Tiny margin on all sides just to keep avatars from touching each other.
          <div key={getPartyId(party)} style={{ margin: 2 }}>
            <RegrelloPartyAvatar party={toFlatParty(party)} size="small" />
          </div>
        ))}
      </div>
    );
  }

  if (options.context === "inlineTruncate") {
    return (
      // (clewis): Ensure this wrapper prevents text overflow, else the avatars may leak out of
      // their container.
      <div className="truncate">
        <RegrelloPartyAvatars
          parties={toFlatPartyArray(value)}
          showInactivePartyWarningForList={isFeatureFlagInactiveAssigneesEnabled}
        />
      </div>
    );
  }

  return parties;
};

const sortComparator: UserFieldPluginType["sortComparator"] = (fieldInstance1, fieldInstance2, direction = "asc") => {
  if (direction === "desc") {
    return UserFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const { values: value1 } = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance1);
  const { values: value2 } = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance2);

  const parties1 = value1.map((party) => getPartyFullNameAndOrganization(party)).join(", ");
  const parties2 = value2.map((party) => getPartyFullNameAndOrganization(party)).join(", ");

  return stringComparator(parties1, parties2);
};

/**
 * Describes a custom field that holds one or more selected users.
 */
export const UserFieldPlugin: UserFieldPluginType = {
  uri: "com.regrello.customField.user",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceFields | FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (propertyTypes: PropertyTypeFields[]): number | undefined => {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.PARTY_ID)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators,

  getCreateFieldInstanceValueInputsFromFormValue: (
    field: FieldFields,
    inputType: FieldInstanceValueInputType,
    value: unknown,
    displayOrder?: number,
    spectrumFieldVersionId?: number,
  ): CreateFieldInstanceValueInputs => {
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FORM_VALUE, { field, inputType, value }));
    }
    const fieldId = field.id;

    const partyMultiValues = value
      ?.filter((v) => PartyTypeUnion.isUser(v) || PartyTypeUnion.isTeam(v) || PartyTypeUnion.isUserWithRoles(v))
      .map((party) => {
        if (PartyTypeUnion.isUser(party) || PartyTypeUnion.isUserWithRoles(party)) {
          return party.user.party.id;
        }
        return (party as PartyTypeUnion_Team).team.party.id;
      });

    return field.isMultiValued
      ? {
          fieldId,
          partyMultiValue: partyMultiValues ?? EMPTY_ARRAY,
          inputType,
          displayOrder,
          spectrumFieldVersionId,
        }
      : {
          fieldId,
          partyValue: partyMultiValues?.[0] ?? undefined,
          inputType,
          displayOrder,
          spectrumFieldVersionId,
        };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return People;
  },

  getFilterDefinition: (field: FieldFields) => {
    if (field.isMultiValued) {
      return getRegrelloDefaultFilterDefinitionMultiSelectValue({ type: "party" });
    }
    return getRegrelloDefaultFilterDefinitionSingleSelectValue({ type: "party" });
  },

  getFilterDefinitionWithValues: (field: FieldFields, filter: ViewFilterFields, parties: PartyTypeUnion[]) => {
    const partyValues = getPartyValues(filter.values ?? EMPTY_ARRAY, parties ?? EMPTY_ARRAY);
    if (field.isMultiValued) {
      return getRegrelloFilterDefinitionPartyMultiSelectValue(filter.operator, partyValues);
    }
    return getRegrelloFilterDefinitionPartySingleSelectValue(filter.operator, partyValues);
  },

  getEmptyValueForFrontend: (): PartyTypeUnion[] => {
    return EMPTY_ARRAY;
  },

  getIconName: (fieldType, field) => {
    // New.
    if (FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)) {
      const isCustomRoleField = field?.fieldRestriction?.filterByRole != null;
      // person icon is without circle border
      return isCustomRoleField ? "role" : fieldType === FieldType.SYSTEM ? "person-field" : "person";
    }

    // Old.
    return "person-field";
  },

  getNameTemplateDisplayValueFromFormValue: (value) => {
    if (!isValueValid(value) || value == null || value.length === 0) {
      return undefined;
    }
    return value.map((party) => getPartyFullName(party)).join(", ");
  },

  getPreferredHomeTableColumnWidth: () => {
    return TableCellDefaultWidths.TEXT_CELL;
  },

  getSourceFieldInstance: (fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstance;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    if (!isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        leftFieldInstance,
        operator,
      });
      return undefined;
    }

    if (operator === ConditionOperator.EMPTY || operator === ConditionOperator.NOT_EMPTY) {
      if (value != null) {
        console.warn(WARNING_UNEXPECTED_DEFINED_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
      }
      return getUpdateStartingConditionsInputsForEmptyOperators(getFieldInstanceId(leftFieldInstance), operator);
    }

    if (!isNonFieldInstancePartyTypeUnionArray(value)) {
      console.warn(getErrorMessageWithPayload(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value }));
      return undefined;
    }

    return {
      leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
      operatorV2: operator,
      rightStringMultiValue: EMPTY_ARRAY,
      rightFloatMultiValue: EMPTY_ARRAY,
      rightIntMultiValue: EMPTY_ARRAY,
      ...(leftFieldInstance.field.isMultiValued
        ? { rightPartyIDMultiValue: getPartyIds(value) }
        : { rightPartyIDValue: getPartyId(value[0]) }),
      rightTimeMultiValue: EMPTY_ARRAY,
    };
  },

  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    const partyValues =
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance).values.map(getPartyId);
    return [
      {
        ...(fieldInstance.isMultiValued ? { partyMultiValue: partyValues } : { partyValue: partyValues[0] }),
        inputType: fieldInstance.values[0].inputType,
        id: fieldInstance.values[0].id,
      },
    ];
  },

  getValueForFrontend: (fieldInstance: FieldInstanceFields | FieldInstanceBaseFields): PartyTypeUnion[] => {
    return translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance).values;
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): boolean => {
    const { values } = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return values == null || values.length === 0;
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    if (fieldInstance.field.isMultiValued) {
      if (
        frontendFieldInstance.values.length === 0 &&
        (proposedChange.partyMultiValue == null || proposedChange.partyMultiValue.length === 0)
      ) {
        return true;
      }

      if (
        frontendFieldInstance.values.length === 0 ||
        proposedChange.partyMultiValue == null ||
        proposedChange.partyMultiValue.length === 0
      ) {
        return false;
      }

      return isArraysEqual(
        frontendFieldInstance.values.map(getPartyId),
        proposedChange.partyMultiValue,
        (a, b) => a === b,
      );
    } else {
      if (frontendFieldInstance.values.length === 0 && proposedChange.partyValue == null) {
        return true;
      }
      if (frontendFieldInstance.values.length === 0 || proposedChange.partyValue == null) {
        return false;
      }

      return getPartyId(frontendFieldInstance.values[0]) === proposedChange.partyValue;
    }
  },

  isMultiValued: (): boolean => {
    return true;
  },

  renderDisplayValue: renderDisplayValue,

  // eslint-disable-next-line react/display-name
  renderFormField: RenderFormFieldInternal,

  renderIcon: (props): React.ReactElement => {
    return (
      // New icon doesn't have circle around the person.
      <RegrelloIcon {...props} iconName={UserFieldPlugin.getIconName(props?.fieldType, props?.field)} />
    );
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }
    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: "inlineTruncate" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: renderDisplayValue(fieldInstance, { context: options?.context ?? "table" }),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName,
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  shouldDisplayMultiValuedToggle: () => true,

  sortComparator,
};

function RenderFormFieldInternal<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  field: FieldFields,
  props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
): React.ReactNode {
  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const { currentUser } = useUser();
  const isSingleValueModeEnabled = !field.isMultiValued;
  const isDeleted = props.fieldInstance?.field.deletedAt != null;

  /**
   * The role ID to which a field's options are limited, if a field restriction is present on the
   * passed field instance value.
   */
  const fieldRestrictionRoleId = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)
    ? props.fieldInstance?.field?.fieldRestriction?.filterByRole?.id ?? null
    : null;

  if (props.isEmailInputForUserFieldShown) {
    const rules = isSingleValueModeEnabled
      ? {
          ...ValidationRules.VALID_EMAIL,
        }
      : {
          ...ValidationRules.VALID_MULTI_EMAIL,
        };

    return (
      <RegrelloControlledFormFieldText
        {...props}
        key={props.controllerProps.name}
        controllerProps={{
          ...props.controllerProps,
          rules,
        }}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        helperText={MultiEmailHelperText}
        infoTooltipText={props.description}
        isDeleted={isDeleted}
        placeholder={isSingleValueModeEnabled ? SingleEmailPlaceholderText : MultiEmailPlaceholderText}
      />
    );
  }

  const limitToRoleIds = fieldRestrictionRoleId != null ? [fieldRestrictionRoleId] : undefined;

  return isSingleValueModeEnabled ? (
    <RegrelloControlledFormFieldPartySingleSelect
      {...props}
      key={props.controllerProps.name}
      allowCreate={{
        teams: props.allowCreate && currentUser.isAllowedToCreateTeams,
        users:
          props.allowCreate &&
          currentUser.isAllowedToCreateUsers &&
          // When the plugin options are limited to a role's parties, users will expect a new user
          // they invite to become available as an option. Only give users the ability to invite
          // someone if they can also assign roles.
          (limitToRoleIds ? currentUser.permissions.canAssignRoles : true),
      }}
      dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
      hiddenTabs={{ teams: fieldRestrictionRoleId != null, users: false }}
      infoTooltipText={props.description}
      isDeleted={isDeleted}
      loadOptions={{
        teams: { limitToRoleIds },
        users: { limitToRoleIds },
      }}
    />
  ) : (
    <RegrelloControlledFormFieldPartySelect
      {...props}
      key={props.controllerProps.name}
      allowCreate={{
        teams: props.allowCreate && currentUser.isAllowedToCreateTeams,
        users:
          props.allowCreate &&
          currentUser.isAllowedToCreateUsers &&
          // When the plugin options are limited to a role's parties, users will expect a new user
          // they invite to become available as an option. Only give users the ability to invite
          // someone if they can also assign roles.
          (limitToRoleIds ? currentUser.permissions.canAssignRoles : true),
      }}
      dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
      helperText={HelperTextSelectOneOrMore}
      hiddenTabs={{ teams: fieldRestrictionRoleId != null, users: false }}
      infoTooltipText={props.description}
      isDeleted={isDeleted}
      loadOptions={{
        teams: { limitToRoleIds },
        users: { limitToRoleIds },
      }}
    />
  );
}

interface FrontendUserFieldInstance {
  name: string;
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstance?: FieldInstanceFields;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  values: PartyTypeUnion[];
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): FrontendUserFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  switch (field.fieldType) {
    case FieldType.DEFAULT:
      return transformDefaultOrSystemUserFieldInstance(fieldInstance);
    case FieldType.CONTROLLER:
      return transformControllerUserFieldInstance(fieldInstance);
    case FieldType.SYSTEM:
      return transformDefaultOrSystemUserFieldInstance(fieldInstance);
    default:
      // TODO Retiring Roles: reintroduce the `assertNever` here once the role type is removed from
      // the field type enum on the BE.
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }
}

function transformDefaultOrSystemUserFieldInstance(fieldInstance: FieldInstanceFields | FieldInstanceBaseFields) {
  const { field, values } = fieldInstance;

  if ((field.fieldType === FieldType.DEFAULT || FieldType.SYSTEM) && values.length !== 0 && values.length !== 1) {
    // HACKHACK (clewis): It's technically illegal for values to have length > 2, but it's happened
    // at least once in our demo tenant. In this case, we print a warning and just use the first
    // value, which should always be present (if possibly empty).
    //
    // See: https://regrello.slack.com/archives/C01M8SV60U9/p1665619454243309
    console.warn(getErrorMessageWithPayload(ERROR_INVALID_VALUE_COUNT, { fieldInstance }));
  }

  const representativeSingleValue = values[0];

  if (
    // eslint-disable-next-line no-underscore-dangle
    representativeSingleValue.__typename === "FieldInstanceValueParty"
  ) {
    return {
      name: field.name,
      inputType: representativeSingleValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(representativeSingleValue) &&
        representativeSingleValue.crossWorkflowSinksFieldInstanceValueParty != null
          ? representativeSingleValue.crossWorkflowSinksFieldInstanceValueParty.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(representativeSingleValue)
        ? representativeSingleValue.crossWorkflowSourceFieldInstanceValueParty?.id
        : undefined,
      sinksFieldInstanceIds: getIsFieldInstanceValueFields(representativeSingleValue)
        ? representativeSingleValue.sinksFieldInstanceValueParty?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: getIsFieldInstanceValueFields(representativeSingleValue)
        ? representativeSingleValue.sourceFieldInstanceValueParty?.id
        : undefined,
      sourceFieldInstanceInputType: getIsFieldInstanceValueFields(representativeSingleValue)
        ? representativeSingleValue.sourceFieldInstanceValueParty?.inputType
        : undefined,
      values:
        representativeSingleValue.partyValue != null
          ? [representativeSingleValue.partyValue].map(getPartiallyEmptyPartyTypeUnionFromBaseFields)
          : EMPTY_ARRAY,
    };
  }

  if (
    // eslint-disable-next-line no-underscore-dangle
    representativeSingleValue.__typename === "FieldInstanceMultiValueParty"
  ) {
    return {
      name: field.name,
      inputType: representativeSingleValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(representativeSingleValue) &&
        representativeSingleValue.crossWorkflowSinksFieldInstanceMultiValueParty != null
          ? representativeSingleValue.crossWorkflowSinksFieldInstanceMultiValueParty.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(representativeSingleValue)
        ? representativeSingleValue.crossWorkflowSourceFieldInstanceMultiValueParty?.id
        : undefined,
      sinksFieldInstanceIds: getIsFieldInstanceValueFields(representativeSingleValue)
        ? representativeSingleValue.sinksFieldInstanceMultiValueParty?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: getIsFieldInstanceValueFields(representativeSingleValue)
        ? representativeSingleValue.sourceFieldInstanceMultiValueParty?.id
        : undefined,
      sourceFieldInstanceInputType: getIsFieldInstanceValueFields(representativeSingleValue)
        ? representativeSingleValue.sourceFieldInstanceMultiValueParty?.inputType
        : undefined,
      values: representativeSingleValue.partyMultiValue.map(getPartiallyEmptyPartyTypeUnionFromBaseFields),
    };
  }

  throw new Error(getErrorMessageWithPayload(ERROR_INVALID_VALUE_TYPE, { fieldInstance }));
}

function transformControllerUserFieldInstance(fieldInstance: FieldInstanceFields | FieldInstanceBaseFields) {
  const { field, values } = fieldInstance;

  // eslint-disable-next-line no-underscore-dangle
  const isAllValuesHaveCorrectTypename = values.every((value) => value.__typename === "FieldInstanceMultiValueParty");

  if (!isAllValuesHaveCorrectTypename) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_VALUE_TYPE, { fieldInstance }));
  }

  const representativeSingleValue = values[0] as FieldInstanceMultiValuePartyFields;

  return {
    name: field.name,
    inputType: representativeSingleValue.inputType,
    crossWorkflowSinksFieldInstanceIds:
      getIsFieldInstanceValueWithCrossWorkflowFields(representativeSingleValue) &&
      representativeSingleValue.crossWorkflowSinksFieldInstanceMultiValueParty != null
        ? representativeSingleValue.crossWorkflowSinksFieldInstanceMultiValueParty.map((value) => value.id)
        : EMPTY_ARRAY,
    crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(representativeSingleValue)
      ? representativeSingleValue.crossWorkflowSourceFieldInstanceMultiValueParty?.id
      : undefined,
    sinksFieldInstanceIds: representativeSingleValue.sinksFieldInstanceMultiValueParty?.map(({ id }) => id),
    sourceFieldInstanceId: representativeSingleValue.sourceFieldInstanceMultiValueParty?.id,
    sourceFieldInstance:
      // (zstanik): Convert AcyclicFieldInstanceFields to FieldInstanceFields as high up as possible
      representativeSingleValue.sourceFieldInstanceMultiValuePartyV2 != null
        ? getFieldInstanceFieldsFromAcyclicFieldInstanceFields(
            representativeSingleValue.sourceFieldInstanceMultiValuePartyV2,
          )
        : undefined,
    sourceFieldInstanceInputType: representativeSingleValue.sourceFieldInstanceMultiValueParty?.inputType,
    values: representativeSingleValue.partyMultiValue.map(getBasePartyTypeUnion),
  };
}

function isOperatorValid(
  operator: ConditionOperator,
): operator is
  | ConditionOperator.EMPTY
  | ConditionOperator.NOT_EMPTY
  | ConditionOperator.CONTAINS_ALL_OF
  | ConditionOperator.CONTAINS_ANY_OF
  | ConditionOperator.CONTAINS_NONE_OF {
  return getConditionOperators().find((stageStartOperator) => stageStartOperator.operator === operator) != null;
}

function isValueValid(value: unknown): value is UserFieldPluginFrontendValue | null {
  const castedValue = value as PartyTypeUnion[] | null;
  // (zstanik): This preexisting check was hard to reason about and elegantly write in the negated
  // format, so just negate the existing check as is.
  return !(
    castedValue != null &&
    (!Array.isArray(castedValue) ||
      castedValue.some(
        (element) =>
          (!PartyTypeUnion.isUser(element) ||
            // eslint-disable-next-line no-underscore-dangle
            element.user.__typename !== "User" ||
            typeof element.user.id !== "number") &&
          (!PartyTypeUnion.isTeam(element) ||
            // eslint-disable-next-line no-underscore-dangle
            element.team.__typename !== "Team" ||
            typeof element.team.id !== "number") &&
          (!PartyTypeUnion.isUserWithRoles(element) ||
            // eslint-disable-next-line no-underscore-dangle
            element.user.__typename !== "User" ||
            typeof element.user.id !== "number"),
      ))
  );
}
