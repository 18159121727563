// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type AcyclicFieldInstanceMultiValueTimeFields = { __typename: 'FieldInstanceMultiValueTime', id: number, inputType: Types.FieldInstanceValueInputType, timeMultiValue: Array<string> };

export const AcyclicFieldInstanceMultiValueTimeFields = gql`
    fragment AcyclicFieldInstanceMultiValueTimeFields on FieldInstanceMultiValueTime {
  __typename
  id
  inputType
  timeMultiValue
}
    `;