// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type FieldInstanceValueStringBaseFields = { __typename: 'FieldInstanceValueString', id: number, inputType: Types.FieldInstanceValueInputType, stringValue?: string | null };

export const FieldInstanceValueStringBaseFields = gql`
    fragment FieldInstanceValueStringBaseFields on FieldInstanceValueString {
  __typename
  id
  inputType
  stringValue
}
    `;