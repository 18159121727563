export enum RegrelloJWTClaimKeys {
  aud = "aud",
  sub = "sub",
  email = "email",
  emailVerified = "email_verified",
  emailWithNamespace = "https://regrello.com/email",
  emailVerifiedWithNamespace = "https://regrello.com/email_verified",
  exp = "exp",
}

export const REGRELLO_SESSION_TOKEN_AUDIENCE = "regrello_session_token";

export interface RegrelloJWTClaim {
  [key: string]: unknown;
  [RegrelloJWTClaimKeys.sub]: string;
  [RegrelloJWTClaimKeys.exp]: number;
  [RegrelloJWTClaimKeys.email]: string;
  [RegrelloJWTClaimKeys.emailVerified]: boolean;
  [RegrelloJWTClaimKeys.emailVerifiedWithNamespace]: boolean;
  [RegrelloJWTClaimKeys.emailWithNamespace]: string;
  [RegrelloJWTClaimKeys.aud]: string[];
}

export enum RegrelloGranularAccessJWTClaimKeys {
  userEmail = "user_email",
  isRecipientTeamEmail = "is_recipient_team_email",
}

export interface RegrelloGranularAccessJWTClaim {
  [key: string]: unknown;
  [RegrelloGranularAccessJWTClaimKeys.userEmail]: string;
  [RegrelloGranularAccessJWTClaimKeys.isRecipientTeamEmail]: boolean;
}

export function parseJwtClaim<T extends RegrelloJWTClaim | RegrelloGranularAccessJWTClaim>(token: string): T {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join(""),
  );

  return JSON.parse(jsonPayload);
}
