import { RegrelloNonIdealStateWithRefresh } from "@regrello/ui-core";
import { ErrorBoundaryDescription, ErrorBoundaryTitle } from "@regrello/ui-strings";
import { ErrorBoundary, ErrorBoundaryProps } from "@sentry/react";
import React, { useMemo } from "react";

import { isDevEnvironment } from "../../../utils/environmentUtils";

export interface RegrelloErrorBoundaryProps
  extends Pick<Required<ErrorBoundaryProps>, "beforeCapture">,
    Pick<ErrorBoundaryProps, "onReset"> {
  /** Content to render. Errors caught in this content will be captured by this error boundary. */
  children: React.ReactNode;

  /** A custom description to display. If omitted, a default title will be shown. */
  description?: string;

  /** A custom fallback component to render. If omitted, a default fallback page will be shown.  */
  fallback?: React.ReactElement;

  /** A custom title to display. If omitted, a default title will be shown. */
  title?: string;
}

/**
 * A generic error boundary to display. When an error occurs, displays a fallback and forwards all
 * caught errors to Sentry. You can recover from the error via a custom `onReset` prop.
 */
export const RegrelloErrorBoundary = React.memo<RegrelloErrorBoundaryProps>(function RegrelloErrorBoundaryFn({
  beforeCapture,
  children,
  description,
  fallback,
  onReset,
  title,
}) {
  /**
   * (anthony) There are some instances where this `RegrelloErrorBoundary` suppresses runtime errors
   * from packages. Specifically, the `@tanstack/react-table` library throws a runtime exception
   * about a `rowId` mismatch which is impossible to stack trace unless `RegrelloErrorBoundary` is
   * turned off.
   *
   * @example Set `VITE_DISABLE_ERROR_BOUNDARY="true"` in your `.env` file.
   */
  const isErrorBoundaryDisabled = useMemo(() => {
    const willDisableErrorBoundary = isDevEnvironment() && import.meta.env.VITE_DISABLE_ERROR_BOUNDARY === "true";

    if (willDisableErrorBoundary) {
      // biome-ignore lint/suspicious/noConsoleLog: <explanation>
      console.log(
        'You have VITE_DISABLE_ERROR_BOUNDARY="true" in your .env file. This will allow nested exceptions to bubble up without getting caught.',
      );
    }
    return willDisableErrorBoundary;
  }, []);

  if (isErrorBoundaryDisabled) {
    // We need to wrap with fragment to satisfy types of `React.memo` - it won't be necessary in React 18
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <ErrorBoundary
      beforeCapture={beforeCapture}
      fallback={
        fallback ?? (
          <RegrelloNonIdealStateWithRefresh
            description={description ?? ErrorBoundaryDescription}
            title={title ?? ErrorBoundaryTitle}
          />
        )
      }
      onReset={onReset}
    >
      {/* We need to wrap with fragment to satisfy types of `React.memo` - it won't be necessary in React 18 */}
      {/* biome-ignore lint/complexity/noUselessFragments: <explanation> */}
      <>{children}</>
    </ErrorBoundary>
  );
});
