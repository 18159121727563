import { clsx, EMPTY_STRING, getEmailDomain } from "@regrello/core-utils";
import { RegrelloButton, RegrelloIcon, RegrelloLinkV2, RegrelloTooltipV4, RegrelloTypography } from "@regrello/ui-core";
import {
  ConfirmPassword,
  CopyrightTitleCased,
  CreatePassword,
  CreateYourPassword,
  ErrorSignUp,
  FormErrorPasswordDoesntMatch,
  FormErrorPasswordDoesntMeetCriteria,
  PasswordRuleHasLowerCaseLetter,
  PasswordRuleHasNumber,
  PasswordRuleHasSpecialCharacter,
  PasswordRuleHasUpperCaseLetter,
  PasswordRuleHasValidCharacterCombination,
  PasswordRuleMinLength,
  PasswordRuleNoConsecutiveCharacter,
  PrivacyPolicyTitleCased,
  ShowPassword,
  SignInWithSSO,
  SignInWithSSOToGetStarted,
  SignUp,
} from "@regrello/ui-strings";
import throttle from "lodash/throttle";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { REGRELLO_PRIVACY_POLICY_URL, ValidationRules } from "../../../../../constants/globalConstants";
import { RegrelloRestApiService } from "../../../../../services/RegrelloRestApiService";
import { RegrelloSessionStorageKey, sessionStorageSetTyped } from "../../../../../utils/getFromSessionStorage";
import { useRegrelloHistory } from "../../../../../utils/hooks/useRegrelloHistory";
import { PasswordRules, validatePassword } from "../../../../../utils/passwordRulesRegexUtils";
import { AuthenticationConnectionName } from "../../../../app/authentication/RegrelloAuthenticationProvider";
import { useRegrelloAuthV2 } from "../../../../app/authentication/userContextUtils";
import { RouteQueryStringKeys } from "../../../../app/routes/consts";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloFormFieldCheckbox } from "../../../../molecules/formFields/RegrelloFormFieldCheckbox";

export interface UnauthenticatedSignUpPageFormFields {
  email: string;
  password: string;
  passwordConfirm: string;
}

const SUBMIT_THROTTLE_IN_MILLISECONDS = 1000;

export const UnauthenticatedSignUpPage = React.memo(function UnauthenticatedSignUpPageFn() {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const passwordInputRef = useRef<HTMLInputElement | null>(null);
  const [isHintTooltipOpen, setIsHintTooltipOpen] = useState(false);
  const { signUp, loading, authError: signUpError } = useRegrelloAuthV2();
  const { getQueryParam } = useRegrelloHistory();

  const email = getQueryParam(RouteQueryStringKeys.EMAIL) ?? EMPTY_STRING;
  const userVerificationUUID = getQueryParam(RouteQueryStringKeys.USER_VERIFICATION_UUID);
  if (userVerificationUUID != null) {
    sessionStorageSetTyped(RegrelloSessionStorageKey.USER_VERIFICATION_UUID, userVerificationUUID);
  }

  const defaultValues: UnauthenticatedSignUpPageFormFields = {
    email,
    password: EMPTY_STRING,
    passwordConfirm: EMPTY_STRING,
  };

  const onCheckboxChange = useCallback((isChecked: boolean) => {
    setIsPasswordShown(isChecked);
  }, []);

  const form = useForm<UnauthenticatedSignUpPageFormFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    // (clewis): Our own defaultValues prop requires a default value for each field, whereas this
    // hook expects a partial. A full object is simply a superset of a partial, but the types are
    // still complaining that we're not providing a partial-typed object. Hence, we cast as any.
    //
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: defaultValues as any,
  });

  const [ssoConnectionName, setSsoConnectionName] = useState<string | null>(null);
  const [isSsoOptional, setIsSsoOptional] = useState<boolean>(false);

  useEffect(() => {
    const domain = getEmailDomain(email);
    const updateConnectionName = async () => {
      const result = await RegrelloRestApiService.isSso(domain);
      setSsoConnectionName(result.status === 200 && result.json.isSso ? result.json.connectionName : null);
      setIsSsoOptional(result.status === 200 && result.json.isSsoOptional);
    };
    void updateConnectionName();
    return () => {
      setSsoConnectionName(null);
    };
  }, [email]);

  const shouldUseSSO = ssoConnectionName != null && isSsoOptional;

  // (hchen): Watch the form changes so that we can validate passwords.
  const password = useWatch({ control: form.control, name: "password" });

  const passwordRules = useMemo<PasswordRules>(() => {
    return validatePassword(password);
  }, [password]);

  const throttledOnSubmit = useMemo(
    () =>
      throttle(async (data: UnauthenticatedSignUpPageFormFields) => {
        await signUp({
          connection: AuthenticationConnectionName.DEFAULT,
          email: data.email,
          password: data.password,
        });
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [signUp],
  );

  const submit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      // (hchen): Prevent refreshing.
      event.preventDefault();
      // (clewis): Be sure to include an extra '()', since formHandleSubmit(...) returns a function.
      void form.handleSubmit(throttledOnSubmit)();
    },
    [form, throttledOnSubmit],
  );

  const throttledOnContinueWithSso = useMemo(
    () =>
      throttle(async () => {
        if (ssoConnectionName != null) {
          await signUp({
            connection: ssoConnectionName,
            email: form.getValues().email,
            password: form.getValues().password,
          });
        } else {
          await signUp({
            connection: AuthenticationConnectionName.SSO,
          });
        }
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [signUp, form, ssoConnectionName],
  );

  const validatePasswordMatch = useCallback(
    (confirmPasswordValue: string) => {
      return confirmPasswordValue === password ? true : FormErrorPasswordDoesntMatch;
    },
    [password],
  );

  const validatePasswordRule = useCallback(
    () => (passwordRules.minLength ? true : FormErrorPasswordDoesntMeetCriteria),
    [passwordRules.minLength],
  );

  const getPasswordRuleIcon = useCallback((isValid: boolean) => {
    return (
      <div className="mr-2">{isValid ? <RegrelloIcon iconName="selected" /> : <RegrelloIcon iconName="blank" />}</div>
    );
  }, []);

  const onPasswordFocus = useCallback(() => {
    setIsHintTooltipOpen(true);
  }, []);

  const onPasswordBlur = useCallback(() => {
    setIsHintTooltipOpen(false);
  }, []);

  // (hchen): Do this instead of setting `autoFocus={true}` to make sure the pop up shows up on
  // autofocus.
  useLayoutEffect(() => passwordInputRef.current?.focus(), []);

  const passwordPopover = useMemo(
    () => (
      <RegrelloTypography component="div">
        <div
          className={clsx("flex p-1.25", {
            "text-danger-text": !passwordRules.minLength,
            "text-success-text": passwordRules.minLength,
          })}
        >
          {getPasswordRuleIcon(passwordRules.minLength)}
          {PasswordRuleMinLength}
        </div>
        <div
          className={clsx("flex p-1.25", {
            "text-success-text": passwordRules.hasValidCharacterCombination,
          })}
        >
          {getPasswordRuleIcon(passwordRules.hasValidCharacterCombination)}
          {PasswordRuleHasValidCharacterCombination}
        </div>
        <div className="pl-7">
          <div
            className={clsx("flex p-1.25", {
              "text-success-text": passwordRules.hasLowercaseLetter,
            })}
          >
            {getPasswordRuleIcon(passwordRules.hasLowercaseLetter)}
            {PasswordRuleHasLowerCaseLetter}
          </div>
          <div
            className={clsx("flex p-1.25", {
              "text-success-text": passwordRules.hasUppercaseLetter,
            })}
          >
            {getPasswordRuleIcon(passwordRules.hasUppercaseLetter)}
            {PasswordRuleHasUpperCaseLetter}
          </div>
          <div
            className={clsx("flex p-1.25", {
              "text-success-text": passwordRules.hasNumber,
            })}
          >
            {getPasswordRuleIcon(passwordRules.hasNumber)}
            {PasswordRuleHasNumber}
          </div>
          <div
            className={clsx("flex p-1.25", {
              "text-success-text": passwordRules.hasSpecialCharacter,
            })}
          >
            {getPasswordRuleIcon(passwordRules.hasSpecialCharacter)}
            {PasswordRuleHasSpecialCharacter}
          </div>
        </div>
        <div
          className={clsx("flex p-1.25", {
            "text-success-text": passwordRules.hasNoConsecutiveCharacters,
          })}
        >
          {getPasswordRuleIcon(passwordRules.hasNoConsecutiveCharacters)}
          {PasswordRuleNoConsecutiveCharacter}
        </div>
      </RegrelloTypography>
    ),
    [
      getPasswordRuleIcon,
      passwordRules.hasLowercaseLetter,
      passwordRules.hasNoConsecutiveCharacters,
      passwordRules.hasNumber,
      passwordRules.hasSpecialCharacter,
      passwordRules.hasUppercaseLetter,
      passwordRules.hasValidCharacterCombination,
      passwordRules.minLength,
    ],
  );

  const passwordFields = useMemo(
    () => (
      <>
        <RegrelloTooltipV4
          content={passwordPopover}
          contentProps={{
            className: "bg-backgroundSoft",
            style: {
              width: "var(--radix-tooltip-trigger-width)",
              maxHeight: "var(--radix-tooltip-content-available-height)",
            },
          }}
          open={isHintTooltipOpen && !shouldUseSSO}
          variant="popover"
        >
          <div className="w-full mb-4">
            <RegrelloControlledFormFieldText
              controllerProps={{
                control: form.control,
                name: "password",
                rules: { ...ValidationRules.REQUIRED, validate: { rule: validatePasswordRule } },
              }}
              disabled={shouldUseSSO}
              inputRef={passwordInputRef}
              isDefaultMarginsOmitted={true}
              onBlur={onPasswordBlur}
              onFocus={onPasswordFocus}
              placeholder={CreatePassword}
              type={isPasswordShown ? "text" : "password"}
            />
          </div>
        </RegrelloTooltipV4>
        <RegrelloControlledFormFieldText
          className={clsx("w-full mb-1")}
          controllerProps={{
            control: form.control,
            name: "passwordConfirm",
            rules: {
              ...ValidationRules.REQUIRED,
              validate: { match: validatePasswordMatch },
            },
          }}
          disabled={shouldUseSSO}
          isDefaultMarginsOmitted={true}
          placeholder={ConfirmPassword}
          type={isPasswordShown ? "text" : "password"}
        />

        <div className={clsx("w-full flex items-center justify-between mb-6")}>
          <RegrelloFormFieldCheckbox
            disabled={shouldUseSSO}
            isDefaultMarginsOmitted={true}
            label={ShowPassword}
            onChange={onCheckboxChange}
            value={isPasswordShown}
          />
        </div>
      </>
    ),
    [
      isHintTooltipOpen,
      passwordPopover,
      form.control,
      isPasswordShown,
      onCheckboxChange,
      onPasswordBlur,
      onPasswordFocus,
      shouldUseSSO,
      validatePasswordMatch,
      validatePasswordRule,
    ],
  );

  const signInWithSSOButton = useMemo(
    () => (
      <RegrelloButton
        className={clsx("w-full mb-2.5")}
        loading={loading}
        onClick={throttledOnContinueWithSso}
        size="large"
        startIcon={<RegrelloIcon iconName="sso" />}
        variant="outline"
      >
        {SignInWithSSO}
      </RegrelloButton>
    ),
    [loading, throttledOnContinueWithSso],
  );

  const signUpButton = useMemo(
    () => (
      <RegrelloButton
        className={clsx("w-full mb-8")}
        disabled={shouldUseSSO}
        intent="primary"
        loading={loading}
        size="large"
        type="submit"
      >
        {SignUp}
      </RegrelloButton>
    ),
    [loading, shouldUseSSO],
  );

  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col px-12 pb-4 w-97.5">
        <form className="flex flex-1 flex-col justify-center items-center" onSubmit={submit}>
          <RegrelloTypography className="font-normal mb-6" variant="h5">
            {shouldUseSSO ? SignInWithSSOToGetStarted : CreateYourPassword}
          </RegrelloTypography>

          {passwordFields}
          {signUpError != null && (
            <div className="self-start mb-2">
              <RegrelloTypography className="text-danger-textMuted">{ErrorSignUp}</RegrelloTypography>
            </div>
          )}
          {ssoConnectionName != null ? signInWithSSOButton : signUpButton}
        </form>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {PrivacyPolicyTitleCased}
          </RegrelloLinkV2>
          {CopyrightTitleCased()}
        </RegrelloTypography>
      </div>
    </div>
  );
});
