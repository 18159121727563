// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type FieldInstanceValueIntBaseFields = { __typename: 'FieldInstanceValueInt', id: number, inputType: Types.FieldInstanceValueInputType, intValue?: number | null };

export const FieldInstanceValueIntBaseFields = gql`
    fragment FieldInstanceValueIntBaseFields on FieldInstanceValueInt {
  __typename
  id
  inputType
  intValue
}
    `;