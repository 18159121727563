import React from "react";

export interface RegrelloDataGridColumnHeaderDragPreviewProps {
  /** Callback to return the correct title to display on the drag preview given the column id. */
  title: string;
}

/** Custom drag preview component for RegrelloDataGrid column reordering via drag 'n' drop. */
export const RegrelloDataGridColumnHeaderDragPreview = React.memo<RegrelloDataGridColumnHeaderDragPreviewProps>(
  function RegrelloDataGridColumnHeaderDragPreviewFn({ title }: RegrelloDataGridColumnHeaderDragPreviewProps) {
    return (
      <div className="flex items-center justify-center h-8 p-3 rounded bg-primary-solidMuted text-textContrast">
        {title}
      </div>
    );
  },
);
