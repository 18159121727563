import { assertNever } from "@regrello/core-utils";
import {
  ActionItemFrozeForReason,
  ActionItemStatus,
  ActionItemTemplateActionItemFields,
  ActionItemTemplateActionItemWithLinkedWorkflowFields,
  ActionItemType,
  ApprovalStatus,
  DueOnClass,
  WorkflowScheduleStatus,
} from "@regrello/graphql-api";
import { WorkflowFrontendTaskStatus } from "@regrello/ui-app-molecules";

export function getWorkflowFrontendTaskTemplateStatus(actionItemTemplate: {
  actionItems: Array<
    Pick<
      ActionItemTemplateActionItemWithLinkedWorkflowFields,
      "approvalStatus" | "dueOnClass" | "frozeForReason" | "hasException" | "linkedWorkflowReference" | "status"
    >
  >;
  type: ActionItemType;
}): WorkflowFrontendTaskStatus {
  const { actionItems, type } = actionItemTemplate;

  switch (type) {
    case ActionItemType.APPROVAL:
      if (actionItems.length === 0) {
        return WorkflowFrontendTaskStatus.APPROVAL_NOT_STARTED;
      }
      return getWorkflowFrontendStandaloneApprovalTaskStatus(actionItems[0]);
    case ActionItemType.AUTOMATION:
    case ActionItemType.DEFAULT:
      if (actionItems.length === 0) {
        return WorkflowFrontendTaskStatus.NOT_STARTED;
      }
      return getWorkflowFrontendTaskStatus(actionItems[0]);
    case ActionItemType.LINKED_WORKFLOW:
      if (actionItems.length === 0) {
        return WorkflowFrontendTaskStatus.LINKED_WORKFLOW_NOT_STARTED;
      }
      return getWorkflowFrontendLinkedWorkflowTaskStatus(actionItems[0]);
    default:
      assertNever(type);
  }
}

export function getWorkflowFrontendTaskStatus(
  actionItem: Pick<
    ActionItemTemplateActionItemWithLinkedWorkflowFields,
    "dueOnClass" | "frozeForReason" | "hasException" | "status" | "linkedWorkflowReference"
  >,
): WorkflowFrontendTaskStatus {
  if (
    actionItem.status !== ActionItemStatus.COMPLETED &&
    actionItem.frozeForReason === ActionItemFrozeForReason.ENDED
  ) {
    return WorkflowFrontendTaskStatus.ENDED;
  } else if (
    actionItem.status !== ActionItemStatus.COMPLETED &&
    actionItem.status !== ActionItemStatus.INACTIVE &&
    (actionItem.frozeForReason === ActionItemFrozeForReason.PAUSED ||
      actionItem.linkedWorkflowReference?.scheduleStatus === WorkflowScheduleStatus.PAUSED)
  ) {
    return WorkflowFrontendTaskStatus.PAUSED;
  }
  switch (actionItem.status) {
    case ActionItemStatus.COMPLETED:
      return WorkflowFrontendTaskStatus.COMPLETED;
    case ActionItemStatus.PENDING_ACKNOWLEDGEMENT:
    case ActionItemStatus.PENDING_REVIEW:
    case ActionItemStatus.PENDING_SUBMISSION:
    case ActionItemStatus.REJECTED:
      if (actionItem.hasException) {
        return WorkflowFrontendTaskStatus.EXCEPTION;
      }
      if (actionItem.dueOnClass === DueOnClass.OVERDUE) {
        return WorkflowFrontendTaskStatus.IN_PROGRESS_OVERDUE;
      }
      return WorkflowFrontendTaskStatus.IN_PROGRESS_ON_TRACK;
    case ActionItemStatus.INACTIVE:
      return WorkflowFrontendTaskStatus.NOT_STARTED;
    default:
      assertNever(actionItem.status);
  }
}

export function getWorkflowFrontendApprovalChildTaskTemplateStatus(approvalActionItemTemplate: {
  actionItems: Array<
    Pick<ActionItemTemplateActionItemFields, "approvalStatus" | "dueOnClass" | "hasException" | "status">
  >;
}): WorkflowFrontendTaskStatus {
  const { actionItems } = approvalActionItemTemplate;

  if (actionItems.length === 0) {
    return WorkflowFrontendTaskStatus.APPROVAL_NOT_STARTED;
  }

  return getWorkflowFrontendApprovalChildTaskStatus(actionItems[0]);
}

export function getWorkflowFrontendApprovalChildTaskStatus(
  approvalActionItem: Pick<
    ActionItemTemplateActionItemFields,
    "approvalStatus" | "dueOnClass" | "frozeForReason" | "hasException" | "status"
  >,
): WorkflowFrontendTaskStatus {
  if (
    approvalActionItem.status !== ActionItemStatus.COMPLETED &&
    approvalActionItem.status !== ActionItemStatus.INACTIVE &&
    approvalActionItem.frozeForReason === ActionItemFrozeForReason.PAUSED
  ) {
    return WorkflowFrontendTaskStatus.PAUSED;
  }
  if (
    approvalActionItem.status !== ActionItemStatus.COMPLETED &&
    approvalActionItem.frozeForReason === ActionItemFrozeForReason.ENDED
  ) {
    return WorkflowFrontendTaskStatus.ENDED;
  }
  if (approvalActionItem.status === ActionItemStatus.INACTIVE) {
    return WorkflowFrontendTaskStatus.APPROVAL_NOT_STARTED;
  }
  if (approvalActionItem.approvalStatus === ApprovalStatus.REJECTED) {
    return WorkflowFrontendTaskStatus.REJECTED;
  }
  if (approvalActionItem.status === ActionItemStatus.COMPLETED) {
    return WorkflowFrontendTaskStatus.APPROVED;
  }

  if (approvalActionItem.dueOnClass === DueOnClass.OVERDUE) {
    return WorkflowFrontendTaskStatus.IN_PROGRESS_OVERDUE;
  }
  return WorkflowFrontendTaskStatus.IN_PROGRESS_ON_TRACK;
}

export function getWorkflowFrontendStandaloneApprovalTaskStatus(
  standaloneApprovalActionItem: Pick<
    ActionItemTemplateActionItemFields,
    "approvalStatus" | "dueOnClass" | "frozeForReason" | "hasException" | "status"
  >,
): WorkflowFrontendTaskStatus {
  if (
    standaloneApprovalActionItem.status !== ActionItemStatus.COMPLETED &&
    standaloneApprovalActionItem.status !== ActionItemStatus.INACTIVE &&
    standaloneApprovalActionItem.frozeForReason === ActionItemFrozeForReason.PAUSED
  ) {
    return WorkflowFrontendTaskStatus.PAUSED;
  }
  if (
    standaloneApprovalActionItem.status !== ActionItemStatus.COMPLETED &&
    standaloneApprovalActionItem.frozeForReason === ActionItemFrozeForReason.ENDED
  ) {
    return WorkflowFrontendTaskStatus.ENDED;
  }
  if (standaloneApprovalActionItem.status === ActionItemStatus.INACTIVE) {
    return WorkflowFrontendTaskStatus.APPROVAL_NOT_STARTED;
  }
  if (standaloneApprovalActionItem.hasException) {
    return WorkflowFrontendTaskStatus.EXCEPTION;
  }
  if (standaloneApprovalActionItem.approvalStatus === ApprovalStatus.REJECTED) {
    return WorkflowFrontendTaskStatus.REJECTED;
  }
  if (standaloneApprovalActionItem.status === ActionItemStatus.COMPLETED) {
    return WorkflowFrontendTaskStatus.APPROVED;
  }
  if (standaloneApprovalActionItem.dueOnClass === DueOnClass.OVERDUE) {
    return WorkflowFrontendTaskStatus.IN_PROGRESS_OVERDUE;
  }
  return WorkflowFrontendTaskStatus.IN_PROGRESS_ON_TRACK;
}

export function getWorkflowFrontendLinkedWorkflowTaskStatus(
  linkedWorkflowActionItem: Pick<
    ActionItemTemplateActionItemWithLinkedWorkflowFields,
    "approvalStatus" | "dueOnClass" | "frozeForReason" | "hasException" | "linkedWorkflowReference" | "status"
  >,
): WorkflowFrontendTaskStatus {
  // (zstanik): Linked workflow tasks whose linked workflow has been ended are marked as completed
  // on the BE. But they should be rendered differently in the UI than "normal" completed tasks, so
  // this check takes priority over checking if the task is completed.
  if (linkedWorkflowActionItem.linkedWorkflowReference?.scheduleStatus === WorkflowScheduleStatus.ENDED) {
    return WorkflowFrontendTaskStatus.LINKED_WORKFLOW_ENDED;
  }
  if (linkedWorkflowActionItem.status === ActionItemStatus.COMPLETED) {
    return WorkflowFrontendTaskStatus.COMPLETED;
  }
  if (linkedWorkflowActionItem.frozeForReason === ActionItemFrozeForReason.PAUSED) {
    return WorkflowFrontendTaskStatus.PAUSED;
  }
  if (linkedWorkflowActionItem.frozeForReason === ActionItemFrozeForReason.ENDED) {
    return WorkflowFrontendTaskStatus.ENDED;
  }
  if (linkedWorkflowActionItem.status === ActionItemStatus.INACTIVE) {
    return WorkflowFrontendTaskStatus.LINKED_WORKFLOW_NOT_STARTED;
  }
  if (linkedWorkflowActionItem.hasException) {
    return WorkflowFrontendTaskStatus.LINKED_WORKFLOW_PROBLEM;
  }
  if (linkedWorkflowActionItem.linkedWorkflowReference?.scheduleStatus === WorkflowScheduleStatus.PAUSED) {
    return WorkflowFrontendTaskStatus.LINKED_WORKFLOW_PAUSED;
  }
  if (linkedWorkflowActionItem.dueOnClass === DueOnClass.OVERDUE) {
    return WorkflowFrontendTaskStatus.LINKED_WORKFLOW_DELAYED;
  }
  return WorkflowFrontendTaskStatus.LINKED_WORKFLOW_IN_PROGRESS;
}
