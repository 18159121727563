// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueStringFields } from './FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueStringWithCrossWorkflowFields = (
  { crossWorkflowSourceFieldInstanceMultiValueString?: AcyclicFieldInstanceMultiValueStringFields | null, crossWorkflowSinksFieldInstanceMultiValueString?: Array<AcyclicFieldInstanceMultiValueStringFields> | null }
  & FieldInstanceMultiValueStringFields
);

export const FieldInstanceMultiValueStringWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueStringWithCrossWorkflowFields on FieldInstanceMultiValueString {
  ...FieldInstanceMultiValueStringFields
  crossWorkflowSourceFieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
  crossWorkflowSinksFieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
}
    `;