import { clsx } from "@regrello/core-utils";
import React, { useCallback, useId, useMemo } from "react";

import { REGRELLO_CONTROL_LABEL_OFFSET_CLASS_NAME } from "./regrelloControlConstants";

export interface RegrelloControlWithLabelProps {
  /**
   * If the label is multi-line, whether to align the control to the `top` of the label or the
   * `center`.
   * @default "center"
   */
  align?: "center" | "top";

  /** Children are not permitted. Pass {@link control} and {@link label} instead. */
  children?: never;

  /** The control (checkbox, radio, switch) element. Should be memoized! */
  control: JSX.Element;

  /**
   * Whether this control is non-interactive. Must provide this in addition to disabling the child
   * control to ensure that the label behaves correctly while disabled.
   */
  disabled?: boolean;

  /** Component id used in htmlFor */
  id?: string;

  /** The label to display next to the control. */
  label?: React.ReactNode;
}

/** Displays a control (e.g., a checkbox, switch, or radio button) with a label next to it. */
export const RegrelloControlWithLabel = React.memo<RegrelloControlWithLabelProps>(function RegrelloControlWithLabelFn({
  align = "center",
  control,
  disabled: isDisabled = false,
  id,
  label,
}) {
  const localId = useId();
  const htmlId = id ?? localId;

  const stableControlElementWithId = useMemo(() => {
    return React.cloneElement(control, { id: htmlId });
  }, [control, htmlId]);

  const handleRootClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    // (clewis): Use target rather than currentTarget to ensure we get the root element.
    (event.target as HTMLElement).querySelector("label")?.click();
  }, []);

  return (
    <div
      className={clsx(`group flex ${REGRELLO_CONTROL_LABEL_OFFSET_CLASS_NAME} cursor-pointer`, {
        "items-center": align === "center",
        "cursor-not-allowed": isDisabled,
      })}
      onClick={handleRootClick}
    >
      <div
        className={clsx({
          "mt-px": align === "top", // (clewis): Optically center with a(n assumed) body-sized label.
        })}
      >
        {stableControlElementWithId}
      </div>
      {label != null && (
        <label
          className={clsx("text-sm hover:cursor-pointer", {
            "hover:cursor-not-allowed": isDisabled,
            "opacity-disabled": isDisabled,
          })}
          htmlFor={htmlId}
        >
          {label}
        </label>
      )}
    </div>
  );
});
