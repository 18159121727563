// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type AcyclicFieldInstanceMultiValueIntFields = { __typename: 'FieldInstanceMultiValueInt', id: number, inputType: Types.FieldInstanceValueInputType, intMultiValue: Array<number> };

export const AcyclicFieldInstanceMultiValueIntFields = gql`
    fragment AcyclicFieldInstanceMultiValueIntFields on FieldInstanceMultiValueInt {
  __typename
  id
  inputType
  intMultiValue
}
    `;