// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type NameTemplateWithSuffixFields = { stringTemplate: string, fieldIds: Array<number>, suffix?: string | null };

export const NameTemplateWithSuffixFields = gql`
    fragment NameTemplateWithSuffixFields on NameTemplateWithSuffix {
  stringTemplate
  fieldIds
  suffix
}
    `;