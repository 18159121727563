// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type TagTypeFields = { id: number, name: string, backgroundColor: string, borderColor: string };

export const TagTypeFields = gql`
    fragment TagTypeFields on TagType {
  id
  name
  backgroundColor
  borderColor
}
    `;