// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type RejectActionFields = { id: number, createdAt: string, entity: { __typename: 'RejectActionEntityEndWorkflow', workflow?: { id: number } | null } | { __typename: 'RejectActionEntityReopenActionItem', actionItemTemplate: { id: number, name: string } } | { __typename: 'RejectActionEntityReportException', actionItemTemplate: { id: number } } | { __typename: 'RejectActionEntityRestartStage', workflowStage?: { id: number, name: string } | null, workflowStageTemplate?: { id: number } | null } | { __typename: 'RejectActionEntityRestartWorkflow', workflow?: { id: number } | null } };

export const RejectActionFields = gql`
    fragment RejectActionFields on RejectAction {
  id
  createdAt
  entity {
    ... on RejectActionEntityReportException {
      __typename
      actionItemTemplate {
        id
      }
    }
    ... on RejectActionEntityReopenActionItem {
      __typename
      actionItemTemplate {
        id
        name
      }
    }
    ... on RejectActionEntityRestartStage {
      __typename
      workflowStage {
        id
        name
      }
      workflowStageTemplate {
        id
      }
    }
    ... on RejectActionEntityRestartWorkflow {
      __typename
      workflow {
        id
      }
    }
    ... on RejectActionEntityEndWorkflow {
      __typename
      workflow {
        id
      }
    }
  }
}
    `;