export const SpectrumFieldValidationType = {
  EMAIL: "email",
  PHONE: "phone",
  SIGNATURE: "signature",
  COUNTRY: "country",
  CURRENCY: "currency",
  CERTIFICATE: "certificate",
  DOCUMENT: "document",
  PARTY: "party",
  BOOLEAN: "boolean",
  SELECT: "select",
  MULTI_SELECT: "multi-select",
  TIMESTAMP: "timestamp",
  LONG_FORM_TEXT: "long-form-text",
  RAW: "raw",
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SpectrumFieldValidationType =
  (typeof SpectrumFieldValidationType)[keyof typeof SpectrumFieldValidationType];
