import { CellContext, ColumnDefTemplate, HeaderContext, Row, sortingFns } from "@tanstack/react-table";

import type { RegrelloDataGridColumnDef, RegrelloDataGridData, ResolvedColumnDef } from "./RegrelloDataGrid";
import { RegrelloDataGridColumnHeaderDisplay } from "./RegrelloDataGridColumnHeaderDisplay";

/**
 * Utility function to convert a `RegrelloColumnDef` column into a `ResolvedColumnDef`, which
 * `react-table` can accept. Use this function to convert all columns before passing them to the
 * `RegrelloDataGrid` {@link RegrelloDataGridProps.columns} prop.
 */
export function regrelloDataGridColumnHelper<TData extends RegrelloDataGridData<TData>, K extends keyof TData>(
  regrelloColumnDef: RegrelloDataGridColumnDef<TData, K>,
): ResolvedColumnDef<TData, TData[K]> {
  const id = regrelloColumnDef.columnId as string;
  const accessorKey = regrelloColumnDef.columnId;
  const header: ColumnDefTemplate<HeaderContext<TData, TData[K]>> = () => (
    <RegrelloDataGridColumnHeaderDisplay
      displayIcon={regrelloColumnDef.displayIcon}
      displayName={
        regrelloColumnDef.displayName ??
        (regrelloColumnDef.displayIcon == null ? (regrelloColumnDef.columnId as string) : undefined)
      }
    />
  );
  const cell: ColumnDefTemplate<CellContext<TData, TData[K]>> = (params) =>
    regrelloColumnDef.renderCell(params.row.getValue(id));
  const enableColumnFilter = regrelloColumnDef.filterable ?? true;
  const enableSorting = regrelloColumnDef.sortable ?? true;
  const sort = regrelloColumnDef.sort;
  const sortingFn =
    sort == null || !enableSorting
      ? undefined
      : sort === "alphanumeric"
        ? sortingFns.alphanumeric
        : (rowA: Row<TData>, rowB: Row<TData>, columnId: string) =>
            sort(rowA.getValue<TData[K]>(columnId), rowB.getValue<TData[K]>(columnId));
  const enableResizing = regrelloColumnDef.resizable ?? true;
  const size = regrelloColumnDef.width ?? 150;
  const minSize =
    regrelloColumnDef.minWidth != null && regrelloColumnDef.minWidth >= 50 ? regrelloColumnDef.minWidth : 50;
  const maxSize = regrelloColumnDef.maxWidth;
  return {
    id,
    accessorKey,
    header,
    cell,
    enableColumnFilter,
    enableSorting,
    sortingFn,
    enableResizing,
    size,
    minSize,
    visibilityController: regrelloColumnDef.visibilityController,
    tooltipText: regrelloColumnDef.tooltipText,
    maxSize,
    alignContent: regrelloColumnDef.alignContent,
    containsRowExpandIcon: regrelloColumnDef.containsRowExpandIcon,
    displayIcon: regrelloColumnDef.displayIcon,
    displayName: regrelloColumnDef.displayName,
    title: regrelloColumnDef.title,
  };
}
