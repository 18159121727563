// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { AutomationRequestParamFields } from './AutomationRequestParamFields.generated';
import { FieldInstanceFields } from './FieldInstanceFields.generated';
import { FieldInstanceBaseFields } from './FieldInstanceBaseFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { FieldInstanceSpectrumMetadataFields } from './FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from './FieldInstanceSpectrumFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValueFieldsFieldInstanceMultiValueDocument, FieldInstanceValueFieldsFieldInstanceMultiValueFloat, FieldInstanceValueFieldsFieldInstanceMultiValueInt, FieldInstanceValueFieldsFieldInstanceMultiValueParty, FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueFieldsFieldInstanceMultiValueString, FieldInstanceValueFieldsFieldInstanceMultiValueTime, FieldInstanceValueFieldsFieldInstanceValueBoolean, FieldInstanceValueFieldsFieldInstanceValueFloat, FieldInstanceValueFieldsFieldInstanceValueInt, FieldInstanceValueFieldsFieldInstanceValueParty, FieldInstanceValueFieldsFieldInstanceValueString, FieldInstanceValueFieldsFieldInstanceValueTime } from './FieldInstanceValueFields.generated';
import { FieldInstanceValueStringFields } from './FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringFields } from './FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntFields } from './FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatFields } from './FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanFields } from './FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeFields } from './FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from './FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from './AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from './AcyclicFieldInstanceFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from './AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from './AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceValuePartyFields } from './FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyFields } from './FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentFields } from './FieldInstanceMultiValueDocumentFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntFields } from './FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatFields } from './FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeFields } from './FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { gql } from '@apollo/client';
import { FieldInstanceValueFields } from './FieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValueFields } from './AcyclicFieldInstanceValueFields.generated';
export type AutomationRequestFields = { id: number, url: string, method: Types.AutomationRequestMethod, authorizationType: Types.AuthorizationType, createNewUsersFromResponse: boolean, token?: string | null, createdAt: string, updatedAt: string, deletedAt?: string | null, oauth2Config?: AutomationOAuth2ConfigFields | null, queryParams: Array<AutomationRequestParamFields>, headerParams: Array<AutomationRequestParamFields>, formDataParams: Array<AutomationRequestParamFields>, jsonDataParams: Array<AutomationRequestParamFields>, responseJsonExtractorConfigs: Array<{ id: number, jsonExpression: string, isAllowedToAppendAllowedValues?: boolean | null, field: FieldFields, fieldInstance?: FieldInstanceFields | null }>, paginationConfig?: { paginationType: Types.PaginationType, limit?: number | null, initialOffset?: number | null } | null, healthCheckConfig?: { id: number, isPaused: boolean, healthCheckRequest: { url: string }, scheduledJob?: { id: number, type: Types.ScheduledJobType, interval: Types.ScheduledJobInterval, lastRunAt?: string | null, nextRunAt: string, runAt?: string | null, createdAt: string, updatedAt: string, errorNotificationConfig?: { id: number, notificationType: Types.ScheduledJobErrorNotificationType, createdAt: string, updatedAt: string, notifyParties: Array<{ id: number, user?: UserBaseFields | null }> } | null } | null, lastHealthCheckResult?: { id: number, statusCode: number, details: string, createdAt: string } | null } | null };

export const AutomationRequestFields = gql`
    fragment AutomationRequestFields on AutomationRequest {
  id
  url
  method
  authorizationType
  createNewUsersFromResponse
  token
  oauth2Config {
    ...AutomationOAuth2ConfigFields
  }
  queryParams {
    ...AutomationRequestParamFields
  }
  headerParams {
    ...AutomationRequestParamFields
  }
  formDataParams {
    ...AutomationRequestParamFields
  }
  jsonDataParams {
    ...AutomationRequestParamFields
  }
  responseJsonExtractorConfigs {
    id
    jsonExpression
    field {
      ...FieldFields
    }
    fieldInstance {
      ...FieldInstanceFields
    }
    isAllowedToAppendAllowedValues
  }
  paginationConfig {
    paginationType
    limit
    initialOffset
  }
  healthCheckConfig {
    id
    isPaused
    healthCheckRequest {
      url
    }
    scheduledJob {
      id
      type
      interval
      lastRunAt
      nextRunAt
      runAt
      createdAt
      updatedAt
      errorNotificationConfig {
        id
        notificationType
        notifyParties {
          id
          user {
            ...UserBaseFields
          }
        }
        createdAt
        updatedAt
      }
    }
    lastHealthCheckResult {
      id
      statusCode
      details
      createdAt
    }
  }
  createdAt
  updatedAt
  deletedAt
}
    `;