import { clsx } from "@regrello/core-utils";
import { cva } from "class-variance-authority";

import { RegrelloIntentV2 } from "../../../utils/enums/RegrelloIntentV2";

export const menuItemVariants = cva<{
  intent: Record<RegrelloIntentV2, string>;
  isDisabled: { true: string; false: string }; // (clewis): It's annoyingly hard to style [data-disabled] and [data-disabled="true"] and [data-disabled="false"] properly via Tailwind rules, hence this hammer approach.
  isSelected: { true: string; false: string };
}>(
  [
    `
    cursor-pointer
    rounded
    outline-none
    `,
  ],
  {
    variants: {
      intent: {
        neutral: clsx([
          "text-textDefault",
          "focus:bg-neutral-transparentHovered",
          "hover:bg-neutral-transparentHovered",
          "active:bg-neutral-transparentPressed",
          "aria-selected:bg-neutral-transparentHovered",
        ]),
        primary: clsx([
          "text-primary-textMuted",
          "focus:bg-primary-transparentHovered",
          "hover:bg-primary-transparentHovered",
          "active:bg-primary-transparentPressed",
          "aria-selected:bg-primary-transparentHovered",
        ]),
        secondary: clsx([
          "text-secondary-textMuted",
          "focus:bg-secondary-transparentHovered",
          "hover:bg-secondary-transparentHovered",
          "active:bg-secondary-transparentPressed",
          "aria-selected:bg-secondary-transparentHovered",
        ]),
        success: clsx([
          "text-success-textMuted",
          "focus:bg-success-transparentHovered",
          "hover:bg-success-transparentHovered",
          "active:bg-success-transparentPressed",
          "aria-selected:bg-success-transparentHovered",
        ]),
        warning: clsx([
          "text-warning-textMuted",
          "focus:bg-warning-transparentHovered",
          "hover:bg-warning-transparentHovered",
          "active:bg-warning-transparentPressed",
          "aria-selected:bg-warning-transparentHovered",
        ]),
        danger: clsx([
          "text-danger-textMuted",
          "focus:bg-danger-transparentHovered",
          "hover:bg-danger-transparentHovered",
          "active:bg-danger-transparentPressed",
          "aria-selected:bg-danger-transparentHovered",
        ]),
      },
      isSelected: {
        true: "",
        false: "",
      },
      isDisabled: {
        // Note: Do not set pointer-events: none when disabled, because sometimes we show a tooltip
        // over a disabled select option to provide the user with more info on why it's disabled.
        true: clsx(["cursor-default", "hover:bg-background", "active:bg-background", "opacity-30"]),
        false: "",
      },
    },
    compoundVariants: [
      {
        intent: "neutral",
        isSelected: true,
        isDisabled: false,
        class: clsx([
          "bg-neutral-soft",
          "hover:bg-neutral-softHovered",
          "active:bg-neutral-softPressed",
          "aria-selected:bg-neutral-softHovered",
        ]),
      },
      {
        intent: "primary",
        isSelected: true,
        isDisabled: false,
        class: clsx([
          "bg-primary-soft",
          "hover:bg-primary-softHovered",
          "active:bg-primary-softPressed",
          "aria-selected:bg-primary-softHovered",
        ]),
      },
      {
        intent: "success",
        isSelected: true,
        isDisabled: false,
        class: clsx([
          "bg-success-soft",
          "hover:bg-success-softHovered",
          "active:bg-success-softPressed",
          "aria-selected:bg-success-softHovered",
        ]),
      },
      {
        intent: "warning",
        isSelected: true,
        isDisabled: false,
        class: clsx([
          "bg-warning-soft",
          "hover:bg-warning-softHovered",
          "active:bg-warning-softPressed",
          "aria-selected:bg-warning-softHovered",
        ]),
      },
      {
        intent: "danger",
        isSelected: true,
        isDisabled: false,
        class: clsx([
          "bg-danger-soft",
          "hover:bg-danger-softHovered",
          "active:bg-danger-softPressed",
          "aria-selected:bg-danger-softHovered",
        ]),
      },
    ],
    defaultVariants: {
      intent: "neutral",
      isSelected: false,
      isDisabled: false,
    },
  },
);

export const menuItemSeparatorClasses = "-mx-1 my-1 h-1px bg-border";
