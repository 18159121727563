import { PureQueryOptions } from "@apollo/client";
import {
  ActionItemDetailsQueryDocument,
  ActionItemDetailsQueryVariables,
  ActionItemDetailsWithFormInstancesQueryDocument,
  ActionItemDetailsWithFormInstancesQueryVariables,
  ApprovalAttemptsForActionItemQueryDocument,
  ApprovalAttemptsForActionItemQueryVariables,
  EditActionItemTemplateDialogFormQueryDocument,
  EditActionItemTemplateDialogFormQueryVariables,
  FieldInstancesForFormInstanceQueryDocument,
  FieldInstancesForFormInstanceQueryVariables,
  FieldsQueryV2Document,
  FieldsQueryV2Variables,
  FormVersionPreviewQueryDocument,
  FormVersionPreviewQueryVariables,
  LatestFormVersionQueryDocument,
  LatestFormVersionQueryVariables,
  ValidateWorkflowQueryDocument,
  ValidateWorkflowQueryVariables,
  VariantBlueprintsQueryDocument,
  VariantBlueprintsQueryVariables,
  WorkflowQueryDocument,
  WorkflowQueryVariables,
  WorkflowTemplatesListQueryDocument,
  WorkflowTemplatesListQueryVariables,
  WorkflowTemplatesQueryDocument,
  WorkflowTemplatesQueryVariables,
} from "@regrello/graphql-api";

export function getWorkflowQueryWithVariable(variables: WorkflowQueryVariables): PureQueryOptions {
  return {
    query: WorkflowQueryDocument,
    variables,
  };
}

export function getWorkflowTemplateQueryWithVariables(variables: WorkflowTemplatesQueryVariables): PureQueryOptions {
  return {
    query: WorkflowTemplatesQueryDocument,
    variables,
  };
}

export function getWorkflowTemplatesListQueryWithVariables(
  variables: WorkflowTemplatesListQueryVariables,
): PureQueryOptions {
  return {
    query: WorkflowTemplatesListQueryDocument,
    variables,
  };
}

export function getVariantBlueprintsQueryWithVariables(variables: VariantBlueprintsQueryVariables): PureQueryOptions {
  return {
    query: VariantBlueprintsQueryDocument,
    variables,
  };
}

export function getFieldsV2QueryWithVariables(variables: FieldsQueryV2Variables): PureQueryOptions {
  return {
    query: FieldsQueryV2Document,
    variables,
  };
}

export function getValidateWorkflowQueryWithVariables(variables: ValidateWorkflowQueryVariables): PureQueryOptions {
  return {
    query: ValidateWorkflowQueryDocument,
    variables,
  };
}

export function getActionItemDetailQueryWithVariables(variables: ActionItemDetailsQueryVariables): PureQueryOptions {
  return {
    query: ActionItemDetailsQueryDocument,
    variables,
  };
}

export function getApprovalAttemptsForActionItemWithVariables(
  variables: ApprovalAttemptsForActionItemQueryVariables,
): PureQueryOptions {
  return {
    query: ApprovalAttemptsForActionItemQueryDocument,
    variables,
  };
}

export function getEditActionItemTemplateDialogFormsWithVariables(
  variables: EditActionItemTemplateDialogFormQueryVariables,
): PureQueryOptions {
  return {
    query: EditActionItemTemplateDialogFormQueryDocument,
    variables,
  };
}

export function getLatestFormVersionQueryWithVariables(variables: LatestFormVersionQueryVariables): PureQueryOptions {
  return {
    query: LatestFormVersionQueryDocument,
    variables,
  };
}

export function getActionItemWithFormInstanceQueryWithVariables(
  variables: ActionItemDetailsWithFormInstancesQueryVariables,
): PureQueryOptions {
  return {
    query: ActionItemDetailsWithFormInstancesQueryDocument,
    variables,
  };
}

export function getFormVersionPreviewQueryWithVariables(variables: FormVersionPreviewQueryVariables): PureQueryOptions {
  return {
    query: FormVersionPreviewQueryDocument,
    variables,
  };
}

export function getFieldInstancesForFormInstanceQueryWithVariables(
  variables: FieldInstancesForFormInstanceQueryVariables,
): PureQueryOptions {
  return {
    query: FieldInstancesForFormInstanceQueryDocument,
    variables,
  };
}
