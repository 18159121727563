// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type FormFieldConstraintRuleFields = { id: number, uuid: string, formFieldConstraintRule: string };

export const FormFieldConstraintRuleFields = gql`
    fragment FormFieldConstraintRuleFields on FormFieldConstraintRule {
  id
  uuid
  formFieldConstraintRule
}
    `;