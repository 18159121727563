import { EMPTY_STRING, localStorageGetTyped, RegrelloLocalStorageKey } from "@regrello/core-utils";
import { UserVerificationStatus, VerifyUserIdentityResponse, WorkspaceSetting } from "@regrello/graphql-api";

import { API_HOSTNAME } from "../constants/globalConstants";
import { HttpStatusCode } from "../constants/HttpStatusCodes";
import { RouteQueryStringKeys } from "../ui/app/routes/consts";
import { isDevEnvironment } from "../utils/environmentUtils";

/**
 * A service for communicating with the Regrello backend via REST. Typically used for GET'ing
 * peripheral metadata that doesn't make sense to surface via the graph-modeled GraphQL API.
 */
export const RegrelloRestApiService = {
  getUserVerificationStatus: () => executeAuthenticatedGetRequest("/user/verification-status"),
  verifyUserIdentity: (authUserEmail: string | null, uuid: string | null, forceVerifyForExistingUser: boolean | null) =>
    executePostRequest(
      "/user/verify",
      { AuthUserEmail: authUserEmail, UUID: uuid, ForceVerifyForExistingUser: forceVerifyForExistingUser },
      { accessToken: getAuthToken(), includeCredentials: true },
    ),
  getEnvironment: () => executeGetRequest("/environment"),
  getToken: (code: string) => executePostRequest("/auth/request-for-token-with-code", { code: code }),
  refreshToken: (token: string) => executePostRequest("/auth/request-for-token-with-token", { access_token: token }),
  logout: (token: string) => executePostRequest("/auth/logout", { access_token: token }),
  getDevToken: (username: string) => executePostRequest("/dev/login-as", { username }),
  isSso: (emailDomain: string) => executePostRequest("/is-sso", { domain: emailDomain }),
  reportFpsPerformance: ({ userEmail, tenantName, fps }: { userEmail: string; tenantName: string; fps: number }) =>
    executePostRequest(
      "/fe-slowdown-reports",
      { user: userEmail, workspace: tenantName, fps },
      { discardResponse: true },
    ),
  resendInvite: (email: string) => executePostRequest("/resend-invite", { Email: email }),
  requestVerificationLink: (granularAccessToken: string, email: string | undefined | null) =>
    executeGetRequestWithQueryParams("/verification/request", {
      token: granularAccessToken,
      ...(email != null ? { email: email } : {}),
    }),
  getWorkspacePersonalization: (granularAccessToken: string) =>
    executeGetRequestWithQueryParams("/workspace-personalization", {
      token: granularAccessToken,
    }),
};

// This should match the EnvironmentResponse payload in handlers.go.
//
// TODO (clewis, dhanson): It would be better to derive our REST response types from our server API
// via Swagger or Conjure. Right now, we have to manually keep types on both sides in sync.
//
// See: https://swagger.io/, https://palantir.github.io/conjure/
type RegrelloRestApi = {
  "/environment": {
    Auth0Domain: string;
    Auth0ClientId: string;
    Auth0ClientSecret: string;
    Auth0Audience: string;
    AuthenticationProvider: string;
    BoxClientId: string;
    DocusignDeveloperAccountIntegrationKey: string;
    DocusignProductionAccountIntegrationKey: string;
    Environment: string;
    FeatureFlagOverrides: Record<string, boolean>;
    ForceProviderName: string | undefined;
    LaunchDarklyClientId: string;
    ReactAppSentryDSN: string;
    SentrySampleRate: string;
    SentryReplaySessionSampleRate: string;
    SentryReplayOnErrorSampleRate: string;
    SentryReplayEnableMasking: boolean;
    SentryEnablePII: boolean;
    EnablePrivacyAcknowledgementBanner: boolean;
    PrivacyAcknowledgementBannerLink: string;
    IsPendoEnabled: boolean;
  };
  "/is-sso": {
    isSso: boolean;
    isSsoOptional: boolean;
    connectionName: string;
  };
  "/resend-invite": {
    Success: string;
    Error: string | undefined;
  };
  "/auth/request-for-token-with-code": {
    accessToken: string;
    expireAt: string;
  };
  "/auth/request-for-token-with-token": {
    accessToken: string;
    expireAt: string;
  };
  "/auth/logout": {
    accessToken: string;
  };
  "/dev/login-as": {
    authUserId: number;
    tenantUserId: number;
    token: string;
    error: string;
  };
  "/verification/request": Record<string, never>;
  "/workspace-personalization": {
    Logo: WorkspaceSetting | undefined;
    FeatureFlagContext: { IsFruitEmailRedesignEnabled: boolean } | undefined;
  };
  "/fe-slowdown-reports": undefined;
  "/user/verification-status": UserVerificationStatus;
  "/user/verify": VerifyUserIdentityResponse;
};

export class HttpError extends Error {
  public status: HttpStatusCode;

  public responseBody: unknown;

  constructor(message: string, status: number, responseBody: unknown) {
    super(message);
    this.status = status;
    this.responseBody = responseBody;
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

// TODO(Elle) refactor http handlers
function executeGetRequestBase<TEndpoint extends keyof RegrelloRestApi>(
  endpoint: TEndpoint,
  queryParams?: Record<string, string> | null,
  accessToken?: string,
): Promise<Response> {
  const urlParts = new URLSearchParams();
  if (queryParams != null) {
    Object.entries(queryParams).forEach(([key, val]) => urlParts.append(key, val));
  }

  const headers = new Headers();
  if (accessToken) {
    headers.append("Authorization", `Bearer ${accessToken}`);
  }

  return fetch(`${API_HOSTNAME}${endpoint}?${urlParts.toString()}`, {
    method: "GET",
    headers: headers,
    // (elle): include cookie in the header for device verification
    credentials: "include",
  });
}

async function executeGetRequest<TEndpoint extends keyof RegrelloRestApi, TResponse extends RegrelloRestApi[TEndpoint]>(
  endpoint: TEndpoint,
): Promise<TResponse> {
  const response = await executeGetRequestBase(endpoint, null);
  return response.json();
}

async function executeAuthenticatedGetRequest<
  TEndpoint extends keyof RegrelloRestApi,
  TResponse extends RegrelloRestApi[TEndpoint],
>(endpoint: TEndpoint, queryParams?: Record<string, string> | null): Promise<{ status: number; json: TResponse }> {
  const accessToken = getAuthToken();
  if (!accessToken) {
    throw new Error("No authentication token available for the user");
  }

  const response = await executeGetRequestBase(endpoint, queryParams, accessToken);

  const responseBody = await parseResponseBody(response);

  if (response.status !== HttpStatusCode.Ok) {
    throw new HttpError("Request failed", response.status, responseBody);
  }

  return {
    status: response.status,
    json: responseBody,
  };
}

async function executeGetRequestWithQueryParams<
  TEndpoint extends keyof RegrelloRestApi,
  TResponse extends RegrelloRestApi[TEndpoint],
>(endpoint: TEndpoint, queryParams: Record<string, string>): Promise<{ status: number; json: TResponse }> {
  const response = await executeGetRequestBase(endpoint, queryParams);
  const responseBody = await parseResponseBody(response);

  return {
    status: response.status,
    json: responseBody,
  };
}

/**
 * Executes a `POST` HTTP request against the backend.
 *
 * @param endpoint The backend path to request against.
 * @param body Body to supply as a JSON payload in the request body.
 * @param options Configuration for how the response is handled.
 * @returns The backend's reply.
 */
async function executePostRequest<
  TEndpoint extends keyof RegrelloRestApi,
  TResponse extends RegrelloRestApi[TEndpoint],
>(
  endpoint: TEndpoint,
  body: Record<string, string | number | boolean | null>,
  options?: {
    /** Whether the response body should be ignored entirely. */
    discardResponse?: boolean;
    accessToken?: string | null;
    includeCredentials?: boolean;
  },
): Promise<{ status: number; json: TResponse }> {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (options?.accessToken != null) {
    headers.append("Authorization", `Bearer ${options.accessToken}`);
  }

  const response = await fetch(`${API_HOSTNAME}${endpoint}`, {
    headers: headers,
    method: "POST",
    body: JSON.stringify(body),
    // (elle): include cookie in the header for device verification
    credentials: options?.includeCredentials === true ? "include" : undefined,
  });
  return {
    status: response.status,
    json: options?.discardResponse ? undefined : await response.json(),
  };
}

// Retrieves the authentication token from storage
function getAuthToken(): string | null {
  // Fetch the user's authentication token, which is required to get data from our backend.
  const spoofingUser = isDevEnvironment()
    ? localStorageGetTyped(RegrelloLocalStorageKey.SPOOFING_USER_INFO)
    : undefined;

  // Find token from spoofing user
  if (spoofingUser?.token != null) {
    return spoofingUser.token;
  }

  // Find token from local storage key for the Regrello website
  const auth0UserV2 = localStorageGetTyped(RegrelloLocalStorageKey.AUTH_0_USER_KEY);

  if (auth0UserV2?.token != null) {
    return auth0UserV2?.token;
  }

  // Find token from URL for webform
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(RouteQueryStringKeys.TOKEN);
  if (token != null) {
    return token;
  }

  // No token found
  return EMPTY_STRING;
}

// Attempt to parse the response body as JSON, fallback to plain text if parsing fails
async function parseResponseBody(response: Response) {
  const contentType = response.headers.get("Content-Type") || "";

  if (contentType.includes("application/json")) {
    return response.json();
  } else {
    return response.text();
  }
}
