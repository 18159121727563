// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { TagTypeFields } from '../../fragments/generated/TagTypeFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TagTypesQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type TagTypesQuery = { tagTypes: Array<TagTypeFields> };


export const TagTypesQueryDocument = gql`
    query TagTypesQuery($id: ID) {
  tagTypes(id: $id) {
    ...TagTypeFields
  }
}
    ${TagTypeFields}`;

/**
 * __useTagTypesQuery__
 *
 * To run a query within a React component, call `useTagTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagTypesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagTypesQuery(baseOptions?: Apollo.QueryHookOptions<TagTypesQuery, TagTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagTypesQuery, TagTypesQueryVariables>(TagTypesQueryDocument, options);
      }
export function useTagTypesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagTypesQuery, TagTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagTypesQuery, TagTypesQueryVariables>(TagTypesQueryDocument, options);
        }
export function useTagTypesQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TagTypesQuery, TagTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagTypesQuery, TagTypesQueryVariables>(TagTypesQueryDocument, options);
        }
export type TagTypesQueryHookResult = ReturnType<typeof useTagTypesQuery>;
export type TagTypesQueryLazyQueryHookResult = ReturnType<typeof useTagTypesQueryLazyQuery>;
export type TagTypesQuerySuspenseQueryHookResult = ReturnType<typeof useTagTypesQuerySuspenseQuery>;
export type TagTypesQueryResult = Apollo.QueryResult<TagTypesQuery, TagTypesQueryVariables>;