// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValueIntFields = (
  { sourceFieldInstanceValueInt?: AcyclicFieldInstanceValueIntFields | null, sinksFieldInstanceValueInt: Array<AcyclicFieldInstanceValueIntFields> }
  & FieldInstanceValueIntBaseFields
);

export const FieldInstanceValueIntFields = gql`
    fragment FieldInstanceValueIntFields on FieldInstanceValueInt {
  ...FieldInstanceValueIntBaseFields
  sourceFieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
  sinksFieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
}
    `;