import { assertNever } from "@regrello/core-utils";
import { NoChanges } from "@regrello/ui-strings";

import { RegrelloToastIntent } from "../ui/molecules/toast/RegrelloToast";

// (clewis): Some of these are standard (strongly typeable) reasons from react-hook-form, while
// others are custom. They exist in this enum to avoid spelling mistakes.
export enum RegrelloFormValidationReason {
  PATTERN = "pattern",
  UNIQUE = "unique",
}

/**
 * Returns the status needed in the function `getBulkResponseToastDetails`, based off the number of
 * successful and failed results in a bulk operation.
 */
export function getBulkResponseStatus(numSuccess: number, numFailure: number) {
  return numSuccess > 0 && numFailure > 0
    ? "successAndFailure"
    : numSuccess > 0
      ? "success"
      : numFailure > 0
        ? "failure"
        : "noChanges";
}

/**
 * Constructs the info for `showToast` when displaying the results of bulk operations.
 */
export function getBulkResponseToastDetails(
  bulkResponseStatus: "successAndFailure" | "success" | "failure" | "noChanges",
  successMessage: string,
  failMessage: string,
  successAndFailureMessage: string,
): {
  content: string;
  intent: RegrelloToastIntent;
} {
  switch (bulkResponseStatus) {
    case "successAndFailure":
      return {
        content: successAndFailureMessage,
        intent: "info",
      };
    case "success":
      return {
        content: successMessage,
        intent: "success",
      };
    case "failure":
      return {
        content: failMessage,
        intent: "danger",
      };
    case "noChanges":
      return {
        content: NoChanges,
        intent: "warning",
      };
    default:
      assertNever(bulkResponseStatus);
  }
}
