// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormConstraintConditionGroupBaseFields } from './FormConstraintConditionGroupBaseFields.generated';
import { FormConstraintConditionFields } from './FormConstraintConditionFields.generated';
import { gql } from '@apollo/client';
export type FormConstraintConditionGroupFields = (
  { groups: Array<(
    { groups: Array<(
      { groups: Array<FormConstraintConditionGroupBaseFields> }
      & FormConstraintConditionGroupBaseFields
    )> }
    & FormConstraintConditionGroupBaseFields
  )> }
  & FormConstraintConditionGroupBaseFields
);

export const FormConstraintConditionGroupFields = gql`
    fragment FormConstraintConditionGroupFields on FormConstraintConditionGroup {
  ...FormConstraintConditionGroupBaseFields
  groups {
    ...FormConstraintConditionGroupBaseFields
    groups {
      ...FormConstraintConditionGroupBaseFields
      groups {
        ...FormConstraintConditionGroupBaseFields
      }
    }
  }
}
    `;