// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormConstraintConditionFields } from './FormConstraintConditionFields.generated';
import { gql } from '@apollo/client';
export type FormConstraintConditionGroupBaseFields = { operator: Types.FormConstraintConditionGroupOperator, displayOrder: number, constraints: Array<FormConstraintConditionFields> };

export const FormConstraintConditionGroupBaseFields = gql`
    fragment FormConstraintConditionGroupBaseFields on FormConstraintConditionGroup {
  operator
  constraints {
    ...FormConstraintConditionFields
  }
  displayOrder
}
    `;