// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type PropertyTypeFields = { id: number, name: string, dataType: Types.PropertyDataType };

export const PropertyTypeFields = gql`
    fragment PropertyTypeFields on PropertyType {
  id
  name
  dataType
}
    `;