// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type SpectrumFieldValidationTypeFields = { id: number, uuid: string, validationType: string };

export const SpectrumFieldValidationTypeFields = gql`
    fragment SpectrumFieldValidationTypeFields on SpectrumFieldValidationType {
  id
  uuid
  validationType
}
    `;