export const TableCellDefaultWidths = {
  SCIM_SYNC_CELL: 5,
  CHECKBOX_CELL: 100,
  DATE_CELL: 180,
  DOCUMENT_CELL: 160,
  ICON_CELL: 60,
  ICON_BUTTON_CELL: 48,
  MANAGE_CELL: 200,
  NAME_CELL: 300,
  NUMBER_CELL: 120,
  PARTIES_CELL: 150,
  TAGS_CELL: 200,
  TEXT_CELL: 200,
} as const;
