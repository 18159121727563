import {
  EMPTY_STRING,
  localStorageGetTyped,
  localStorageSetTyped,
  RegrelloLocalStorageKey,
  WithChildren,
} from "@regrello/core-utils";
import {
  AuthenticationErrorTooManyAttempts,
  ErrorExchangingAccessToken,
  ErrorLogoutFailed,
  ErrorSendEmail,
  ErrorTooManyResendInviteAttempts,
} from "@regrello/ui-strings";
import { Auth0Error, WebAuth } from "auth0-js";
import { useAtom, useSetAtom } from "jotai";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-use";

import { RegrelloAuthenticationProviderInternal } from "./userContextUtils";
import { MILLISECONDS_IN_MINUTE, ResponseStatus, SECONDS_IN_DAY } from "../../../constants/globalConstants";
import { HttpStatusCode } from "../../../constants/HttpStatusCodes";
import { MILLISECONDS_PER_SECOND } from "../../../constants/numbers";
import { RegrelloRestApiService } from "../../../services/RegrelloRestApiService";
import {
  IS_AUTHENTICATED_COOKIE_KEY,
  isAuthenticatedAtom,
  isWaitingForAuth0AuthenticationAtom,
} from "../../../state/applicationState";
import { isDevEnvironment, isProductionEnvironment } from "../../../utils/environmentUtils";
import {
  RegrelloSessionStorageKey,
  sessionStorageGetTyped,
  sessionStorageRemoveTyped,
  sessionStorageSetTyped,
} from "../../../utils/getFromSessionStorage";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { useErrorLogger } from "../../../utils/hooks/useErrorLogger";
import {
  parseJwtClaim,
  REGRELLO_SESSION_TOKEN_AUDIENCE,
  RegrelloJWTClaim,
  RegrelloJWTClaimKeys,
} from "../../../utils/jwtUtil";
import { RoutePaths, RouteQueryStringKeys } from "../routes/consts";

/**
 * Name of the connection that will be used for SSO (e.g. google-oauth2). The names needs to be
 * consistent with Auth0 settings.
 */
export enum AuthenticationConnectionName {
  DEFAULT = "Username-Password-Authentication",
  GOOGLE = "google-oauth2",
  SSO = "sso",
}

interface DefaultLoginParams {
  connection: AuthenticationConnectionName.DEFAULT | string;
  email: string;
  password: string;
}

interface SocialLoginParams {
  connection: AuthenticationConnectionName.GOOGLE | AuthenticationConnectionName.SSO;
}

export type LogoutFn = (options?: { preserveLocation: boolean }) => Promise<void>;

export interface RegrelloAuthenticationContext {
  clearError: () => void;
  loading: boolean;
  login: (params: DefaultLoginParams | SocialLoginParams, artificialDelay?: number) => Promise<void>;
  logout: LogoutFn;
  authError: string | undefined;
  requestResetPassword: (email: string) => Promise<void>;
  resendInvite: (email: string, supplierModeRedirect?: boolean) => Promise<void>;
  signUp: (params: DefaultLoginParams | SocialLoginParams) => Promise<void>;
}

export interface RegrelloAuthenticationProviderProps extends WithChildren {
  auth0Audience: string;
  auth0ClientID: string;
  auth0Domain: string;
  isLoginSpoofingEnabled: boolean;
}

enum Auth0UrlParams {
  CODE = "code",
  ERROR_DESCRIPTION = "error_description",
}

export const RegrelloAuthenticationProvider = React.memo<RegrelloAuthenticationProviderProps>(
  function RegrelloAuthenticationProviderFn({
    children,
    auth0Audience,
    auth0ClientID,
    auth0Domain,
    isLoginSpoofingEnabled,
  }) {
    const webAuth = useMemo(
      () =>
        new WebAuth({
          audience: auth0Audience,
          clientID: auth0ClientID,
          domain: auth0Domain,
          responseType: "code",
          redirectUri: window.location.origin,
        }),
      [auth0Audience, auth0ClientID, auth0Domain],
    );

    const [loading, setLoading] = useState(false);
    const [authError, setAuthError] = useState<string | undefined>();
    const [auth0AuthorizationCode, setAuth0AuthorizationCode] = useState<string | undefined>();
    const { handleError } = useErrorHandler();
    const { logAuth0Error } = useErrorLogger();
    const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
    const setIsWaitingForAuth0Authentication = useSetAtom(isWaitingForAuth0AuthenticationAtom);

    const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const auth0ErrorInUrl = useMemo(() => urlParams.get(Auth0UrlParams.ERROR_DESCRIPTION) ?? undefined, [urlParams]);
    const authorizationCodeInUrl = useMemo(() => urlParams.get(Auth0UrlParams.CODE) ?? EMPTY_STRING, [urlParams]);

    const resendInvite = useCallback(
      async (email: string, supplierModeRedirect?: boolean) => {
        setLoading(true);
        const { status, json } = await RegrelloRestApiService.resendInvite(email);
        if (status === ResponseStatus.TOO_MANY_REQUESTS) {
          setAuthError(ErrorTooManyResendInviteAttempts);
        } else if (json.Success || json.Success === "true") {
          setAuthError(undefined);
          window.location.replace(
            `${window.location.origin}${RoutePaths.INVITE_SENT}${
              supplierModeRedirect ? `?${RouteQueryStringKeys.SUPPLIER}=1` : ""
            }`,
          );
        } else {
          handleError(new Error(`${json.Error} ${status}: ${ErrorSendEmail}`));
          setAuthError(ErrorSendEmail);
        }
        setLoading(false);
      },
      [handleError],
    );

    // (krashanoff): uses the backend's spoofing functionality to simulate logging in as a user.
    const loginSpoof = useCallback(
      async ({ email }: DefaultLoginParams) => {
        if (isProductionEnvironment()) {
          return;
        }

        try {
          const emailUsernameEndIndex = email.indexOf("@");
          if (emailUsernameEndIndex === -1) {
            setAuthError("could not find email username portion");
          }

          const {
            status,
            json: { authUserId, tenantUserId, token, error },
          } = await RegrelloRestApiService.getDevToken(email);
          if (error != null && error !== "") {
            setAuthError(error);
            setLoading(false);
            return;
          }
          if (status !== HttpStatusCode.Ok) {
            setAuthError("failed to spoof login!");
            setLoading(false);
            return;
          }

          localStorageSetTyped(RegrelloLocalStorageKey.SPOOFING_USER_INFO, {
            token,
            authUserId,
            tenantUserId,
            email,
          });
          setIsAuthenticated(true);
        } catch (e) {
          setAuthError((e as string).toString());
        }
        setLoading(false);
      },
      [setIsAuthenticated],
    );

    const loginAuth0 = useCallback(
      async (params: DefaultLoginParams | SocialLoginParams) => {
        if (params.connection === AuthenticationConnectionName.DEFAULT) {
          await webAuth.login(
            {
              username: params.email,
              password: params.password,
              realm: params.connection,
            },
            (error) => {
              // (hchen): NOTE: This function only execute if there is an error, though it should
              // also execute on success.
              if (error != null) {
                setIsAuthenticated(false);
                const errorString = buildAuth0ErrorString(error);
                logAuth0Error(error, params.email);
                setAuthError(errorString);
                localStorage.clear();
                setIsWaitingForAuth0Authentication(false);
              }
            },
          );
          setLoading(false);
        } else if (params.connection === AuthenticationConnectionName.SSO) {
          // (hchen): Redirect to Auth0 to sign in with SSO
          await webAuth.authorize();
        } else {
          // (hchen) Specifying a connection when calling webAuth.authorize skips the step of asking
          // the user for email. The user will be routed to the corresponding enterprise or social
          // login page directly.
          //
          // (krashanoff): We do not stop loading in this case, since it is a full redirect.
          await webAuth.authorize({ connection: params.connection });
        }
      },
      [logAuth0Error, setIsAuthenticated, setIsWaitingForAuth0Authentication, webAuth],
    );

    const login = useCallback(
      async (params: DefaultLoginParams | SocialLoginParams, artificialDelay?: number) => {
        setLoading(true);

        if (artificialDelay != null) {
          await new Promise((resolve) => setTimeout(resolve, artificialDelay));
        }

        if (
          isLoginSpoofingEnabled &&
          isDevEnvironment() &&
          params.connection === AuthenticationConnectionName.DEFAULT
        ) {
          await loginSpoof(params);
        } else {
          await loginAuth0(params);
        }
      },
      [isLoginSpoofingEnabled, loginAuth0, loginSpoof],
    );

    const logout = useCallback<LogoutFn>(
      async ({ preserveLocation } = { preserveLocation: false }) => {
        if (preserveLocation) {
          sessionStorageSetTyped(RegrelloSessionStorageKey.REDIRECT_URL_AFTER_LOGIN, window.location.toString());
        } else {
          sessionStorageRemoveTyped(RegrelloSessionStorageKey.REDIRECT_URL_AFTER_LOGIN);
        }
        const auth0UserV2 = localStorageGetTyped(RegrelloLocalStorageKey.AUTH_0_USER_KEY);
        const token = auth0UserV2?.token;
        if (token != null) {
          const jwtClaim = parseJwtClaim<RegrelloJWTClaim>(token);
          // only call logout on Regrello JWTs that are of type SESSION_TOKEN
          if (jwtClaim[RegrelloJWTClaimKeys.aud].indexOf(REGRELLO_SESSION_TOKEN_AUDIENCE) !== -1) {
            const { status } = await RegrelloRestApiService.logout(token);
            if (status !== ResponseStatus.SUCCESS) {
              setAuthError(ErrorLogoutFailed);
            }
          }
        }

        await webAuth.logout({ returnTo: window.location.origin });
        // (hchen): Clear the cookie directly instead of using setIsAuthenticated to prevent the
        // login page from flashing before the mandatory navigation completes
        Cookies.remove(IS_AUTHENTICATED_COOKIE_KEY);

        localStorage.clear();
      },
      [webAuth],
    );

    const clearError = useCallback(() => setAuthError(undefined), []);

    const requestResetPasswordAuth0 = useCallback(
      async (email: string) => {
        await webAuth.changePassword(
          {
            connection: AuthenticationConnectionName.DEFAULT,
            email,
          },
          (error) => {
            if (error != null) {
              logAuth0Error(error, email);
              setAuthError(ErrorSendEmail);
            } else {
              window.location.replace(`${window.location.origin}${RoutePaths.PASSWORD_RESET_SENT}`);
            }
          },
        );
        setLoading(false);
      },
      [logAuth0Error, webAuth],
    );

    const requestResetPassword = useCallback(
      async (email: string) => {
        setLoading(true);
        await requestResetPasswordAuth0(email);
      },
      [requestResetPasswordAuth0],
    );

    const signUpAuth0 = useCallback(
      async (params: DefaultLoginParams | SocialLoginParams) => {
        if (params.connection === AuthenticationConnectionName.DEFAULT) {
          await webAuth.signup(
            {
              connection: params.connection,
              email: params.email,
              password: params.password,
            },
            async (error) => {
              if (error != null) {
                const errorString = buildAuth0ErrorString(error);
                logAuth0Error(error, params.email);
                setAuthError(errorString);
                setIsWaitingForAuth0Authentication(false);
                return;
              }
              await login(params);
            },
          );
        } else if (params.connection === AuthenticationConnectionName.SSO) {
          // (hchen): Redirect to Auth0 to sign up with any supported SSO
          await webAuth.authorize();
        } else {
          // (hchen): Logging in via Google or enterprise SSO automatically signs up. Specifying a
          // connection when calling webAuth.authorize skips the step of asking the user for email.
          // The user will be routed to the corresponding enterprise or social login page directly.
          await webAuth.authorize({
            connection: params.connection,
          });
        }
        setLoading(false);
      },
      [logAuth0Error, login, setIsWaitingForAuth0Authentication, webAuth],
    );

    const signUp = useCallback(
      async (params: DefaultLoginParams | SocialLoginParams) => {
        setLoading(true);
        setIsWaitingForAuth0Authentication(true);
        await signUpAuth0(params);
      },
      [setIsWaitingForAuth0Authentication, signUpAuth0],
    );

    useEffect(() => {
      // (hchen): Store the authorizationCode as a state so that we don't lose it when the browser
      // navigates to somewhere else.
      if (authorizationCodeInUrl != null && authorizationCodeInUrl.length > 0) {
        setAuth0AuthorizationCode(authorizationCodeInUrl);
        setIsWaitingForAuth0Authentication(true);
      }
    }, [authorizationCodeInUrl, setIsWaitingForAuth0Authentication]);

    useEffect(() => {
      if (auth0ErrorInUrl != null) {
        handleError(auth0ErrorInUrl);
      }
    }, [auth0ErrorInUrl, handleError]);

    const consumeNewAuthToken = useCallback(
      (json: { accessToken: string }) => {
        try {
          interface Auth0JWTPaylod {
            iss?: string;
            sub?: string;
            aud?: string[] | string;
            exp?: number;
            nbf?: number;
            iat?: number;
            jti?: string;
            "https://regrello.com/email"?: string;
            "https://regrello.com/email_verified"?: boolean;
          }
          const user = jwtDecode<Auth0JWTPaylod>(json.accessToken);
          localStorageSetTyped(RegrelloLocalStorageKey.AUTH_0_USER_KEY, {
            sub: user.sub ?? EMPTY_STRING,
            email: user["https://regrello.com/email"] ?? EMPTY_STRING,
            token: json.accessToken,
            emailVerified: user["https://regrello.com/email_verified"]?.toString() ?? EMPTY_STRING,
          });
          setAuth0AuthorizationCode(undefined);
          setIsAuthenticated(true);
        } catch (error) {
          if (error instanceof Error) {
            setAuthError(error.toString());
          }
          setIsAuthenticated(false);
          setIsWaitingForAuth0Authentication(false);
          return;
        }
      },
      [setIsAuthenticated, setIsWaitingForAuth0Authentication],
    );

    useAsync(async () => {
      if (!isAuthenticated && auth0AuthorizationCode != null) {
        const { status, json } = await RegrelloRestApiService.getToken(authorizationCodeInUrl);
        if (status === ResponseStatus.NOT_FOUND && "message" in json) {
          const errorMessage = json.message as string;
          handleError(errorMessage);
          setAuthError(errorMessage);
          setIsAuthenticated(false);
          setIsWaitingForAuth0Authentication(false);
          return;
        } else if (status !== ResponseStatus.SUCCESS) {
          handleError(ErrorExchangingAccessToken);
          setAuthError(ErrorExchangingAccessToken);
          setIsAuthenticated(false);
          setIsWaitingForAuth0Authentication(false);
          return;
        }

        consumeNewAuthToken(json);
      }
    }, [auth0AuthorizationCode, isAuthenticated, setIsAuthenticated]);

    useEffect(() => {
      if (isAuthenticated) {
        const REFRESH_INTERVAL = MILLISECONDS_IN_MINUTE;
        const refreshFn = async () => {
          const auth0UserV2 = localStorageGetTyped(RegrelloLocalStorageKey.AUTH_0_USER_KEY);
          const token = auth0UserV2?.token;
          if (token != null) {
            const jwtClaim = parseJwtClaim<RegrelloJWTClaim>(token);
            // only refresh Regrello JWTs that are of type SESSION_TOKEN
            if (jwtClaim[RegrelloJWTClaimKeys.aud].indexOf(REGRELLO_SESSION_TOKEN_AUDIENCE) !== -1) {
              const nowUnixTimeSeconds = Math.round(new Date().getTime() / MILLISECONDS_PER_SECOND);
              const oneDayForwardUnixTimeSeconds = nowUnixTimeSeconds + SECONDS_IN_DAY;
              const isJwtGoingToExpireInTheNextDay = jwtClaim.exp < oneDayForwardUnixTimeSeconds;
              if (isJwtGoingToExpireInTheNextDay) {
                // request a new token
                const { status, json } = await RegrelloRestApiService.refreshToken(token);
                if (status !== ResponseStatus.SUCCESS) {
                  setIsAuthenticated(false);
                  setIsWaitingForAuth0Authentication(false);
                  return;
                }
                consumeNewAuthToken(json);
              }
            }
          }
        };
        const intervalId = setInterval(refreshFn, REFRESH_INTERVAL);
        return () => clearInterval(intervalId);
      }
      return () => undefined;
    }, [isAuthenticated, consumeNewAuthToken, setIsAuthenticated, setIsWaitingForAuth0Authentication]);

    useEffect(() => {
      const redirectTo = sessionStorageGetTyped(RegrelloSessionStorageKey.REDIRECT_URL_AFTER_LOGIN);
      if (!isAuthenticated && redirectTo == null) {
        sessionStorageSetTyped(RegrelloSessionStorageKey.REDIRECT_URL_AFTER_LOGIN, window.location.toString());
      }
      if (isAuthenticated && redirectTo != null) {
        sessionStorageRemoveTyped(RegrelloSessionStorageKey.REDIRECT_URL_AFTER_LOGIN);
        window.location.replace(redirectTo);
      }
    }, [isAuthenticated]);

    return (
      <RegrelloAuthenticationProviderInternal
        value={{
          clearError,
          loading,
          login,
          logout,
          authError,
          requestResetPassword,
          resendInvite,
          signUp,
        }}
      >
        {children}
      </RegrelloAuthenticationProviderInternal>
    );
  },
);

function buildAuth0ErrorString(error: Auth0Error) {
  switch (error.error) {
    case "access_denied":
      return error.description ?? "empty description";
    case "too_many_attempts":
      return AuthenticationErrorTooManyAttempts;
    default:
      return `${error.error}: ${error.description ?? "empty description"}`;
  }
}
