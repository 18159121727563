import { ApolloError, FetchResult, ServerError } from "@apollo/client";
import { isDefined } from "@regrello/core-utils";
import { NetworkErrorFailedToFetch } from "@regrello/ui-strings";
import { GraphQLError } from "graphql";

import { ErrorMessagePrefix } from "../constants/errors";
import { HttpStatusCode } from "../constants/HttpStatusCodes";

// Apollo error types: https://www.apollographql.com/docs/react/data/error-handling/

/** GraphQL error `code`s that may be returned by the Regrello server. */
// These should stay in sync with the error codes defined on the backend (see common.go).
/** @knipignore */
export enum RegrelloGraphQlErrorCode {
  ACTION_ITEM_TEMPLATE_BAD_REQUEST = "ACTION_ITEM_TEMPLATE_BAD_REQUEST",
  ACTION_ITEM_ZERO_ASSIGNEES = "ACTION_ITEM_ZERO_ASSIGNEES",
  VALIDATE_ACTION_ITEM_ZERO_ASSIGNEES = "ValidationErrorCodeActionItemZeroAssignee",
  ERR_CODE_ACTION_ITEM_TEMPLATE_DUE_DATE_ORDER_VIOLATION = "ERR_CODE_ACTION_ITEM_TEMPLATE_DUE_DATE_ORDER_VIOLATION",
  BAD_REQUEST = "BAD_REQUEST",
  DUPLICATE_FIELDS_NOT_ALLOWED = "DUPLICATE_FIELDS_NOT_ALLOWED",
  OBJECT_DESCRIPTION_TOO_LONG = "OBJECT_DESCRIPTION_TOO_LONG",
  OBJECT_NAME_MUST_BE_UNIQUE = "OBJECT_NAME_MUST_BE_UNIQUE",
  OBJECT_NAME_MUST_NOT_BE_EMPTY = "OBJECT_NAME_MUST_NOT_BE_EMPTY",
  OBJECT_NAME_TOO_LONG = "OBJECT_NAME_TOO_LONG",
  OBJECT_NOT_FOUND_BY_ID = "OBJECT_NOT_FOUND_BY_ID",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  WORKFLOW_TEMPLATE_MUST_CONTAIN_STAGE_WITH_ACTION_ITEM = "ERR_CODE_WORKFLOW_TEMPLATE_MUST_CONTAIN_STAGE_WITH_ACTION_ITEM",
  FIELD_INSTANCE_MUST_BE_COMPLETED = "FIELD_INSTANCE_MUST_BE_COMPLETED",
  WORKFLOW_ACTION_ITEM_TEMPLATE_REQUIRES_1_ASSIGNEE = "WORKFLOW_ACTION_ITEM_TEMPLATE_REQUIRES_1_ASSIGNEE",
  TEAM_NAME_ALREADY_EXISTS = "TEAM_NAME_ALREADY_EXISTS",
  TEAM_EMAIL_ALREADY_EXISTS = "TEAM_EMAIL_ALREADY_EXISTS",
  ACTION_ITEM_INVALID_DOCUMENT = "ACTION_ITEM_INVALID_DOCUMENT",
  SPECTRUM_FIELD_CONSTRAINT_MIN_CHARACTERS = "SPECTRUM_FIELD_CONSTRAINT_MIN_CHARACTERS",
  SPECTRUM_FIELD_CONSTRAINT_MAX_CHARACTERS = "SPECTRUM_FIELD_CONSTRAINT_MAX_CHARACTERS",
  SPECTRUM_FIELD_CONSTRAINT_EMAIL = "SPECTRUM_FIELD_CONSTRAINT_EMAIL",
  STATUS_HISTORY_NOT_FOUND = "ACTION_ITEM_ACTIVE_STATUS_HISTORY_NOT_FOUND",
  VALIDATION_ERROR_CODE_NAME_CONFLICT = "ValidationErrorCodeNameConflict",
  WORKFLOW_OWNER_MUST_BE_INTERNAL = "WORKFLOW_OWNER_MUST_BE_INTERNAL",
  WORKFLOW_OWNER_CONTROLLED_BY_PARENT = "ValidationErrorCodeCannotUpdateWorkflowOwnerDirectlyIfControledByCrossWorkflowField",
}

export enum RegrelloNetworkErrorMessage {
  CURRENT_SESSION_TENANT_ID_AND_USER_TENANT_ID_DO_NOT_MATCH = "Current session Tenant ID and User TenantID do not match",
}

/**
 * (hchen): The extended part is what contains the useful information about the error. It's included
 * in the object but not typed properly by Apollo.
 */
export interface NetworkErrorResult {
  data: unknown;
  errors: Array<{
    message: string | undefined;
    extensions: {
      code: string | undefined;
    };
  }>;
}

/**
 * Returns true if the provided Apollo error's message starts with the provided message prefix.
 * This is a hacky mechanism that we'll need to keep in place until the following is fixed:
 * https://app.regrello.com/assigned-tasks?task-id=5e72de18-05e8-4ef0-b320-8f4a9ec4fb3f
 */
export function isApolloErrorStartsWith(error: ApolloError, messagePrefix: ErrorMessagePrefix) {
  return error.message.startsWith(messagePrefix);
}

export function isApolloNetworkErrorWithStatus(error: ApolloError, httpStatusCode: HttpStatusCode) {
  return (error.networkError as ServerError | null)?.statusCode === httpStatusCode;
}

export function isApolloNetworkErrorFailedToFetch(error: ApolloError) {
  return (error.networkError as ServerError | null)?.message === NetworkErrorFailedToFetch;
}
/**
 * Returns the error `code` from the first GraphQL error found in the provided `ApolloError`. This
 * code can then be used to craft a human-readable error message.
 *
 * @example
 * const code = getGraphQlErrorCode(error);
 * if (code === "OBJECT_NAME_MUST_BE_UNIQUE") {
 *   setErrorMessage("A workflow with that name already exists.");
 * } else {
 *   setErrorMessage("An unknown error occurred.")
 * }
 */
export function getGraphQlErrorCode(error: ApolloError | GraphQLError): string | undefined {
  const graphQlError = extractGraphQlError(error);
  if (graphQlError?.extensions?.code == null) {
    return undefined;
  }
  return String(graphQlError.extensions.code);
}

/**
 * Extract the first GraphQL error found in the provided `ApolloError`. If the error is already a GraphQL error, return
 * as is.
 */
function extractGraphQlError(error: ApolloError | GraphQLError): GraphQLError | undefined {
  if (error instanceof ApolloError) {
    if (error.graphQLErrors.length === 0) {
      return undefined;
    }

    return error.graphQLErrors[0];
  }

  return error;
}

export function graphqlErrorsFromResults(updateResults: FetchResult[]) {
  return updateResults.flatMap((result) => result.errors).filter(isDefined);
}

/**
 * Returns the error message from the first network error found in the provided `ApolloError`. This
 * message is returned by the BE and provides more detail than a general network error like "Failed
 * to fetch". It can then be used to craft a human-readable error message.
 */
export function getDetailedMessageIfNetworkError(apolloError: ApolloError): string | undefined {
  if (apolloError.networkError == null) {
    return undefined;
  }

  const networkError = apolloError.networkError as ServerError;
  const networkErrorResult = networkError.result as NetworkErrorResult | undefined;
  if (networkErrorResult == null) {
    return undefined;
  }
  const errors = (networkError.result as NetworkErrorResult).errors;
  if (errors.length === 0) {
    return undefined;
  }
  const error = errors[0];
  return error.message ?? undefined;
}
