// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { WorkflowTemplatePrimitiveFields } from '../../fragments/generated/WorkflowTemplatePrimitiveFields.generated';
import { UserBaseFields } from '../../fragments/generated/UserBaseFields.generated';
import { WorkflowTemplatePermissionsFields } from '../../fragments/generated/WorkflowTemplatePermissionsFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkflowTemplatesListQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  includeDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  types?: Types.InputMaybe<Array<Types.WorkflowTemplateType>>;
  permissions?: Types.InputMaybe<Array<Types.WorkflowTemplateAction>>;
  usingAutomationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  usingFieldIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type WorkflowTemplatesListQuery = { paginatedWorkflowTemplates: { totalCount: number, workflowTemplates: Array<WorkflowTemplatePrimitiveFields> } };


export const WorkflowTemplatesListQueryDocument = gql`
    query WorkflowTemplatesListQuery($id: ID, $includeDeleted: Boolean, $types: [WorkflowTemplateType!], $permissions: [WorkflowTemplateAction!], $usingAutomationIds: [ID!], $usingFieldIds: [ID!], $search: String, $offset: Int, $limit: Int) {
  paginatedWorkflowTemplates(
    id: $id
    includeDeleted: $includeDeleted
    types: $types
    permissions: $permissions
    usingAutomationIds: $usingAutomationIds
    usingFieldIds: $usingFieldIds
    search: $search
    offset: $offset
    limit: $limit
  ) {
    totalCount
    workflowTemplates {
      ...WorkflowTemplatePrimitiveFields
    }
  }
}
    ${WorkflowTemplatePrimitiveFields}
${UserBaseFields}
${WorkflowTemplatePermissionsFields}`;

/**
 * __useWorkflowTemplatesListQuery__
 *
 * To run a query within a React component, call `useWorkflowTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowTemplatesListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeDeleted: // value for 'includeDeleted'
 *      types: // value for 'types'
 *      permissions: // value for 'permissions'
 *      usingAutomationIds: // value for 'usingAutomationIds'
 *      usingFieldIds: // value for 'usingFieldIds'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWorkflowTemplatesListQuery(baseOptions?: Apollo.QueryHookOptions<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>(WorkflowTemplatesListQueryDocument, options);
      }
export function useWorkflowTemplatesListQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>(WorkflowTemplatesListQueryDocument, options);
        }
export function useWorkflowTemplatesListQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>(WorkflowTemplatesListQueryDocument, options);
        }
export type WorkflowTemplatesListQueryHookResult = ReturnType<typeof useWorkflowTemplatesListQuery>;
export type WorkflowTemplatesListQueryLazyQueryHookResult = ReturnType<typeof useWorkflowTemplatesListQueryLazyQuery>;
export type WorkflowTemplatesListQuerySuspenseQueryHookResult = ReturnType<typeof useWorkflowTemplatesListQuerySuspenseQuery>;
export type WorkflowTemplatesListQueryResult = Apollo.QueryResult<WorkflowTemplatesListQuery, WorkflowTemplatesListQueryVariables>;