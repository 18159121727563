// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormInstanceSectionMetadataFields } from './FormInstanceSectionMetadataFields.generated';
import { gql } from '@apollo/client';
export type FormSectionStatusFields = { formSectionID: number, status: Types.FormSectionStatusString, metadata: Array<FormInstanceSectionMetadataFields> };

export const FormSectionStatusFields = gql`
    fragment FormSectionStatusFields on FormSectionStatus {
  formSectionID
  status
  metadata {
    ...FormInstanceSectionMetadataFields
  }
}
    `;