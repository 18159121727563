// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceMultiValueFloatFields = (
  { sourceFieldInstanceMultiValueFloat?: AcyclicFieldInstanceMultiValueFloatFields | null, sinksFieldInstanceMultiValueFloat: Array<AcyclicFieldInstanceMultiValueFloatFields> }
  & FieldInstanceMultiValueFloatBaseFields
);

export const FieldInstanceMultiValueFloatFields = gql`
    fragment FieldInstanceMultiValueFloatFields on FieldInstanceMultiValueFloat {
  ...FieldInstanceMultiValueFloatBaseFields
  sourceFieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
  sinksFieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
}
    `;