import { ApolloError } from "@apollo/client";
import { EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { useCreateDocumentMutation } from "@regrello/graphql-api";
import { AddLink, FileName, Link as LinkMessage } from "@regrello/ui-strings";
import React, { useCallback } from "react";

import { ValidationRules } from "../../../../constants/globalConstants";
import { CreatedDocument } from "../../../../types";
import { SubmitHandler } from "../../../../types/form";
import { RegrelloFormDialogName } from "../../../../utils/sentryScopeUtils";
import { RegrelloFormDialog } from "../../../atoms/dialog/RegrelloFormDialog";
import { RegrelloControlledFormFieldText } from "../controlled/RegrelloControlledFormFieldText";

export interface RegrelloPasteDocumentLinkDialoFormFields {
  fileName: string;
  fileUrl: string;
}

const DEFAULT_VALUES: RegrelloPasteDocumentLinkDialoFormFields = {
  fileName: EMPTY_STRING,
  fileUrl: EMPTY_STRING,
};

export interface RegrelloPasteDocumentLinkDialogProps {
  /** Whether the dialog is currently open. */
  isOpen: boolean;

  /** Callback invoked when the dialog wants to close. */
  onClose: () => void;

  /** Callback invoked when submission fails due to an error. */
  onError: (error: ApolloError) => void;

  /** Callback invoked when the dialog is succesfully submitted. */
  onSubmit: (createdDocument: CreatedDocument) => void;
}

/** A form dialog that allows the user to paste a document link, give it a name, and upload it. */
export const RegrelloPasteDocumentLinkDialog = React.memo<RegrelloPasteDocumentLinkDialogProps>(
  function RegrelloPasteDocumentLinkDialogFn({ isOpen, onClose, onError, onSubmit }) {
    const [createAttachmentAsync] = useCreateDocumentMutation({ onError });

    const handleSubmit: SubmitHandler<RegrelloPasteDocumentLinkDialoFormFields> = useCallback(
      async (data) => {
        // (hchen): data.fieldDefinition and data.fieldName are always strings. If data.fieldDefinition ===
        // null, the validation won't pass and this function won't execute.

        const response = await createAttachmentAsync({
          variables: {
            filename: data.fileName,
            externalLink: data.fileUrl,
          },
        });

        if (response.errors != null || response.data?.createDocument?.document == null) {
          return false;
        }

        onSubmit(response.data.createDocument);
        return true;
      },
      [createAttachmentAsync, onSubmit],
    );

    return (
      <RegrelloFormDialog
        dataTestId={DataTestIds.FORM_FIELD_DOCUMENT_ADD_LINK_DIALOG}
        defaultValues={DEFAULT_VALUES}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        scopeNameForSentry={RegrelloFormDialogName.ADD_DOCUMENT_LINK_DIALOG}
        showRequiredInstruction={true}
        submitButtonText={AddLink}
        title={AddLink}
      >
        {(form) => (
          <>
            <RegrelloControlledFormFieldText
              autoFocus={true}
              controllerProps={{
                control: form.control,
                name: "fileName",
                rules: {
                  ...ValidationRules.REQUIRED,
                },
              }}
              dataTestId={DataTestIds.FORM_FIELD_DOCUMENT_ADD_LINK_DIALOG_FILE_NAME}
              isRequiredAsteriskShown={true}
              label={FileName}
            />
            <RegrelloControlledFormFieldText
              controllerProps={{
                control: form.control,
                name: "fileUrl",
                rules: {
                  ...ValidationRules.REQUIRED,
                  ...ValidationRules.VALID_URL_WITH_OPTIONAL_SCHEME,
                },
              }}
              dataTestId={DataTestIds.FORM_FIELD_DOCUMENT_ADD_LINK_DIALOG_FILE_URL}
              isRequiredAsteriskShown={true}
              label={LinkMessage}
            />
          </>
        )}
      </RegrelloFormDialog>
    );
  },
);
