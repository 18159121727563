// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { ActionItemTemplateStartAfterActionItemTemplateFields } from './ActionItemTemplateStartAfterActionItemTemplateFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { FieldInstanceFields } from './FieldInstanceFields.generated';
import { FieldInstanceBaseFields } from './FieldInstanceBaseFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { FieldInstanceSpectrumMetadataFields } from './FieldInstanceSpectrumMetadataFields.generated';
import { FieldInstanceSpectrumFields } from './FieldInstanceSpectrumFields.generated';
import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldInstanceValueFieldsFieldInstanceMultiValueDocument, FieldInstanceValueFieldsFieldInstanceMultiValueFloat, FieldInstanceValueFieldsFieldInstanceMultiValueInt, FieldInstanceValueFieldsFieldInstanceMultiValueParty, FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, FieldInstanceValueFieldsFieldInstanceMultiValueString, FieldInstanceValueFieldsFieldInstanceMultiValueTime, FieldInstanceValueFieldsFieldInstanceValueBoolean, FieldInstanceValueFieldsFieldInstanceValueFloat, FieldInstanceValueFieldsFieldInstanceValueInt, FieldInstanceValueFieldsFieldInstanceValueParty, FieldInstanceValueFieldsFieldInstanceValueString, FieldInstanceValueFieldsFieldInstanceValueTime } from './FieldInstanceValueFields.generated';
import { FieldInstanceValueStringFields } from './FieldInstanceValueStringFields.generated';
import { FieldInstanceValueStringBaseFields } from './FieldInstanceValueStringBaseFields.generated';
import { AcyclicFieldInstanceValueStringFields } from './AcyclicFieldInstanceValueStringFields.generated';
import { FieldInstanceMultiValueStringFields } from './FieldInstanceMultiValueStringFields.generated';
import { FieldInstanceMultiValueStringBaseFields } from './FieldInstanceMultiValueStringBaseFields.generated';
import { AcyclicFieldInstanceMultiValueStringFields } from './AcyclicFieldInstanceMultiValueStringFields.generated';
import { FieldInstanceValueIntFields } from './FieldInstanceValueIntFields.generated';
import { FieldInstanceValueIntBaseFields } from './FieldInstanceValueIntBaseFields.generated';
import { AcyclicFieldInstanceValueIntFields } from './AcyclicFieldInstanceValueIntFields.generated';
import { FieldInstanceValueFloatFields } from './FieldInstanceValueFloatFields.generated';
import { FieldInstanceValueFloatBaseFields } from './FieldInstanceValueFloatBaseFields.generated';
import { AcyclicFieldInstanceValueFloatFields } from './AcyclicFieldInstanceValueFloatFields.generated';
import { FieldInstanceValueBooleanFields } from './FieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueBooleanBaseFields } from './FieldInstanceValueBooleanBaseFields.generated';
import { AcyclicFieldInstanceValueBooleanFields } from './AcyclicFieldInstanceValueBooleanFields.generated';
import { FieldInstanceValueTimeFields } from './FieldInstanceValueTimeFields.generated';
import { FieldInstanceValueTimeBaseFields } from './FieldInstanceValueTimeBaseFields.generated';
import { AcyclicFieldInstanceValueTimeFields } from './AcyclicFieldInstanceValueTimeFields.generated';
import { AcyclicFieldInstanceFields } from './AcyclicFieldInstanceFields.generated';
import { AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString, AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime, AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean, AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat, AcyclicFieldInstanceValueFieldsFieldInstanceValueInt, AcyclicFieldInstanceValueFieldsFieldInstanceValueParty, AcyclicFieldInstanceValueFieldsFieldInstanceValueString, AcyclicFieldInstanceValueFieldsFieldInstanceValueTime } from './AcyclicFieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValuePartyFields } from './AcyclicFieldInstanceMultiValuePartyFields.generated';
import { AcyclicFieldInstanceMultiValueDocumentFields } from './AcyclicFieldInstanceMultiValueDocumentFields.generated';
import { DocumentBaseFields } from './DocumentBaseFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from './AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { AcyclicFieldInstanceMultiValueIntFields } from './AcyclicFieldInstanceMultiValueIntFields.generated';
import { AcyclicFieldInstanceMultiValueFloatFields } from './AcyclicFieldInstanceMultiValueFloatFields.generated';
import { AcyclicFieldInstanceMultiValueTimeFields } from './AcyclicFieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceValuePartyFields } from './FieldInstanceValuePartyFields.generated';
import { FieldInstanceMultiValuePartyFields } from './FieldInstanceMultiValuePartyFields.generated';
import { FieldInstanceMultiValueDocumentFields } from './FieldInstanceMultiValueDocumentFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from './FieldInstanceMultiValueRegrelloObjectInstanceFields.generated';
import { FieldInstanceMultiValueIntFields } from './FieldInstanceMultiValueIntFields.generated';
import { FieldInstanceMultiValueIntBaseFields } from './FieldInstanceMultiValueIntBaseFields.generated';
import { FieldInstanceMultiValueFloatFields } from './FieldInstanceMultiValueFloatFields.generated';
import { FieldInstanceMultiValueFloatBaseFields } from './FieldInstanceMultiValueFloatBaseFields.generated';
import { FieldInstanceMultiValueTimeFields } from './FieldInstanceMultiValueTimeFields.generated';
import { FieldInstanceMultiValueTimeBaseFields } from './FieldInstanceMultiValueTimeBaseFields.generated';
import { gql } from '@apollo/client';
import { FieldInstanceValueFields } from './FieldInstanceValueFields.generated';
import { AcyclicFieldInstanceValueFields } from './AcyclicFieldInstanceValueFields.generated';
export type ActionItemTemplateStartAfterActionItemTemplateChain = { startAfterActionItemTemplate?: (
    { startAfterActionItemTemplate?: (
      { startAfterActionItemTemplate?: (
        { startAfterActionItemTemplate?: (
          { startAfterActionItemTemplate?: (
            { startAfterActionItemTemplate?: (
              { startAfterActionItemTemplate?: (
                { startAfterActionItemTemplate?: (
                  { startAfterActionItemTemplate?: (
                    { startAfterActionItemTemplate?: (
                      { startAfterActionItemTemplate?: (
                        { startAfterActionItemTemplate?: (
                          { startAfterActionItemTemplate?: (
                            { startAfterActionItemTemplate?: (
                              { startAfterActionItemTemplate?: (
                                { startAfterActionItemTemplate?: (
                                  { startAfterActionItemTemplate?: (
                                    { startAfterActionItemTemplate?: (
                                      { startAfterActionItemTemplate?: (
                                        { startAfterActionItemTemplate?: ActionItemTemplateStartAfterActionItemTemplateFields | null }
                                        & ActionItemTemplateStartAfterActionItemTemplateFields
                                      ) | null }
                                      & ActionItemTemplateStartAfterActionItemTemplateFields
                                    ) | null }
                                    & ActionItemTemplateStartAfterActionItemTemplateFields
                                  ) | null }
                                  & ActionItemTemplateStartAfterActionItemTemplateFields
                                ) | null }
                                & ActionItemTemplateStartAfterActionItemTemplateFields
                              ) | null }
                              & ActionItemTemplateStartAfterActionItemTemplateFields
                            ) | null }
                            & ActionItemTemplateStartAfterActionItemTemplateFields
                          ) | null }
                          & ActionItemTemplateStartAfterActionItemTemplateFields
                        ) | null }
                        & ActionItemTemplateStartAfterActionItemTemplateFields
                      ) | null }
                      & ActionItemTemplateStartAfterActionItemTemplateFields
                    ) | null }
                    & ActionItemTemplateStartAfterActionItemTemplateFields
                  ) | null }
                  & ActionItemTemplateStartAfterActionItemTemplateFields
                ) | null }
                & ActionItemTemplateStartAfterActionItemTemplateFields
              ) | null }
              & ActionItemTemplateStartAfterActionItemTemplateFields
            ) | null }
            & ActionItemTemplateStartAfterActionItemTemplateFields
          ) | null }
          & ActionItemTemplateStartAfterActionItemTemplateFields
        ) | null }
        & ActionItemTemplateStartAfterActionItemTemplateFields
      ) | null }
      & ActionItemTemplateStartAfterActionItemTemplateFields
    ) | null }
    & ActionItemTemplateStartAfterActionItemTemplateFields
  ) | null };

export const ActionItemTemplateStartAfterActionItemTemplateChain = gql`
    fragment ActionItemTemplateStartAfterActionItemTemplateChain on ActionItemTemplate {
  startAfterActionItemTemplate {
    ...ActionItemTemplateStartAfterActionItemTemplateFields
    startAfterActionItemTemplate {
      ...ActionItemTemplateStartAfterActionItemTemplateFields
      startAfterActionItemTemplate {
        ...ActionItemTemplateStartAfterActionItemTemplateFields
        startAfterActionItemTemplate {
          ...ActionItemTemplateStartAfterActionItemTemplateFields
          startAfterActionItemTemplate {
            ...ActionItemTemplateStartAfterActionItemTemplateFields
            startAfterActionItemTemplate {
              ...ActionItemTemplateStartAfterActionItemTemplateFields
              startAfterActionItemTemplate {
                ...ActionItemTemplateStartAfterActionItemTemplateFields
                startAfterActionItemTemplate {
                  ...ActionItemTemplateStartAfterActionItemTemplateFields
                  startAfterActionItemTemplate {
                    ...ActionItemTemplateStartAfterActionItemTemplateFields
                    startAfterActionItemTemplate {
                      ...ActionItemTemplateStartAfterActionItemTemplateFields
                      startAfterActionItemTemplate {
                        ...ActionItemTemplateStartAfterActionItemTemplateFields
                        startAfterActionItemTemplate {
                          ...ActionItemTemplateStartAfterActionItemTemplateFields
                          startAfterActionItemTemplate {
                            ...ActionItemTemplateStartAfterActionItemTemplateFields
                            startAfterActionItemTemplate {
                              ...ActionItemTemplateStartAfterActionItemTemplateFields
                              startAfterActionItemTemplate {
                                ...ActionItemTemplateStartAfterActionItemTemplateFields
                                startAfterActionItemTemplate {
                                  ...ActionItemTemplateStartAfterActionItemTemplateFields
                                  startAfterActionItemTemplate {
                                    ...ActionItemTemplateStartAfterActionItemTemplateFields
                                    startAfterActionItemTemplate {
                                      ...ActionItemTemplateStartAfterActionItemTemplateFields
                                      startAfterActionItemTemplate {
                                        ...ActionItemTemplateStartAfterActionItemTemplateFields
                                        startAfterActionItemTemplate {
                                          ...ActionItemTemplateStartAfterActionItemTemplateFields
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;