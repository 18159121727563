// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { gql } from '@apollo/client';
export type AdminRoleFields = { id: number, name: string, isSystem: boolean, createdAt: string, deletedAt?: string | null, updatedAt: string, canInviteUsersToWorkspace: boolean, scimId?: string | null, createdByUser?: UserFields | null };

export const AdminRoleFields = gql`
    fragment AdminRoleFields on Role {
  id
  name
  isSystem
  createdAt
  deletedAt
  updatedAt
  canInviteUsersToWorkspace
  scimId
  createdByUser {
    ...UserFields
  }
}
    `;