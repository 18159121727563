// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { gql } from '@apollo/client';
export type FormMinimalFields = { id: number, uuid: string, createdAt: string, deletedAt?: string | null, publishedFromFormID?: number | null, createdBy: { id: number } };

export const FormMinimalFields = gql`
    fragment FormMinimalFields on Form {
  id
  uuid
  createdAt
  deletedAt
  publishedFromFormID
  createdBy {
    id
  }
}
    `;