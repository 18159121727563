import {
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  PropertyDataType,
} from "@regrello/graphql-api";

import { getErrorMessageWithPayload } from "../../../../../utils/getErrorMessageWithPayload";
import { CheckboxFieldPlugin } from "../CheckboxFieldPlugin";
import { CurrencyFieldPlugin } from "../CurrencyFieldPlugin";
import { DateFieldPlugin } from "../DateFieldPlugin";
import { DocumentFieldPlugin } from "../DocumentFieldPlugin";
import { MultiSelectFieldPlugin } from "../MultiSelectFieldPlugin";
import { NumberFieldPlugin } from "../NumberFieldPlugin";
import { RegrelloObjectFieldPlugin } from "../RegrelloObjectFieldPlugin";
import { SelectFieldPlugin } from "../SelectFieldPlugin";
import { TextFieldPlugin } from "../TextFieldPlugin";
import { CustomFieldPlugin } from "../types/CustomFieldPlugin";
import { UserFieldPlugin } from "../UserFieldPlugin";

export const CustomFieldPluginRegistrar = {
  /**
   * A hardcoded list of known custom-field plugins. When introducing a new custom field, all you have
   * to do is add it to this array, and it'll work throughout the frontend.
   */
  getAllPlugins(): Array<CustomFieldPlugin<unknown>> {
    return [
      CheckboxFieldPlugin,
      CurrencyFieldPlugin,
      DateFieldPlugin,
      DocumentFieldPlugin,
      MultiSelectFieldPlugin,
      NumberFieldPlugin,
      RegrelloObjectFieldPlugin,
      SelectFieldPlugin,
      TextFieldPlugin,
      UserFieldPlugin,
    ];
  },

  /**
   * Returns the one custom-field plugin that is compatible with the provided field.
   *
   * @throws if no compatible custom-field plugin is found, or if multiple are found
   */
  getPluginForField(field: FieldFields): CustomFieldPlugin<unknown> {
    const matches: Array<CustomFieldPlugin<unknown>> = [];

    for (const customFieldPlugin of CustomFieldPluginRegistrar.getAllPlugins()) {
      if (customFieldPlugin.canProcessField(field)) {
        matches.push(customFieldPlugin);
      }
    }

    if (matches.length === 0) {
      throw new Error(
        getErrorMessageWithPayload(
          "Could not find a custom-field plugin that was able to process the current field. " +
            "This is a developer error. " +
            "Please review the `canProcessField` logic in all custom-field plugins.",
          { field },
        ),
      );
    }

    if (matches.length > 1) {
      throw new Error(
        getErrorMessageWithPayload(
          "Multiple custom-field plugins claimed to be able to process the current field. " +
            "Only one custom-field plugin should be able to process any given field. " +
            "This is a developer error. " +
            "Please review the `canProcessField` logic in the custom-field plugins mentioned here.",
          {
            field,
            ostensiblyCompatibleCustomFieldPluginUris: matches.map(({ uri }) => uri),
          },
        ),
      );
    }

    return matches[0];
  },

  /**
   * Returns the one custom-field plugin that is compatible with the provided field instance.
   *
   * @throws if no compatible custom-field plugin is found, or if multiple are found
   */
  getPluginForFieldInstance(
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): CustomFieldPlugin<unknown> {
    const matches: Array<CustomFieldPlugin<unknown>> = [];

    for (const customFieldPlugin of CustomFieldPluginRegistrar.getAllPlugins()) {
      if (customFieldPlugin.canProcessFieldInstance(fieldInstance)) {
        matches.push(customFieldPlugin);
      }
    }

    if (matches.length === 0) {
      throw new Error(
        getErrorMessageWithPayload(
          "Could not find a custom-field plugin that was able to process the current field instance. " +
            "This is a developer error. " +
            "Please review the `canProcessFieldInstance` logic in all custom-field plugins.",
          { fieldInstance },
        ),
      );
    }

    if (matches.length > 1) {
      throw new Error(
        getErrorMessageWithPayload(
          "Multiple custom-field plugins claimed to be able to process the current field instance. " +
            "Only one custom-field plugin should be able to process any given field instance. " +
            "This is a developer error. " +
            "Please review the `canProcessFieldInstance` logic in the custom-field plugins mentioned here.",
          {
            fieldInstance,
            ostensiblyCompatibleCustomFieldPluginUris: matches.map(({ uri }) => uri),
          },
        ),
      );
    }

    return matches[0];
  },

  /**
   * Returns an array of custom-field plugins that are compatible with the provided
   * `PropertyDataType`. This is useful for determining which select field options (or form fields
   * in general) should be shown to users without having a `FieldInstanceFields` handy for providing
   * to the `getPluginForFieldInstance` function.
   *
   * It may also return an empty array.
   */
  getPluginsForPropertyDataType(propertyDataType: PropertyDataType): Array<CustomFieldPlugin<unknown>> {
    const matches: Array<CustomFieldPlugin<unknown>> = [];

    for (const customFieldPlugin of CustomFieldPluginRegistrar.getAllPlugins()) {
      if (customFieldPlugin.canProcessPropertyDataType(propertyDataType)) {
        matches.push(customFieldPlugin);
      }
    }

    return matches;
  },
};
