// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { gql } from '@apollo/client';
export type CommentFields = { id: number, text: string, createdAt: string, createdByUnknownEmail?: string | null, hasCurrentUserRead: boolean, visibilityType: Types.CommentVisibilityType, mentioningActionItemDeepLink?: string | null, mentioningActionItemName?: string | null, createdBy: { id: number }, documents: Array<DocumentFields> };

export const CommentFields = gql`
    fragment CommentFields on Comment {
  id
  text
  createdAt
  createdBy {
    id
  }
  createdByUnknownEmail
  documents {
    ...DocumentFields
  }
  hasCurrentUserRead
  visibilityType
  mentioningActionItemDeepLink
  mentioningActionItemName
}
    `;