// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { ActionItemAuditHistoryDeltaFields } from './ActionItemAuditHistoryDeltaFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { RegrelloObjectInstanceFields } from './RegrelloObjectInstanceFields.generated';
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from './ActionItemAuditHistoryDocumentRelatedRecordFields.generated';
import { DocumentFields } from './DocumentFields.generated';
import { DocumentVersionFields } from './DocumentVersionFields.generated';
import { TagFields } from './TagFields.generated';
import { TagTypeFields } from './TagTypeFields.generated';
import { WorkflowPermissionsFields } from './WorkflowPermissionsFields.generated';
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from './ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated';
import { gql } from '@apollo/client';
export type ActionItemAuditHistoryFields = { id: number, createdAt: string, createdByUnknownEmail?: string | null, event: Types.AuditHistoryEvent, reason?: Types.TaskStatusChangeReason | null, createdBy: PartyBaseFields, fields: Array<ActionItemAuditHistoryDeltaFields> };

export const ActionItemAuditHistoryFields = gql`
    fragment ActionItemAuditHistoryFields on ActionItemAuditHistory {
  id
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  createdByUnknownEmail
  event
  fields {
    ...ActionItemAuditHistoryDeltaFields
  }
  reason
}
    `;