import { EMPTY_ARRAY } from "@regrello/core-utils";
import {
  AcyclicFieldInstanceFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
} from "@regrello/graphql-api";

/**
 * Converts an acyclic field instance (i.e., no references to other field instances in the object)
 * as `FieldInstanceFields` with the missing fields stubbed.
 */
export function getFieldInstanceFieldsFromAcyclicFieldInstanceFields(
  fieldInstance: AcyclicFieldInstanceFields | FieldInstanceFieldsWithBaseValues,
): FieldInstanceFields {
  const newValuesArray: FieldInstanceFields["values"] = [];
  fieldInstance.values.forEach((value) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (value.__typename) {
      case "FieldInstanceMultiValueParty":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceMultiValueParty: undefined,
          sinksFieldInstanceMultiValueParty: EMPTY_ARRAY,
          sourceFieldInstanceMultiValuePartyV2: undefined,
        });
        break;
      case "FieldInstanceMultiValueString":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceMultiValueString: undefined,
          sinksFieldInstanceMultiValueString: EMPTY_ARRAY,
        });
        break;
      case "FieldInstanceValueBoolean":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceValueBoolean: undefined,
          sinksFieldInstanceValueBoolean: EMPTY_ARRAY,
        });
        break;
      case "FieldInstanceMultiValueDocument":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceMultiValueDocument: undefined,
          sinksFieldInstanceMultiValueDocument: EMPTY_ARRAY,
        });
        break;
      case "FieldInstanceValueFloat":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceValueFloat: undefined,
          sinksFieldInstanceValueFloat: EMPTY_ARRAY,
        });
        break;
      case "FieldInstanceValueInt":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceValueInt: undefined,
          sinksFieldInstanceValueInt: EMPTY_ARRAY,
        });
        break;
      case "FieldInstanceValueParty":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceValueParty: undefined,
          sinksFieldInstanceValueParty: EMPTY_ARRAY,
        });
        break;
      case "FieldInstanceValueString":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceValueString: undefined,
          sinksFieldInstanceValueString: EMPTY_ARRAY,
        });
        break;
      case "FieldInstanceValueTime":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceValueTime: undefined,
          sinksFieldInstanceValueTime: EMPTY_ARRAY,
          sourceFieldInstanceValueTimeV2: undefined,
        });
        break;
      case "FieldInstanceMultiValueRegrelloObjectInstance":
        newValuesArray.push({
          ...value,
          sourceFieldInstanceMultiValueRegrelloObjectInstance: undefined,
          sinksFieldInstanceMultiValueRegrelloObjectInstance: EMPTY_ARRAY,
        });
        break;
      default:
        break;
    }
  });
  const newFieldInstance = { ...fieldInstance, values: newValuesArray };
  return newFieldInstance;
}
