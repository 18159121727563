// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { gql } from '@apollo/client';
export type ActionItemRegrelloObjectAuditHistoryFields = { createdAt: string, createdBy?: PartyBaseFields | null, changes: Array<{ regrelloObjectId: number, regrelloObjectInstanceId: number, event?: Types.AuditHistoryEvent | null, valueAfter?: Array<{ key: string, value: { stringValue?: string | null } }> | null, valueBefore?: Array<{ key: string, value: { stringValue?: string | null } }> | null }> };

export const ActionItemRegrelloObjectAuditHistoryFields = gql`
    fragment ActionItemRegrelloObjectAuditHistoryFields on RegrelloObjectChangeEvent {
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  changes {
    regrelloObjectId
    regrelloObjectInstanceId
    event
    valueAfter {
      key
      value {
        ... on DataObjectCellValueString {
          stringValue
        }
      }
    }
    valueBefore {
      key
      value {
        ... on DataObjectCellValueString {
          stringValue
        }
      }
    }
  }
}
    `;