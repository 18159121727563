// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { SpectrumFieldVersionFields } from './SpectrumFieldVersionFields.generated';
import { SpectrumFieldFields } from './SpectrumFieldFields.generated';
import { PropertyTypeFields } from './PropertyTypeFields.generated';
import { SpectrumFieldValidationTypeFields } from './SpectrumFieldValidationTypeFields.generated';
import { SpectrumFieldConstraintFields } from './SpectrumFieldConstraintFields.generated';
import { SpectrumValueConstraintFields } from './SpectrumValueConstraintFields.generated';
import { SpectrumFieldAllowedValueFields } from './SpectrumFieldAllowedValueFields.generated';
import { FieldUnitFields } from './FieldUnitFields.generated';
import { FieldFields } from './FieldFields.generated';
import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { RegrelloObjectFields } from './RegrelloObjectFields.generated';
import { AutomationOAuth2ConfigFields } from './AutomationOAuth2ConfigFields.generated';
import { RegrelloObjectPropertyFields } from './RegrelloObjectPropertyFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceSpectrumFields = { formInstanceID?: number | null, formFieldID?: number | null, spectrumFieldVersion?: SpectrumFieldVersionFields | null, latestAvailableSpectrumFieldVersion?: SpectrumFieldVersionFields | null };

export const FieldInstanceSpectrumFields = gql`
    fragment FieldInstanceSpectrumFields on FieldInstance {
  formInstanceID
  formFieldID
  spectrumFieldVersion {
    ...SpectrumFieldVersionFields
  }
  latestAvailableSpectrumFieldVersion {
    ...SpectrumFieldVersionFields
  }
}
    `;