// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserFields } from './UserFields.generated';
import { UserFieldsWithoutRoles } from './UserFieldsWithoutRoles.generated';
import { AccessRoleFields } from './AccessRoleFields.generated';
import { gql } from '@apollo/client';
export type TeamMemberFields = { isAdmin: boolean, user: UserFields };

export const TeamMemberFields = gql`
    fragment TeamMemberFields on TeamMember {
  isAdmin
  user {
    ...UserFields
  }
}
    `;