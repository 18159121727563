import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloLinkV2, RegrelloTypography } from "@regrello/ui-core";
import {
  CheckYourEmail,
  CheckYourEmailForInviteInstructionsListItem1,
  CheckYourEmailForInviteInstructionsListItem2,
  CheckYourEmailForInviteInstructionsListItem3,
  CheckYourEmailForInviteInstructionsListItem4,
  CheckYourEmailForInviteInstructionsListItem5,
  ContactSupportAt,
  CopyrightTitleCased,
  InviteResent,
  PasswordResetEmailSent,
  PrivacyPolicyTitleCased,
  ReturnToLogin,
  ReturnToSupplierLogin,
  VerificationRequiredSentInstruction,
  VerificationRequiredSentInstructionBulletOne,
  VerificationRequiredSentInstructionBulletThree,
  VerificationSentInstruction,
  WhatToDoNext,
} from "@regrello/ui-strings";
import React from "react";

import { REGRELLO_PRIVACY_POLICY_URL, REGRELLO_SUPPORT_EMAIL } from "../../../../../constants/globalConstants";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { RoutePaths, RouteQueryStringKeys } from "../../../../app/routes/consts";

export interface UnauthenticatedEmailSentPageProps {
  /**
   * Whether the email is sent for invitatation or password reset. Different instructions are
   * rendered based on the selection.
   */
  mode: "lostInvite" | "passwordReset" | "verificationSent";
}

export const UnauthenticatedEmailSentPage = React.memo(function UnauthenticatedEmailSentPageFn({
  mode,
}: UnauthenticatedEmailSentPageProps) {
  const { supplier: supplierQueryValue } = useQueryMap();
  const isSupplierMode = supplierQueryValue != null;

  const signInButtonHref = `${RoutePaths.LOGIN}/${
    isSupplierMode ? { [RouteQueryStringKeys.SUPPLIER]: "1" } : undefined
  }`;

  return (
    <div className="flex justify-center w-full h-full" data-testid={DataTestIds.UNAUTHENTICATED_EMAIL_SENT_PAGE_ROOT}>
      <div className="flex flex-col px-12 pb-4">
        <div className="flex flex-col justify-center flex-auto">
          <RegrelloTypography className="mb-10" variant="h1">
            {getHeaderText(mode, isSupplierMode)}
          </RegrelloTypography>

          <RegrelloTypography className="mb-4" variant="body" weight="semi-bold">
            {getDescriptionText(mode, isSupplierMode)}
          </RegrelloTypography>
          <RegrelloTypography>{getInstructions(mode, isSupplierMode)}</RegrelloTypography>

          <div className="mb-10">
            <RegrelloLinkV2 className="font-normal" to={signInButtonHref}>
              {mode === "lostInvite" && isSupplierMode ? ReturnToSupplierLogin : ReturnToLogin}
            </RegrelloLinkV2>
          </div>

          <RegrelloTypography>
            {ContactSupportAt}

            <RegrelloLinkV2 className="font-normal" to={`mailto:${REGRELLO_SUPPORT_EMAIL}`}>
              {REGRELLO_SUPPORT_EMAIL}
            </RegrelloLinkV2>
          </RegrelloTypography>
        </div>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {PrivacyPolicyTitleCased}
          </RegrelloLinkV2>
          {CopyrightTitleCased()}
        </RegrelloTypography>
      </div>
    </div>
  );
});

function getHeaderText(mode: "lostInvite" | "passwordReset" | "verificationSent", isSupplierModeEnabled = false) {
  if (mode === "passwordReset") {
    return PasswordResetEmailSent;
  }
  if (mode === "lostInvite" && isSupplierModeEnabled) {
    return CheckYourEmail;
  }

  return InviteResent;
}

function getDescriptionText(mode: "lostInvite" | "passwordReset" | "verificationSent", isSupplierModeEnabled = false) {
  if (mode === "passwordReset") {
    return VerificationRequiredSentInstruction;
  }
  if (mode === "lostInvite" && isSupplierModeEnabled) {
    return WhatToDoNext;
  }

  return VerificationSentInstruction;
}

function getInstructions(mode: "lostInvite" | "passwordReset" | "verificationSent", isSupplierModeEnabled = false) {
  if (mode === "passwordReset") {
    return (
      <ul className="pl-4 mb-10 list-disc">
        <li className="pb-1">{VerificationRequiredSentInstructionBulletOne()}</li>
        <li>{VerificationRequiredSentInstructionBulletThree}</li>
      </ul>
    );
  }
  if (mode === "lostInvite" && isSupplierModeEnabled) {
    return (
      <ul className="pl-4 mb-10 list-disc">
        <li>{CheckYourEmailForInviteInstructionsListItem1()}</li>
        <li>{CheckYourEmailForInviteInstructionsListItem2()}</li>
        <li>{CheckYourEmailForInviteInstructionsListItem3()}</li>
        <li>{CheckYourEmailForInviteInstructionsListItem4()}</li>
        <li>{CheckYourEmailForInviteInstructionsListItem5()}</li>
      </ul>
    );
  }

  return null;
}
