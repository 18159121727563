import { MutableRefObject, useCallback, useRef, useState } from "react";
import { useUnmount } from "react-use";

import { useSimpleDialog } from "./useSimpleDialog";

export namespace useConfirmationDialog {
  export type Args<T> = {
    /**
     * An asynchronous submit function to be invoked when the confirm button is clicked. This
     * function should return `false` to keep the popover open after submit, or `true` to close the
     * dialog after submit.
     *
     * If `undefined`, the `confirm` function will simply no-op and then close the dialog.
     */
    submitAsync?: ((payload?: T) => Promise<boolean>) | undefined;
  };

  export interface Return<T> {
    isOpen: boolean;
    isSubmitting: boolean;
    open: (payload?: T) => void;
    close: () => void;
    confirm: () => void;
    payload: MutableRefObject<T | undefined>;
  }
}

/**
 * A simple hook that can track and manipulate the "open" state of a dialog. Works well with
 * `RegrelloConfirmationDialogV2`.
 */
export function useConfirmationDialog<T>({
  submitAsync,
}: useConfirmationDialog.Args<T> = {}): useConfirmationDialog.Return<T> {
  const { isOpen, open, close } = useSimpleDialog();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isUnmounted, setIsUnmounted] = useState<boolean>(false);

  const payloadRef = useRef<T | undefined>(undefined);

  const openWithOptionalPayload = useCallback(
    (payload?: T) => {
      payloadRef.current = payload;
      open();
    },
    [open],
  );

  const closeAndClearPayload = useCallback(() => {
    payloadRef.current = undefined;
    close();
  }, [close]);

  const handleConfirm = useCallback(async () => {
    if (submitAsync == null) {
      closeAndClearPayload();
      return;
    }

    setIsSubmitting(true);
    const shouldClose = await submitAsync(payloadRef.current);

    // (clewis): Include this extra check in case the parent component was deleted during the async
    // operation - in which case we shouldn't update component state afterward.
    if (!isUnmounted) {
      setIsSubmitting(false);
    }

    if (shouldClose) {
      closeAndClearPayload();
    }
  }, [closeAndClearPayload, isUnmounted, submitAsync]);

  useUnmount(() => {
    setIsUnmounted(true);
  });

  return {
    isOpen,
    isSubmitting,
    open: openWithOptionalPayload,
    close: closeAndClearPayload,
    confirm: handleConfirm,
    payload: payloadRef,
  };
}
