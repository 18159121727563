// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { PartyBaseFields } from './PartyBaseFields.generated';
import { UserBaseFields } from './UserBaseFields.generated';
import { TeamWithoutMembersFields } from './TeamWithoutMembersFields.generated';
import { AcyclicFieldInstanceValuePartyFields } from './AcyclicFieldInstanceValuePartyFields.generated';
import { gql } from '@apollo/client';
export type FieldInstanceValuePartyFields = { __typename: 'FieldInstanceValueParty', id: number, inputType: Types.FieldInstanceValueInputType, partyValue?: PartyBaseFields | null, sourceFieldInstanceValueParty?: AcyclicFieldInstanceValuePartyFields | null, sinksFieldInstanceValueParty: Array<AcyclicFieldInstanceValuePartyFields> };

export const FieldInstanceValuePartyFields = gql`
    fragment FieldInstanceValuePartyFields on FieldInstanceValueParty {
  __typename
  id
  inputType
  partyValue {
    ...PartyBaseFields
  }
  sourceFieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
  sinksFieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
}
    `;