import { RegrelloTypography } from "@regrello/ui-core";
import React from "react";

/**
 * Helper text to display beneath each field. This include the default top margin as well.
 */
export const RegrelloFormFieldHelperText = React.memo(function RegrelloFormFieldHelperTextFn({
  children,
}: React.PropsWithChildren) {
  return (
    <RegrelloTypography className="mt-1 w-full" muted={true} variant="body-xs">
      {children}
    </RegrelloTypography>
  );
});
