// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { FormsQueryFormFields } from '../../fragments/generated/FormsQueryFormFields.generated';
import { PartyBaseFields } from '../../fragments/generated/PartyBaseFields.generated';
import { UserBaseFields } from '../../fragments/generated/UserBaseFields.generated';
import { TeamWithoutMembersFields } from '../../fragments/generated/TeamWithoutMembersFields.generated';
import { FormMinimalFields } from '../../fragments/generated/FormMinimalFields.generated';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormSelectFormsForBlueprintsQueryVariables = Types.Exact<{
  workflowTemplateID: Types.Scalars['ID']['input'];
}>;


export type FormSelectFormsForBlueprintsQuery = { formsForWorkflowTemplate: Array<FormsQueryFormFields> };


export const FormSelectFormsForBlueprintsQueryDocument = gql`
    query FormSelectFormsForBlueprintsQuery($workflowTemplateID: ID!) {
  formsForWorkflowTemplate(workflowTemplateID: $workflowTemplateID) {
    ...FormsQueryFormFields
  }
}
    ${FormsQueryFormFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${FormMinimalFields}`;

/**
 * __useFormSelectFormsForBlueprintsQuery__
 *
 * To run a query within a React component, call `useFormSelectFormsForBlueprintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSelectFormsForBlueprintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSelectFormsForBlueprintsQuery({
 *   variables: {
 *      workflowTemplateID: // value for 'workflowTemplateID'
 *   },
 * });
 */
export function useFormSelectFormsForBlueprintsQuery(baseOptions: Apollo.QueryHookOptions<FormSelectFormsForBlueprintsQuery, FormSelectFormsForBlueprintsQueryVariables> & ({ variables: FormSelectFormsForBlueprintsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormSelectFormsForBlueprintsQuery, FormSelectFormsForBlueprintsQueryVariables>(FormSelectFormsForBlueprintsQueryDocument, options);
      }
export function useFormSelectFormsForBlueprintsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormSelectFormsForBlueprintsQuery, FormSelectFormsForBlueprintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormSelectFormsForBlueprintsQuery, FormSelectFormsForBlueprintsQueryVariables>(FormSelectFormsForBlueprintsQueryDocument, options);
        }
export function useFormSelectFormsForBlueprintsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FormSelectFormsForBlueprintsQuery, FormSelectFormsForBlueprintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormSelectFormsForBlueprintsQuery, FormSelectFormsForBlueprintsQueryVariables>(FormSelectFormsForBlueprintsQueryDocument, options);
        }
export type FormSelectFormsForBlueprintsQueryHookResult = ReturnType<typeof useFormSelectFormsForBlueprintsQuery>;
export type FormSelectFormsForBlueprintsQueryLazyQueryHookResult = ReturnType<typeof useFormSelectFormsForBlueprintsQueryLazyQuery>;
export type FormSelectFormsForBlueprintsQuerySuspenseQueryHookResult = ReturnType<typeof useFormSelectFormsForBlueprintsQuerySuspenseQuery>;
export type FormSelectFormsForBlueprintsQueryResult = Apollo.QueryResult<FormSelectFormsForBlueprintsQuery, FormSelectFormsForBlueprintsQueryVariables>;